import * as React from "react";
import { Modal } from "antd";
import "../../assets/sass/modal.scss";
import styled from "styled-components";
import warningIcon from "../../assets/images/warning.svg";
import { useSelector } from "react-redux";

interface IModalProps {
  title: string;
  open: boolean;
  onClose: (e: any) => void;
  content: string;
  children: React.ReactNode;
}

const ModalContent = styled.div`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: left;
  color: #2b2b2b;
  flex: 1;
  margin-left: 30px;
  margin-bottom: 10px;

  .subText {
    font-family: Muli;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.21;
    letter-spacing: normal;
    text-align: left;
    color: #2b2b2b;
    margin-top: 10px;
  }
`;

const ModalError: React.FunctionComponent<IModalProps> = (props: IModalProps) => {
  const { children, content, open, onClose, title } = props;
  const { error } = useSelector((state: any) => state.ui);
  const errorMessage = error && error.status && error.status === 404 ? "Error occurred while saving changes" : error;
  return (
    <Modal
      title={title}
      width="439px"
      className="a8-console-modal-error-container"
      visible={open}
      onCancel={onClose}
      footer={null}
    >
      <div style={{ display: "flex", alignItems: "end" }}>
        <img data-testid="warningIcon" src={warningIcon} alt="warning" />
        <ModalContent data-testid="modalContent">
          {content} 404
          <div className="subText" data-testid="subText">
            {errorMessage}
          </div>
        </ModalContent>
      </div>
      <div className="action">{children}</div>
    </Modal>
  );
};

export default ModalError;
