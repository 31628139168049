import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { updateSettingShow } from '../../../actions/uiAction';
import { settingSaveDetails } from '../../../actions/taskAction';
import agentIcon from '../../../assets/images/agent-icon.svg';
import botIcon from '../../../assets/images/a8chat_icon.svg';

class AgentCardList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            roles: props.roles,
            updateSettingShow: false
        };
    }

    onAddOrUpdateDetails = (agent, event: any) => {
        agent = { ...agent, type: "agent" };
        event.preventDefault();
        this.setState({ updateSettingShow: true });
        this.props.settingSaveDetails({ info: agent })
        this.props.updateSettingShow({ updateSettingShow: true });
    }

    async componentDidUpdate() {
    }

    loadGruopDetails = (agentId, groupIds) => {
        let groups = this.props.group.groups;
        let groupCount = 0;
        let groupsList = [];
        let isMore = false;
        let title = [];
        groupIds.map((gId) => {
            return groups && groups.map((group) => {
                if (group._id == gId) {
                    groupCount++;
                    if (groupCount >= 3) {
                        isMore = true;
                        title.push(<Col key={0} span={24} className="item single">{group.name}</Col>);
                    } else if (!isMore) {
                        groupsList.push(<Col key={agentId + "_" + gId} className="group" ><div className="item single ellipsis">{group.name}</div></Col>)
                    }
                }
            })
        });
        if (isMore) {
            groupsList.push(<Col key={0} className="group"><Tooltip placement="rightTop" title={<Row className="more-group">{title}</Row>}><div className="item more">+{(groupIds.length - 2)}</div></Tooltip></Col>);
        }
        return groupsList;
    }

    loadAgentStatus = (record) => {
        if (record.state && record.state.name && record.state.name == "available") {
            return true;
        } else {
            return false;
        }
    }

    render() {
        let agent = this.props.agent;
        return (
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 flex-item" onClick={this.onAddOrUpdateDetails.bind(this, agent)}>
                <div className={'box card-item card-grid-item flex-inner ' + (agent.action ? "action" : "view")}>
                    <div className={agent.action ? "item" : ""} >
                        {
                            agent.action &&
                            <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                <div className="in" onClick={this.onAddOrUpdateDetails.bind(this, agent)} >
                                    <span className="add-new-task">
                                        <span className="icon-add"></span>
                                    </span>
                                </div>
                            </div>
                        }
                        {
                            !agent.action &&
                            <div style={{ margin: "20px" }}>
                                <span className="dot" style={{ background: agent.isActive ? "#46b275" : "#a7a7a7" }}></span>
                                <Row style={{ height: "16px" }}>
                                    <Col span={4}></Col>
                                    <Col span={10}>
                                        {agent.isVirtualAgent && <div className="va">VIRTUAL AGENT</div>}
                                    </Col>
                                    <Col span={10}>
                                        {this.state.roles.includes("admin") && <EditOutlined className="edit" onClick={this.onAddOrUpdateDetails.bind(this, agent)} />}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={4}>
                                        <div style={{
                                            position: "relative",
                                            width: "52px",
                                            height: "52px",
                                        }}>
                                            {!agent.isVirtualAgent && <span className={agent.state && agent.state.name ? agent.state.name : "offline"}></span>}
                                            <img src={agent.isVirtualAgent ? botIcon : agentIcon} style={{
                                                width: "52px",
                                                height: "52px",
                                                borderRadius: "30px"
                                            }} />
                                        </div>
                                    </Col>
                                    <Col span={20}>
                                        <div className="name ellipsis">{agent.name.charAt(0).toUpperCase() + agent.name.slice(1)}</div>
                                        <div className="email ellipsis">{agent.email}</div>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "4px" }} >
                                    <Col span={24}>
                                        <Row>
                                            {this.loadGruopDetails(agent._id, agent.groupIds)}
                                        </Row>
                                    </Col>
                                </Row>

                            </div>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("Agent List mapStateToProps: ", state);
    return {
        auth: state.auth,
        group: state.group
    }
}

export default connect(mapStateToProps, { updateSettingShow, settingSaveDetails })(AgentCardList);
