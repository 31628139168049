import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Row, Col } from 'antd';
import { rootSelectedTab } from "../../actions/uiAction";

const { TabPane } = Tabs;
const resourceMenuList = ["faq", "quickresponse", "keyboardshortcuts", "knowlegdebase"];

class ResourceMenu extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    componentWillMount() {
        let activeRootTab = "quickresponse";
        if (resourceMenuList.indexOf(this.props.ui.activeRootTab) > -1) {
            activeRootTab = this.props.ui.activeRootTab;
        }
        this.setState({
            selectedMenu: activeRootTab
        });
        if (this.props.ui.activeRootTab != null) {
            this.props.rootSelectedTab(activeRootTab);
        }
    }

    onTabChange = (key) => {
        this.props.rootSelectedTab(key)
    }

    render() {
        return (
            <Row className="menu">
                <Col span="1" className="title" style={{ marginRight: "50px" }} >RESOURCES</Col>
                <Col span="19">
                    <Tabs defaultActiveKey={this.state.selectedMenu} onChange={this.onTabChange}>
                        <TabPane tab="Quick Response" key="quickresponse"></TabPane>
                        <TabPane tab="FAQs" key="faq"></TabPane>
                        {/* <TabPane tab="Keyboard Shortcuts" key="keyboardshortcuts" ></TabPane> */}
                        {/* <TabPane tab="Knowlegde Base" key="knowlegdebase" ></TabPane> */}
                    </Tabs>
                </Col>
                <Col span="4"></Col>
            </Row>
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("Resource Menu mapStateToProps: ", state);
    return {
        auth: state.auth,
        ui: state.ui
    }
}

export default connect(mapStateToProps, { rootSelectedTab })(ResourceMenu);
