import * as React from "react";
import styled from "styled-components";
import { Radio, InputNumber, Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateAgentSettings } from "../../../../actions/agentsAction";
import ModalComponent from "../../../common/Modal";
import ModalLoading from "../../../common/ModalLoading";
import ModalSuccess from "../../../common/ModalSuccess";
import ModalError from "../../../common/ModalError";

export interface IChatRoutingProps {
  maximumChat?: number;
  setMaximumChat?: (arg: number) => void;
  queueTimeout?: number;
  setQueueTimeout?: (arg: number) => void;
  message?: string;
  setMessage?: (arg: string) => void;
  msgWaitInterval?: number;
  setMSGWaitInterval?: (arg: number) => void;
}

const Container = styled.div`
  padding: 0 25px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  label.ant-radio-wrapper {
    margin-right: 35px;
  }

  .ant-input-number-handler-wrap {
    width: 12px;

    .anticon-up,
    .anticon-down {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const PageTitle = styled.div`
  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0948b2;
  margin-bottom: ${(props) => (props.theme.marginBottom ? props.theme.marginBottom : "17px")};
`;

const SectionTitle = styled.div`
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #7f90af;
  margin-bottom: 19px;
`;
const InputWrapper = styled.div`
  display: flex;

  label {
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #010f34;
    margin-right: 16px;
  }

  .ant-input-number {
    width: 47px;
  }
`;

const PrimaryButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 3px;
  background-color: #1d7aff;
  border: none;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-right: 15px;
  outline: none !important;
`;

const SecondaryButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 3px;
  background-color: #e6ecff;
  border: none;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #223979;
  text-align: center;
  outline: none !important;
`;

const Chatimit = ({
  maximumChat,
  setMaximumChat,
  queueTimeout,
  setQueueTimeout,
  msgWaitInterval,
  setMSGWaitInterval,
  message,
  setMessage,
}: IChatRoutingProps) => {
  return (
    <div style={{ marginTop: "35px" }}>
      <PageTitle theme={{ marginBottom: "8px" }}>Chat Limit</PageTitle>
      <SectionTitle>Limit the number of chats that each agent</SectionTitle>
      <InputWrapper>
        <label style={{ paddingTop: "2px" }}>Maximum Chat</label>
        <InputNumber value={maximumChat} onChange={(value) => setMaximumChat(value)} size="small" min={1} max={10000} />
      </InputWrapper>
      <div style={{ marginTop: "16px" }}>
        <InputWrapper>
          <label style={{ paddingTop: "2px" }}>Queue Timeout</label>
          <InputNumber
            value={queueTimeout}
            onChange={(value) => setQueueTimeout(value)}
            size="small"
            min={0}
            max={3600}
          />
          <span style={{ fontSize: "10px", color: "#7f90af", padding: "5px 7px" }}>[ minutes ]</span>
        </InputWrapper>
      </div>
    </div>
  );
};

const WaitTimeNotification = ({ message, setMessage, msgWaitInterval, setMSGWaitInterval }: IChatRoutingProps) => {
  return (
    <div style={{ marginTop: "35px" }}>
      <PageTitle theme={{ marginBottom: "8px" }}>Wait Time Notification</PageTitle>
      <SectionTitle>A message sent on intervals to users waiting in the queue</SectionTitle>
      <InputWrapper>
        <label style={{ width: "100px", paddingTop: "2px" }}>Message</label>
        <Input
          style={{ width: "400px" }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          size="small"
          placeholder="You are in the queue. Wait time is approx ${waitTime}"
        />
      </InputWrapper>
      <div style={{ marginTop: "16px" }}>
        <InputWrapper>
          <label style={{ width: "100px", paddingTop: "2px" }}>Interval</label>
          <InputNumber
            value={msgWaitInterval}
            onChange={(value) => setMSGWaitInterval(value)}
            size="small"
            min={0}
            max={3600}
          />
          <span style={{ fontSize: "10px", color: "#7f90af", padding: "5px 7px" }}>[ minutes ]</span>
        </InputWrapper>
      </div>
    </div>
  );
};

const ChatRouting: React.FunctionComponent<IChatRoutingProps> = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openLoadingModal, setOpenLoadingModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const onClose = () => {
    setOpenModal(false);
    setIsLoading(false);
    setOpenSuccessModal(false);
    setOpenErrorModal(false);
  };
  const [selectedChatRouting, setSelectedCharRouting] = React.useState("");
  const [queueTimeout, setQueueTimeout] = React.useState(0);
  const [maximumChat, setMaximumChat] = React.useState(25);
  const [message, setMessage] = React.useState("");
  const [msgWaitInterval, setMSGWaitInterval] = React.useState(25);
  const { agentChatRouting, error } = useSelector((state: any) => state.ui);
  const dispatch = useDispatch();
  const updateSettings = React.useCallback((payload) => dispatch(updateAgentSettings(payload)), [dispatch]);
  const handleSaveChanges = () => {
    setOpenLoadingModal(true);
    setOpenModal(false);
    setIsLoading(true);
    setOpenErrorModal(false);
    if (selectedChatRouting) {
      let payload;
      if (selectedChatRouting === "broadcast") {
        payload = {
          routingAlgorithm: selectedChatRouting,
        };
      } else {
        payload = {
          waitTimeNotification: {
            interval: msgWaitInterval * 60,
            message: message,
          },
          routingAlgorithm: selectedChatRouting,
          maxChatsPerAgent: maximumChat,
          queueTimeout: queueTimeout * 60,
        };
      }
      updateSettings(payload);
    }
  };
  const handleReset = () => {
    // setSelectedCharRouting("");
    setMaximumChat(25);
  };
  React.useEffect(() => {
    if (agentChatRouting) {
      setOpenLoadingModal(false);
      setOpenModal(false);
      if (isLoading) {
        setOpenSuccessModal(true);
      }
      const { routingAlgorithm, maxChatsPerAgent, waitTimeNotification } = agentChatRouting;
      let { queueTimeout } = agentChatRouting;
      let msgWaitInterval = 0;
      let message = "";
      if (waitTimeNotification && waitTimeNotification.interval) {
        msgWaitInterval = waitTimeNotification.interval;
      }
      if (waitTimeNotification && waitTimeNotification.message) {
        message = waitTimeNotification.message;
      }
      queueTimeout = queueTimeout ? queueTimeout : 0;
      setMSGWaitInterval(msgWaitInterval / 60);
      setMessage(message);
      setMaximumChat(maxChatsPerAgent);
      setSelectedCharRouting(routingAlgorithm);
      setQueueTimeout(Math.floor(queueTimeout / 60));
    }
  }, [agentChatRouting]);
  React.useEffect(() => {
    if (error) {
      setOpenLoadingModal(false);
      setOpenModal(false);
      setOpenSuccessModal(false);
      setOpenErrorModal(true);
    }
  }, [error]);
  return (
    <Container>
      <SectionTitle>Choose how agents will receive incoming chat requests.</SectionTitle>
      <Radio.Group onChange={(e) => setSelectedCharRouting(e.target.value)} value={selectedChatRouting}>
        <Radio disabled value={"broadcast"}>
          Broadcast
        </Radio>
        <Radio disabled value={"intelligentRouting"}>
          Intelligent Routing
        </Radio>
      </Radio.Group>
      {selectedChatRouting === "intelligentRouting" && (
        <>
          <Chatimit
            maximumChat={maximumChat}
            setMaximumChat={setMaximumChat}
            queueTimeout={queueTimeout}
            setQueueTimeout={setQueueTimeout}
          />
          <WaitTimeNotification
            msgWaitInterval={msgWaitInterval}
            setMSGWaitInterval={setMSGWaitInterval}
            message={message}
            setMessage={setMessage}
          />
        </>
      )}
      <div style={{ marginTop: "36px" }}>
        <PrimaryButton className="save-changes-btn" onClick={() => setOpenModal(true)}>
          Save Changes
        </PrimaryButton>
        <SecondaryButton className="reset-btn" onClick={handleReset}>
          Reset
        </SecondaryButton>
      </div>
      <ModalComponent
        open={openModal}
        onClose={onClose}
        title="SAVE"
        content="Are you sure, you want to save the changes"
      >
        <Button className="yes" onClick={handleSaveChanges}>
          YES
        </Button>
        <Button className="no" onClick={onClose}>
          NO
        </Button>
      </ModalComponent>
      <ModalLoading open={openLoadingModal} onClose={() => setOpenLoadingModal(false)}>
        Saving your changes…
      </ModalLoading>
      <ModalSuccess open={openSuccessModal} onClose={() => setOpenSuccessModal(false)}>
        Successfully updated your changes
      </ModalSuccess>
      <ModalError title="Error" content="Error Code" open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
        <Button className="try-again" onClick={handleSaveChanges}>
          TRY AGAIN
        </Button>
        <Button className="no" onClick={onClose}>
          CANCEL
        </Button>
      </ModalError>
    </Container>
  );
};

export default ChatRouting;
