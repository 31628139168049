import * as React from "react";
import styled from "styled-components";
import { toCustomDateTimeFormat } from "../../../../helpers/util";
import { SelectedUserContext } from "..";
import { getRandomColor } from "../../../../utils/randomColor";
import HTMLReactParser from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import { updateSelectedLiveChatId } from "../../../../actions/chatsAction";
import { loadClientConversation } from '../../../../actions/uiAction';
import AttachmentIcon from "../../../../assets/images/attachments.svg";
import { CreditCardOutlined } from "@ant-design/icons";
import { LightenDarkenColor } from "../../../../utils/helpers";
import { removeToRead } from "../../../../actions/uiAction";
export interface IUserCardProps {
  message: any;
  name: string;
  id: string;
  isUnread?: boolean;
  clientId: string;
}

const Container = styled.div`
  position: relative;
  min-height: 70px;
  max-height: 100px;
  margin-top: 12px;
  border-bottom: 2px solid ${(props) => props.theme.borderColor};
`;

const Statics = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.background + "40"};
  color: ${(props) => LightenDarkenColor(props.theme.background, 5)};
  border: 1px solid ${(props) => props.theme.background};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

const Name = styled.div`
  flex: 1;
  text-transform: capitalize;
  font-size: 13px;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => props.theme.color};
  font-family: Muli;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  word-break: break-all;
  margin-right: 16px;
`;

const Time = styled.div`
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.color};
`;

const Message = styled.div`
  color: ${(props) => props.theme.color};
  margin: 12px 0;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  word-break: break-all;
  padding-right: ${(props) => (props.theme.isUnread ? "22px" : 0)};
  max-height: 20px;
  overflow: hidden;
`;

const UnreadCircle = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #1d7aff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  position: absolute;
  bottom: 8px;
  right: 0;
`;

const UserCard = React.forwardRef<HTMLDivElement, IUserCardProps>((props: IUserCardProps, ref) => {
  const dispatch = useDispatch()
  const { assignedChats } = useSelector((state: any) => (state.chat))
  const { message, name, id, clientId } = props;
  const { unread: unreadFromUi } = useSelector((state: any) => state.ui);
  const isUnread = unreadFromUi && unreadFromUi.find(chatId => chatId === id) || false;
  const setSelectedId = React.useCallback(() => dispatch(updateSelectedLiveChatId(id, clientId)), [dispatch]);
  const handleClientConversation = React.useCallback(() => dispatch(loadClientConversation(null)), [dispatch]);
  const readMsg = () => {
    dispatch(removeToRead(id));
    setSelectedId();
  }
  React.useEffect(() => {
    if (!selectedLiveChatId) {
      assignedChats && dispatch(updateSelectedLiveChatId(Object.keys(assignedChats)[0]));
    }
  }, []);
  const { theme, chats } = useSelector((state: any) => state.ui);
  const SavedChat = chats[id];
  const getMessage = (type, msg) => {
    if (type === "pdf" || type === "image") {
      return (
        <div>
          <span>
            <img
              style={{
                width: "14px",
                marginRight: "6px",
              }}
              src={AttachmentIcon}
              alt="Attachment"
            />
          </span>
          <span>{msg.desc ? msg.desc.substr(0, 70) : msg.fileName && msg.fileName.substr(0, 70)}</span>{" "}
        </div>
      );
    } else if (type === "text") {
      return HTMLReactParser(msg.text.substr(0, 70));
    } else if (type === "adaptiveCard") {
      const title = msg.title ? msg.title : msg.data ? msg.data.title : "Adaptive Card"
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CreditCardOutlined />
          &nbsp;<span>{title}</span>
        </div>
      );
    } else {
      return "Message";
    }
  };

  const chatLastMsg = message && getMessage(message.msgType, message.msg);
  const { selectedLiveChatId } = useSelector((state: any) => state.chat);
  React.useEffect(() => { handleClientConversation() }, [selectedLiveChatId]);
  const isSelected = selectedLiveChatId === id;
  const hasUnread = SavedChat && SavedChat.messages && SavedChat.messages.unread;
  const borderColor =
    isUnread || (hasUnread && !isSelected) ? "#1d7aff" : isSelected ? theme.secondaryColor : "rgba(168, 171, 189, 0.4)";
  const timeColor = isUnread || hasUnread ? "#010f34" : "#a8abbd";
  const messageColor = isUnread || hasUnread ? "#1d7aff" : "#a8abbd";
  return (
    <Container theme={{ borderColor }} onClick={readMsg} ref={ref}>
      <Statics>
        <Icon theme={{ background: getRandomColor(id) }}>{name[0].toUpperCase()}</Icon>
        <Name theme={{ color: timeColor }} title={name}>{name}</Name>
        <Time theme={{ color: timeColor }}>{message && toCustomDateTimeFormat(message.sentAt)}</Time>
      </Statics>
      <Message theme={{ color: messageColor, isUnread: isUnread || hasUnread }}>{chatLastMsg}</Message>
      {SavedChat && SavedChat.messages ? <UnreadCircle>{SavedChat.messages.unread}</UnreadCircle> : null}
    </Container>
  );
});

export default UserCard;
