import React, { useEffect, useRef } from "react";
import * as AdaptiveCards from "adaptivecards";
import * as ACData from "adaptivecards-templating";
import MarkdownIt from "markdown-it";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import HTMLReactParser from "html-react-parser";
import TextMessage from "../../../ChatMessages/TextMessage";
import { sendChatMessage } from "../../../../../helpers/mqttService";

const decodeEntities = (encodedString: string, maxLimit = 140, shouldTruncate = false) => {
  const md = new MarkdownIt({ html: true });
  if (encodedString) {
    const newStr = encodedString.replace(/\\n/g, "<br />");
    // if (shouldTruncate) {
    //   const caret = newStr.length > maxLimit ? "..." : "";
    //   newStr = newStr.substr(0, maxLimit) + caret;
    // }
    const textArea = document.createElement("textarea");
    textArea.innerHTML = newStr;
    const val = textArea.value;
    const markdownOutput = md.render(val);
    if (shouldTruncate) {
      const caret = markdownOutput.length > maxLimit ? "..." : "";
      return markdownOutput.substr(0, maxLimit) + caret;
    }
    return markdownOutput;
  }
  return encodedString;
};

interface IProps {
  template: any;
  data: any;
  from: any;
  fromName: any;
  isFeedback: boolean;
  id: any;
  type?: string;
  assignChatId?: string
  isPrivate?: boolean
}

const validateFields = (data: any) => {
  for (const i in data) {
    if (data[i] === "") {
      return false;
    }
  }
  return true;
};

function getFormatteData(data: Record<string, any>) {
  const argsOrder: string[] = [];
  const formattedData: Record<string, string> = {};
  Object.keys(data).forEach((key) => {
    const formattedKey = key.replace(/^\d+-/, "");
    if (data[key]) {
      argsOrder.push(formattedKey);
      formattedData[formattedKey] = data[key];
    }
  });
  return formattedData
}


const Title = styled.div`
  padding: 8px 16px;
  font-size: 16px;
  background: white;
  font-weight: 600;
`;

const AdaptiveCard = ({ template, data, from, fromName, isFeedback, id, type, assignChatId, isPrivate  }: IProps) => {
  const activeRootTab = useSelector((state: any) => state.ui.activeRootTab)
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  let state = {};

  const cardTemplate = new ACData.Template(template);
  const md = new MarkdownIt();

  const dataContext: ACData.IEvaluationContext = {
    $root: data,
  };
  dataContext.$root = data;

  const card = cardTemplate.expand(dataContext);
  // const deleteLastLog = useCallback(
  //   () => dispatch(deleteLastLogActionCreator()),
  //   [dispatch]
  // );
  // const updateLastLog = useCallback(
  //   (payload: any) => dispatch(updateSingleLogActionCreator(payload)),
  //   [dispatch]
  // );
  useEffect(() => {
    AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
      result.outputHtml = decodeEntities(text);
      result.didProcess = true;
    };

    const adaptiveCard: any = new AdaptiveCards.AdaptiveCard();

    adaptiveCard.onInputValueChanged = function (e: any) {
      const { id, value } = e;
      state = { ...state, [id]: value };
    };

    adaptiveCard.onExecuteAction = function (e: any) {
      const action = JSON.parse(JSON.stringify(e));
      switch (action.type) {
        case "Action.OpenUrl": {
          const win = window.open(action.url, "_blank");
          win.focus();
          break;
        }
        case "Action.Submit": {
          const { data: submittedData } = e;
          if (validateFields(submittedData)) {
            const updatedAction = {
              ...action,
              type: "adaptiveCardResponse",
              data: getFormatteData(submittedData)
            };
            const responsePayload = {
              msgType: "adaptiveCardResponse",
              msg: {
                ...updatedAction,
              },
            };
            if(assignChatId && activeRootTab) {
              sendChatMessage({
                isPrivate,
                msg: {
                  data: getFormatteData(submittedData)
                },
                chatId: assignChatId,
                msgType: 'adaptiveCardResponse'
              })
            }
            window.parent.postMessage(responsePayload, "*");
          }
        }
        default:
          break;
      }
    };

    adaptiveCard.parse(card);

    if (wrapperRef.current) {
      wrapperRef.current.innerHTML = "";
    }
    adaptiveCard.render(wrapperRef.current);
  }, []);

  return (
    <>
      {type === "carousel" || !data.isForm ? (
        <div className="adative-card_container" style={{ marginBottom: "24px", marginLeft: "auto", maxWidth: "400px" }}>
          <div ref={wrapperRef} />
        </div>
      ) : (
        <TextMessage
          isUser={true}
          fontSize="16px"
          color={false ? "#FFFFFF" : "#212332"}
          backgroundColor={false ? "#2b2b2b" : false ? "#e8e9f1" : "#FBEBE3"}
          message={HTMLReactParser(decodeEntities(data.title || ""))}
          borderRadius="10px 10px 35px 10px"
        />
      )}
    </>
  );
};

export default AdaptiveCard;
