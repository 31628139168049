const randomColors = [
  "#6db1b5",
  "#66a5d1",
  "#ffa340",
  "#408cff",
  "#f26fc8",
  "#796bff",
  "#9f65f7",
  "#4ccfb7",
  "#5dfa52",
  "#05acb0",
  "#595deb",
  "#9ab5ab",
  "#5abf72",
  "#ba9936",
  "#767c91",
  "#f57158",
  "#a35a98",
  "#78d433",
  "#35b84f",
  "#6f73e8",
  "#d4b713",
  "#2da321",
  "#804a5c",
  "#d4d416",
  "#d93b97",
  "#b950c7",
  "#398ea3",
  "#7856c4",
  "#77c963",
  "#239fdb",
  "#a556cc",
  "#7c44d4",
  "#8bc34a",
  "#5491d6",
  "#a258ed",
  "#cf5fb0",
  "#63c772",
  "#5cb88c",
  "#8e55ab",
  "#6fc462",
  "#bf8521",
  "#8c6f8a",
  "#8c6f8a",
  "#1f8245",
  "#473854",
  "#8c3b61",
  "#8f4ea6",
  "#d19d00",
  "#549e4d",
  "#7db82c",
];

export const getRandomColor = (key: string) => {
  let hash = 0;
  for (let i = 0; i < key.length; i++) {
    hash = (hash + key.charCodeAt(i) * i) % 50;
  }
  return randomColors[hash];
};
