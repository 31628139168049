import { combineReducers } from "redux";
import ui from "./uiReducers";
import task from "./taskReducers";
import auth from "./authReducers";
import agent from "./agentReducers";
import group from "./groupReducers";
import faq from "./faqReducers";
import chat from "./chatReducers";
import toast from "./toastReducer";
import theme from "./themeReducer";
import quickResp from "./quickRespReducers";
import credential from "./credentialReducers";
import soloWidget from "./soloWidgetReducer";

export default combineReducers({
  ui,
  task,
  auth,
  agent,
  group,
  faq,
  chat,
  toast,
  theme,
  quickResp,
  credential,
  soloWidget
});
