import * as React from "react";
import styled from "styled-components";
import { FaExpandAlt } from "react-icons/fa";
export interface IMaximizeProps {
  color: string;
  size: string;
}

export default function Maximize(props: IMaximizeProps) {
  return (
    <FaExpandAlt style={{ color: props.color, fontSize: props.size }}></FaExpandAlt>
  );
}
