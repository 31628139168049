import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { decodeEntities, htmlToReactEl } from "./utils";
import { sendChatMessage } from "../../../../helpers/mqttService";
import { useTheme } from "../../../../utils/useTheme";
import TextMessage from "../TextMessage";
import HTMLReactParser from "html-react-parser";
import { TextWrapper } from "..";
import _ from "lodash";

type Props = {
  assignChatId: string
  msg: any
  fromId: string
  fromName: string;
  mId: string;
  isPrivate: boolean
  logs: any[]
}

export function CardChoice(props: Props) {
  const activeRootTab = useSelector((state: any) => state.ui.activeRootTab)
  const theme = useTheme(props.fromId)
  const widgetProps = theme.widget.dimension
  const messageProps = theme.widget.messages
  const globalSettings = theme.widget.messages.globalSettings
  const {
    borderRadius,
    borderTopLeft,
    borderTopRight,
    borderBottomRight,
    borderBottomLeft,
    outlineColor,
    outlineTop,
    outlineRight,
    outlineBottom,
    outlineLeft,
  } = globalSettings["agent"];

  const { msg, logs, assignChatId, isPrivate } = props;
  const isUser = props.fromId.includes("u/");
  const lastMsg = logs[logs.length - 1]

  const shouldShowActions =
    lastMsg && lastMsg.msgType === "cardChoice" && (props.mId === lastMsg.mId) && activeRootTab === 'live';

  const handleOptionSelect = (label: string, description: string, id: any) => {
    sendChatMessage({
      isPrivate,
      chatId: assignChatId,
      msgType: "cardChoiceResponse",
      msg: {
        label,
        description,
        id,
      },
    })
  };

  const containerStyle: React.CSSProperties = {
    width: "350px",
    fontFamily: `${widgetProps.font}`,
    backgroundColor: messageProps.globalSettings.agent.backgroundColor,
    color: messageProps.globalSettings.agent.color,
    // border: messageProps.globalSettings.agent.border,
    borderRadius: messageProps.globalSettings.agent.borderRadius
      ? messageProps.globalSettings.agent.borderRadius
      : `${messageProps.globalSettings.agent.borderTopLeft} ${messageProps.globalSettings.agent.borderTopRight} ${messageProps.globalSettings.agent.borderBottomRight} ${messageProps.globalSettings.agent.borderBottomLeft}`,
  };

  const cardTitleStyle: React.CSSProperties = {
    fontFamily: `${widgetProps.font}`,
    fontSize: messageProps.globalSettings.agent.fontSize ? messageProps.globalSettings.agent.fontSize + "px" : "16px",
    fontWeight: 600,
    marginBottom: "8px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: messageProps.globalSettings.agent.color,
  };
  const cardListStyle: React.CSSProperties = {
    paddingLeft: 0,
    justifyContent: "center",
    alignItems: "center",
    listStyleType: "none",
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: msg.style.orientation === "H" ? 'repeat(2,1fr)' : 'repeat(1,1fr)',
    gridAutoFlow: "unset"
  };
  const cardOptionStyle: React.CSSProperties = {
    display: "flex",
    wordBreak: "break-all",
    justifyContent: "start",
    alignItems: "center",
    width: "100%",
    padding: "5px 20px",
    cursor: "pointer",
    textAlign: "left",
    color: messageProps.cardChoice.agent.optionsFRColor,
    borderRadius: messageProps.cardChoice.agent.optionsRadius + "px",
    boxShadow: messageProps.cardChoice.agent.optionsShadow,
    border: messageProps.cardChoice.agent.optionsBorder,
    background: messageProps.cardChoice.agent.optionsBGColor,
    fontSize: messageProps.cardChoice.agent.optionsFontSize ? messageProps.cardChoice.agent.optionsFontSize + "px" : "15px",
    fontWeight: messageProps.cardChoice.agent.optionsFontWeight ? messageProps.cardChoice.agent.optionsFontWeight : "normal",
    height: "100%",
  };
  const optionHover = (liOption: HTMLLIElement, isFlag: boolean) => {
    if (shouldShowActions && liOption) {
      liOption.style.background = isFlag
        ? messageProps.cardChoice.agent.optionsHRColor
        : messageProps.cardChoice.agent.optionsBGColor;
      liOption.style.color = isFlag
        ? messageProps.cardChoice.agent.optionsTextHRColor
        : messageProps.cardChoice.agent.optionsFRColor;
    }
  };
  const cardOptionValueStyle: React.CSSProperties = {
    display: "block",
  };
  const optionLabelStyle: React.CSSProperties = {
    wordBreak: "break-word"
  };
  const liOptions = React.useRef<HTMLLIElement[]>([]);

  if (!shouldShowActions) {
    const cardChoiceTitle = msg.text ? msg.text : msg.title
    return (
      <TextWrapper>
        <TextMessage
          isUser={!isUser}
          fontSize="16px"
          color="#212332"
          backgroundColor={isUser ? "#e8e9f1" : "#FBEBE3"}
          message={HTMLReactParser(decodeEntities(cardChoiceTitle))}
        />
      </TextWrapper>
    )
  }

  return (
    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
    <div style={containerStyle}>
      <Outline
        theme={{
          outlineColor,
          outlineTop,
          outlineRight,
          outlineBottom,
          outlineLeft,
          shouldShowActions,
        }}
        style={{
          borderRadius: borderRadius
            ? borderRadius
            : `${borderTopLeft} ${borderTopRight} ${borderBottomRight} ${borderBottomLeft}`,
        }}
      >
        <div style={cardTitleStyle}>
          {htmlToReactEl(msg.title)}
        </div>
        <ul style={cardListStyle}>
          {msg.choices.map((choice, index) => {
            const description = choice.description ? choice.description : choice.subText as string
            return (
              <li
                style={cardOptionStyle}
                key={choice.id}
                ref={(el) => {
                  if (liOptions.current && el) {
                    liOptions.current[index] = el
                  }
                }}
                onMouseEnter={() =>
                  optionHover(liOptions.current[index], true)
                }
                onMouseLeave={() =>
                  optionHover(liOptions.current[index], false)
                }
                onClick={() =>
                  handleOptionSelect(
                    choice.label,
                    description,
                    choice.id
                  )
                }
              >
                <span className="option">
                  <span
                    title={decodeEntities(choice.label)}
                  >
                    <div style={optionLabelStyle}>
                      {htmlToReactEl(choice.label)}
                    </div>
                  </span>
                  <span style={cardOptionValueStyle}>
                    {htmlToReactEl(description)}
                  </span>
                </span>
              </li>
            );
          })}
        </ul>
      </Outline>
    </div>
    </div>
  );
};


const Outline = styled.div`
  padding: 16px;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-bottom: ${(props) =>
    (props.theme.outlineBottom ? props.theme.outlineBottom : 0) +
    ` solid ${props.theme.outlineColor}`};
  border-top: ${(props) =>
    (props.theme.outlineTop ? props.theme.outlineTop : 0) +
    ` solid ${props.theme.outlineColor}`};
  border-left: ${(props) =>
    (props.theme.outlineLeft ? props.theme.outlineLeft : 0) +
    ` solid ${props.theme.outlineColor}`};
  border-right: ${(props) =>
    (props.theme.outlineRight ? props.theme.outlineRight : 0) +
    ` solid ${props.theme.outlineColor}`};
`;
