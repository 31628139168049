import React, { ReactElement } from "react";
import styled from "styled-components";
import { decodeEntities } from '../../../../utils/helpers'

interface Props {
  card: any;
  isUser: boolean
}

const Card = styled.div`
  width: 200px;
  min-height: 165px;
  background: transparent;
  box-shadow: 0 2px 4px 0 #e8e9f1;
  border-radius: 0 0 15px 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: 24px;
  transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s,
    background-color 1s;
`;
const CardImageContainer = styled.div`
  width: 100%;
  height: 100px;
`;

const CardContent = styled.div`
  padding: 5px 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const InfoContainer = styled.div`
  flex-grow: 1;
`;

const CardTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212332;
`;

const CardSubtitle = styled.div`
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #2f3245;

  & .primary {
    margin: 5px 0;
  }
`;

export default function CarouselResponse({ card, isUser }: Props): ReactElement {
  const { url } = card;
  const imageUrl = url || "https://via.placeholder.com/350x200";
  return (
    <div style={{
      flexDirection: isUser ? "row" : "row-reverse",
      display: "flex",
    }}>
      <Card>
        <CardImageContainer>
          <div
            onClick={() => {}}
            style={{
              background: `url(${imageUrl}) no-repeat`,
              width: "100%",
              height: "100%",
              borderRadius: "15px 8px 0 0",
              backgroundSize: "cover",
            }}
          />
        </CardImageContainer>
        <CardContent>
          <InfoContainer>
            <CardTitle dangerouslySetInnerHTML={{ __html: decodeEntities(card.title, 30, false) }} />
            <CardSubtitle>
              <div className="primary">
                {card.subtitle.substring(1, 150) + "..."}
              </div>
              <div className="secondary">{card.description}</div>
            </CardSubtitle>
          </InfoContainer>
        </CardContent>
      </Card>
    </div>
  );
}
