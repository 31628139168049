import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Input } from 'antd';
import { Select as A8_SELECT } from "a8-uicomponents";

import '../../../assets/sass/quickResp.sass';

const { Search } = Input;
let sorts = [
    {
        _id: "asc",
        name: "ASC",
    },
    {
        _id: "desc",
        name: "DESC",
    },
];

let selectSortOptions = {
    defaultValue: ["asc"],
    optionValue: "_id",
    title: "name",
    multiSelect: false,
    primaryColor: null,
};


let selectGroupOptions = {
    defaultValue: [],
    optionValue: "_id",
    isSearch: false,
    displayLimit: window.innerWidth > 1700 ? 5 : 3,
    title: "name",
    multiSelect: true,
    placeholder: "Filter By Groups",
    primaryColor: null,
};

class Header extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            search: props.ui.filterSearchQuickResp ? props.ui.filterSearchQuickResp : null,
            groupIds: props.ui.filterGroupQuickResp ? props.ui.filterGroupQuickResp : [],
            sort: props.ui.filterSortQuickResp ? props.ui.filterSortQuickResp : ['asc']
        };
        selectGroupOptions.defaultValue = props.ui.filterGroupQuickResp ? props.ui.filterGroupQuickResp : [];
        selectSortOptions.defaultValue = props.ui.filterSortQuickResp ? props.ui.filterSortQuickResp : ['asc'];
    }

    onAdd = () => {
        if (typeof this.props.onAdd == "function") {
            this.props.onAdd({ action: true });
        }
    }

    onFilterChange = (key, value) => {
        this.setState({ [key]: value }, () => {
            if (typeof this.props.onHandleFilter == "function") {
                this.props.onHandleFilter(this.state);
            }
        });
    };

    componentWillReceiveProps(props) {
        selectGroupOptions.defaultValue = props.ui.filterGroupQuickResp;
    }

    render() {
        let groups = this.props.groups;
        return (
            <div className="header">
                <div onClick={this.onAdd}>
                    <div className="add" onClick={this.onAdd} ><span className="plus">+</span></div>
                    <label>Add Quick Response</label>
                </div>
                <div>
                    <Search
                        defaultValue={this.props.ui.filterSearchQuickResp}
                        style={{ backgroundColor: "#e8e9f1" }}
                        className="option search"
                        placeholder="search text"
                        onSearch={(value) => this.onFilterChange("search", value)}
                        onChange={(e) => this.onFilterChange("search", e.target.value)}
                    />
                </div>
                <div>
                    <div className="option groups">
                        <span className="lab">Groups</span>
                        {groups.length > 0 && <A8_SELECT
                            data={groups}
                            options={selectGroupOptions}
                            onChange={(selectIds) =>
                                this.onFilterChange("groupIds", selectIds)
                            }
                        ></A8_SELECT>}
                    </div>
                </div>
                <div>
                    <div className="option sort">
                        <span style={{ paddingLeft: "18px" }} className="lab">Sort by</span>
                        <A8_SELECT
                            data={sorts}
                            options={selectSortOptions}
                            onChange={(selectIds) =>
                                this.onFilterChange("sort", selectIds)
                            }
                        ></A8_SELECT>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("Resources mapStateToProps: ", state)
    return {
        auth: state.auth,
        ui: state.ui
    }
}

export default connect(mapStateToProps, {})(Header);
