import React, { useContext, useRef, useEffect, useState, Fragment } from "react";
import styled from "styled-components";

import { SelectedChatHistoryMessageContext } from "..";
import { useSelector } from "react-redux";
import ChatMessages from "../../ChatMessages";
import { FiDownloadCloud } from "react-icons/fi";
import {
  getChatTranscript,
  getChatById,
} from "../../../../actions/chatsAction";
import { useComponentVisible, useOnScreen } from "../../../../utils/customHooks";
import Spinner from '../../../common/Spinner'

export interface IMainProps { }

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MessageWrapper = styled.div`
  padding: 20px 0px;
  background: white;
  flex: 1;
  max-height: calc(100vh - 58px - 130px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

const HeaderContainer = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
`;

const NameContainer = styled.p`
  flex: 1;
`;

const DownloadWrapper = styled.div`
  cursor: pointer;
  font-size: 1.6rem;
  transition: font-size 0.5s;
  &:hover {
    font-size: 1.65rem;
  }
`;

export default function Main(props: IMainProps) {
  const [chatMessage, setChatMessage] = useState("");
  const [messagesStartRef, messagesStartVisible] = useOnScreen({ threshold: 0.5 });
  const [chatPosition, setChatPosition] = useState({ position: 0 });
  const [recentMessages, setRecentMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const {
    ref: shortcutRef,
    isComponentVisible: isShortcutVisible,
    setIsComponentVisible: setShortcutVisible,
  } = useComponentVisible(false);
  const downloadChatTranscript = (payload: string) => {
    getChatTranscript(payload)()
      .then((resp) => {
        let csvString = resp.split("\n").join("\n");
        let universalBOM = "\uFEFF";
        let a = window.document.createElement('a');
        a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvString));
        a.setAttribute('download', `${chatId}.csv`);
        window.document.body.appendChild(a);
        a.click();
      })
      .catch((e) => console.log(e));
  };
  const context = useContext(SelectedChatHistoryMessageContext);
  const chats = useSelector((state: any) => state.chat.chatHistory);
  const [conversation, setConversation] = useState([]);
  const messages = (context.selectedChat && chats[context.selectedChat] && chats[context.selectedChat].messages) || [];
  const clientData =
    context.selectedChat && chats[context.selectedChat] && chats[context.selectedChat].clientData;
  const chatId = context.selectedChat && chats[context.selectedChat] && chats[context.selectedChat]._id;
  useEffect(scrollToBottom, [conversation, messages, context.selectedChat]);
  useEffect(() => {
    if (context.selectedChat) {
      setLoading(true);
      getChatById(context.selectedChat)().then((resp) => {
        setConversation(resp.messages);
        const messagesCount = resp.messages.length;
        let position = messagesCount - 50;
        if(position < 0) {
          position = 0;
        }
        setChatPosition({ position });
        setRecentMessages(resp.messages.slice(position))
        setLoading(false);
      });
    }
  }, [context.selectedChat]);

  useEffect(() => {
    if (messagesStartVisible) {
      setChatPosition(prevState => {
        let newPosition = prevState.position - 50;
        if(newPosition < 0) {
          newPosition = 0;
        }
        setRecentMessages(conversation.slice(newPosition))
        const temp = conversation.find((message, index) => index === prevState.position) || ""
        const node = document.getElementById(temp.mId)
        if(node && prevState.position !== 0) {
          node.scrollIntoView();
        }
        return {...prevState, position: newPosition}
      })
    }

  }, [messagesStartVisible]);

  return (
    <Fragment>
      <Spinner loading={loading}/>
      <Container style={{  filter: loading ? "blur(3px)" : "" }}>
        {clientData && clientData.fromName && (
          <HeaderContainer>
            <NameContainer>
              <strong>{clientData.fromName}</strong>
            </NameContainer>
            <DownloadWrapper
              title="Chat Transcript"
              onClick={() => downloadChatTranscript(chatId)}
            >
              <FiDownloadCloud color="#a5a6ae" size="19px" />
            </DownloadWrapper>
          </HeaderContainer>
        )}
        <MessageWrapper>
          <div ref={messagesStartRef as React.LegacyRef<HTMLDivElement>} />
          {Object.keys(chats).length !== 0 && <ChatMessages chatId={chatId} chats={recentMessages} />}
          <div ref={messagesEndRef} />
        </MessageWrapper>
      </Container>
    </Fragment>
  );
}
