import * as React from "react";
import { Collapse, Select, Switch, Input as InputAntd } from "antd";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { ChromePicker } from "react-color";
import ImageUploadIcon from "../../../../../../../../assets/images/image-upload.svg";
import { ThemeContext } from "../../../../../themeDesigner";
import GradientPickerIcon from "../../../../../../../../assets/icons/gradient.svg";
const Panel = Collapse.Panel;

const { Option } = Select;

function callback(key) {
  console.log(key);
}
export interface IGeneralProps { }

interface IColor {
  hex: string;
  rgb: any;
}
export interface IWidgetProperties { }
interface IState {
  ui: IUiTheme;
}

interface IUiTheme {
  theme: ITheme;
}
interface ITheme {
  primaryColor: string;
  secondaryColor: string;
}

const Container = styled.div`
  margin-top: 14px;
`;

const InputWrapper = styled.div`
  height: 58.5px;
  padding: 8px 0 16px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .label {
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    position: absolute;
    right: 3px;
    top: 44%;
    transform: translateY(-50%);
  }
`;

const GeneralPropertyWrapper = styled.div`
  margin-top:10px;
`;

const Input = styled.input`
  /* width: ${(props) => props.theme.width}px; */
  width: ${(props) => props.theme.width || "100%"};
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;
  margin-bottom: 14px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const ImageUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;

  .text {
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
  .text {
    flex: 1;
    color: #7f90af;
  }
`;

const ColorContainerWrapper = styled.div`
  margin-bottom: 14px;
`;

const BackgroundColorContainer = styled.div`
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

const Button = styled.button`
  width: 89px;
  height: 22px;
  border: none;
  border-radius: 2px;
  background-color: #e5e5e5;
  font-family: Muli;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #7f90af;
`;

const Label = styled.label`
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  margin: 14px 0;
`;

const ToggleContainer = styled.div`
  // margin-top: 14px;
`;

const ToggleWrapper = styled.div`
  display: flex;

  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const Span = styled.span`
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #7f90af;
  display: block;

  ${(props) => props.theme.toPad && css`
    display: inline-block;
    padding: 10px 0;
  `}
`

const Textarea = styled.textarea`
  height: 50px;
  width: 100%;
  border-radius: 4px;
  font-size: 13px;
  padding: 0.5rem 1rem;
  border: 1px solid #d3daef;
  color: #010f34;

  &:focus {
    outline: 1px solid ${props => props.theme.outlineColor};
  }
`;

export default function WidgetProperties(props: IWidgetProperties) {
  const { secondaryColor: outlineColor } = useSelector(
    (state: IState) => state.ui.theme
  );
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: { dimension, calloutMessage },
    widgetIcon
  } = themeProps;
  const {
    typesense,
    chatIcon: { icon: agentIcon, userIcon, isVisible: enableChatIcon },
    loading: { url: loadingUrl, size: loadingSize }
  } = dimension;

  const { icon: chatWidgetIcon, size: chatWidgetSize } = widgetIcon;
  const [chatIcon, setChatIcon] = React.useState(false);
  const [chatTypesense, setChatTypesense] = React.useState(false);

  const defaultWelcomeText = 'Hello! How may I assist you?'
  let timeoutValue;
  let text;

  if (!calloutMessage) {
    timeoutValue = 5;
    text = defaultWelcomeText;
  } else {
    timeoutValue = calloutMessage.timeoutValue || 5;
    text = calloutMessage.text || defaultWelcomeText;
  }

  React.useEffect(() => {
    setChatTypesense(typesense);
  }, [typesense]);
  React.useEffect(() => {
    setChatIcon(enableChatIcon);
  }, [enableChatIcon]);

  React.useEffect(() => {
    setWelcomeTimeout(Number(timeoutValue));
  }, [timeoutValue])

  React.useEffect(() => {
    setWelcomeMsg(text);
  }, [text])
  React.useEffect(() => {
    setUserIconUrl(userIcon);
  }, [userIcon])
  React.useEffect(() => {
    setAgentIconUrl(agentIcon);
  }, [agentIcon])

  const [agentIconUrl, setAgentIconUrl] = React.useState(agentIcon);
  const [userIconUrl, setUserIconUrl] = React.useState(userIcon);
  const [widgetIconUrl, setWidgetIconUrl] = React.useState(chatWidgetIcon);
  const [widgetIconSize, setWidgetIconSize] = React.useState(chatWidgetSize);

  const [widgetLoadingUrl, setWidgetLoadingUrl] = React.useState(loadingUrl);
  const [widgetLoadingSize, setWidgetLoadingSize] = React.useState(loadingSize);

  const [welcomeTimeout, setWelcomeTimeout] = React.useState(timeoutValue);
  const [welcomeMsg, setWelcomeMsg] = React.useState(text);

  React.useEffect(() => {
    setWelcomeTimeout(timeoutValue);
  }, [timeoutValue]);

  React.useEffect(() => {
    setWidgetLoadingUrl(loadingUrl);
  }, [loadingUrl]);

  React.useEffect(() => {
    setWidgetLoadingSize(loadingSize);
  }, [loadingSize]);

  React.useEffect(() => {
    setWidgetIconUrl(chatWidgetIcon);
  }, [chatWidgetIcon]);

  React.useEffect(() => {
    setWidgetIconSize(chatWidgetSize);
  }, [chatWidgetSize]);

  // const handleChange = (value: string, property: string) => {
  //   const updatedAvatarProperties = update(themeProps, {

  //     widgetProperties: {
  //       [property]: {
  //         size: { $set: value },
  //       },
  //     },
  //   });
  //   setContainer(updatedAvatarProperties);
  // };
  const [openPicker, setOpenPicker] = React.useState(false);
  const [openSecondaryPicker, setOpenSecondaryPicker] = React.useState(false);
  const [openUserPicker, setOpenUserPicker] = React.useState(false);
  const [openUserSecondaryPicker, setOpenUserSecondaryPicker] = React.useState(false);
  const [color, setColor] = React.useState(dimension.agentColor.hex);
  const [secondaryColor, setSecondaryColor] = React.useState(dimension.userColor.hex);
  const [userTextColor, setUserTextColor] = React.useState(dimension.userTextColor.hex);
  const [agentTextColor, setAgentTextColor] = React.useState(dimension.agentTextColor.hex);
  React.useEffect(() => {
    if (dimension.agentColor.hex !== color) {
      setColor(dimension.agentColor.hex)
    }
    if (dimension.userColor.hex !== secondaryColor) {
      setSecondaryColor(dimension.userColor.hex)
    }
    if (dimension.userTextColor.hex !== userTextColor) {
      setUserTextColor(dimension.userTextColor.hex)
    }
    if (dimension.agentTextColor.hex !== agentTextColor) {
      setAgentTextColor(dimension.agentTextColor.hex)
    }
  }, [dimension])
  const handlePropertyChange = (property: string) => (color: IColor) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        dimension: {
          [property]: {
            hex: { $set: color.hex },
          },
        },
      },
    });
    setThemeProps(updatedGeneralProperties);
  };
  const handleInputBlur = (value: string, property: string) => {
    const updatedAvatarProperties = update(themeProps, {
      widget: {
        dimension: {
          chatIcon: {
            [property]: { $set: value },
          },
        },
      },
    });
    setThemeProps(updatedAvatarProperties);
  };

  const handleWidgetIconBlur = (value: string) => {
    const updatedAvatarProperties = update(themeProps, {
      widgetIcon: {
        icon: { $set: value },
      },
    });
    setThemeProps(updatedAvatarProperties);
  };

  const handleToggleChange = (checked: boolean) => {
    setChatIcon(checked);
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        dimension: {
          chatIcon: {
            isVisible: { $set: checked },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };

  const handleToggleTypesence = (checked: boolean) => {
    setChatTypesense(checked);
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        dimension: {
          typesense: { $set: checked }
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };


  const handleChange = (property: string, value: any) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        dimension: {
          [property]: { $set: value },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };

  const handleChangeWidgetIconSize = (value: string) => {
    if (value !== "custom") {
      const updatedWidgetIconProperties = update(themeProps, {
        widgetIcon: {
          size: { $set: value }
        },
      });
      setThemeProps(updatedWidgetIconProperties);
    }
    setWidgetIconSize(value);
  };

  const handleLoadingUrlBlur = (value: string) => {
    const updatedAvatarProperties = update(themeProps, {
      widget: {
        dimension: {
          loading: {
            url: { $set: value }
          }
        }
      }
    });
    setThemeProps(updatedAvatarProperties);
  };

  const handleChangeLoadingSize = (value: string) => {
    if (value !== "custom") {
      const updatedWidgetIconProperties = update(themeProps, {
        widget: {
          dimension: {
            loading: {
              size: { $set: value }
            }
          }
        }
      });
      setThemeProps(updatedWidgetIconProperties);
    }
    setWidgetLoadingSize(value);
  };

  const handleWelcomeTimeout = () => {
    let timeoutValue = Number(welcomeTimeout)

    if (timeoutValue <= 0 || Number.isNaN(timeoutValue)) {
      timeoutValue = 5;
    }

    const updatedTimeoutValue = update(themeProps, {
      widget: {
        calloutMessage: {
          timeoutValue: { $set: timeoutValue }
        }
      }
    });
    setThemeProps(updatedTimeoutValue);
  }

  const handleWelcomeMsg = () => {
    const updatedWelcomeMsg = update(themeProps, {
      widget: {
        calloutMessage: {
          text: { $set: welcomeMsg || defaultWelcomeText }
        }
      }
    });

    setThemeProps(updatedWelcomeMsg);
  }

  return (
    <Container>
      <Collapse defaultActiveKey={["1"]} onChange={callback}>
        <Panel header="Widget Properties" key="1">
          <ToggleContainer>
            <ToggleWrapper>
              <span className="text">Chat Icon</span>
              <Switch
                size="small"
                checked={chatIcon}
                onChange={(checked) => handleToggleChange(checked)}
              />
            </ToggleWrapper>
          </ToggleContainer>
          <Label>Agent Chat Icon</Label>
          <Input
            theme={{ outlineColor }}
            placeholder="Image URL"
            value={agentIconUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAgentIconUrl(e.target.value)
            }
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputBlur(e.target.value, "icon")
            }
          />
          {/* <DropdownWrapper>
            <span className="text">Size</span>
            <Select style={{ width: 161, height: 28 }} size="small">
              <Option value="31">Small</Option>
              <Option value="40">Medium</Option>
              <Option value="50">Large</Option>
            </Select>
          </DropdownWrapper>
          <ImageUploadWrapper>
            <span className="text">Image</span>
            <img src={ImageUploadIcon} alt={"upload"} />
            <Button>Upload Image</Button>
          </ImageUploadWrapper> */}

          <br></br>

          <span>Display Name In Icon</span>

          <br></br>

          <InputWrapper>
            <div style={{ position: "relative" }}>
              <span className="label" title="Width">
                W
              </span>
              <Input style={{ width: "100px" }}
                placeholder="100%"
                value={dimension.agentWidth}
                onChange={(e) => handleChange("agentWidth", e.target.value)}
                theme={{ width: 85 }}
              />
            </div>
            <div style={{ position: "relative" }}>
              <span className="label" title="Height">
                H
              </span>
              <Input style={{ width: "100px" }}
                placeholder="45px"
                value={dimension.agentHeight}
                onChange={(e) => handleChange("agentHeight", e.target.value)}
                theme={{ width: 85 }}
              />
            </div>
          </InputWrapper>
          <InputWrapper marginBottom="20px">
            <div style={{ position: "relative" }}>
              <span>Border Radius</span>
              <Input
                placeholder="18px 0px 18px 0px"
                value={dimension.borderRadiusAgent}
                onChange={(e) => handleChange("borderRadiusAgent", e.target.value)}
                theme={{ width: 85 }}
              />
            </div>
          </InputWrapper>
          <ColorContainerWrapper>
            <BackgroundColorContainer>
              <span className="text">Icon Color</span>
              <DisplayColor
                theme={{ backgroundColor: color }}
                className="picker"
                onClick={() => {
                  setOpenPicker(!openPicker);
                  setOpenSecondaryPicker(false);
                }}
              />
              {openPicker && (
                <div className="color-picker">
                  <ChromePicker
                    color={color}
                    onChange={(color: IColor) => setColor(color.hex)}
                    onChangeComplete={handlePropertyChange("agentColor")}
                  />
                </div>
              )}
              <GradientPickerWrapper>
                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
              </GradientPickerWrapper>
            </BackgroundColorContainer>
          </ColorContainerWrapper>

          <ColorContainerWrapper>
            <BackgroundColorContainer>
              <span className="text">Text Color</span>
              <DisplayColor
                theme={{ backgroundColor: agentTextColor }}
                className="picker"
                onClick={() => {
                  setOpenSecondaryPicker(!openSecondaryPicker);
                  setOpenPicker(false);
                }}
              />
              {openSecondaryPicker && (
                <div className="color-picker">
                  <ChromePicker
                    color={agentTextColor}
                    onChange={(agentTextColor: IColor) => setAgentTextColor(agentTextColor.hex)}
                    onChangeComplete={handlePropertyChange("agentTextColor")}
                  />
                </div>
              )}
              <GradientPickerWrapper>
                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
              </GradientPickerWrapper>
            </BackgroundColorContainer>
          </ColorContainerWrapper>


          <Label>User Chat Icon</Label>
          <Input
            theme={{ outlineColor }}
            placeholder="Image URL"
            value={userIconUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUserIconUrl(e.target.value)
            }
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputBlur(e.target.value, "userIcon")
            }
          />
          {/* <DropdownWrapper>
            <span className="text">Size</span>
            <Select style={{ width: 161, height: 28 }} size="small">
              <Option value="31">Small</Option>
              <Option value="40">Medium</Option>
              <Option value="50">Large</Option>
            </Select>
          </DropdownWrapper>
          <ImageUploadWrapper>
            <span className="text">Image</span>
            <img src={ImageUploadIcon} alt={"upload"} />
            <Button>Upload Image</Button>
          </ImageUploadWrapper> */}

          <br></br>

          <span>Dispaly Name In Icon</span>

          <br></br>
          <InputWrapper>
            <div style={{ position: "relative" }}>
              <span className="label" title="Width">
                W
              </span>
              <Input style={{ width: "100px" }}
                placeholder="100%"
                value={dimension.userWidth}
                onChange={(e) => handleChange("userWidth", e.target.value)}
                theme={{ width: 85 }}
              />
            </div>
            <div style={{ position: "relative" }}>
              <span className="label" title="Height">
                H
              </span>
              <Input style={{ width: "100px" }}
                placeholder="45px"
                value={dimension.userHeight}
                onChange={(e) => handleChange("userHeight", e.target.value)}
                theme={{ width: 85 }}
              />
            </div>
          </InputWrapper>
          <InputWrapper>
            <div style={{ position: "relative" }}>
              <span>Border Radius</span>
              <Input
                placeholder="18px 0px 18px 0px"
                value={dimension.borderRadiusUser}
                onChange={(e) => handleChange("borderRadiusUser", e.target.value)}
                theme={{ width: 85 }}
              />
            </div>
          </InputWrapper>

          <ColorContainerWrapper>
            <BackgroundColorContainer>
              <span className="text">Icon Color</span>
              <DisplayColor
                theme={{ backgroundColor: secondaryColor }}
                className="picker"
                onClick={() => {
                  setOpenUserPicker(!openUserPicker);
                  setOpenUserSecondaryPicker(false);
                }}
              />
              {openUserPicker && (
                <div className="color-picker">
                  <ChromePicker
                    color={secondaryColor}
                    onChange={(secondaryColor: IColor) => setSecondaryColor(secondaryColor.hex)}
                    onChangeComplete={handlePropertyChange("userColor")}
                  />
                </div>
              )}
              <GradientPickerWrapper>
                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
              </GradientPickerWrapper>
            </BackgroundColorContainer>
          </ColorContainerWrapper>
          <hr />

          <ColorContainerWrapper>
            <BackgroundColorContainer>
              <span className="text">Text Color</span>
              <DisplayColor
                theme={{ backgroundColor: userTextColor }}
                className="picker"
                onClick={() => {
                  setOpenUserSecondaryPicker(!openUserSecondaryPicker);
                  setOpenUserPicker(false);
                }}
              />
              {openUserSecondaryPicker && (
                <div className="color-picker">
                  <ChromePicker
                    color={userTextColor}
                    onChange={(userTextColor: IColor) => setUserTextColor(userTextColor.hex)}
                    onChangeComplete={handlePropertyChange("userTextColor")}
                  />
                </div>
              )}
              <GradientPickerWrapper>
                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
              </GradientPickerWrapper>
            </BackgroundColorContainer>
          </ColorContainerWrapper>

          <Label>Widget Icon</Label>
          <Input
            theme={{ outlineColor }}
            placeholder="Image URL"
            value={widgetIconUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setWidgetIconUrl(e.target.value)
            }
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleWidgetIconBlur(e.target.value)
            }
          />
          <DropdownWrapper>
            <span className="text">Size</span>
            <Select
              style={{ width: 161, height: 28 }}
              onChange={handleChangeWidgetIconSize}
              size="small"
              value={widgetIconSize}
            >
              <Option value="31">Small</Option>
              <Option value="40">Medium</Option>
              <Option value="50">Large</Option>
              <Option value="custom">Custom</Option>
            </Select>
          </DropdownWrapper>
          {widgetIconSize === "custom" && (
            <InputContainer>
              <span className="text">Height</span>
              <InputAntd
                style={{ width: "88px" }}
                maxLength={2}
                type="number"
                placeholder="60"
                suffix={"px"}
                defaultValue={chatWidgetSize}
                onBlur={(e) => { handleChangeWidgetIconSize(e.target.value) }}
              />
            </InputContainer>
          )}
          <ImageUploadWrapper>
            <span className="text">Image</span>
            <img src={ImageUploadIcon} alt={"upload"} />
            <Button>Upload Image</Button>
          </ImageUploadWrapper>

          <GeneralPropertyWrapper>
            <Span>Position</Span>
            <InputWrapper>
              <div style={{ position: "relative" }}>
                <span className="label" title="Bottom">B</span>
                <Input
                  value={dimension.bottom}
                  placeholder="20px"
                  onChange={(e) => handleChange("bottom", e.target.value)}
                  theme={{ width: "85px" }}
                />
              </div>
              <div style={{ position: "relative" }}>
                <span className="label" title="Right">R</span>
                <Input
                  placeholder="20px"
                  value={dimension.right}
                  onChange={(e) => handleChange("right", e.target.value)}
                  theme={{ width: "85px" }}
                />
              </div>
            </InputWrapper>
          </GeneralPropertyWrapper>
          <hr />

          <Label>Loading Icon</Label>
          <Input
            theme={{ outlineColor }}
            placeholder="Image URL"
            value={widgetLoadingUrl}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setWidgetLoadingUrl(e.target.value)
            }
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleLoadingUrlBlur(e.target.value)
            }
          />
          <DropdownWrapper>
            <span className="text">Size</span>
            <Select
              defaultValue={widgetLoadingSize}
              style={{ width: 161, height: 28 }}
              onChange={handleChangeLoadingSize}
              size="small"
              value={widgetLoadingSize}
            >
              <Option value="31">Small</Option>
              <Option value="40">Medium</Option>
              <Option value="50">Large</Option>
              <Option value="custom">Custom</Option>
            </Select>
          </DropdownWrapper>
          {widgetLoadingSize === "custom" && (
            <InputContainer>
              <span className="text">Height</span>
              <InputAntd
                style={{ width: "88px" }}
                maxLength={2}
                type="number"
                placeholder="60"
                suffix={"px"}
                defaultValue={loadingSize}
                onBlur={(e) => { handleChangeLoadingSize(e.target.value) }}
              />
            </InputContainer>
          )}
          <hr />

          <Label>Callout Message</Label>
          <Span>Timeout Value</Span>
          <InputWrapper style={{ marginBottom: "0px" }}>
            <div style={{ position: "relative" }}>
              <span className="label" title="Timeout Value">
                Seconds
              </span>
              <Input
                value={welcomeTimeout}
                placeholder="1"
                onChange={e => setWelcomeTimeout(e.target.value)}
                onBlur={handleWelcomeTimeout}
                theme={{ width: "85px" }}
              />
            </div>
          </InputWrapper>
          <Span theme={{ toPad: true }}>Welcome Message</Span>
          <Textarea
            placeholder="welcome message"
            value={welcomeMsg}
            onChange={e => setWelcomeMsg(e.target.value)}
            onBlur={handleWelcomeMsg}
          />
          <hr />

          <ToggleContainer>
            <ToggleWrapper>
              <span className="text">Type Sense</span>
              <Switch
                size="small"
                checked={chatTypesense}
                onChange={(checked) => handleToggleTypesence(checked)}
              />
            </ToggleWrapper>
          </ToggleContainer>
        </Panel>
      </Collapse>
    </Container>
  );
}
