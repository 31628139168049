import type from "../types/actionTypes";
import update from "immutability-helper";

const initialState = {
  data: [],
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.TOAST_UPDATE:
      return update(state, {
        data: { $set: action.data },
      });
    case type.TOAST_ERROR:
      return update(state, {
        error: { $set: action.data },
      });
    default:
      return state;
  }
};
