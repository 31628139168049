import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { ChromePicker } from "react-color";

import GradientPickerIcon from "../../../../../../../assets/icons/gradient.svg";
import { IState, ThemeContext } from "../../../../themeDesigner";

export interface ITextProps {
  msgType: string;
}

const GeneralPropertyWrapper = styled.div`
  padding: 0 15px;
`;

const BackgroundColorContainer = styled.div`
  height: 59px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #aaa;
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const Input = styled.input`
  width: ${(props) => props.theme.width}px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }
`;

const AllWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 14px 0px;

  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const MarginWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.marginBottom}px;
  justify-content: space-between;
  margin: 10px 0px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

export default function Text(props: ITextProps) {
  const { secondaryColor: outlineColor } = useSelector((state: IState) => state.ui.theme);
  // const [openBGPicker, setOpenBGPicker] = React.useState(false);
  // const [openFRPicker, setOpenFRPicker] = React.useState(false);
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: { messages },
  } = themeProps;
  // const [bgColor, setBGColor] = React.useState(messages["text"][props.msgType].background);
  // const [frColor, setFRColor] = React.useState(messages["text"][props.msgType].color);

  const handleChange = (value: string, property: string) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        messages: {
          text: {
            [props.msgType]: {
              [property]: { $set: value },
            },
          },
        },
      },
    });
    setThemeProps(updatedGeneralProperties);
  };

  // const handleBackgroundColorChange = (color) => {
  //     const updatedGeneralProperties = update(themeProps, {
  //         widget: {
  //             messages: {
  //                 text: {
  //                     [props.msgType]: {
  //                         "backgroundColor": { $set: color.hex }
  //                     }
  //                 },
  //             },
  //         },
  //     });
  //     setThemeProps(updatedGeneralProperties);
  // };

  // const handleForeColorChange = (color) => {
  //     const updatedGeneralProperties = update(themeProps, {
  //         widget: {
  //             messages: {
  //                 text: {
  //                     [props.msgType]: {
  //                         "color": { $set: color.hex }
  //                     }
  //                 },
  //             },
  //         },
  //     });
  //     setThemeProps(updatedGeneralProperties);
  // }

  return (
    <GeneralPropertyWrapper>
      {/* <BackgroundColorContainer>
                <span className="text">Background Color</span>
                <DisplayColor
                    theme={{ backgroundColor: bgColor }}
                    className="picker"
                    onClick={() => setOpenBGPicker(!openBGPicker)}
                />
                {openBGPicker && (
                    <div className="color-picker">
                        <ChromePicker
                            color={bgColor}
                            onChange={(color: any) => setBGColor(color.hex)}
                            onChangeComplete={handleBackgroundColorChange}
                        />
                    </div>
                )}
                <GradientPickerWrapper>
                    <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                </GradientPickerWrapper>
            </BackgroundColorContainer>
            <BackgroundColorContainer>
                <span className="text">Foreground Color</span>
                <DisplayColor
                    theme={{ backgroundColor: frColor }}
                    className="picker"
                    onClick={() => setOpenFRPicker(!openFRPicker)}
                />
                {openFRPicker && (
                    <div className="color-picker">
                        <ChromePicker
                            color={frColor}
                            onChange={(color: any) => setFRColor(color.hex)}
                            onChangeComplete={handleForeColorChange}
                        />
                    </div>
                )}
                <GradientPickerWrapper>
                    <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                </GradientPickerWrapper>
            </BackgroundColorContainer> */}
      <AllWrapper>
        <span className="text">Padding</span>
        <Input
          theme={{ outlineColor, width: 147 }}
          value={messages["text"][props.msgType].padding}
          onChange={(e) => handleChange(e.target.value, "padding")}
        />
      </AllWrapper>
      <MarginWrapper theme={{ marginBottom: 0 }}>
        <ColumnWrapper>
          <label>Right</label>
          <Input
            theme={{ outlineColor, width: 45 }}
            value={messages["text"][props.msgType].paddingRight}
            onChange={(e) => handleChange(e.target.value, "paddingRight")}
          />
        </ColumnWrapper>
        <ColumnWrapper>
          <label>Left</label>
          <Input
            theme={{ outlineColor, width: 45 }}
            value={messages["text"][props.msgType].paddingLeft}
            onChange={(e) => handleChange(e.target.value, "paddingLeft")}
          />
        </ColumnWrapper>
        <ColumnWrapper>
          <label>Top</label>
          <Input
            theme={{ outlineColor, width: 45 }}
            value={messages["text"][props.msgType].paddingTop}
            onChange={(e) => handleChange(e.target.value, "paddingTop")}
          />
        </ColumnWrapper>
        <ColumnWrapper>
          <label>Bottom</label>
          <Input
            theme={{ outlineColor, width: 45 }}
            value={messages["text"][props.msgType].paddingBottom}
            onChange={(e) => handleChange(e.target.value, "paddingBottom")}
          />
        </ColumnWrapper>
      </MarginWrapper>
    </GeneralPropertyWrapper>
  );
}
