import * as React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import update from "immutability-helper";

import GradientPickerIcon from "../../../../../../../assets/icons/gradient.svg";
import { IState, ThemeContext } from "../../../../themeDesigner";
import { ChromePicker } from "react-color";
import { Switch } from "antd";

export interface IInputGeneralProps { }
interface IColor {
  hex: string;
  rgb: any;
}

interface Itoggle {
  id: number;
  label: string;
  name: string;
  checked?: boolean;
}

const GeneralPropertyWrapper = styled.div`
  padding: 14px 15px;
`;

const Input = styled.input`
  width: ${(props) => props.theme.width}px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacingproperties: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }

  &::placeholder {
    font-family: Muli;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #ccd0dd;
  }
`;

const AllWrapper = styled.div`
  margin-bottom: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d3daef;

  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    margin-bottom: 5px;
  }
`;

const BackgroundColorContainer = styled.div`
  height: 39px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

const ToggleContainer = styled.div`
  margin-top: 14px;
`;

const ToggleWrapper = styled.div`
  display: flex;

  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

export default function InputGeneral(props: IInputGeneralProps) {
  const { secondaryColor: outlineColor } = useSelector(
    (state: IState) => state.ui.theme
  );
  const [openBackgroundPicker, setOpenBackgroundPicker] = React.useState(false);
  const [openTextColorPicker, setOpenTextColorPicker] = React.useState(false);

  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: { userInput },
  } = themeProps;
  const [backgroundColor, setBackgroundColor] = React.useState(
    userInput.background.hex
  );
  const [textColor, setTextColor] = React.useState(userInput.color.hex);
  const handleChange = (placeholder: string) => {
    const updatedInput = update(themeProps, {
      widget: {
        userInput: {
          placeholder: { $set: placeholder },
        },
      },
    });
    setThemeProps(updatedInput);
  };
  const handleColorChange = (property: string) => (color: IColor) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        userInput: {
          [property]: {
            hex: { $set: color.hex },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };
  const handleToggleChange = (checked: boolean, label: string) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        userInput: {
          [label]: {
            isVisible: { $set: checked },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };
  const toggleIconsList: Itoggle[] = [
    {
      id: 1,
      label: "Mic",
      name: "mic",
      checked: userInput.mic.isVisible,
    },
    {
      id: 2,
      label: "Text to Speech",
      name: "textToSpeech",
      checked: userInput.textToSpeech.isVisible,
    },
    {
      id: 3,
      label: "Notes",
      name: "note",
      checked: userInput.note.isVisible,
    },
    {
      id: 4,
      label: "Attachment",
      name: "attachment",
      checked: userInput.attachment.isVisible,
    },
    {
      id: 5,
      label: "Send Button",
      name: "sendIcon",
      checked: userInput.sendIcon && userInput.sendIcon.isVisible ? userInput.sendIcon.isVisible : false,
    }, {
      id: 6,
      label: "Keyboard",
      name: "keyboard",
      checked: userInput.keyboard.isVisible,
    },
  ];
  return (
    <GeneralPropertyWrapper>
      <AllWrapper>
        <span className="text">Placeholder</span>
        <Input
          theme={{ outlineColor, width: 227 }}
          placeholder="1px solid black"
          value={userInput.placeholder}
          onChange={(e) => handleChange(e.target.value)}
        />
      </AllWrapper>
      <BackgroundColorContainer>
        <span className="text">Background Color</span>
        <DisplayColor
          theme={{ backgroundColor }}
          className="picker"
          onClick={() => setOpenBackgroundPicker(!openBackgroundPicker)}
        />
        {openBackgroundPicker && (
          <div className="color-picker">
            <ChromePicker
              color={backgroundColor}
              onChange={(color: IColor) => setBackgroundColor(color.hex)}
              onChangeComplete={handleColorChange("background")}
            />
          </div>
        )}
        <GradientPickerWrapper>
          <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
        </GradientPickerWrapper>
      </BackgroundColorContainer>
      <BackgroundColorContainer>
        <span className="text">Text Color</span>
        <DisplayColor
          theme={{ backgroundColor: textColor }}
          className="picker"
          onClick={() => setOpenTextColorPicker(!openTextColorPicker)}
        />
        {openTextColorPicker && (
          <div className="color-picker">
            <ChromePicker
              color={textColor}
              onChange={(color: IColor) => setTextColor(color.hex)}
              onChangeComplete={handleColorChange("color")}
            />
          </div>
        )}
        <GradientPickerWrapper>
          <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
        </GradientPickerWrapper>
      </BackgroundColorContainer>
      {toggleIconsList.map((item: Itoggle) => (
        <ToggleContainer key={item.id}>
          <ToggleWrapper>
            <span className="text">{item.label}</span>
            <Switch
              size="small"
              defaultChecked={item.checked}
              onChange={(checked) => handleToggleChange(checked, item.name)}
            />
          </ToggleWrapper>
        </ToggleContainer>
      ))}
    </GeneralPropertyWrapper>
  );
}
