import type from "../types/actionTypes";
import axios from "axios";
import config from "../apiConfig";
import { store } from "../store";
import { notification } from "antd";

const actionNotification = (notificationType, title, message) => {
  notification[notificationType]({
    message: title,
    description: message,
  });
  setTimeout(function () {
    notification.close();
  }, 1000);
};

export function getGroups() {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    try {
      let response = await axios(
        `${config.baseUrl}${config.org}${config.v1}${config.groupsUrl}`,
        options
      );
      dispatch({
        type: type.GROUPS_SUCCESS,
        data: response.data,
      });
      return response.data;
    } catch (e) {
      console.error(e);
    }
  };
}

export function createGroup(data, originalData) {
  const options = {
    method: "POST",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    axios(
      `${config.baseUrl}${config.org}${config.v1}${config.groupsUrl}`,
      options
    )
      .then(function (resp) {
        let response = resp;
        dispatch({
          type: type.GROUP_SAVE_SUCCESS,
          data: response.data,
        });
        actionNotification(
          "info",
          "NEW GROUP CREATED",
          "Group successfully created"
        );
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          Object.assign(originalData, { error: error.response.data.message });
          dispatch({
            type: type.GROUP_ERROR,
            data: { settingSaveDetails: originalData },
          });
        }
      });
  };
}

export function updateGroup(data, groupId, originalData) {
  const options = {
    method: "PUT",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    await axios(
      `${config.baseUrl}${config.org}${
      config.v1
      }${config.updateGroupUrl.replace("{groupId}", groupId)}`,
      options
    )
      .then(function (response) {
        dispatch({
          type: type.GROUP_SAVE_SUCCESS,
          data: response.data,
        });
        actionNotification(
          "info",
          "GROUP UPDATED",
          `Group ${data.name} updated successfully`
        );
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          Object.assign(originalData, { error: error.response.data.message });
          dispatch({
            type: type.GROUP_ERROR,
            data: { settingSaveDetails: originalData },
          });
        }
      });
  };
}

export function updateAssignedAgents(data, groupId, originalData) {
  const options = {
    method: "POST",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    await axios(
      `${config.baseUrl}${config.org}${
      config.v1
      }${config.updateAssignedAgentsUrl.replace("{groupId}", groupId)}`,
      options
    )
      .then(function (resp) {
        // let response = resp;
        // dispatch({
        //     type: type.GROUP_SAVE_SUCCESS,
        //     data: response.data
        // });
      })
      .catch(function (error) {
        // dispatch({
        //     type: type.GROUP_CREATE_ERROR,
        //     data: { settingSaveDetails: originalData, message: error.message }
        // });
      });
  };
}

export function updateUnassignedAgents(data, groupId, originalData) {
  const options = {
    method: "POST",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    await axios(
      `${config.baseUrl}${config.org}${
      config.v1
      }${config.updateUnassignedAgentsUrl.replace("{groupId}", groupId)}`,
      options
    )
      .then(function (resp) {
        let response = resp;
        dispatch({
          type: type.GROUP_SAVE_SUCCESS,
          data: response.data,
        });
      })
      .catch(function (error) {
        // dispatch({
        //     type: type.GROUP_CREATE_ERROR,
        //     data: { settingSaveDetails: originalData, message: error.message }
        // });
      });
  };
}
