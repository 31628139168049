import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components"
import { CheckOutlined } from "@ant-design/icons";
import { getAPICredentials, getAPIScopes, generateToken, removeToken } from "../../../../actions/credentialAction";
import { clearRemoveTokenSuccess, clearGenerateTokenSuccess } from "../../../../actions/taskAction";
import ModalLoading from "../../../common/ModalLoading";
import SearchIcn from "../../../../assets/images/ico-search.svg";
import DelectIcn from "../../../../assets/images/delete-icon.svg";
import KeyIcon from "../../../../assets/images/key.svg";
import CopyEnableIcon from "../../../../assets/images/copyClipboard_enabled.svg";
import CopyIcon from "../../../../assets/images/copyClipboard.svg";
import moment from "moment";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export interface IReasonCodesProps { }

const Container = styled.div`
  padding: 0 25px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`;

const Panel = styled.div`
  /* min-height: 371px; */
  margin: 36px;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 12px 0 rgba(215, 217, 229, 0.5);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 100%; */
`;

const Apis = styled.div`
  width: 100%;
`;

const SelectAPIs = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 54px;
  padding: 0px 6px;
  border-radius: 5px;
  border: solid 1px #d7d9e5;
  img {
    width: 14px;
    height: 14px;
    margin-top: 20px;
    margin-left: 16px;
  }
  input, input:focus {
    border: 1px solid #fff;
    margin-left: 10px;
    padding: 0px 6px;
    outline-color: #fff;
  }
`;

const Select = styled.div`
    width: 100%;
    padding: 4px 14px;
    display: flex;
    flex-wrap: wrap;
`;

const SelectedValue = styled.div`
    margin: 8px 2px;
    border-radius: 30px;
    background-color: ${(props) => props.theme.bgColor}2b;
    width: auto;
    padding: 0px 12px;
    display: flex;
    img {
      margin-top: 0px;
      margin-left: 12px;
    }
    height: 26px;
`;

const Text = styled.div`
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.bgColor};
  padding: 5px 0px;
`;

const Close = styled.div`
  cursor: pointer;
`;

const Options = styled.div`
  padding: 0px 10px;
`;

const Option = styled.div`
  display: flex;
  cursor: pointer;
`;

const List = styled.div`
  position: relative;
  margin: 1px 0 0;
  padding: 14px 0 17px;
  border-radius: 5px;
  box-shadow: 0 0 12px 0 #d7d9e5;
  border: solid 1px #d7d9e5;
  background-color: #ffffff;
`;


const CheckBox = styled.div`
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.borderColor};
  background-color: ${(props) => props.theme.bgColor};
  width: 20px;
  height: 20px;
`;

const Label = styled.div`
  margin: 1px 0 1px 12px;
  font-family: Muli;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #212332;
`;

const HDivider = styled.div`
  margin: 13px 0px;
  width: 100%;
  height: 1px;
  background: #dee5f49c;
`;

const VDivider = styled.div`
  width: 1px;
  height: 100%;
  background: #dee5f49c;
`;

const GenerateToken = styled.div`
  height: 44px;
  padding: 14px 42px 8px 45px;
  border-radius: 6px;
  box-shadow: 0 0 12px 0 ${(props) => props.theme.bgColor}2b;
  background-color: ${(props) => props.theme.bgColor};
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  width: 372px;
  margin: 30px 0px;
`;

const Action = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 16px;
  font-family: Muli;
  font-weight: 900;
  margin-bottom: 8px;
`;

const Remove = styled.div`
  label {
    cursor: pointer;
    color: #ff3a3a;
    padding-top: 14px;
    padding-right: 10px
  }
`;

const AccessToken = styled.div`
  height: 54px;
  margin-bottom: 8px;
  padding: 0 16px 0 0;
  border-radius: 5px;
  border: solid 1px #d7d9e5;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
    height: auto;
  }  
`;

const Token = styled.div`
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.fgColor};
  overflow: hidden;
  width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TokenList = styled.div`
  /* height: ${window.innerHeight - 100}+'px'; */
  margin: 0px 36px;
  /* overflow-x: auto; */
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TokenDetail = styled.div`
  border-radius: 10px;
  box-shadow: 0 0 12px 0 rgba(215, 217, 229, 0.5);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 5px;
  border: solid 1px #d7d9e5;
  padding: 16px;
  margin-right: 4px;
`;

const Scope = styled.div`
  display: flex;
  margin-bottom: 12px;
  label {
    padding-top: 9px;
    margin-right: 12px;
  }
`;

const Exp = styled.div`
  font-size: 10px;
`;

const NoData = styled.div`
  font-size: 10px;
  margin-left: 18px;
  padding-top: 4px;
`;

const Copied = styled.div`
  label{
    text-transform: capitalize;
  }
`;

let apiTokenList = null;
class Credentials extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      noDataFound: false,
      removeTokenProcess: false,
      removeTokenSuccess: false,
      copied: false,
      newgenerateToken: null,
      searchAPI: "",
      selectOpen: false,
      apiList: [],
      selectedAPIs: [],
      apiTokenList: []
    };
    this.initialLoad();
  }

  async initialLoad() {
    apiTokenList = await this.props.getAPICredentials();
    if (apiTokenList && apiTokenList.scoped_tokens) {
      this.setState({
        apiTokenList: apiTokenList.scoped_tokens,
        searchAPI: "",
        selectedAPIs: [],
        copied: false,
        newgenerateToken: null,
        removeTokenProcess: false,
        removeTokenSuccess: false,
      });
    }

    let apiList = await this.props.getAPIScopes();
    if (apiList && apiList.apiScopes) {
      this.setState({
        apiScopes: apiList.apiScopes,
        removeTokenSuccess: false,
        apiList: apiList.apiScopes
      });
    }
  }

  onSelectAPIs = (e, item) => {
    e.stopPropagation();
    let { selectedAPIs } = this.state;
    let position = selectedAPIs.indexOf(item);
    if (position < 0) {
      selectedAPIs.push(item)
    } else {
      selectedAPIs.splice(position, 1);
    }
    this.setState({
      searchAPI: "",
      selectedAPIs
    })
  }

  generateToken = () => {
    if (this.state.newgenerateToken) {
      this.props.clearGenerateTokenSuccess();
      this.setState({
        searchAPI: "",
        selectedAPIs: [],
        newgenerateToken: null
      });
      this.initialLoad();
    } else if (this.state.selectedAPIs.length > 0) {
      this.props.generateToken({ apiScopes: this.state.selectedAPIs });
    }
  }

  removeAccess = (accessToken) => {
    this.setState({
      removeTokenProcess: true
    })
    this.props.removeToken({
      "token": accessToken
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnObj = {};
    if (nextProps.credential && nextProps.credential.generateTokenSuccess) {
      returnObj["newgenerateToken"] = nextProps.credential.generateTokenSuccess;
    }
    if (nextProps.credential && nextProps.credential.removeTokenSuccess != undefined) {
      returnObj["removeTokenSuccess"] = nextProps.credential.removeTokenSuccess;
    }
    return (returnObj);
  }

  componentDidUpdate() {
    if (this.props.credential && this.props.credential.removeTokenSuccess) {
      this.props.clearGenerateTokenSuccess();
      this.props.clearRemoveTokenSuccess();
      this.initialLoad();
    }
  }

  isApiAvailable = (value) => {
    let apiList = [];
    this.state.apiScopes.map((api, i) => {
      if ((!value || api.toLocaleLowerCase().includes(value.toLocaleLowerCase()) || value.trim().length == 0)) {
        apiList.push(api);
      }
    });
    this.setState({
      noDataFound: value.trim().length == 0 ? false : apiList.length > 0 ? false : true,
      searchAPI: value,
      apiList,
      selectOpen: value.trim().length == 0 ? false : true
    })
  }


  render() {

    return (
      // <Row>
      //   <Col span={24}>
      <Container className="credentials">
        <ModalLoading open={this.state.removeTokenProcess} >
          Removing token
        </ModalLoading>
        <Panel className="apis" onClick={() => { this.setState({ selectOpen: false }) }}>
          <Apis>
            <Title>APIs</Title>
            <SelectAPIs>
              {/* <img src={SearchIcn} /> */}
              {/* onClick={() => { this.setState({ selectOpen: !this.state.selectOpen }) }} */}
              <Select>
                {this.state.selectedAPIs.map((api, i) => {
                  return <SelectedValue theme={{
                    bgColor: this.state.selectedAPIs.includes(api) ? this.props.ui.theme.secondaryColor : '#fff',
                  }} key={i}>
                    <Text theme={{
                      bgColor: this.state.selectedAPIs.includes(api) ? this.props.ui.theme.secondaryColor : '#fff',
                    }}>{api}</Text>
                    <Close onClick={(e) => { this.onSelectAPIs(e, api) }}>
                      <img src={DelectIcn} />
                    </Close>
                  </SelectedValue>
                })}
                <input placeholder="Search APIs" type="text" value={this.state.searchAPI} onChange={(e) => { this.isApiAvailable(e.target.value) }} />
              </Select>
            </SelectAPIs>
            <List className="list" style={{ display: this.state.selectOpen ? 'block' : 'none' }}>
              {this.state.apiList.map((api, i) => {
                return <Options key={i} onClick={(e) => { this.onSelectAPIs(e, api) }}>
                  <Option>
                    <CheckBox theme={{
                      bgColor: this.state.selectedAPIs.includes(api) ? this.props.ui.theme.secondaryColor : '#fff',
                      borderColor: this.state.selectedAPIs.includes(api) ? this.props.ui.theme.secondaryColor : '#d7d9e5'
                    }}>
                      {this.state.selectedAPIs.includes(api) && <CheckOutlined style={{
                        position: "absolute",
                        padding: "2px 1px"
                      }}></CheckOutlined>}
                    </CheckBox>
                    <Label>{api}</Label>
                  </Option>
                  {this.state.apiList.length - 1 > i && <HDivider></HDivider>}
                </Options>
              })}
              {this.state.noDataFound && <NoData>No API Found</NoData>}
            </List>
          </Apis>
          <GenerateToken onClick={this.generateToken} theme={{
            bgColor: this.state.selectedAPIs.length > 0 ? this.props.ui.theme.primaryColor : '#d7d9e5',
          }}>{this.state.newgenerateToken ? "RESET" : "GENERATE TOKEN"}</GenerateToken>
          {this.state.newgenerateToken && <AccessToken>
            <img style={{
              marginLeft: "16px",
              marginRight: "10px"
            }} src={KeyIcon} />
            <VDivider style={{ marginRight: "15px" }}></VDivider>
            <Token theme={{
              fgColor: this.props.ui.theme.primaryColor
            }}>{this.state.newgenerateToken.scoped_token}</Token>
            <VDivider style={{ marginLeft: "15px" }}></VDivider>
            <CopyToClipboard text={this.state.newgenerateToken.scoped_token}
              onCopy={() => this.setState({ copied: this.state.newgenerateToken.scoped_token })}>
              <img style={{
                marginLeft: "16px",
                marginRight: "2px",
                cursor: "pointer"
              }} src={this.state.copied == this.state.newgenerateToken.scoped_token ? CopyEnableIcon : CopyIcon} />
            </CopyToClipboard>
            {this.state.copied == this.state.newgenerateToken.scoped_token && <Copied>
              <label>Copied</label>
            </Copied>}
          </AccessToken>}
          {this.state.newgenerateToken && <Exp style={{ marginBottom: "30px" }}>
            Expires on: {moment().add(this.state.newgenerateToken.expires_in, 'seconds').utc().format("MMM-DD-YYYY").toLocaleUpperCase()}
          </Exp>}
          <TokenList>
            {this.state.apiTokenList.length > 0 && <Title>List of API tokens</Title>}
            {this.state.apiTokenList.map((tk, index) => {
              return <TokenDetail key={index}>
                <Scope>
                  {/* <label>Scopes:</label> */}
                  {tk.scope.map((api, i) => {
                    return <SelectedValue theme={{
                      bgColor: this.props.ui.theme.secondaryColor,
                    }} key={i}>
                      <Text theme={{
                        bgColor: this.props.ui.theme.secondaryColor,
                      }}>{api}</Text>
                    </SelectedValue>
                  })}
                </Scope>
                <Exp>
                  Expires on: {moment(tk.expires_on).utc().format("MMM-DD-YYYY HH:mm a").toLocaleUpperCase()}
                </Exp>
                <Action>
                  <AccessToken style={{
                    margin: "0px"
                  }}>
                    <img style={{
                      width: "24px",
                      marginLeft: "16px",
                      marginRight: "10px"
                    }} src={KeyIcon} />
                    <VDivider style={{ marginRight: "15px" }}></VDivider>
                    <Token theme={{
                      fgColor: this.props.ui.theme.primaryColor
                    }}>{tk.token}</Token>
                    <VDivider style={{ marginLeft: "15px" }}></VDivider>
                    <CopyToClipboard text={tk.token}
                      onCopy={() => this.setState({ copied: tk.token })}>
                      <img style={{
                        cursor: "pointer",
                        marginLeft: "16px",
                        marginRight: "2px"
                      }} src={this.state.copied == tk.token ? CopyEnableIcon : CopyIcon} />
                    </CopyToClipboard>
                    {this.state.copied == tk.token && <Copied>
                      <label>Copied</label>
                    </Copied>}
                  </AccessToken>
                  <Remove onClick={() => this.removeAccess(tk.token)} >
                    <label>Revoke</label>
                  </Remove>
                </Action>
              </TokenDetail>
            })}
          </TokenList>
        </Panel>
      </Container>
      //   </Col>
      // </Row>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    ui: state.ui,
    credential: state.credential
  };
};
export default connect(mapStateToProps, {
  clearGenerateTokenSuccess,
  clearRemoveTokenSuccess,
  getAPICredentials,
  generateToken,
  getAPIScopes,
  removeToken
})(Credentials);