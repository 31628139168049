import * as React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

export interface ICloseProps {
  color: string;
  size: string;
}

export default function Close(props: ICloseProps) {
  return (
    <FaTimes style={{ fill: props.color, fontSize: props.size }} ></FaTimes>
  );
}
