import React from "react";
import styled from "styled-components";
import HTMLReactParser from "html-react-parser";

const getFontSize = (size: string) => {
  switch (size) {
    case "lg":
      return "19px";
    case "xs":
      return "13px";
    default:
      return "16px";
  }
};

interface ICardChoiceResponseProps {
  label: string;
  subText: string;
  isUser?: boolean;
}

const CardChoiceResponseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  min-width: 350px;
  padding: 16px;
  box-shadow: 0 2px 4px 0 #e8e9f1;
  background: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.color};
  font-size: ${(props) => props.theme.fontSize};
  border-radius: 10px 10px 35px 10px;
  font-family: "Muli", sans-serif;
  margin-bottom: 8px;
`;
const Label = styled.div`
  font-size: 16px;
  color: inherit;
`;
const SubText = styled.div`
  font-size: 15px;
`;

const CardChoiceResponse: React.FunctionComponent<ICardChoiceResponseProps> = (props: ICardChoiceResponseProps) => {
  // const widgetProps = useSelector((state: any) => state.theme.widget.dimension);
  // const config = useSelector((state: any) => state.theme.text);
  const { label, subText, isUser } = props;
  const decodeEntities = (encodedString: string) => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = encodedString;
    return textArea.value;
  };
  return (
    <div style={{
      flexDirection: isUser ? "row" : "row-reverse",
      display: "flex",
    }}>
      <CardChoiceResponseContainer
        theme={{
          backgroundColor: "#e8e9f1",
          color: "#212332",
          fontSize: getFontSize("sm"),
        }}
        className="message"
      >
        <Label>{HTMLReactParser(decodeEntities(label))}</Label>
        <SubText>{HTMLReactParser(decodeEntities(subText))}</SubText>
      </CardChoiceResponseContainer>
    </div>
  );
};

export default CardChoiceResponse;
