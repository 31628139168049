import React, { useState } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

export interface IAccordianProps {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}

const Container = styled.div``;

const Title = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f3245;
  text-transform: uppercase;
  transition: 0.4s;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Panel = styled(animated.div)`
  padding: 20px 0px;
  background-color: white;
  overflow: hidden;
  transition: display 0.9s ease-out;
  border-bottom: 1px solid rgba(168, 171, 189, 0.4);
  text-overflow: ellipsis;
`;

export default function Accordian(props: IAccordianProps): React.ReactElement {
  const { title, children } = props;
  const [openPanel, setOpenPanel] = useState(true);
  const animProps = useSpring({
    to: async (next) => {
      await next({ display: openPanel ? "block" : "none" });
    },
    from: { display: "none" },
    config: { duration: 1000 },
  });
  return (
    <Container>
      <Title data-testid="title" onClick={() => setOpenPanel(!openPanel)}>
        {title} {openPanel ? <DownOutlined /> : <UpOutlined />}
      </Title>
      <Panel data-testid="panel" style={animProps}>
        {children}
      </Panel>
    </Container>
  );
}
