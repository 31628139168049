import React, { useCallback, useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { humanFileSize } from "../../../../helpers/util";
import { useSelector, useDispatch } from "react-redux";
import { FiDownloadCloud } from "react-icons/fi";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import moreOption from "../../../../assets/images/moreOption.svg";
import {
  getChatTranscript,
  updateAgentNotification,
  getFileUploadUrl,
  uploadFile,
  getChatById,
  updateSelectedMonitorChatMessages,
} from "../../../../actions/chatsAction";
import { openMonitoredChatsTab, setSelectedChatMonitorGroup } from "../../../../actions/taskAction";

import { useComponentVisible, useOnScreen } from "../../../../utils/customHooks";
import Transfer from "../../../common/Transfer";
import { sendChatMessage, monitorChat } from "../../../../helpers/mqttService";
import { Alert, message, Popover } from "antd";
import "../../../../assets/sass/live.sass";
import { persistChat } from "../../../../actions/uiAction";
import QuickRespPopup from "../../../common/QuickRespPopup";
import Spinner from "../../../common/Spinner";
import FAQPopup from "../../../common/FAQPopup";
import MemoizedChatMessages from "../../../common/MemoizedChatMessages";
import TypingArea from "./TypingArea";

export interface IMainProps {}

const AlertWrapper = styled.div`
  position: absolute;
  top: 15px;
  z-index: 10;
  min-width: 600px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MessageWrapper = styled.div`
  padding: 20px 0px;
  background: white;
  flex: 1;
  max-height: calc(100vh - 58px - 130px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

const InputContianer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const HeaderContainer = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
`;

const NameContainer = styled.div`
  flex: 1;
  width: 100%;
  display: inline-block;
`;

const MonitorStatus = styled.div`
  font-size: 22px;
  text-shadow: 2px 2px blue;
  display: inline-block;
  position: relative;
  top: -1px;
  margin-right: 10px;
  @keyframes blink {
    0% {
      color: #a5a6ae;
    }
    100% {
      color: ${(props) => {
        return props.theme.monitorStatus ? "#1c7aff" : "#a5a6ae";
      }};
    }
  }
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  animation: ${(props) => {
      return props.theme.monitorStatus ? "blink" : "";
    }}
    1s linear infinite;
`;

export const MainChatContext = React.createContext({
  chatMessage: {},
  setChatMessage: (arg: any) => {},
});

export default function Main(props: IMainProps) {
  let selectedChatGroupId = null;
  const [selectedChatHandledAgent, setSelectedChatHandledAgent] = useState(null);
  const [enableQuickResp, setEnableQuickResp] = useState(false);
  const [enableFAQ, setEnableFAQ] = useState(false);
  const [shortCode, setShortCode] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messagesStartRef, messagesStartVisible] = useOnScreen({ threshold: 0.5 });
  const [chatPosition, setChatPosition] = useState({ position: 0 });
  const [recentMessages, setRecentMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState({});
  const [privateChat, setPrivateChat] = useState(true);
  const [isMoreOptionVisible, setIsMoreOptionVisible] = useState(false);
  const [transferChatOption, setTransferChatOption] = useState(false);
  const dispatch = useDispatch();
  const { theme, chats: persistedChats } = useSelector((state: any) => state.ui);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const { info } = useSelector((state: any) => state.auth);

  const { selectedChatMonitorGroup } = useSelector((state: any) => state.task);

  const content = (
    <div className="more-option-panel">
      <p onClick={() => handleMonitoring(!selectedChatMonitorGroup)}>
        <label>{!selectedChatMonitorGroup ? "Start Monitoring" : "Stop Monitoring"}</label>
        <div className="action">
          {!selectedChatMonitorGroup ? (
            <EyeOutlined style={{ color: "#a5a6ae", fontSize: "22px" }} />
          ) : (
            <EyeInvisibleOutlined style={{ color: "#1c7aff", fontSize: "22px" }} />
          )}
        </div>
      </p>
      <p onClick={() => downloadChatTranscript(chatId)}>
        <label>Chat Transcript</label>
        <div className="action">
          <FiDownloadCloud color="#a5a6ae" size="22px" />
        </div>
      </p>
    </div>
  );
  const validFileContentType = {
    image: ["image/jpg", "image/png", "image/jpeg"],
    // video: ['video/mp4'],
    // audio: ['audio/mp3'],
    pdf: ["application/pdf"],
    // doc: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    // txt: ['text/plain'],
    // xls: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'csv', 'application/vnd.ms-excel'],
    // ppt: ['application/vnd.openxmlformats-officedocument.presentationml.presentation']
  };
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const {
    ref: shortcutRef,
    isComponentVisible: isShortcutVisible,
    setIsComponentVisible: setShortcutVisible,
  } = useComponentVisible(false);
  const downloadChatTranscript = (payload: string) => {
    setIsMoreOptionVisible(false);
    getChatTranscript(payload)()
      .then((resp) => {
        let csvString = resp.split("\n").join("\n");
        let universalBOM = "\uFEFF";
        let a = window.document.createElement("a");
        a.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent(universalBOM + csvString));
        a.setAttribute("download", `${selectedMonitorChatId}.csv`);
        window.document.body.appendChild(a);
        a.click();
      })
      .catch((e) => console.log(e));
  };
  const { selectedMonitorChatId } = useSelector((state: any) => state.chat);
  const { unMonitoredChats, agentNotification } = useSelector((state: any) => state.chat);
  const { monitoredChats } = useSelector((state: any) => state.chat);

  const chats = { ...unMonitoredChats, ...monitoredChats };

  const [messages, setMessages] = useState([]);
  const [inbox, setInbox] = useState([]);
  const clientData = selectedMonitorChatId && chats[selectedMonitorChatId] && chats[selectedMonitorChatId].clientData;
  const chatId = selectedMonitorChatId && chats[selectedMonitorChatId] && chats[selectedMonitorChatId]._id;
  useEffect(() => {
    if (messages && messages.length > 0) {
      selectedChatGroupId = null;
      if (selectedChatHandledAgent != messages[messages.length - 1].fromId) {
        setSelectedChatHandledAgent(messages[messages.length - 1].fromId);
      }
    }
    scrollToBottom();
  }, [inbox, loading, messages, selectedMonitorChatId]);

  useEffect(() => {
    if (selectedMonitorChatId) {
      setLoading(false);
      getChatById(selectedMonitorChatId)()
        .then((data) => {
          let monitorGroup =
            selectedMonitorChatId &&
            chats[selectedMonitorChatId] &&
            chats[selectedMonitorChatId].monitoredBy &&
            chats[selectedMonitorChatId].monitoredBy.includes(info.agentId)
              ? true
              : false;
          setInbox([]);
          setMessages(data.messages);
          const messagesCount = data.messages.length;
          let position = messagesCount - 50;
          if (position < 0) {
            position = 0;
          }
          setChatPosition({ position });
          setLoading(false);
          setRecentMessages(data.messages.slice(position));
          dispatch(
            updateSelectedMonitorChatMessages(
              selectedMonitorChatId,
              data.messages,
              monitorGroup,
              data.participants,
              data.groupId,
              data.clientData,
              data.clientId
            )
          );
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  }, [selectedChatHandledAgent]);

  useEffect(() => {
    if (selectedMonitorChatId === "") {
      setRecentMessages([]);
      setLoading(false);
      setInbox([]);
    }
  }, [selectedMonitorChatId]);

  useEffect(() => {
    dispatch(
      setSelectedChatMonitorGroup(
        selectedMonitorChatId &&
          chats[selectedMonitorChatId].monitoredBy &&
          chats[selectedMonitorChatId].monitoredBy.includes(info.agentId)
      )
    );
    if (selectedMonitorChatId) {
      setLoading(true);
      getChatById(selectedMonitorChatId)()
        .then((data) => {
          let monitorGroup =
            selectedMonitorChatId &&
            chats[selectedMonitorChatId] &&
            chats[selectedMonitorChatId].monitoredBy &&
            chats[selectedMonitorChatId].monitoredBy.includes(info.agentId)
              ? true
              : false;
          // setMessages(data.messages);
          // console.log(monitorGroup);
          setInbox([]);
          setMessages(data.messages);
          setLoading(false);
          const messagesCount = data.messages.length;
          let position = messagesCount - 50;
          if (position < 0) {
            position = 0;
          }
          setChatPosition({ position });
          setRecentMessages(data.messages.slice(position));
          dispatch(
            updateSelectedMonitorChatMessages(
              selectedMonitorChatId,
              data.messages,
              monitorGroup,
              data.participants,
              data.groupId,
              data.clientData,
              data.clientId
            )
          );
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  }, [selectedMonitorChatId]);

  useEffect(() => {
    if (selectedMonitorChatId && chats[selectedMonitorChatId] && chats[selectedMonitorChatId].messages) {
      const messagesCount = chats[selectedMonitorChatId].messages.length;
      const lastMessage = chats[selectedMonitorChatId].messages[messagesCount - 1];
      if (lastMessage.fromInbox) {
        setInbox((prevState) => [...prevState, lastMessage]);
      }
    }
  }, [selectedMonitorChatId && chats[selectedMonitorChatId] && chats[selectedMonitorChatId].messages]);

  useEffect(() => {
    if (!loading && messagesStartVisible && selectedMonitorChatId) {
      setChatPosition((prevState) => {
        let newPosition = prevState.position - 50;
        if (newPosition < 0) {
          newPosition = 0;
        }
        setRecentMessages(messages.slice(newPosition));
        const temp = messages.find((message, index) => index === prevState.position) || "";
        const node = document.getElementById(temp.mId);
        if (node && prevState.position !== 0) {
          node.scrollIntoView();
        }
        return { ...prevState, position: newPosition };
      });
    }
  }, [messagesStartVisible]);

  const handleSubmit = (e) => {
    if (chatMessage && chatMessage[selectedMonitorChatId].charAt(0) == "/") {
      setShortCode(chatMessage[selectedMonitorChatId]);
      return;
    }
    e.preventDefault();
    if (uploadFileProps.fileList.length > 0) {
      uploadFile(uploadFileProps.fileList[0])().then(() => {
        let msg = {
          url: uploadFileProps.fileList[0].uploadUrl.split("?")[0],
          fileName: uploadFileProps.fileList[0].name,
          fileSize: uploadFileProps.fileList[0].size,
          desc: (chatMessage && chatMessage[selectedMonitorChatId]) || "",
        };
        const payload = {
          msgType: uploadFileProps.fileList[0].msgType,
          msg,
          chatId: selectedMonitorChatId,
          isPrivate: privateChat,
        };
        setChatMessage({ ...chatMessage, [selectedMonitorChatId]: "" });
        sendChatMessage(payload);
        setIsComponentVisible(false);
        setUploadFileProps({
          ...uploadFileProps,
          fileList: [],
        });
      });
    } else if (chatMessage && chatMessage[selectedMonitorChatId] && chatMessage[selectedMonitorChatId].trim()) {
      const payload = {
        msgType: "text",
        msg: { text: chatMessage[selectedMonitorChatId].replace(/(?:\r\n|\r|\n)/g, "<br>").trim() },
        chatId: selectedMonitorChatId,
        isPrivate: privateChat,
      };

      setChatMessage({ ...chatMessage, [selectedMonitorChatId]: "" });
      sendChatMessage(payload);
    }
  };
  const handleMonitoring = (flag: boolean) => {
    setIsMoreOptionVisible(false);
    dispatch(setSelectedChatMonitorGroup(flag));
    monitorChat(chatId, flag);
    if (flag) {
      dispatch(openMonitoredChatsTab(1));
    }
  };

  const handleNotificationClose = useCallback(() => dispatch(updateAgentNotification("")), [dispatch]);

  const handlePrivateChat = useCallback(
    (checked: boolean, selectedMonitorChatId: string) =>
      dispatch(persistChat(selectedMonitorChatId, { isPrivate: checked })),
    [dispatch]
  );

  const beforeUpload = (file: any) => {
    let isValidType = false;
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (isLt2M) {
      for (const i in validFileContentType) {
        if (validFileContentType[i].indexOf(file.type) > -1) {
          isValidType = true;
          file.msgType = i;
        }
      }
      if (!isValidType) {
        message.error(`The ${file.name} file extension is not able to attached in chat.`);
      }
    } else {
      message.error(
        `File size limit to be attached in email is less than 2MB. ${file.name} size is ${humanFileSize(
          file.size,
          true
        )}`
      );
    }

    if (isValidType && isLt2M) {
      let fileDetails = file.name.split(/[\s.]+/);
      let fileExt = fileDetails[fileDetails.length - 1];
      setUploadFileProps({
        ...uploadFileProps,
        fileList: [file],
      });
      getFileUploadUrl(fileExt)().then((resp) => {
        if (resp.url) {
          file.uploadUrl = resp.url;
        }
        setUploadFileProps({
          ...uploadFileProps,
          fileList: [file],
        });
      });
    }
    return false;
  };

  const onRemove = () => {
    setUploadFileProps({
      ...uploadFileProps,
      fileList: [],
    });
  };

  const [uploadFileProps, setUploadFileProps] = useState({
    multiple: false,
    accept: "image/png, image/jpeg, image/jpg,.pdf,.doc,.docx",
    processData: false,
    fileList: [],
    beforeUpload: beforeUpload,
    onRemove: onRemove,
  });

  const onCheckChar = (val) => {
    if (val.charAt(0) == "/") {
      setEnableQuickResp(true);
    } else {
      setEnableQuickResp(false);
    }
  };

  const onSelectQuickResp = (item) => {
    if (item) {
      setEnableQuickResp(false);
      if (item && item.response) {
        setChatMessage({ ...chatMessage, [selectedMonitorChatId]: item.response });
        setShortCode(null);
      }
    }
  };

  const onSelectFAQ = (item) => {
    if (item) {
      setEnableFAQ(false);
      if (item && item.answer) {
        setChatMessage({ ...chatMessage, [selectedMonitorChatId]: item.answer });
      }
    }
  };

  return (
    <MainChatContext.Provider value={{ chatMessage, setChatMessage }}>
      <Spinner loading={loading}/>
      <Container className="live" style={{ filter: loading ? "blur(3px)" : "" }}>
        {clientData && clientData.fromName && (
          <HeaderContainer>
            {agentNotification && (
              <AlertWrapper>
                <Alert
                  message="Error"
                  description={agentNotification}
                  type="error"
                  closable
                  onClose={handleNotificationClose}
                />
              </AlertWrapper>
            )}
            <NameContainer>
              <MonitorStatus theme={{ monitorStatus: selectedChatMonitorGroup }}>
                <EyeOutlined />
              </MonitorStatus>
              <strong>
                You are {selectedChatMonitorGroup ? "monitoring" : "viewing"} Agent chat with {clientData.fromName}
              </strong>
            </NameContainer>
            <Popover placement="bottomRight" visible={isMoreOptionVisible} content={content} trigger="click">
              <div className="more" onClick={() => setIsMoreOptionVisible(!isMoreOptionVisible)}>
                <img src={moreOption} className="icon" />
              </div>
            </Popover>
          </HeaderContainer>
        )}
        <MessageWrapper>
          <div ref={messagesStartRef as React.LegacyRef<HTMLDivElement>} />
          <MemoizedChatMessages chatId={chatId} messages={recentMessages} />
          <MemoizedChatMessages chatId={chatId} messages={inbox} />
          <div ref={messagesEndRef} />
        </MessageWrapper>
        {transferChatOption && (
          <Transfer visible={transferChatOption} onCloseTransferChatOption={() => setTransferChatOption(false)} />
        )}
        {selectedMonitorChatId && (
          <QuickRespPopup
            visible={enableQuickResp}
            getQuickRespByCode={shortCode}
            typingText={(chatMessage && chatMessage[selectedMonitorChatId]) || ""}
            onSelect={onSelectQuickResp}
          />
        )}
        {selectedMonitorChatId && enableFAQ && <FAQPopup onSelect={onSelectFAQ} />}
        {selectedMonitorChatId && (
          <InputContianer>
            <TypingArea
              selectedLiveChatId={selectedMonitorChatId}
              setChatMessage={setChatMessage}
              privateChat={privateChat}
              handlePrivateChat={handlePrivateChat}
              enableFAQ={enableFAQ}
              setEnableFAQ={setEnableFAQ}
              setEnableQuickResp={setEnableQuickResp}
              uploadFileProps={uploadFileProps}
              setIsComponentVisible={setIsComponentVisible}
              isComponentVisible={isComponentVisible}
              onRemove={onRemove}
              theme={theme}
              ref={ref}
              uploadFile={uploadFile}
              setUploadFileProps={setUploadFileProps}
              setShortCode={setShortCode}
              clientData={clientData}
              onCheckChar={onCheckChar}
            />
          </InputContianer>
        )}
      </Container>
    </MainChatContext.Provider>
  );
}
