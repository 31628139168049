import * as React from "react";
import { Collapse, Select } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { IState, ThemeContext } from "../../../../../../themeDesigner";

const Panel = Collapse.Panel;

const { Option } = Select;

function handleChange(value) {
    console.log(`selected ${value}`);
}

function callback(key) {
    console.log(key);
}

export interface IBorderPropertiesProps {
    msgType?: string
}

const Container = styled.div`
  margin-top: 14px;
`;

const Input = styled.input`
  width: ${(props) => props.theme.width}px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }

  &::placeholder {
    font-family: Muli;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #ccd0dd;
  }
`;

const AllWrapper = styled.div`
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;

  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    margin-bottom: 5px;
  }
`;

const MarginWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.marginBottom}px;
  justify-content: space-between;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

export default function BorderProperties(props: IBorderPropertiesProps) {
    const { secondaryColor: outlineColor } = useSelector(
        (state: IState) => state.ui.theme
    );
    const { themeProps, setThemeProps } = React.useContext(ThemeContext);
    const {
        widget: { surfaceQuestion },
    } = themeProps;
    const {
        border,
        borderRadius,
        borderRadiusBottomLeft,
        borderRadiusBottomRight,
        borderRadiusTopLeft,
        borderRadiusTopRight,
    } = surfaceQuestion;

    const handleChange = (value: string, property: string) => {
        const updatedHeader = update(themeProps, {
            widget: {
                surfaceQuestion: {
                    [property]: { $set: value },
                },
            },
        });
        setThemeProps(updatedHeader);
    };

    return (
        // <Collapse defaultActiveKey={["1"]} onChange={callback}>
        //     <Panel header="Border" key="1">
        <div>
            <AllWrapper>
                <span className="text">Border</span>
                <Input
                    theme={{ outlineColor, width: 210 }}
                    placeholder="1px solid black"
                    value={border}
                    onChange={(e) => handleChange(e.target.value, "border")}
                />
            </AllWrapper>
            <AllWrapper>
                <span className="text">Border Radius</span>
                <Input
                    theme={{ outlineColor, width: 210 }}
                    placeholder="1px or 1% or 1rem or 1em"
                    value={borderRadius}
                    onChange={(e) => handleChange(e.target.value, "borderRadius")}
                />
            </AllWrapper>
            <MarginWrapper theme={{ marginBottom: 0 }}>
                <ColumnWrapper>
                    <label>Right</label>
                    <Input
                        theme={{ outlineColor, width: 45 }}
                        value={borderRadiusTopRight}
                        onChange={(e) => handleChange(e.target.value, "borderRadiusTopRight")}
                    />
                </ColumnWrapper>
                <ColumnWrapper>
                    <label>Left</label>
                    <Input
                        theme={{ outlineColor, width: 45 }}
                        value={borderRadiusBottomLeft}
                        onChange={(e) => handleChange(e.target.value, "borderRadiusBottomLeft")}
                    />
                </ColumnWrapper>
                <ColumnWrapper>
                    <label>Top</label>
                    <Input
                        theme={{ outlineColor, width: 45 }}
                        value={borderRadiusTopLeft}
                        onChange={(e) => handleChange(e.target.value, "borderRadiusTopLeft")}
                    />
                </ColumnWrapper>
                <ColumnWrapper>
                    <label>Bottom</label>
                    <Input
                        theme={{ outlineColor, width: 45 }}
                        value={borderRadiusBottomRight}
                        onChange={(e) =>
                            handleChange(e.target.value, "borderRadiusBottomRight")
                        }
                    />
                </ColumnWrapper>
            </MarginWrapper>
        </div>
        //     </Panel>
        // </Collapse>
    );
}
