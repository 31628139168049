import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { updateSettingShow } from '../../../actions/uiAction';
import { settingSaveDetails } from '../../../actions/taskAction';
import agentIcon from '../../../assets/images/agent-icon.svg';

class GroupList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            roles: props.roles,
            updateSettingShow: false
        };
    }

    onAddOrUpdateDetails = (group, event: any) => {
        group = { ...group, type: "group" };
        event.preventDefault();
        this.setState({ updateSettingShow: true });
        this.props.settingSaveDetails({ info: group })
        this.props.updateSettingShow({ updateSettingShow: true });
    }

    async componentDidUpdate() {
    }

    loadAgentsDetails = (groupId) => {
        let agents = this.props.agent.agents;
        let agentCount = 0;
        let agentsList = [];
        let isMore = false;
        let title = [];
        agents.map((agent) => {
            if (agent && agent.groupIds.indexOf(groupId) > -1) {
                agentCount++;
                if (agentCount >= 5) {
                    isMore = true
                    title.push(<Col className="item single" title={agent.name}><img className="icon" src={agentIcon} /></Col>);
                } else if (!isMore) {
                    agentsList.push(<Col key={agent._id} className="group" title={agent.name}><div className="item single"><img src={agentIcon} /></div></Col>)
                }
            }
        });
        if (isMore) {
            agentsList.push(<Col key={0} className="group"><Tooltip placement="rightTop" title={<Row className={(agentCount - 4) > 3 ? "more-agent width" : "more-agent max-width"}>{title}</Row>}><div className="item more">+{(agentCount - 4)}</div></Tooltip></Col>);
        }
        return agentsList;
    }

    render() {
        let group = this.props.group;
        return (
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 flex-item" onClick={this.onAddOrUpdateDetails.bind(this, group)}>
                <div className={'box card-item card-grid-item flex-inner ' + (group.action ? "action" : "view")}>
                    <div className={group.action ? "item" : ""} >
                        {
                            group.action &&
                            <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                <div className="in"  >
                                    <span className="add-new-task">
                                        <span className="icon-add"></span>
                                    </span>
                                </div>
                            </div>

                            // <div className="in" onClick={this.onAddOrUpdateDetails.bind(this, group)} ><div className="circle" ></div><div className="plus">+</div></div>
                        }
                        {
                            !group.action &&
                            <div style={{ margin: "20px" }}>
                                <span className="dot" style={{ background: group.isActive ? "#46b275" : "#a7a7a7" }}></span>

                                <Row style={{ height: "70px" }}>
                                    <Col span={14}>
                                        <div className="name ellipsis">{group.name.charAt(0).toUpperCase() + group.name.slice(1)}</div>
                                        <div className="id ellipsis"><span style={{ fontWeight: "bold", color: "#2123327a" }}>#</span> {group._id}</div>
                                    </Col>
                                    <Col span={10}>
                                        {this.state.roles.includes("admin") && <EditOutlined className="edit" onClick={this.onAddOrUpdateDetails.bind(this, group)} />}
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "4px" }} >
                                    <Col span={24}>
                                        <Row>
                                            {this.loadAgentsDetails(group._id)}
                                            {/* <span style={{ color: "#a8abbd", paddingTop: "10px", paddingLeft: "4px" }}>agents</span> */}
                                        </Row>
                                    </Col>
                                </Row>

                            </div>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("Agent List mapStateToProps: ", state);
    return {
        auth: state.auth,
        groups: state.group,
        agent: state.agent
    }
}

export default connect(mapStateToProps, { updateSettingShow, settingSaveDetails })(GroupList);
