import * as React from "react";
import LeftDrawer from "../components/leftDrawer";
import ChatRouting from "../components/chatRouting";
import ReasonCodes from "../components/reasonCodes";
import Credentials from "../components/credentials";
import styled from "styled-components";
import Navbar from "../components/Navbar";

export interface IGeneralProps { }

export const SelectedSettingsContext = React.createContext({
  selectedMenu: "",
  setSelectedMenu: (arg: string) => { },
});

const renderComponent = (selectedMenu: string) => {
  switch (selectedMenu) {
    case "Chat Routing":
      return <ChatRouting />;
    case "Reason Codes":
      return <ReasonCodes />;
    case "Credentials":
      return <Credentials />;
  }
};

const FlexContainer = styled.div`
  display: flex;
`;

export default function General(props: IGeneralProps) {
  const [selectedMenu, setSelectedMenu] = React.useState("Chat Routing");
  return (
    <SelectedSettingsContext.Provider value={{ selectedMenu, setSelectedMenu }}>
      <FlexContainer>
        <LeftDrawer />
        <div style={{ flex: 1 }}>
          <Navbar />
          {renderComponent(selectedMenu)}
        </div>
      </FlexContainer>
    </SelectedSettingsContext.Provider>
  );
}
