import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col } from "antd";
import { Select as A8_SELECT } from "a8-uicomponents";
import DateRangePicker from "../common/DateRangePicker";
import { getAgents } from "../../actions/agentsAction";
import { getGroups } from "../../actions/groupsAction";
import { compareValues } from "../../helpers/util";
import { getDashboarDetals } from '../../actions/authActions';
import { setDashboardFilters } from '../../actions/uiAction';
import config from "../../apiConfig";
import moment from "moment";
import "../../assets/sass/dashboard.sass";
import calIcon from '../../assets/images/calendar.svg';
import refreshIcon from '../../assets/images/refresh.svg';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/a8Loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};
let agentResp = [];
let groupResp = [];
let selectAgentOptions = {
    defaultValue: [],
    optionValue: "_id",
    isSearch: true,
    title: "name",
    multiSelect: false,
    subTitle: "email",
    placeholder: "Filter By Agent",
    primaryColor: null,
};
let selectGroupOptions = {
    defaultValue: [],
    optionValue: "_id",
    isSearch: false,
    title: "name",
    multiSelect: false,
    placeholder: "Filter By Groups",
    primaryColor: null,
};
let agentAction = {
    _id: null,
    action: true,
    isActive: true,
};
const dashboardRef: any = React.createRef();
class Dashboard extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        let customFilters = "";
        let dateFilter = "";
        let agentIds = [];
        let groupIds = [];
        let selectedDate = null;
        let dateRangeSelection = {
            type: "AbsoluteTimeRange",
            range: null
        };
        if (props.ui.dashboardFilters) {
            customFilters = props.ui.dashboardFilters.customFilters || "";
            if (customFilters) {
                let filters = customFilters.split("&").filter(item => item);
                for (let i in filters) {
                    if (filters[i].indexOf("var-groupId=") > -1) {
                        groupIds.push(filters[i].replace("var-groupId=", ""));
                    }
                    if (filters[i].indexOf("var-agentId=") > -1) {
                        agentIds.push(filters[i].replace("var-agentId=", ""));
                    }
                }
            }
            selectedDate = props.ui.dashboardFilters.selectedDate || {
                startDate: new Date(),
                endDate: new Date(),
                dateRangeSelection
            };
            if (selectedDate) {
                if (selectedDate.dateRangeSelection.type == "RelativeTimeRange") {
                    dateFilter = dateFilter + `&from=now${selectedDate.dateRangeSelection.range.value}${selectedDate.dateRangeSelection.range.type.charAt(0)}&to=now`
                } else {
                    if (selectedDate.startDate) {
                        dateFilter = dateFilter + `&from=${new Date(selectedDate.startDate).getTime()}`;
                    }
                    if (selectedDate.endDate) {
                        dateFilter = dateFilter + `&to=${new Date(selectedDate.endDate).getTime()}`;
                    }
                }
                if (selectedDate.dateRangeSelection) {
                    dateRangeSelection = selectedDate.dateRangeSelection;
                }
            }
        } else {
            selectedDate = {
                startDate: new Date(),
                endDate: new Date(),
                dateRangeSelection
            };
        }
        this.state = {
            isAgentOnly: true,
            windowHeight: window.innerHeight,
            dateRangeSelection,
            baseUrl: "",
            renderDashboard: false,
            visibleCalander: false,
            agentList: [],
            groupList: [],
            agentIds,
            groupIds,
            selectedDate,
            loading: true,
            dateFilter,
            isDashboardLoaded: false,
            customFilters
        };
    }
    async initialLoad() {
        let dashboardDetails = await this.props.getDashboarDetals();
        if (dashboardDetails && dashboardDetails.url) {
            this.setState({
                baseUrl: dashboardDetails.url
            });
        }
        groupResp = [];
        agentResp = [];
        let groupList = await this.props.getGroups();
        if (groupList) {
            if (this.props.auth.info.roles.includes("supervisor") && !this.props.auth.info.roles.includes("admin")) {
                for (let j in groupList.groups) {
                    for (let i = 0; i < this.props.auth.info.groups.length; i++) {
                        if (this.props.auth.info.groups[i]._id == groupList.groups[j]._id) {
                            groupResp.push(groupList.groups[j]);
                            break;
                        }
                    }
                }
                this.setState({ groupList: groupResp });
            } else {
                groupResp = groupList.groups;
            }
        }
        let agentList = await this.props.getAgents();
        if (agentList && agentList.agents) {
            if (this.props.auth.info.roles.includes("supervisor") && !this.props.auth.info.roles.includes("admin")) {
                for (let j in agentList.agents) {
                    for (let i = 0; i < this.props.auth.info.groups.length; i++) {
                        if (!agentList.agents[j].isVirtualAgent && agentList.agents[j].groupIds.includes(this.props.auth.info.groups[i]._id)) {
                            agentResp.push(agentList.agents[j]);
                            break;
                        }
                    }
                }
            } else {
                agentResp = agentList.agents;
            }
        }
        let agents = JSON.parse(JSON.stringify(agentResp));
        agents.sort(compareValues("name", null, "string", "asc"));
        if (agentResp) {
            agents.splice(0, 0, agentAction);
            this.setState({ agentList: agents });
        }
        if (this.props.ui.theme) {
            let theme = this.props.ui.theme;
            selectGroupOptions.defaultValue = this.state.groupIds;
            selectGroupOptions.primaryColor = theme.primaryColor;
            selectAgentOptions.defaultValue = this.state.agentIds;
            selectAgentOptions.primaryColor = theme.primaryColor;
        }
        if (this.props.auth.info.roles.length == 1 && this.props.auth.info.roles[0] === "agent") {
            this.setState({
                isAgentOnly: true
            });
            this.onApplyFilter("agentId", [this.props.auth.info.agentId])
        } else {
            this.setState({
                isAgentOnly: false
            })
        }
        this.setInitialIFrameUrl();
    }
    componentDidMount() {
        if (this.props.auth && this.props.auth.info) {
            this.initialLoad();
        }
    }
    setInitialIFrameUrl = () => {
        let { accessToken, agentId, orgId } = this.props.auth.info;
        let { baseUrl } = this.state;
        this.setState({
            baseUrl: `${config.baseUrl.replace("/apis", "")}${baseUrl}&token=${accessToken}`
        })
    }
    onDateChange = (selectedDate) => {
        let { dateFilter, customFilters } = this.state;
        dateFilter = "";
        if (selectedDate.dateRangeSelection.type == "RelativeTimeRange") {
            dateFilter = dateFilter + `&from=now${selectedDate.dateRangeSelection.range.value}${selectedDate.dateRangeSelection.range.type.charAt(0)}&to=now`
        } else {
            if (selectedDate.startDate) {
                dateFilter = dateFilter + `&from=${new Date(selectedDate.startDate).getTime()}`;
            }
            if (selectedDate.endDate) {
                dateFilter = dateFilter + `&to=${new Date(selectedDate.endDate).getTime()}`;
            }
        }
        this.setState({
            dateFilter,
            selectedDate,
            visibleCalander: false
        });
        this.props.setDashboardFilters({ customFilters, selectedDate })

    }
    onApplyFilter = (type, selectedIds) => {
        let { groupIds, agentIds, selectedDate } = this.state;
        let customFilters = "";
        if (type != "agentId") {
            for (let i in agentIds) {
                customFilters = customFilters + `&var-agentId=${agentIds[i]}`;
            }
        }
        if (type != "groupId") {
            for (let i in groupIds) {
                customFilters = customFilters + `&var-groupId=${groupIds[i]}`;
            }
        }
        for (let i in selectedIds) {
            customFilters = customFilters + `&var-${type}=${selectedIds[i]}`;
        }
        console.log(customFilters);
        this.setState({
            [`${type}s`]: selectedIds,
            customFilters,
        });
        this.props.setDashboardFilters({ customFilters, selectedDate })
    }
    onCancel = () => {
        this.setState({
            visibleCalander: false
        })
    }
    dashboardLoaded = (e) => {
        try {
            let cssLink = document.createElement("link");
            cssLink.href = "/a8console/assets/css/dashboard.css";
            cssLink.rel = "stylesheet";
            cssLink.type = "text/css";
            frames['iframeDashboard'].document.head.appendChild(cssLink);
            this.setState({
                renderDashboard: true
            })
        } catch (e) {
            console.log(e)
        }
    }
    loadDashboard = () => {
        const { baseUrl, dateFilter, customFilters } = this.state;
        this.setState({ isDashboardLoaded: true }, () => {
            if(dashboardRef && dashboardRef.current) {
              dashboardRef.current.src = `${baseUrl}${dateFilter}${customFilters}`
            }
        })

    }
    getReport = () => {
        const { baseUrl, dateFilter, customFilters } = this.state;
        if(dashboardRef && dashboardRef.current) {
            dashboardRef.current.src = `${baseUrl}${dateFilter}${customFilters}`;
        } else {
            this.loadDashboard();
        }
    }
    render() {
        return (
            <div className="dashboard">
                <div className="filter">
                    <Row>
                        <Col span={12} className="option" style={{
                            borderWidth: this.state.isAgentOnly ? 0 : 1
                        }}>
                            {!this.state.isAgentOnly && <Row style={{
                                display: "flex",
                                justifyContent: "space-around",
                                alignItems: "center",
                                height: 38
                            }}>
                                <Col span={8} className="agent">
                                    <span className="lab">Agents</span>
                                    <A8_SELECT
                                        data={agentResp}
                                        options={selectAgentOptions}
                                        onChange={(selectIds) =>
                                            this.onApplyFilter("agentId", selectIds)
                                        }
                                    ></A8_SELECT>
                                </Col>
                                <Col span={8} className="groups">
                                    <span className="lab">Groups</span>
                                    <A8_SELECT
                                        data={groupResp}
                                        options={selectGroupOptions}
                                        onChange={(selectIds) =>
                                            this.onApplyFilter("groupId", selectIds)
                                        }
                                    ></A8_SELECT>
                                </Col>
                            </Row>}
                        </Col>
                        {/* <Col span={1}></Col> */}
                        <Col span={12} className="option" style={{
                            borderWidth: 0,
                        }}>
                            <Row>
                                <Col span={24} style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "flex-end",
                                    paddingRight: "12px",
                                    alignItems: "center",
                                    height: 34,
                                }}>
                                    <div style={{
                                        textAlign: "center",
                                        paddingRight: 10,
                                        fontSize: 12,
                                        fontWeight: 700,
                                        borderRadius: 20,
                                        border: "solid 1px #d7d9e5",
                                        marginRight: 10,
                                        height: 42,
                                        padding: "10px 22px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        {this.state.selectedDate && this.state.selectedDate.dateRangeSelection && this.state.selectedDate.dateRangeSelection.type == "AbsoluteTimeRange" && <span>
                                            {moment(new Date(this.state.selectedDate.startDate)).format("DD-MM-YYYY  HH:mm:ss")}  ~
                                        {moment(new Date(this.state.selectedDate.endDate)).format("DD-MM-YYYY  HH:mm:ss")}</span>}
                                        {this.state.selectedDate && this.state.selectedDate.dateRangeSelection && this.state.selectedDate.dateRangeSelection.type == "RelativeTimeRange" && <span>
                                            {this.state.selectedDate.dateRangeSelection.range.label}
                                        </span>}

                                        <img id="a8calender" style={{ paddingLeft: "8px", cursor: "pointer" }} onClick={() => { this.setState({ visibleCalander: !this.state.visibleCalander }); }} src={calIcon}></img>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        {this.state.visibleCalander && <DateRangePicker onCancel={this.onCancel} dateRangeSelection={this.state.dateRangeSelection} defaultValue={{ startDate: this.state.selectedDate && this.state.selectedDate.startDate, endDate: this.state.selectedDate && this.state.selectedDate.endDate }} onChange={this.onDateChange}></DateRangePicker>}
                                        <Button type="danger" onClick={this.getReport}>Get Report</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="content">
                    <Row>
                        <Col span={24}
                            style={{
                                // height: this.state.windowHeight - (!this.state.isAgentOnly ? 190 : 140),
                                height: this.state.windowHeight - 192,
                                overflowY: "auto",
                                margin: 10
                            }}>
                            {!this.state.renderDashboard && this.state.isDashboardLoaded ? (<div style={{
                                position: "absolute",
                                height: "100%",
                                width: "100%"
                            }}>
                                <div style={{
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}>
                                    <Lottie
                                        options={defaultOptions}
                                        height={100}
                                        width={150}
                                    />
                                </div>
                            </div>) : null}
                            {this.state.isDashboardLoaded ?
                            <iframe
                                ref={dashboardRef}
                                name="iframeDashboard"
                                width="100%"
                                height="100%"
                                style={{ border: "none", display: this.state.renderDashboard ? "block" : "none" }}
                                onLoad={(e) => this.dashboardLoaded(e)}
                                    /> : <div style={{ height: "80vh", display: "flex", justifyContent:"center", alignItems: "center" }}><Button type="primary" size="large" onClick={this.loadDashboard}>Load Dashboard</Button></div>}
                        </Col>
                    </Row>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        ui: state.ui
    };
};

export default connect(mapStateToProps, { getAgents, getGroups, getDashboarDetals, setDashboardFilters })(Dashboard);
