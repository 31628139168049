import type from "../types/actionTypes";
const initialState = {}
export default function quickReplyReducers(state = initialState, action) {
    switch (action.type) {
        case type.QUICK_RESP_SUCCESS:
            return {
                ...state,
                quickResp: action.data.quickResponses
            }
        case type.QUICK_RESP_SAVE_SUCCESS:
            return {
                ...state,
                quickRespSave: action.data,
            };
        case type.QUICK_RESP_ERROR:
            return {
                ...state,
                quickRespError: action.data,
            };
        case type.QUICK_RESP_DELETE_SUCCESS:
            return {
                ...state,
                quickRespDelete: action.data,
            };
        case type.QUICK_RESP_DELETE_ERROR:
            return {
                ...state,
                quickRespDelete: action.data,
            };
        default:
            return state;
    }
}