import * as React from "react";
import { connect } from "react-redux";
import { Drawer, Modal, Row, Col, Input, Divider, Button } from "antd";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { getAgents } from "../../actions/agentsAction";
import { getGroups } from "../../actions/groupsAction";
import { transferChatToGroup, transferChatToAgent } from "../../helpers/mqttService";
import { compareValues } from "../../helpers/util";
import { getRandomColor } from "../../utils/randomColor";
import styled from "styled-components";
import "../../assets/sass/transfer.sass";
import agentIcon from "../../assets/images/agent-icon.svg";

let agentResp = null;
let groupResp = null;
const Icon = styled.div`
  padding: 8px;
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  background-color: ${(props) => props.theme.background + "26"};
  color: ${(props) => props.theme.background};
  font-weight: bold;
  text-align: center;
`;
let liveUpdateTimer = null;
class Transfer extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            selected: null,
            search: "",
            type: null,
            windowHeight: window.innerHeight,
            agentList: [],
            groupList: [],
            selectedMenu: "agent",
            confirmTransfer: false
        };
        this.initialLoad();
        liveUpdateTimer = setInterval(function () {
            this.initialLoad();
        }.bind(this), 5000);
    }
    async initialLoad() {
        groupResp = await this.props.getGroups();
        let groups = JSON.parse(JSON.stringify(groupResp ? groupResp.groups : []));
        groups.sort(compareValues("noOfAvailableAgents", null, "number", "desc"));
        groupResp.groups = JSON.parse(JSON.stringify(groups ? groups : []));
        // if (groups) {
        //     this.setState({ groupList: groups });
        // }

        agentResp = await this.props.getAgents();
        let agents = JSON.parse(JSON.stringify(agentResp ? agentResp.agents : []));
        agents.map((item) => {
            if (!item.state || !item.state.name) {
                item["state"] = {
                    name: "offline"
                }
            }
        });
        agents.sort(compareValues("state", "name", "string", "asc"));
        agentResp.agents = JSON.parse(JSON.stringify(agents ? agents : []));
        // if (agentResp) {
        //     this.setState({ agentList: agents });
        // }
        this.applyFilter(this.state.search);
    }
    onClose = () => {
        if (this.props.onCloseTransferChatOption) {
            this.props.onCloseTransferChatOption();
        }
    }
    componentWillUnmount() {
        clearInterval(liveUpdateTimer);
    }
    onSearch = (e) => {
        this.applyFilter(e.target.value)
        this.setState({
            search: e.target.value
        });
    }

    applyFilter = (search) => {
        if (this.state.selectedMenu == "agent") {
            let filterAgentList = agentResp.agents.filter(function (item) {
                return item.name.toLowerCase().includes(search);
            });
            this.setState({ agentList: filterAgentList });
        } else if (this.state.selectedMenu == "group") {
            let filterGroupList = groupResp.groups.filter(function (item) {
                return item.name.toLowerCase().includes(search);
            });
            this.setState({ groupList: filterGroupList });
        }
    }

    onSelect = (selected, type) => {
        // if (type == "group" || type == "agent" && selected.state && selected.state.name == "available") {
        this.setState({
            selected,
            type,
            confirmTransfer: true
        });
        // }
    }

    onTransfer = () => {
        const { assignedChats } = this.props.chat;
        let isAutoPilot = false;
        if (this.props.chatId && assignedChats && assignedChats[this.props.chatId] && assignedChats[this.props.chatId].autoPilot) {
            isAutoPilot = assignedChats[this.props.chatId].autoPilot.isOn;
        }
        clearInterval(liveUpdateTimer);
        if (this.state.type == "group") {
            transferChatToGroup(this.props.chatId, this.state.selected._id, this.state.selected.name, isAutoPilot);
        } else if (this.state.type == "agent") {
            transferChatToAgent(this.props.chatId, this.state.selected._id, this.state.selected.name, isAutoPilot)
        }
        this.setState({
            confirmTransfer: false
        });
        this.onClose();
    }

    render() {
        let { auth } = this.props
        let visible = this.props.visible;
        return (
            <Drawer
                placement="right"
                closable={false}
                width={450}
                visible={visible}
            >
                <Row className="transfer">
                    <Col span={24} >
                        <Row>
                            <Col
                                className="header"
                                span={24}
                            >
                                <ArrowLeftOutlined
                                    onClick={this.onClose}
                                    className="back-arrow"
                                />
                                <span className="title">Chat Transfer</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="contain">
                                <div className="options">
                                    <div className="action">
                                        <Row className="menu">
                                            <Col className={this.state.selectedMenu == "agent" ? "item active" : "item"} onClick={() => this.setState({ selectedMenu: "agent", search: "", agentList: agentResp.agents })} span={12}>AGENTS</Col>
                                            <Col className={this.state.selectedMenu == "group" ? "item active" : "item"} onClick={() => this.setState({ selectedMenu: "group", search: "", groupList: groupResp.groups })} span={12}>GROUPS</Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Input onChange={(e) => this.onSearch(e)} className="search" value={this.state.search} placeholder={this.state.selectedMenu == "agent" ? "Search Agent Name" : "Search Group Name"} prefix={<SearchOutlined />} />
                                                <div className="list" style={{ height: this.state.windowHeight - 400 + "px" }}>
                                                    {this.state.selectedMenu == "agent" && this.state.agentList.map((item, index) => {
                                                        return (auth.info.agentId && auth.info.agentId != item._id) && item.isVirtualAgent != true && item.isActive && item.roles.indexOf("agent") > -1 && (
                                                            <div key={item._id} className="item" onClick={() => this.onSelect(item, "agent")}>
                                                                <div style={{ float: "left" }}><img className="icn" src={agentIcon} /></div>
                                                                <div style={{ marginLeft: "54px" }}>
                                                                    <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
                                                                        <div className="name ellipsis" title={item.name}>{item.name}</div>
                                                                        <div className={item && item.state && item.state.name == "available" ? "state available" : item.state.name == "unavailable" ? "state unavailable" : "state offline"}></div>
                                                                    </div>
                                                                    <div className="email">{item.email}</div>
                                                                </div>
                                                                {this.state.agentList.length - 1 != index && <Divider />}
                                                            </div>
                                                        );
                                                    })}
                                                    {this.state.selectedMenu == "group" && this.state.groupList.map((item, index) => {
                                                        return item.isActive && (
                                                            <div key={item._id} className="item" onClick={() => this.onSelect(item, "group")}>
                                                                <div style={{ height: "20px", marginBottom: "32px" }}>
                                                                    <div>
                                                                        <Icon theme={{ background: getRandomColor(item._id) }}>
                                                                            {item.name.charAt(0).toUpperCase()}
                                                                        </Icon>
                                                                    </div>
                                                                    <div style={{ marginLeft: "54px" }}>
                                                                        <div className="name ellipsis" title={item.name}>{item.name}</div>
                                                                        <div className={item.noOfAvailableAgents == 0 ? "counts" : "agent-available counts"}>Agents Available: {item.noOfAvailableAgents}</div>
                                                                    </div>
                                                                </div>
                                                                {this.state.groupList.length - 1 != index && <Divider />}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Modal
                    title="CHAT TRANSFER"
                    width="439px"
                    className="confirm-transfer"
                    visible={this.state.confirmTransfer}
                    onCancel={() => { this.setState({ confirmTransfer: false }) }}
                    footer={null}
                >
                    <div>
                        <div className="desc">Are you sure you want to transfer the chat to <span className="transfer-to">{this.state.type == "agent" ? this.state.type : ""} {this.state.selected && this.state.selected.name} {this.state.type == "group" ? this.state.type : ""}</span>?</div>
                        <div className="action">
                            <Button className="yes" onClick={this.onTransfer}>YES</Button>
                            <Button className="no" onClick={() => { this.setState({ confirmTransfer: false }) }}>NO</Button>
                        </div>
                    </div>
                </Modal>
            </Drawer >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        ui: state.ui,
        chat: state.chat,
        task: state.task,
    };
};
export default connect(mapStateToProps, {
    getAgents,
    getGroups,
})(Transfer);
