import * as React from "react";
import { Collapse, Select } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ThemeContext, IState } from "../../../../../themeDesigner";
import update from "immutability-helper";

const Panel = Collapse.Panel;

const { Option } = Select;

function callback(key) {
  console.log(key);
}

export interface ILayoutPropertiesProps {}
interface IOption {
  id: number;
  label: string;
  value: string;
}

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const displayOptions: IOption[] = [
  {
    id: 1,
    label: "None",
    value: "none",
  },
  {
    id: 2,
    label: "Flex",
    value: "flex",
  },
  {
    id: 3,
    label: "Block",
    value: "block",
  },
  {
    id: 4,
    label: "Inline Block",
    value: "inline-block",
  },
];

const directionOptions: IOption[] = [
  {
    id: 1,
    label: "Row",
    value: "row",
  },
  {
    id: 2,
    label: "Column",
    value: "column",
  },
  {
    id: 3,
    label: "Row Reverse",
    value: "row-reverse",
  },
  {
    id: 4,
    label: "Column Reverse",
    value: "column-reverse",
  },
];

const justifyOptions: IOption[] = [
  {
    id: 1,
    label: "Flex Start",
    value: "flex-start",
  },
  {
    id: 2,
    label: "Center",
    value: "center",
  },
  {
    id: 3,
    label: "Flex End",
    value: "flex-end",
  },
  {
    id: 4,
    label: "Space Between",
    value: "space-between",
  },
  {
    id: 5,
    label: "Space Around",
    value: "space-around",
  },
  {
    id: 6,
    label: "Space Evenly",
    value: "space-evenly",
  },
];

const alignOptions: IOption[] = [
  {
    id: 1,
    label: "Flex Start",
    value: "flex-start",
  },
  {
    id: 2,
    label: "Center",
    value: "center",
  },
  {
    id: 3,
    label: "Flex End",
    value: "flex-end",
  },
  {
    id: 4,
    label: "Space Between",
    value: "space-between",
  },
  {
    id: 5,
    label: "Space Around",
    value: "space-around",
  },
  {
    id: 6,
    label: "Space Evenly",
    value: "space-evenly",
  },
];

export default function LayoutProperties(props: ILayoutPropertiesProps) {
  const { secondaryColor: outlineColor } = useSelector(
    (state: IState) => state.ui.theme
  );
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: { header: layout },
  } = themeProps;
  const handleChange = (value: string, property: string) => {
    const updatedHeader = update(themeProps, {
      widget: {
        header: {
          [property]: { $set: value },
        },
      },
    });
    setThemeProps(updatedHeader);
  };
  return (
    <Collapse defaultActiveKey={["1"]} onChange={callback}>
      <Panel header="Layout" key="1">
        <DropdownWrapper>
          <span className="text">Display</span>

          <Select
            defaultValue={layout.display}
            style={{ width: 110, height: 28 }}
            onChange={(value) => handleChange(value, "display")}
            size="small"
          >
            {displayOptions.map((item: IOption) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </DropdownWrapper>
        <DropdownWrapper>
          <span className="text">Direction</span>

          <Select
            defaultValue={layout.flexDirection}
            style={{ width: 110, height: 28 }}
            onChange={(value) => handleChange(value, "direction")}
            size="small"
          >
            {directionOptions.map((item: IOption) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </DropdownWrapper>
        <DropdownWrapper>
          <span className="text">Justify Content</span>

          <Select
            defaultValue={layout.justifyContent}
            style={{ width: 110, height: 28 }}
            onChange={(value) => handleChange(value, "justifyContent")}
            size="small"
          >
            {justifyOptions.map((item: IOption) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </DropdownWrapper>
        <DropdownWrapper>
          <span className="text">Align Items</span>

          <Select
            defaultValue={layout.alignItems}
            style={{ width: 110, height: 28 }}
            onChange={(value) => handleChange(value, "alignItems")}
            size="small"
          >
            {alignOptions.map((item: IOption) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        </DropdownWrapper>
      </Panel>
    </Collapse>
  );
}
