import React from "react";
import { connect } from "react-redux";
import { Switch, Row, Col, Input, Button } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { isNotNullOrUndefinedString } from "../../../helpers/util";
import agentIcon from "../../../assets/images/agent-icon.svg";
import botIcon from "../../../assets/images/a8chat_icon.svg";
import { Select as A8_SELECT } from "a8-uicomponents";
import zxcvbn from "zxcvbn";
import styled from "styled-components";
import { checkPassword } from "../../../utils/passwordStrength";

const InputInstruction = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #95abd3;
  margin-bottom: 16px;
`;

const UppercaseLabel = styled.span`
  color: #95abd3;
`;

const LowercaseLabel = styled.span`
  color: #95abd3;
`;

const NumberLabel = styled.span`
  color: #95abd3;
`;

const SymbolLabel = styled.span`
  color: #95abd3;
`;

const InputError = styled.p`
  font-family: Muli;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #d30029;
`;

const ProgressIndicator = styled.div`
  background: #e0eafc;
  border-radius: 4px;
  width: 100%;
  height: 5px;
  margin-bottom: 36px;

  div {
    width: ${(props) => props.theme.width.toString() + "%"};
    height: 5px;
    transition: width 0.5s;
    background: ${(props) =>
    props.theme.width <= 25
      ? "#d30029"
      : props.theme.width > 25 && props.theme.width < 50
        ? "#fa7412"
        : props.theme.width > 50 && props.theme.width < 75
          ? "#698f3f"
          : props.theme.width > 75
            ? "#00be2c"
            : "#e0eafc"};
  }

  p {
    text-align: right;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${(props) =>
    props.theme.width <= 25
      ? "#d30029"
      : props.theme.width > 25 && props.theme.width < 50
        ? "#fa7412"
        : props.theme.width > 50 && props.theme.width < 75
          ? "#698f3f"
          : props.theme.width > 75
            ? "#00be2c"
            : "#e0eafc"};
  }
`;

let selectGPOptions = {
  defaultValue: [],
  optionValue: "_id",
  isSearch: false,
  title: "name",
  multiSelect: true,
  primaryColor: null,
};

let selectRolesOptions = {
  disabled: false,
  defaultValue: [],
  optionValue: "_id",
  isSearch: false,
  title: "name",
  multiSelect: true,
  primaryColor: null,
};

const roles = [
  {
    _id: "admin",
    name: "Admin",
  },
  {
    _id: "agent",
    name: "Agent",
  },
  {
    _id: "supervisor",
    name: "Supervisor",
  },
];

const passwordLevel = {
  weak: "Weak",
  good: "Good",
  medium: "Medium",
  great: "Great",
};

let theme = null;

class AgentSaveDetials extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      ...this.props.agentDetails,
      passwordStrength: 0,
      passwordStrengthLabel: "",
      passwordError: false,
      confirmPasswordError: ""
    };
    selectGPOptions.defaultValue = JSON.parse(
      JSON.stringify(this.state.groupIds ? this.state.groupIds : [])
    );
    selectRolesOptions.defaultValue = JSON.parse(
      JSON.stringify(this.state.roles ? this.state.roles : [])
    );
    if (this.props.agentDetails && this.props.agentDetails.isVirtualAgent) {
      this.onHandleVirtualAgent(true);
    }
  }

  componentWillMount() {
    this.setState({
      email: this.state.email && this.state.email.split("@")[0],
    });
    if (this.props.ui.theme) {
      theme = this.props.ui.theme;
    }
    selectGPOptions.primaryColor = theme.secondaryColor;
    selectRolesOptions.primaryColor = theme.secondaryColor;
  }

  onForceLogout = () => {
    if (typeof this.props.onForceLogout == "function") {
      debugger
      this.props.onForceLogout(this.props.agentDetails._id, this.props.agentDetails.name)
    }
  }

  onSubmit = () => {
    if (
      !isNotNullOrUndefinedString(this.fieldValidation()) &&
      !this.state.passwordError &&
      !this.state.confirmPasswordError
    ) {
      const payload = { ...this.state };
      delete payload.passwordStrength;
      delete payload.confirmPasswordError;
      delete payload.passwordError;
      delete payload.retypePassword;
      delete payload.passwordStrengthLabel;
      delete payload.action;
      delete payload.error;
      delete payload.state;
      delete payload.oauth;
      delete payload.bot;
      delete payload.lastTouched;
      if (payload.isVirtualAgent && !this.state.action) {
        delete payload.botHandle;
      }

      let data = JSON.parse(JSON.stringify(payload));
      data.email = `${data.email.split("@")[0]}@${this.props.auth.info.email.split("@")[1]
        }`;
      this.props.onSubmit(data);
    }
  };

  onClose = () => {
    this.props.onClose();
  };

  handlePasswordChange = (str) => {
    if (str) {
      const { score } = zxcvbn(str);
      switch (score) {
        case 0:
          this.setState({
            passwordStrengthLabel: passwordLevel.weak,
            passwordStrength: 10,
            passwordError: checkPassword(str),
          });
          break;
        case 1:
          this.setState({
            passwordStrengthLabel: passwordLevel.weak,
            passwordStrength: 20,
            passwordError: checkPassword(str),
          });
          break;
        case 2:
          this.setState({
            passwordStrengthLabel: passwordLevel.medium,
            passwordStrength: 45,
            passwordError: checkPassword(str),
          });
          break;
        case 3:
          this.setState({
            passwordStrengthLabel: passwordLevel.good,
            passwordStrength: 70,
            passwordError: checkPassword(str),
          });
          break;
        case 4:
          this.setState({
            passwordStrengthLabel: passwordLevel.great,
            passwordStrength: 100,
            passwordError: checkPassword(str),
          });
          break;
        default:
          break;
      }
    } else {
      this.setState({
        passwordStrengthLabel: "",
        passwordStrength: 0,
        passwordError: false,
      });
    }
  };

  fieldValidation = () => {
    let error = "";
    if (
      !isNotNullOrUndefinedString(this.state.name) ||
      !isNotNullOrUndefinedString(this.state.email)
    ) {
      error = "Please enter the all fields";
    } else if (
      this.state.isVirtualAgent &&
      !isNotNullOrUndefinedString(this.state.botHandle)
    ) {
      error = "Please enter the bot name";
    } else if (!isNotNullOrUndefinedString(this.state.name)) {
      error = "Please enter the name";
    } else if (!this.state.email) {
      error = "Please enter the email";
    } else if (/^[a-zA-Z0-9._-]+$/.test(this.state.email) == false) {
      error = "Please enter the valid email";
    } else if (
      this.state.action &&
      !isNotNullOrUndefinedString(this.state.password)
    ) {
      error = "Please enter the password";
    } else if (
      this.state.action &&
      !isNotNullOrUndefinedString(this.state.retypePassword)
    ) {
      error = "Please enter the confirm password";
    } else if (
      this.state.action &&
      this.state.password != this.state.retypePassword
    ) {
      error = "Retype password mismatch";
    } else if (!this.state.groupIds || this.state.groupIds.length == 0) {
      error = "Please choose the group";
    } else if (!this.state.roles || this.state.roles.length == 0) {
      error = "Please select the role";
    }
    this.setState({ error: error });
    return error;
  };

  loadGruopDetails = () => {
    // let groups = this.props.group.groups;
    // for (let i in groups) {
    //     groupOpt.push(<Option key={groups[i]._id} value={groups[i]} >{groups[i].name}</Option>);
    // };
    // return groupOpt;
  };

  onHandleVirtualAgent = (checked) => {
    this.setState({
      isVirtualAgent: checked,
    });
    if (checked) {
      this.setState({
        roles: ["agent"]
      });
    } else {
      this.setState({
        roles: []
      });
    }
    selectRolesOptions.disabled = checked;
    selectRolesOptions.defaultValue = checked ? ["agent"] : [];
  };

  onBulkUpload = () => {
    this.props.onBulkUpload("agents");
  };

  componentWillReceiveProps(props) {
    if (props.ui.theme) {
      theme = props.ui.theme;
      selectGPOptions.primaryColor = theme.secondaryColor;
      selectRolesOptions.primaryColor = theme.secondaryColor;
    }
  }

  handleConfirmPasswordBlur = () => {
    this.setState((prevState) => ({
      confirmPasswordError: prevState.password !== prevState.retypePassword,
    }));
  };

  loadAgentStatus = () => {
    if (!this.state.state || !this.state.state.name || this.state.state.name == "offline") {
      return false;
    } else {
      return true;
    }
  }
  canForceLogout = () => {
    const agentDetails = this.props.agentDetails;
    const { roles, agentId } = this.props.auth.info;
    let isForceLogout: boolean = false;
    if (!agentDetails.isVirtualAgent && agentId !== agentDetails._id) {
      isForceLogout = roles.includes("admin");
      if (!isForceLogout) {
        isForceLogout = roles.includes("supervisor") && !agentDetails.roles.includes("admin") && !agentDetails.roles.includes("supervisor");
      }
    }
    return isForceLogout;
  }

  render() {
    let groups = this.props.group.groups;
    let auth = this.props.auth.info;
    let disabled = (auth.roles.includes("supervisor") && !auth.roles.includes("admin"));
    return (
      <div className="agent-details">
        <Row>
          <Col span={24}>
            <Row>
              <Col
                style={{
                  fontSize: "24px",
                  color: "#212332",
                  fontWeight: 600,
                }}
                span={11}
              >
                <ArrowLeftOutlined
                  onClick={this.onClose}
                  style={{
                    cursor: "pointer",
                    color: "#a8abbd",
                    position: "absolute",
                    marginTop: "6px",
                  }}
                />
                <span style={{ marginLeft: "32px" }}>
                  {this.state.action ? "New " : (auth.roles.includes("supervisor") && auth.roles.length == 1) ? "View " : "Update "} Details
                </span>
              </Col>
              <Col span={11} style={{ textAlign: "right", color: "#ff3300" }}></Col>
              {!disabled && <Col span={2} onClick={this.onBulkUpload}>
                <UploadOutlined className="bulkupload" title="Bulk Upload" />
              </Col>}
            </Row>

            <Row style={{ marginTop: "24px" }}>
              <Col span={1}></Col>
              <Col span={5} className="upload">
                {!this.state.isVirtualAgent && <span className={this.state.state && this.state.state.name ? this.state.state.name : "offline"}></span>}
                <img src={this.state.isVirtualAgent ? botIcon : agentIcon} />
                {/* <span className="text">Upload Photo</span> */}
              </Col>
              <Col span={1}></Col>
              <Col span={17}>
                <div className="form-group">
                  <div className="label">NAME</div>
                  <div className="value">
                    <Input
                      disabled={disabled}
                      className="form-control"
                      value={this.state.name}
                      onChange={(event) =>
                        this.setState({ name: event.target.value })
                      }
                    />
                  </div>
                </div>
                {(this.state.action || this.state.isVirtualAgent) && <div className="form-group">
                  <div className="value">
                    <Switch
                      disabled={!this.state.action}
                      defaultChecked={this.state.isVirtualAgent}
                      onChange={(checked) => this.onHandleVirtualAgent(checked)}
                    />
                    <span
                      style={{
                        marginLeft: "10px",
                        fontSize: "12px",
                        fontWeight: 700,
                      }}
                    >
                      VIRTUAL AGENT {this.state.isVirtualAgent ? "ON" : "OFF"}
                    </span>
                  </div>
                </div>}
                {this.state.isVirtualAgent && (
                  <div className="form-group">
                    <div className="label">BOT NAME</div>
                    <div className="value">
                      <Input
                        disabled={!this.state.action}
                        className="form-control"
                        value={this.state.botHandle}
                        onChange={(event) =>
                          this.setState({ botHandle: event.target.value })
                        }
                      />
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <div className="label">EMAIL ID</div>
                  <div className="value">
                    <Input
                      disabled={disabled}
                      className="form-control"
                      value={this.state.email && this.state.email.split("@")[0]}
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                    />
                    <span>
                      {this.state.email && this.state.email.split("@")[0]}@
                      {auth.email && auth.email.split("@")[1]}
                    </span>
                  </div>
                </div>
                {this.state.action && (
                  <div className="form-group">
                    <div className="label">PASSWORD</div>
                    <div className="value">
                      <Input.Password
                        className="form-control"
                        value={this.state.password}
                        onChange={(event) => {
                          this.setState({ password: event.target.value });
                          this.handlePasswordChange(event.target.value);
                        }}
                      />
                      {this.state.action && this.state.passwordError && (
                        <InputError>Invalid Password</InputError>
                      )}
                    </div>
                  </div>
                )}

                {this.state.action && (
                  <InputInstruction>
                    Password must contain minimum{" "}
                    <UppercaseLabel>1 uppercase</UppercaseLabel>,{" "}
                    <LowercaseLabel>1 lowercase</LowercaseLabel>,{" "}
                    <NumberLabel>1 number</NumberLabel>,{" "}
                    <SymbolLabel>1 symbol(@#$%!)</SymbolLabel> in it.
                  </InputInstruction>
                )}
                {this.state.action && (
                  <div className="form-group">
                    <ProgressIndicator
                      theme={{ width: this.state.passwordStrength }}
                    >
                      <div />
                      <p>{this.state.passwordStrengthLabel}</p>
                    </ProgressIndicator>
                  </div>
                )}
                {this.state.action && (
                  <div className="form-group">
                    <div className="label">CONFIRM PASSWORD</div>
                    <div className="value">
                      <Input.Password
                        className="form-control"
                        value={this.state.retypePassword}
                        onBlur={this.handleConfirmPasswordBlur}
                        onChange={(event) =>
                          this.setState({ retypePassword: event.target.value })
                        }
                      />
                      {this.state.action && this.state.confirmPasswordError && (
                        <InputError>
                          Please make sure your passwords match
                        </InputError>
                      )}
                    </div>
                  </div>
                )}

                <div className="form-group">
                  <div className="label">ADD TO GROUPS</div>
                  <div className="value">
                    <A8_SELECT
                      data={groups}
                      options={Object.assign(selectGPOptions, { disabled })}
                      onChange={(arrayList, arrayObjList) =>
                        this.setState({ groupIds: arrayList })
                      }
                    ></A8_SELECT>
                  </div>
                </div>
                <div className="form-group">
                  <div className="label">ADD TO ROLES</div>
                  <div className="value">
                    <A8_SELECT
                      data={roles}
                      options={Object.assign(selectRolesOptions, { disabled: disabled || this.state.isVirtualAgent })}
                      onChange={(items) => this.setState({ roles: items })}
                    ></A8_SELECT>
                  </div>
                </div>
                {!this.state.action && (
                  <div className="form-group">
                    <div className="value">
                      <Switch
                        disabled={disabled}
                        defaultChecked={this.state && this.state.isActive}
                        onChange={(checked) => this.setState({ isActive: checked })}
                      />
                      <span
                        style={{
                          marginLeft: "10px",
                          fontSize: "12px",
                          fontWeight: 700,
                        }}
                      >
                        {this.state && this.state.isActive ? "ACTIVE" : "INACTIVE"}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={7}></Col>
          <Col span={17}>
            <div className="form-group">
              <div style={{ color: "red", fontWeight: 800 }}>
                {this.state.error}
              </div>
            </div>
            <div className="form-group action">
              {auth.roles.includes("admin") && <Button
                className="button button-primary submit"
                onClick={this.onSubmit}
                style={{ width: (!this.props.agentDetails.isVirtualAgent && this.loadAgentStatus() && (this.props.agentDetails.roles && this.props.agentDetails.roles.includes("agent")) && this.props.agentDetails.roles.length == 1 && this.props.agentDetails._id && auth.roles.includes("supervisor")) ? "auto" : "100%" }}
              >
                {this.state.action ? "SAVE" : "UPDATE"} INFO
              </Button>}
              {this.loadAgentStatus() && this.canForceLogout() && <Button
                style={{ marginLeft: "16px", width: auth.roles.length == 1 && auth.roles.includes("supervisor") ? "100%" : "auto" }}
                className="button button-primary force-logout"
                onClick={this.onForceLogout}
              >
                Force Logout
              </Button>}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("Agent mapStateToProps: ", state);
  return {
    ui: state.ui,
    auth: state.auth,
    agent: state.agent,
    group: state.group,
  };
}

export default connect(mapStateToProps, {})(AgentSaveDetials);
