import React from "react";
import styled from "styled-components";
import { FaFilePdf } from "react-icons/fa";

interface IDocumentProps {
  isPrivate?: boolean;
  handleDocumentClick: () => {};
  isUser: boolean;
  desc?: string
}

const Container = styled.div`
  width: 150px;
  /* height: 200px; */
  background: ${(props) => (props.theme.isPrivate ? "#2b2b2b" : "#FBEBE3")};
  padding: 10px;
  border-radius: 10px 10px 35px 10px;
  margin-bottom: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 15px;
  color: #555555;
  text-transform: uppercase;
  margin-top: 16px;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const SubtitleText = styled.div`
  font-size: 13px;
  color: ${(props) => (props.theme.isPrivate ? "#d6d6d6" : "#777777")};
  margin-top: 16px;
`;

const Layout = styled.div`
  display: flex;
  justify-content: ${(props) => (props.theme.isUser ? "flex-start" : "flex-end")};
`;

const DocumentComponent = (props: IDocumentProps) => {
  const { handleDocumentClick, isPrivate, isUser, desc } = props;
  const subtitle = "Click here to view";
  return (
    <Layout theme={{ isUser }}>
      <Container theme={{ isPrivate }} onClick={handleDocumentClick}>
        <InnerContainer>
          <FaFilePdf size="100px" color={isPrivate ? "#eeeeee" : "#ff874b"} />
          {/* <Title>{data.msg.fileName}</Title> */}
          <SubtitleText theme={{ isPrivate }}>{subtitle}</SubtitleText>
          <div style={{ wordBreak: "break-all", paddingTop: "12px" }}>{desc}</div>
        </InnerContainer>
      </Container>
    </Layout>
  );
};

export default DocumentComponent;
