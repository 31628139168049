/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { generateUniqueId, toCustomDateTimeFormat } from "./util";
import { store } from "../store";
import Paho from "paho-mqtt";
import NewMessageTone from "../assets/audio/msgNotification.mp3";
import NewChatTone from "../assets/audio/newChatNotification.mp3";
import Icon from "../assets/images/a8-logo.svg";
// import { newTransferChat, newTestFunction } from "./rxServices";
import { newChatNotification } from "../components/common/toaster";
import {
  updateChatById,
  // getAssignedChats,
  updateSelectedLiveChatId,
  updateAgentNotification,
  updateMonitoredChats,
  updateUnmonitoredChats,
  updateMonitorChatById,
  getChatById,
  clearSelectedMonitorChat,
  assignedChatById,
  // updateAssignedChatMessageById,
  removeUnmonitorChatOnComplete,
  removeChatFromMonitor,
  updateUserChatMsgPreview,
  addToUnmonitoredChatIds,
  removeFromUnmonitoredChatIds,
  addToCompletedChats,
} from "../actions/chatsAction";
import { addToUnread } from "../actions/uiAction";
import { setSelectedChatMonitorGroup } from "../actions/taskAction";
import { updateToast } from "../actions/toastAction";
import update from "immutability-helper";
import type from "../types/actionTypes";
import { notification } from "antd";
import { setAgentState } from "../actions/authActions";
import { updateAgentInformation } from "../actions/agentsAction";
import { initSoloSession, setShowSoloWidget } from "../actions/soloWidgetActions";
import { cleanupSoloSession, openSoloWidget } from "../components/chats/SoloWidget/utils";
import { getDomain } from "../utils/helpers";
let subscribeTypesenseIds = [];

function playNewMsgSound() {
  const audio = new Audio(NewMessageTone);
  audio.play();
}

function playNewChatSound() {
  const audio = new Audio(NewChatTone);
  audio.play();
}

const actionNotification = (notificationType, title, message) => {
  notification[notificationType]({
    message: title,
    description: message,
  });
  setTimeout(function () {
    notification.close();
  }, 1000);
};

const monitorNotification = (notificationType, title, message) => {
  const args = {
    message: title,
    description: message,
    duration: 3,
  };
  notification[notificationType](args);
};

const array2Objects = (array = [], field) => {
  return array.reduce((a, c) => {
    a[c[field]] = c;
    return a;
  }, {});
};

let clientId = null;
let mqtt = null;
let auth = null;
let userInfo = null;
let sessionId = null;
let agentMQTTTopicPrefix = null;
let isBlurred = false;
// let botDestination = null;
let myDestination = null;
let mqttCheckTimer = null;
let transferChatIds = [];

let hidden = "";
// let visibilityChange = "";
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  // visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  // visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  // visibilityChange = "webkitvisibilitychange";
}

window.addEventListener("blur", () => {
  isBlurred = true;
});

window.addEventListener("focus", () => {
  isBlurred = false;
});

const onConnectMQTT = () => {
  auth = store.getState().auth;
  if (!auth || !auth.sessionToken) {
    clearInterval(mqttCheckTimer);
    userInfo = null;
    sessionId = null;
    return;
  }
  userInfo = auth.info;
  if (!userInfo) {
    return;
  }
  sessionId = auth.sessionToken.sessionId;
  clientId =
    sessionStorage.clientId === undefined || sessionStorage.clientId === ""
      ? generateUniqueId("MQTT")
      : sessionStorage.clientId;
  sessionStorage.clientId = clientId;
  let urlParser = document.createElement("a");
  urlParser.href = window.baseURL;
  // let hostName = urlParser.hostname;
  let hostName = auth.info.soloDomain
  let portNo = urlParser.protocol === "https:" ? 443 : 80;
  
  mqtt = new Paho.Client(hostName, portNo, clientId);

  let mqttUsername = `a8chatAgent-${userInfo.orgName}-${userInfo.agentId}`
  let mqttPassword = userInfo.accessToken;
  agentMQTTTopicPrefix = `a8chat/${userInfo.orgName}/agents/${userInfo.agentId}`
  let isSSLEnable = urlParser.protocol === "https:" ? true : false;

  // set callback handlers
  mqtt.onConnectionLost = onConnectionLost;
  mqtt.onMessageArrived = onMessageArrived;
  let payload = {
    status: "offline",
    sessionId,
  };
  //set will lost message
  let willMsg = new Paho.Message(JSON.stringify(payload));
  willMsg.retained = true;
  willMsg.qos = 1;
  willMsg.destinationName = agentMQTTTopicPrefix + "/clients/" + clientId + "/status";
  // connect the client
  mqtt.connect({
    useSSL: isSSLEnable,
    onSuccess: onConnect,
    onFailure: function (data) {
      console.log(data.errorMessage);
    },
    userName: mqttUsername,
    password: mqttPassword,
    willMessage: willMsg,
  });
};

const onConnect = () => {
  // debugger;
  console.log("%c MQTT CONNECTED ", "color: green; background: yellow; font-size: 30px");

  if (!userInfo || !sessionStorage.clientId) {
    return;
  }

  myDestination = "orgs-" + userInfo.orgId + "/agents/" + userInfo.agentId + "/directstream";

  //Set Agent State to Online
  let payload = {
    status: "online",
    sessionId,
  };
  //set will lost message
  let message = new Paho.Message(JSON.stringify(payload));
  message.retained = true;
  message.destinationName = agentMQTTTopicPrefix + "/clients/" + clientId + "/status";
  if (mqtt && mqtt.isConnected()) {
    mqtt.send(message);
    //Subscribe Response
    mqtt.subscribe(agentMQTTTopicPrefix + "/response");

    //Subscribe Events
    mqtt.subscribe(agentMQTTTopicPrefix + "/events");

    //Subscribe Inbox
    mqtt.subscribe(agentMQTTTopicPrefix + "/inbox");

    mqtt.subscribe(agentMQTTTopicPrefix + "/request");

    mqtt.subscribe(agentMQTTTopicPrefix + "/directstream");

    mqtt.subscribe("orgs-" + userInfo.orgId + "/agents/+/directStream");
    mqtt.subscribe(myDestination);

    // mqtt.subscribe("World");
    // const message = new Paho.Message("Hello");
    // message.destinationName = "World";
    // mqtt.send(message);

    if (mqttCheckTimer) {
      clearInterval(mqttCheckTimer);
    }
    mqttCheckTimer = setInterval(function () {
      if (mqtt && mqtt.isConnected() === false && userInfo.orgId) {
        onConnectMQTT();
      }
    }, 5000);
  }
};

const onConnectionLost = (e) => {
  console.log("%c MQTT CONNECTION LOST ", "color: red; background: yellow; font-size: 30px");
  console.log(e.errorMessage);
  //TODO Clear session
  console.log(
    `%c MQTT Agent Offline  ${toCustomDateTimeFormat(new Date().getTime())} `,
    "color: green; background: yellow; font-size: 30px"
  );
};

const onMessageArrived = (message) => {
  // console.log(`Payload ${message.payloadString}`);
  console.log(`Destination Name ${message.destinationName}`);
  if (message.destinationName.includes("typesense")) {
    onTypesense(message.payloadString);
  } else if (message.destinationName.includes("inbox")) {
    onInbox(message.payloadString);
  } else if (message.destinationName.includes("response")) {
    onResponse(message.payloadString);
  } else if (message.destinationName.includes("events")) {
    onEvents(message);
  } else if (message.destinationName.includes("directstream")) {
    onDirectStream(message.payloadString);
  } else if (message.destinationName.includes("request")) {
    console.log("REQUEST PAYLOAD");
    console.log(JSON.parse(message.payloadString));
  }
};

const onTypesense = (receivedPayload) => {
  const payload = JSON.parse(receivedPayload);
  store.dispatch(updateUserChatMsgPreview(payload));
};

export const subscribeTypesense = (mqqtClientId) => {
  if (subscribeTypesenseIds.indexOf(mqqtClientId) === -1) {
    mqtt.subscribe("orgs-" + userInfo.orgId + "/clients/" + mqqtClientId + "/typesense");
    subscribeTypesenseIds.push(mqqtClientId);
  }
};

const onInbox = (receivedPayload) => {
  let payload = JSON.parse(receivedPayload);
  payload.fromInbox = true
  const isUser = payload.fromId.includes("u/");
  const { chatId } = payload;
  const { activeRootTab } = store.getState().ui;
  const {
    auth: { info },
    chat: { selectedLiveChatId },
  } = store.getState();
  if("eventPayload" in payload && payload.eventPayload.event === "nextNode") {
    const isMessageNode = Array.isArray(payload.msg.messages)
    const soloWidget = store.getState().soloWidget
    const isCurrentSession = selectedLiveChatId === chatId && soloWidget.sessionId === payload.eventPayload.sessionId
    if(payload.eventPayload.properties && 
      payload.eventPayload.properties.nodeType !== "form") {
      payload.nodeType = "waitingForm"
    }
    if(isMessageNode) {
      payload = payload.msg.messages.map((el, i) => ({
        mId: payload.mId + "_" + i,
        msg: el.value,
        msgType: el.type,
        fromId: payload.fromId,
        fromName: payload.fromName,
        sentAt: payload.sentAt,
        fromInbox: true,
      }))
      store.dispatch(setShowSoloWidget(false))
    } else if(isCurrentSession && soloWidget.show) {
     openSoloWidget({
      accessToken: info.accessToken,
      sessionId: payload.msg.sessionId,
      appId: payload.msg.appId,
      formKey: payload.msg.formKey,
      isCompleted: false,
      nodeId: payload.msg.nodeId,
      nodeName: payload.msg.nodeName,
     }) 
    }
  }
  if (activeRootTab === "monitor") {
    store.dispatch(updateMonitorChatById(chatId, payload));
  } else {
    const isActiveAgent = info.agentId === payload.handlingAgent;
    const isSelected = selectedLiveChatId === chatId;
    store.dispatch(updateChatById(chatId, payload, isActiveAgent, isSelected));
    if ((document[hidden] || isBlurred) && isUser) {
      const { fromName } = payload;
      const options = {
        body: payload.msg.text,
        iconUrl: Icon,
        image: Icon,
        badge: Icon,
        tag: "assignChat",
        renotify: true,
      };
      playNewMsgSound();
      const notification = new Notification(`${fromName} says`, options);
      notification.onclick = function () {
        window.focus(); //just in case, older browsers
        this.close();
      };
    }
  }
};

const onResponse = (receivedPayload) => {
  console.log("onResponse", receivedPayload);
  const payload = JSON.parse(receivedPayload);
  if (payload.requestId && payload.requestId.includes("AUTOPILOT")) {
    const autoPilotChatInfo = JSON.parse(localStorage.getItem("autoPilotChatInfo"));

    if (autoPilotChatInfo[payload.requestId]) {
      let { chatId, flag, failureMsg } = autoPilotChatInfo[payload.requestId];

      if (payload.error) {
        failureMsg = payload.error.message ? payload.error.message : failureMsg;
        console.log(failureMsg)
        // actionNotification("error", title, failureMsg);
      } else {
        // actionNotification("info", title, successMsg);
        const { assignedChats } = store.getState().chat;
        if (assignedChats[chatId]) {
          const updatedAssignChat = update(assignedChats, {
            [chatId]: {
              autoPilot: { $set: { isOn: flag, mode: "autoPilot" } },
            },
          });

          store.dispatch({
            type: type.MODIFY_ASSIGNED_CHAT,
            data: updatedAssignChat,
          });
        }
      }
    }
  } else if (payload.requestId && payload.requestId.includes("TRANSFER")) {
    const transferedChatInfo = JSON.parse(localStorage.getItem("transferedChatInfo"));
    if (transferedChatInfo[payload.requestId]) {
      let { chatId, successMsg, failureMsg, title } = transferedChatInfo[payload.requestId];
      transferChatIds.splice(transferChatIds.indexOf(chatId), 1);
      if (payload.error) {
        failureMsg = payload.error.message ? payload.error.message : failureMsg;
        actionNotification("error", title, failureMsg);
      } else {
        actionNotification("info", title, successMsg);
        store.dispatch(updateSelectedLiveChatId("", ""));
        const { assignedChats } = store.getState().chat;
        const updatedAssignChat = { ...assignedChats };
        delete updatedAssignChat[chatId];
        store.dispatch({
          type: type.MODIFY_ASSIGNED_CHAT,
          data: updatedAssignChat,
        });
      }
    }
  } else if (payload.requestId && payload.requestId.includes("MONITOR")) {
    if (payload.error && payload.error.code && payload.error.message) {
      const title = payload.error.code.replace("_", " ").toUpperCase();
      actionNotification("error", title, payload.error.message);
      if (payload.error.code === "start_monitor_failed") {
        store.dispatch(setSelectedChatMonitorGroup(false));
        store.dispatch(clearSelectedMonitorChat());
      }
    } else {
      console.log(`Honour Response: Monitor: Missing Payload Values: Payload: ${JSON.stringify(payload)} `);
    }
  }
};

const onDirectStream = (receivedPayload) => {
  console.log("onDirectStream", receivedPayload);
  const parsedPayload = JSON.parse(receivedPayload)
  const { event, chatId, data } = parsedPayload
  const selectedLiveChatId = store.getState().chat.selectedLiveChatId;
  const soloWidget = store.getState().soloWidget

  switch (event) {
    case "notifyAgent":
      if (chatId === selectedLiveChatId) {
        store.dispatch(updateAgentNotification(data.text));
      }
      break;
    case "endFlow": {
      if (chatId === selectedLiveChatId && soloWidget.sessionId === parsedPayload.sessionId) {
        store.dispatch(initSoloSession(null))
        store.dispatch(setShowSoloWidget(false))
        cleanupSoloSession()
      }
      break;
    }
    default:
      break;
  }
};

function onRemoveListener() { }

const onEvents = (message) => {
  console.log("%c MQTT EVENT ", "color: green; background: yellow; font-size: 20px");
  console.log("Payload: ", message.payloadString);
  console.log(`Destination Name: ${message.destinationName}`);
  const { events } = JSON.parse(message.payloadString);
  events &&
    events.length > 0 &&
    events.forEach(async (event) => {
      console.log(`Event Name: ${event.name}`);
      if (event.name === "transferChat") {
        // newTransferChat.sendMessage(event.params);
        // console.log(newTransferChat.getMessage());
        const { data } = store.getState().toast;
        newChatNotification(event.params.chatId);
        store.dispatch({
          type: type.UNASSIGNED_GET_CHATS_SUCCESS,
          data: {
            chats: [{ ...event.params, state: "transferred", _id: event.params.chatId }],
            lastTouched: Date.now(),
          },
        });
        store.dispatch(updateToast(data.concat(event.params)));
      } else if (event.name === "assignChat") {
        console.log(event.params, "ASSIGNED CHATS");
        const { activeRootTab } = store.getState().ui;
        const { roles } = store.getState().auth.info;
        const { completedChats } = store.getState().chat;
        if (activeRootTab === "monitor" && !completedChats.includes(event.params.chatId)) {
          const { monitoredChats, unMonitoredChats } = store.getState().chat;
          const chatIds = [...Object.keys(monitoredChats), ...Object.keys(unMonitoredChats)];
          const isChatPresent = chatIds.find((chatId) => chatId === event.params.chatId);

          if (isChatPresent) {
            const { monitoredChats, unMonitoredChats, selectedMonitorChatId } = store.getState().chat;

            for (let chatId in monitoredChats) {
              if (chatId === event.params.chatId) {
                monitoredChats[chatId].handlingAgent = event.params.agentId;
              }
            }

            for (let chatId in unMonitoredChats) {
              if (chatId === event.params.chatId) {
                unMonitoredChats[chatId].handlingAgent = event.params.agentId;
              }
            }

            store.dispatch({ type: "SET_UNMONITORED_CHATS", data: unMonitoredChats, selectedMonitorChatId });
            store.dispatch({ type: "SET_MONITORED_CHATS", data: monitoredChats, selectedMonitorChatId });
          }
          if (!isChatPresent) {
            getChatById(event.params.chatId)().then((response) => {
              store.dispatch(updateUnmonitoredChats(event.params.chatId, response));
            });
          }
        } else if (activeRootTab === "live") {
          const { params } = event;
          const {
            auth: { info },
          } = store.getState();
          if (params.agentId === info.agentId) {
            store.dispatch(assignedChatById(params.chatId, params));
            store.dispatch(addToUnread(params.chatId));
            const options = {
              type: "basic",
              body: `${params.clientData.fromName} has a message`,
              iconUrl: Icon,
              image: Icon,
              badge: Icon,

              tag: "assignChat",
              renotify: true,
            };
            if (document[hidden] || isBlurred) {
              playNewChatSound();
              const notification = new Notification("Incoming Chat", options);
              notification.onclick = function () {
                window.removeEventListener("blur", onRemoveListener, false);
                window.focus(); //just in case, older browsers
                this.close();
              };
            }

            // if (document[hidden]) {
            //   const options = {
            //     body: `${params.clientData.fromName} has a message`,
            //     icon: "/static/media/a8-footer-logo.459e2363.svg",
            //     tag: "assignChat",
            //     renotify: true,
            //   };
            //   const notification = new Notification("Incoming Chat", options);
            //   notification.onclick = function () {
            //     window.focus(); //just in case, older browsers
            //     this.close();
            //   };
            // }
          }
        } else if (activeRootTab !== "monitor" && roles.includes("supervisor")) {
          store.dispatch(addToUnmonitoredChatIds(event.params.chatId));
        }

        // const { assignedChats: oldAssignedChats } = store.getState().chat;
        // getChatById(event.params.chatId)().then((data) => {
        //   const newAssignedChats = update(oldAssignedChats, {
        //     [data._id]: { $set: data },
        //   });
        //   store.dispatch({
        //     type: type.MODIFY_ASSIGNED_CHAT,
        //     data: newAssignedChats,
        //   });
        // });
        // store.dispatch(updateSelectedLiveChatId(event.params.chatId));
        // newChatNotification(event.params.chatId);
        // newTestFunction.dispatchEvent("Invoke Assigned Chats here");
      } else if (event.name === "startMonitor") {
        getChatById(event.params.chatId)().then((response) => {
          monitorNotification(
            "info",
            "Monitor Started",
            `You are now monitoring the chat between Agent and ${response.clientData.fromName}`
          );
          store.dispatch(updateMonitoredChats(event.params.chatId, response));
        });
      } else if (event.name === "stopMonitor") {
        const { agents: agentReducer } = store.getState().agent;

        // const { group: groupReducer, agent: agentReducer } = useSelector(
        //   (state: any) => state
        // );
        // const groups = array2Objects(groupReducer.groups, "_id");
        const agents = array2Objects(agentReducer, "_id");

        const { groups: agentGroups } = store.getState().auth.info;

        const loggedInAgentGroups = array2Objects(agentGroups, "_id");

        getChatById(event.params.chatId)().then((response) => {
          monitorNotification(
            "info",
            "Monitor Stopped",
            `You Stopped monitoring the chat between Agent and ${response.clientData.fromName}`
          );

          store.dispatch(clearSelectedMonitorChat());
          store.dispatch(updateUnmonitoredChats(event.params.chatId, response));
          // condition to check if the currently handled agent is a bot - if bot just remove the chat without unmonitoring
          if (agents.hasOwnProperty(response.handlingAgent) && agents[response.handlingAgent].isVirtualAgent === true) {
            // remove the chat from monitor tab alone
            store.dispatch(removeChatFromMonitor(event.params.chatId));
          } else if (
            //  Checking whether Agent or supervisor any one of them is missing
            (agents[response.handlingAgent].groupIds.includes(response.groupId) &&
              !loggedInAgentGroups.hasOwnProperty(response.groupId)) ||
            (!agents[response.handlingAgent].groupIds.includes(response.groupId) &&
              loggedInAgentGroups.hasOwnProperty(response.groupId))
          ) {
            // remove the chat from monitor tab alone
            store.dispatch(removeChatFromMonitor(event.params.chatId));
          } else {
            store.dispatch(updateUnmonitoredChats(event.params.chatId, response));
          }
        });
      } else if (event.name === "agentStateChanged") {
        const { state } = event.params;
        if (state && state.name === "offline") {
          console.log(store.getState().auth);
          if (store.getState().auth && store.getState().auth.info) {
            store.dispatch({
              type: type.ERROR,
              data: {
                code: "access_denied",
                status: 401,
              },
            });
          }
        } else {
          store.dispatch(setAgentState(state));
        }
      } else if (event.name === "agent.onChatComplete" || event.name === "agent.onChatTransfer") {
        const { chatId } = event.params;
        const { assignedChats } = store.getState().chat;
        const {
          chat: { selectedLiveChatId },
        } = store.getState();
        const updatedAssignChat = { ...assignedChats };
        delete updatedAssignChat[chatId];

        if (chatId === selectedLiveChatId) {
          store.dispatch(updateSelectedLiveChatId("", ""));
        }

        store.dispatch({
          type: type.MODIFY_ASSIGNED_CHAT,
          data: updatedAssignChat,
        });

        if (event.name === "agent.onChatComplete") {
          const { chatId } = event.params;
          const { selectedMonitorChatId } = store.getState().chat;
          const isSelectedChat = selectedMonitorChatId === chatId ? true : false;
          store.dispatch(removeUnmonitorChatOnComplete(chatId, isSelectedChat));
          store.dispatch(removeFromUnmonitoredChatIds(chatId));
          store.dispatch(addToCompletedChats(chatId));
        }
      } else if (event.name === "agentUpdated") {
        store.dispatch(updateAgentInformation(event.params.agentId));
      }
    });
};

const onPickupChat = (chatId) => {
  let pickupChatInfo = {
    id: generateUniqueId("PICKUP"),
    uri: "chats/" + chatId + "/pickup",
    params: {},
    body: {},
    sessionId,
  };
  let message = new Paho.Message(JSON.stringify(pickupChatInfo));
  message.destinationName = agentMQTTTopicPrefix + "/request";
  mqtt.send(message);
};

const sendChatMessage = ({ msg, msgType, chatId, isPrivate }) => {
  if (msg.text) {
    msg.text = msg.text.replace(/(\r\n|\n|\r)/g, "<br />");
  }
  const metaData = {
    assist: false,
    assistPassed: false,
    assistEdited: false,
    pilotMode: false,
    pilotPassed: false,
    isFaq: false,
    faqId: null,
    wordCount: msg.length,
  };

  const { agentId, agentName } = store.getState().auth.info;
  const payload = {
    sessionId,
    id: new Date().getTime(),
    chatId,
    fromId: `a/${agentId}`,
    fromName: agentName,
    msgType: msgType,
    msg,
    sentAt: new Date().getTime(),
    metaData: metaData,
    isPrivate,
  };

  const message = new Paho.Message(JSON.stringify(payload));
  message.destinationName = agentMQTTTopicPrefix + "/outbox";
  mqtt.send(message);
};

const autoPilot = (chatId, flag) => {
  const transferId = generateUniqueId("AUTOPILOT");
  const autoPilotChatInfo = {};

  autoPilotChatInfo[transferId] = {
    chatId: chatId,
    status: "PILOTMODE",
    title: "AUTO PILOT",
    successMsg: `Auto pilot mode ${flag === true ? "ON" : "OFF"} successfully`,
    failureMsg: `Auto pilot mode ${flag === true ? "ON" : "OFF"} failure`,
    flag: flag,
  };

  var uri = "";
  if (flag) {
    uri = "chats/" + chatId + "/turnOnAutoPilot";
  } else {
    uri = "chats/" + chatId + "/turnOffAutoPilot";
  }

  localStorage.setItem("autoPilotChatInfo", JSON.stringify(autoPilotChatInfo));
  var pickupChatInfo = {
    id: transferId,
    uri: uri,
    sessionId,
    body: {
      mode: "autoPilot",
      isOn: flag,
    },
  };

  localStorage.setItem("autoPilotInfo", JSON.stringify(pickupChatInfo));
  const message = new Paho.Message(JSON.stringify(pickupChatInfo));
  message.destinationName = agentMQTTTopicPrefix + "/request";
  mqtt.send(message);
};

const monitorChat = (chatId, flag) => {
  const monitorId = generateUniqueId("MONITOR");
  const monitorChatInfo = {};

  monitorChatInfo[monitorId] = {
    chatId: chatId,
    status: "MONITOR",
    flag: flag,
  };

  var uri = "";
  if (flag) {
    uri = "chats/" + chatId + "/startMonitoring";
  } else {
    uri = "chats/" + chatId + "/stopMonitoring";
  }

  localStorage.setItem("monitorChatInfo", JSON.stringify(monitorChatInfo));
  var chatMonitorInfo = {
    id: monitorId,
    uri: uri,
    sessionId,
    body: {
      mode: "monitor",
      chatId: chatId,
      flag: flag,
    },
  };

  localStorage.setItem("monitorChatInfo", JSON.stringify(chatMonitorInfo));
  const message = new Paho.Message(JSON.stringify(chatMonitorInfo));
  message.destinationName = agentMQTTTopicPrefix + "/request";
  mqtt.send(message);
};

const disconnect = function () {
  auth = null;
  if (mqtt && mqtt.isConnected()) {
    var payload = {
      status: "offline",
      sessionId: sessionId,
    };
    var message = new Paho.Message(JSON.stringify(payload));
    message.destinationName = agentMQTTTopicPrefix + "/clients/" + clientId + "/status";
    mqtt.send(message);
    mqtt.disconnect();
  }
};

const transferChat = function (chatId, chatInfo) {
  console.log("-=-=-=-=-: MQTT Chat Transfer: ", chatId, transferChatIds);
  if (chatId && !transferChatIds.includes(chatId)) {
    transferChatIds.push(chatId);
    if (chatInfo && chatInfo.autoPilot && chatInfo.autoPilot.isOn) {
      autoPilot(chatId, false);
    }
    var transferId = generateUniqueId("TRANSFER");
    const transferedChatInfo = {};
    transferedChatInfo[transferId] = {
      chatId: chatId,
      status: "START",
      title: "COMPLETE CHAT",
      successMsg: "Chat completed successfully",
      failureMsg: `Chat complete failure`,
    };
    localStorage.setItem("transferedChatInfo", JSON.stringify(transferedChatInfo));
    const pickupChatInfo = {
      id: transferId,
      sessionId,
      uri: "chats/" + chatId + "/transferToBot",
      body: {},
    };
    // if (flag) {
    //   pickupChatInfo.body = json;
    // }
    localStorage.setItem("pickupInfo", JSON.stringify(pickupChatInfo));
    const message = new Paho.Message(JSON.stringify(pickupChatInfo));
    message.destinationName = agentMQTTTopicPrefix + "/request";
    mqtt.send(message);
  }
};
const transferChatToAgent = function (chatId, agentId, agentName, isAutoPilot) {
  console.log("-=-=-=-=-: MQTT Chat Agent Transfer: ", chatId, transferChatIds, isAutoPilot);
  if (chatId && !transferChatIds.includes(chatId)) {
    transferChatIds.push(chatId);
    if (isAutoPilot) {
      autoPilot(chatId, false);
    }
    var transferId = generateUniqueId("TRANSFER");
    const transferedChatInfo = {};
    transferedChatInfo[transferId] = {
      chatId: chatId,
      status: "START",
      title: "TRANSFER CHAT",
      successMsg: `Chat transfered to agent ${agentName} successfully`,
      failureMsg: `Chat transfered to agent ${agentName} failure`,
    };
    localStorage.setItem("transferedChatInfo", JSON.stringify(transferedChatInfo));
    let payload = {
      id: transferId,
      uri: "chats/" + chatId + "/transferToAgent",
      sessionId,
      params: {
        agentId,
      },
    };
    let message = new Paho.Message(JSON.stringify(payload));
    message.destinationName = agentMQTTTopicPrefix + "/request";
    mqtt.send(message);
  }
};
const transferChatToGroup = function (chatId, groupId, groupName, isAutoPilot) {
  console.log("-=-=-=-=-: MQTT Chat Group Transfer: ", chatId, transferChatIds, isAutoPilot);
  if (chatId && !transferChatIds.includes(chatId)) {
    transferChatIds.push(chatId);
    if (isAutoPilot) {
      autoPilot(chatId, false);
    }
    var transferId = generateUniqueId("TRANSFER");
    const transferedChatInfo = {};
    transferedChatInfo[transferId] = {
      chatId: chatId,
      status: "START",
      title: "TRANSFER CHAT",
      successMsg: `Chat transfered to group ${groupName} successfully`,
      failureMsg: `Chat transfered to group ${groupName} failure`,
    };
    localStorage.setItem("transferedChatInfo", JSON.stringify(transferedChatInfo));
    const payload = {
      id: transferId,
      sessionId,
      uri: "chats/" + chatId + "/groups/" + groupId + "/transferToGroup",
    };
    const message = new Paho.Message(JSON.stringify(payload));
    message.destinationName = agentMQTTTopicPrefix + "/request";
    mqtt.send(message);
  }
  //console.log("-=-=-=-=-: MQTT Chat Transfer To Group: " + chatId);
};

const sendTyping = function (chatId, clientId, waitTime = 0) {
  const { agentName } = store.getState().auth.info;
  let payload = {
    event: "typingIndicator",
    chatId,
    waitTime,
    agentName,
  };
  const message = new Paho.Message(JSON.stringify(payload));
  message.destinationName = "orgs-" + userInfo.orgId + "/clients/" + clientId + "/directstream";
  mqtt.send(message);
};

export {
  onConnectMQTT,
  onPickupChat,
  sendChatMessage,
  autoPilot,
  monitorChat,
  transferChat,
  sendTyping,
  disconnect,
  transferChatToGroup,
  transferChatToAgent,
};
