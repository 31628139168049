import * as React from "react";
import styled from "styled-components";
import { Checkbox, Modal, Button, notification } from "antd";

import DeleteIcon from "../../../../assets/icons/delete";
import EmptyCodesIcon from "../../../../assets/images/empty_reason_codes.svg";
import DeleteIconImage from "../../../../assets/images/delete-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import update from "immutability-helper";
import { updateAgentSettings } from "../../../../actions/agentsAction";
import "../../../../assets/sass/reasonCodes.scss";

export interface IReasonCodesProps {}

const openNotification = () => {
  const args = {
    message: "Success",
    description: "Successfully added new reason code",
    duration: 5,
    style: {
      background: "#f6ffed",
      border: "1px solid #b7eb8f",
      borderRadius: "2px",
    },
  };
  notification.success(args);
};

const Container = styled.div`
  padding: 0 25px;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  label.ant-checkbox-wrapper {
    span:last-child {
      padding-left: 29px;
    }

    span.ant-checkbox-inner {
      padding-left: 0;
    }
  }
`;

const PageTitle = styled.div`
  font-family: Muli;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0948b2;
  margin-bottom: 32px;
`;

const InputWrapper = styled.form`
  display: flex;
  align-items: baseline;
  margin-bottom: ${(props) => (!props.theme.statusError ? "12px" : 0)};
`;

const Input = styled.input`
  border-radius: 4px;
  border: solid 1px #d3daef;
  background-color: #ffffff;
  padding: 9px 20px 10px 20px;
  width: 414px;
  height: 35px;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  margin-right: 12px;
  outline: none;

  &::placeholder {
    color: #ccd0dd;
  }

  &::focus {
    outline: none;
  }
`;

const AddButton = styled.button`
  width: 80px;
  height: 35px;
  border-radius: 4px;
  background-color: #1d7aff;
  border: none;
  color: white;
  text-transform: uppercase;
  user-select: none;
  margin-right: 20px;
`;

const VerticalDivider = styled.div`
  border: 1px solid #e6e9f1;
  margin-right: 26px;
  height: 35px;
`;

const DeleteWrapper = styled.div`
  display: flex;
  span {
    margin-left: 10px;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: ${(props) => (props.theme.color ? props.theme.color : "#ccd0dd")};
    cursor: pointer;
  }
`;

const TableWrapper = styled.div`
  height: 40px;
  background-color: #edf4ff;
  padding: 11px 113px 11px 14px;
  display: flex;
  justify-content: space-between;

  span {
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #010f34;
  }
`;

const EmptyImageWrapper = styled.div`
  margin-top: 94.2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span.empty {
    margin-top: 20px;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #223979;
  }

  span.description {
    margin-top: 7px;
    font-family: Muli;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 110px 18px 14px;
  border-bottom: 1px solid #ccd0dd;
`;

const ModalContent = styled.div`
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.72;
  letter-spacing: normal;
  text-align: left;
  color: #2b2b2b;
`;

const DuplicateError = styled.p`
  margin-top: 4px;
  margin-bottom: 9px;
  padding-left: 20px;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #ba0435;
`;

export default function ReasonCodes(props: IReasonCodesProps) {
  const { agentState } = useSelector((state: any) => state.ui);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [statusError, showStatusError] = React.useState(false);
  const [hoveredIconNumber, setHoveredIconNumber] = React.useState(null);
  const dispatch = useDispatch();
  const updateReasons = React.useCallback(
    (payload) => dispatch(updateAgentSettings(payload)),
    [dispatch]
  );
  const [rList, setRList] = React.useState([]);
  const [editMode, setEditMode] = React.useState({
    isActive: false,
    index: null,
  });
  const [reasonText, setReasonText] = React.useState("");
  const [bulkSelectedReasons, setBulkSelectedReasons] = React.useState([]);

  const updateList = (unavailableReasons: any[]) => {
    setRList(unavailableReasons);
    const reasons = unavailableReasons.reduce((a, c) => {
      a.push(c.name);
      return a;
    }, []);
    const payload = {
      unavailableReasons: reasons,
    };

    updateReasons(payload);
  };

  const handleInputBlur = (e) => {
    const list = rList.filter(
      (item) => item.name.toLowerCase() === e.target.value.toLowerCase()
    );
    showStatusError(list.length > 0);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const list = rList.filter(
      (item) => item.name.toLowerCase() === reasonText.toLowerCase()
    );
    if (!list.length && reasonText.trim()) {
      let updatedList: any[];
      if (editMode.isActive) {
        updatedList = update(rList, {
          [editMode.index]: {
            name: { $set: reasonText.trim() },
          },
        });
        setEditMode({
          isActive: false,
          index: null,
        });
      } else {
        updatedList = update(rList, {
          $push: [{ name: reasonText.trim(), isChecked: false }],
        });
      }
      updateList(updatedList);
      setReasonText("");
      openNotification();
    } else {
      showStatusError(list.length > 0);
    }
  };

  React.useEffect(() => {
    if (agentState && agentState.reasonsList) {
      const updatedReasonList = agentState.reasonsList.reduce((a, c) => {
        const data = { name: c, isChecked: false };
        a.push(data);
        return a;
      }, []);

      setRList(updatedReasonList);
      setBulkSelectedReasons([]);
    }
  }, [agentState]);

  const handleEdit = (item: string, index: number) => {
    setReasonText(item);
    setEditMode({
      isActive: true,
      index,
    });
  };

  const handleDelete = (index: number) => {
    const updatedList = update(rList, { $splice: [[index, 1]] });
    setReasonText("");
    setEditMode({
      isActive: false,
      index: null,
    });
    updateList(updatedList);
  };

  const handleCheckboxChange = (
    checked: boolean,
    item: string,
    index: number
  ) => {
    if (checked && !bulkSelectedReasons.includes(item)) {
      const updatedBulkList = update(bulkSelectedReasons, { $push: [item] });
      const updatedReasonList = update(rList, {
        [index]: {
          isChecked: { $set: true },
        },
      });
      setRList(updatedReasonList);
      setBulkSelectedReasons(updatedBulkList);
    } else if (!checked && bulkSelectedReasons.includes(item)) {
      const updatedBulkList = bulkSelectedReasons.filter((i) => i !== item);
      const updatedReasonList = update(rList, {
        [index]: {
          isChecked: { $set: false },
        },
      });
      setRList(updatedReasonList);
      setBulkSelectedReasons(updatedBulkList);
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const list = rList.map((i) => i.name);
      setBulkSelectedReasons(list);
    } else {
      setBulkSelectedReasons([]);
    }
    const updatedRList = rList.map((item) => {
      item.isChecked = checked;
      return item;
    });

    setRList(updatedRList);
  };

  const handleBulkDelete = () => {
    const updatedList = rList.filter(
      (item) => bulkSelectedReasons.findIndex((i) => i === item.name) === -1
    );
    setBulkSelectedReasons([]);
    setDeleteModal(false);
    setReasonText("");
    setEditMode({
      isActive: false,
      index: null,
    });
    updateList(updatedList);
  };

  return (
    <Container>
      <InputWrapper theme={{ statusError }} onSubmit={handleFormSubmit}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Input
            value={reasonText}
            onChange={(e) => {
              setReasonText(e.target.value);
              showStatusError(false);
            }}
            onBlur={handleInputBlur}
            placeholder="Add agent status…"
            theme={{ statusError }}
          />
          {statusError && (
            <DuplicateError>status already available…</DuplicateError>
          )}
        </div>
        <AddButton type="submit">
          {editMode.isActive ? "Update" : "Add"}
        </AddButton>
        <VerticalDivider />
        <DeleteWrapper
          theme={{ color: bulkSelectedReasons.length ? "#DE5E75" : "#ccd0dd" }}
          onClick={() => bulkSelectedReasons.length && setDeleteModal(true)}
        >
          <DeleteIcon
            color={bulkSelectedReasons.length ? "#d35e75" : "#ccd0dd"}
          />
          <span>Delete</span>
        </DeleteWrapper>
      </InputWrapper>
      <TableWrapper>
        <Checkbox
          defaultChecked={false}
          checked={
            rList.length > 0 && bulkSelectedReasons.length === rList.length
          }
          onChange={(e) => handleSelectAll(e.target.checked)}
        >
          <span style={{ paddingLeft: "28px" }}>Name</span>
        </Checkbox>
      </TableWrapper>
      {rList.length > 0 &&
        rList.map((item: any, index: number) => (
          <ListWrapper
            key={index}
            onMouseEnter={() => setHoveredIconNumber(index)}
            onMouseLeave={() => setHoveredIconNumber(null)}
          >
            <Checkbox
              defaultChecked={false}
              checked={item.isChecked}
              onChange={(e) =>
                handleCheckboxChange(e.target.checked, item.name, index)
              }
            >
              <span style={{ color: item.isChecked ? "#223979" : "#7f90af" }}>
                {item.name}
              </span>
            </Checkbox>
            <div>
              {hoveredIconNumber === index &&
                bulkSelectedReasons.length < 1 && (
                  <img
                    onClick={() =>
                      !bulkSelectedReasons.length && handleDelete(index)
                    }
                    src={DeleteIconImage}
                    alt="Delete"
                    style={{ cursor: "pointer" }}
                  />
                )}
            </div>
          </ListWrapper>
        ))}

      {rList.length === 0 && (
        <EmptyImageWrapper>
          <img src={EmptyCodesIcon} alt="Empty Reason Codes" />
          <span className="empty">Empty</span>
          <span className="description">Create custom agent status</span>
        </EmptyImageWrapper>
      )}
      <Modal
        title="DELETE"
        width="439px"
        className="confirm-transfer delete-reason"
        visible={deleteModal}
        onCancel={() => setDeleteModal(false)}
        footer={null}
      >
        <ModalContent>Are you sure you want to delete status?</ModalContent>
        <div className="action">
          <Button className="yes" onClick={handleBulkDelete}>
            YES
          </Button>
          <Button className="no" onClick={() => setDeleteModal(false)}>
            NO
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
