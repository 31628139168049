import { style } from "typestyle";
// import { LightenDarkenColor } from "../../utils/utils";

export const container = (primaryColor: string, backgroundColor: string) =>
    style(
        { borderTop: `2px solid ${primaryColor}` },
        { padding: "16px" },
        { backgroundColor }
    );

export const cardTitle = (fontFamily: string, color: string, fontSize: string) =>
    style(
        { fontFamily: fontFamily },
        { fontSize: fontSize + "px" },
        { fontWeight: 600 },
        { marginBottom: "8px" },
        { fontStretch: "normal" },
        { fontStyle: "normal" },
        { lineHeight: "normal" },
        { letterSpacing: "normal" },
        { color }
    );

export const optionLabel = (fontFamily: string, color: string) =>
    style({ fontFamily: fontFamily });

export const cardList = style(
    { paddingLeft: 0 },
    { display: "flex" },
    { flexDirection: "column" }
);

export const LightenDarkenColor = (col: string, amt: number) => {
    let usePound = false;
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;
    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;
    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;
    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};


export const cardOption = (backgroundColor: string) =>
    style(
        { marginBottom: "15px" },
        { display: "flex" },
        { alignItems: "center" },
        { width: "100%" },
        { marginTop: "5px" },
        { marginBottom: "5px" },
        { padding: "5px 20px" },
        { borderRadius: "15px" },
        { border: "1px solid #e8e9f1" },
        { cursor: "pointer" },

    );

export const cardOptionLabel = style({ fontSize: "15px" });

export const cardOptionValue = (color: string) =>
    style({ display: "block" }, { fontSize: "12px" });
