import * as React from "react";
import HtmlReactParser from "html-react-parser";
import MarkdownIt from "markdown-it";
import styled from "styled-components";
import { ThemeContext, SelectedPropertyContext } from "../../../settings/general/themeDesigner";
import {
    container,
    cardTitle,
    optionLabel,
    cardList,
    cardOption,
    cardOptionLabel,
    cardOptionValue,
} from "./style";
import { SelectedBotContext } from "../../../settings/bots";

export interface ILayoutProps {
    children?: React.ReactNode;
    isUser: boolean;
    onHandelClick?: (arg) => void;
}

export const decodeEntities = (
    encodedString: string,
    maxLimit = 140,
    shouldTruncate = false
) => {
    const md = new MarkdownIt({ html: true });
    if (encodedString) {
        let newStr = encodedString.replace(/\\n/g, "<br />");
        // if (shouldTruncate) {
        //   const caret = newStr.length > maxLimit ? "..." : "";
        //   newStr = newStr.substr(0, maxLimit) + caret;
        // }
        var textArea = document.createElement("textarea");
        textArea.innerHTML = newStr;
        const val = textArea.value;
        const markdownOutput = md.render(val);
        if (shouldTruncate) {
            const caret = markdownOutput.length > maxLimit ? "..." : "";
            return markdownOutput.substr(0, maxLimit) + caret;
        }
        return markdownOutput;
    }
    return encodedString;
};

const Layout = (props: ILayoutProps) => {
    const { isUser, children, onHandelClick } = props;
    return (
        <LayoutContainer onClick={onHandelClick} theme={{ isUser }}>
            {children}
        </LayoutContainer>
    );
};

const LayoutContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.theme.isUser ? "flex-end" : "flex-start")};
`;


interface IChoice {
    id: string;
    label: any;
    subText: any;
}

interface ICardChoiceProps {
    isChatIconVisible: boolean;
    isUser: boolean;
    icon: string;
    primaryColor: string;
    backgroundColor: string;
    color: string;
    label: string;
    fontFamily: string;
    shouldShowActions: boolean;
    choices: IChoice[];
    handleOptionSelect: (label: string, subText: string) => void;
    onHandelClick?: (arg: any) => void;
}

const encodedText = (str: string) => {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = str;
    return textArea.value;
};

const CardChoice: React.FunctionComponent<ICardChoiceProps> = (props) => {
    const { themeProps, setThemeProps } = React.useContext(ThemeContext);
    const { selectedBotName } = React.useContext(SelectedBotContext);
    const {
        widget: { messages },
    } = themeProps;
    const {
        isChatIconVisible,
        isUser,
        icon,
        primaryColor,
        label,
        fontFamily,
        shouldShowActions,
        choices,
        handleOptionSelect,
        onHandelClick,
    } = props;
    const {
        optionsFRColor,
        optionsHRColor,
        optionsBGColor,
        optionsTextHRColor,
        optionsBorder,
        optionsShadow,
        optionsRadius,
        optionsFontSize,
        optionsHeight,
        optionsFontWeight,
    } = messages.cardChoice.agent;
    const {
        fontSize,
        backgroundColor,
        color,
        border,
        borderRadius,
        borderTopLeft,
        borderTopRight,
        borderBottomRight,
        borderBottomLeft,
        outlineColor,
        outlineTop,
        outlineRight,
        outlineBottom,
        outlineLeft
    } = messages.globalSettings.agent;
    const optionHover = (liOption, isFlag) => {
        if (shouldShowActions && liOption) {
            liOption.style.background = isFlag
                ? optionsHRColor
                : optionsBGColor;
            liOption.style.color = isFlag
                ? optionsTextHRColor
                : optionsFRColor;
        }
    };
    const liOptions = React.useRef([]);
    return (
        <>
            <Layout isUser={isUser} onHandelClick={onHandelClick ? onHandelClick : undefined}>
                {isChatIconVisible && <div style={{
                    width: "45px",
                    minWidth: "45px",
                    height: "45px",
                    margin: `5px ${!isUser ? "10px" : 0} 5px ${isUser ? "10px" : 0}`,
                    borderRadius: "50%",
                    padding: "2px",
                    order: isUser ? 1 : 0
                }}>
                    {icon && <img src={icon} height="auto" width="100%" />}
                    {!icon && isUser && <div style={{
                        backgroundColor: themeProps.widget.dimension.userColor.hex,
                        height: themeProps.widget.dimension.userHeight,
                        width: themeProps.widget.dimension.userWidth,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: themeProps.widget.dimension.userTextColor.hex,
                        fontWeight: "bold",
                        borderRadius: themeProps.widget.dimension.borderRadiusUser
                    }}>U</div>}

                    {!icon && !isUser && <div style={{
                        backgroundColor: themeProps.widget.dimension.agentColor.hex,
                        height: themeProps.widget.dimension.agentHeight,
                        width: themeProps.widget.dimension.agentWidth,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: themeProps.widget.dimension.agentTextColor.hex,
                        fontWeight: "bold",
                        borderRadius: themeProps.widget.dimension.borderRadiusAgent
                    }}>{selectedBotName[0]}</div>}
                </div>}
                <div style={{
                    overflow: "hidden",
                    backgroundColor,
                    color,
                    border,
                    borderRadius,
                    borderTopLeftRadius: borderTopLeft,
                    borderBottomRightRadius: borderBottomRight,
                    borderTopRightRadius: borderTopRight,
                    borderBottomLeftRadius: borderBottomLeft
                }} >
                    <div style={{
                        padding: 10,
                        borderWidth: 0,
                        borderStyle: "solid",
                        borderColor: outlineColor,
                        borderLeftWidth: outlineLeft || "0px",
                        borderRightWidth: outlineRight || "0px",
                        borderTopWidth: outlineTop || "0px",
                        borderBottomWidth: outlineBottom || "0px"
                    }} >
                        <div className={cardTitle(fontFamily, color, fontSize)}>{label}</div>
                        {shouldShowActions ? (
                            <ul className={cardList}>
                                {choices.map((choice, index) => {
                                    const decodedLabel = decodeEntities(choice.label);
                                    const decodedSubtext = decodeEntities(choice.subText);
                                    return (
                                        <li
                                            style={{
                                                color: optionsFRColor,
                                                background: optionsBGColor,
                                                boxShadow: optionsShadow,
                                                border: optionsBorder,
                                                height: optionsHeight == "" ? "auto" : optionsHeight,
                                                borderRadius: optionsRadius + "px",
                                                fontWeight: optionsFontWeight,
                                                fontSize: optionsFontSize + "px"
                                            }}
                                            className={cardOption(backgroundColor)}
                                            key={choice.id}
                                            onClick={() =>
                                                handleOptionSelect(choice.label, choice.subText)
                                            }
                                            ref={(el) => (liOptions.current[index] = el)}
                                            onMouseEnter={() =>
                                                optionHover(liOptions.current[index], true)
                                            }
                                            onMouseLeave={() =>
                                                optionHover(liOptions.current[index], false)
                                            }
                                        >
                                            <span className="option">
                                                <span
                                                    title={encodedText(choice.label)}
                                                >
                                                    <div className={optionLabel(fontFamily, color)}>
                                                        {HtmlReactParser(decodedLabel)}
                                                    </div>
                                                </span>
                                                <span className={cardOptionValue(color)}>
                                                    {HtmlReactParser(decodedSubtext)}
                                                </span>
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : null}
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default CardChoice;
