import type from "../types/actionTypes";
import update from "immutability-helper";

const initialState = {
  /**
   * This prop response for add active
   * class to root nav current selection
   */
  clientConversation: null,
  theme: null,
  activeRootNav: "",
  activeRootTab: "",
  showUpdateSetting: false,
  chats: {},
  unread: [],
  agentState: {
    reasonsList: [],
    currentState: "unavailable",
    currentReason: "",
  },
  agentChatRouting: {
    routingAlgorithm: "",
    maxChatsPerAgent: 25,
  },
  error: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.ROOT_SELECTED_MENU:
      return {
        ...state,
        error: "",
        activeRootNav: action.data,
      };
    case type.ROOT_SELECTED_TAB:
      return {
        ...state,
        error: "",
        activeRootTab: action.data,
      };
    case type.AGENT_VIEW_MODE:
      return {
        ...state,
        error: "",
        agentViewMode: action.data,
      };
    case type.AGENT_SEARCH:
      return {
        ...state,
        error: "",
        searchAgent: action.data,
      };
    case type.AGENT_FILTER:
      return {
        ...state,
        error: "",
        filterAgent: action.data,
      };
    case type.AGENT_GROUP_LIST_FILTER:
      return {
        ...state,
        error: "",
        filterAgentGroupList: action.data,
      };
    case type.SIGNOUT_SUCCESS:
      return initialState;
    case type.AGENT_TYPE_FILTER:
      return {
        ...state,
        error: "",
        filterAgentType: action.data,
      };
    case type.AGENT_SORT_FILTER:
      return {
        ...state,
        error: "",
        filterAgentSort: action.data,
      };

    case type.GROUP_SEARCH:
      return {
        ...state,
        error: "",
        searchGroup: action.data,
      };
    case type.THEME:
      return {
        ...state,
        error: "",
        theme: action.data,
      };
    case type.TOGGLE_BULK_UPLOAD:
      return {
        ...state,
        error: "",
        toggleBulkUpload: action.data,
      };
    case type.GROUP_FILTER:
      return {
        ...state,
        error: "",
        filterGroup: action.data,
      };
    case type.GROUP_AGENT_FILTER:
      return {
        ...state,
        error: "",
        filterGroupAgent: action.data,
      };
    case type.GROUP_TYPE_FILTER:
      return {
        ...state,
        error: "",
        filterGroupType: action.data,
      };
    case type.GROUP_SORT_FILTER:
      return {
        ...state,
        error: "",
        filterGroupSort: action.data,
      };
    case type.QUICK_RESP_GROUP_FILTER:
      return {
        ...state,
        error: "",
        filterGroupQuickResp: action.data,
      };
    case type.QUICK_RESP_SEARCH:
      return {
        ...state,
        error: "",
        filterSearchQuickResp: action.data,
      };
    case type.QUICK_RESP_SORT_FILTER:
      return {
        ...state,
        error: "",
        filterSortQuickResp: action.data,
      };
    case type.SIGNOUT:
      return {
        ...initialState,
        error: "",
      };
    case type.SHOW_UPDATE_SETTING:
      return {
        ...state,
        error: "",
        showUpdateSetting: action.data.updateSettingShow,
      };
    case type.SHOW_UPDATE_RESOURCE:
      return {
        ...state,
        error: "",
        showUpdateResource: action.data.updateResourceShow,
      };
    case type.BLOCK_LAYER:
      return {
        ...state,
        error: "",
        isBlockLayer: action.data,
      };
    case type.PERSIST_CHAT:
      return update(state, {
        chats: {
          [action.data.chatId]: { $set: action.data.payload },
        },
      });
    case type.CLEAR_PERSISTED_CHAT:
      return update(state, {
        chats: { $set: {} },
        error: { $set: "" },
      });
    case type.SET_AGENT_STATE:
      return update(state, {
        agentState: {
          isAPI: { $set: action.data.isAPI },
          currentState: { $set: action.data.name },
          currentReason: { $set: action.data.reason || "" },
        },
        error: { $set: "" },
      });
    case type.SET_AGENT_REASONS_LIST: {
      const data = {
        ...state,
        error: "",
        agentState: { ...state.agentState, reasonsList: action.data },
      };
      return data;
    }
    case type.SET_CHAT_ROUTING_ALGORITHM: {
      return {
        ...state,
        error: "",
        agentChatRouting: {
          ...state.agentChatRouting,
          routingAlgorithm: action.data.routingAlgorithm,
          maxChatsPerAgent: action.data.maxChatsPerAgent ? action.data.maxChatsPerAgent : 25,
          queueTimeout: action.data.queueTimeout ? action.data.queueTimeout : 0,
          waitTimeNotification: action.data.waitTimeNotification
            ? action.data.waitTimeNotification
            : {
              interval: 0,
              message: "",
            },
        },
      };
    }
    case "RESET": {
      return { ...initialState, error: "" };
    }
    case type.SIGNIN_SUCCESS: {
      const { data } = action;

      if (data && data.state) {
        const { state: agentState } = data;
        return update(state, {
          agentState: {
            currentState: { $set: agentState.name },
            currentReason: { $set: agentState.reason || "" },
          },
          error: { $set: "" },
        });
      }
      return state;
    }
    case type.UPDATE_CHAT_BY_ID: {
      const { isActiveAgent, chatId, isSelected } = action;
      if (isActiveAgent && !isSelected) {
        const count =
          state.chats &&
            state.chats[chatId] &&
            state.chats[chatId].messages &&
            state.chats[chatId].messages.unread !== undefined
            ? state.chats[chatId].messages.unread + 1
            : 1;
        const chats = update(state, {
          chats: {
            [chatId]: { $set: { messages: { unread: count } } },
          },
          error: { $set: "" },
        });
        // const updatedChats = {
        //   ...state.chats,
        //   [action.chatId]: {
        //     ...state.chats[action.chatId],
        //     messages: {
        //       unread:
        //         state.chats[action.chatId].messages &&
        //         state.chats[action.chatId].messages.unread !== undefined
        //           ? state.chats[action.chatId].messages.unread + 1
        //           : 1,
        //     },
        //   },
        // };
        return chats;
      }
      return state;
    }
    case type.UPDATE_SELECTED_LIVE_CHAT_ID: {
      const updatedChats = { ...state.chats };
      delete updatedChats[action.data];
      return update(state, {
        chats: { $set: updatedChats },
        error: { $set: "" },
      });
    }
    case type.SETTINGS_ERROR: {
      return update(state, {
        error: { $set: action.data },
      });
    }
    case type.DASHBOARD_FILTERS: {
      return {
        ...state,
        dashboardFilters: action.data,
      };
    }
    case type.CLIENT_CONVERSATION: {
      return {
        ...state,
        clientConversation: action.data,
      };
    }
    case type.ADD_TO_UNREAD: {
      return {
        ...state,
        unread: [...state.unread, action.payload]
      }
    }
    case type.REMOVE_FROM_READ: {
      return {
        ...state,
        unread: state.unread.filter(chatId => chatId !== action.payload)
      }
    }
    default:
      return state;
  }
};
