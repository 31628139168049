import React, { useState } from "react";
import Box from "../Box";
import styled from "styled-components";

interface IMenuItem {
  icon: string;
  title: string;
  type: string;
}

interface IProps {
  widgetList: any;
}

const VisualElementContainer = styled.div`
  width: 214px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: solid 1px #d3daef;
  background-color: #ffffff;
  padding: 15px 15px 0px 15px;
  max-height: 326px;
  overflow-y: auto;
`;

const VisualElement = (props: IProps) => {
  const { widgetList } = props;
  const [isOpen, setIsOpen] = useState(true);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <VisualElementContainer>
      {widgetList.map((item: IMenuItem, index: number) => (
        <Box
          key={index}
          isLastChild={index === widgetList.length - 1}
          {...item}
        />
      ))}
    </VisualElementContainer>
  );
};

export default VisualElement;
