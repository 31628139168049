import * as React from "react";
import { connect } from "react-redux";
import { Popover } from 'antd';
import { setTheme } from '../../actions/uiAction';
import themeIcn from '../../assets/images/theme.png';
import { store } from "../../store";

const text = <span>Theme</span>;
const theme = [
    {
        primaryColor: "#FE5F55",
        secondaryColor: "#09BC8A",
        secondaryLighterColor: "#09bc8a15",
        buttonBGColor: "#FE5F55",
        buttonFGColor: "#fff"
    },
    {
        primaryColor: "#3A86FF",
        secondaryColor: "#0D0630",
        secondaryLighterColor: "#0D063015",
        buttonBGColor: "#3A86FF",
        buttonFGColor: "#fff"
    },
    {
        primaryColor: "#EC4067",
        secondaryColor: "#A01A7D",
        secondaryLighterColor: "#A01A7D15",
        buttonBGColor: "#EC4067",
        buttonFGColor: "#fff"
    },
    {
        primaryColor: "#274E96",
        secondaryColor: "#DF2935",
        secondaryLighterColor: "#DF293515",
        buttonBGColor: "#274E96",
        buttonFGColor: "#fff"
    },
    {
        primaryColor: "#07BEB8",
        secondaryColor: "#0466C8",
        secondaryLighterColor: "#0466C815",
        buttonBGColor: "#07BEB8",
        buttonFGColor: "#fff"
    },
    {
        primaryColor: "#95C623",
        secondaryColor: "#0E4749",
        secondaryLighterColor: "#0E474915",
        buttonBGColor: "#95C623",
        buttonFGColor: "#fff"
    },
    {
        primaryColor: "#FCA311",
        secondaryColor: "#0755FF",
        secondaryLighterColor: "#0755FF15",
        buttonBGColor: "#FCA311",
        buttonFGColor: "#fff"
    },
    {
        primaryColor: "#7161EF",
        secondaryColor: "#FE5D26",
        secondaryLighterColor: "#FE5D2615",
        buttonBGColor: "#7161EF",
        buttonFGColor: "#fff"
    },
    {
        primaryColor: "#A26769",
        secondaryColor: "#D5B9B2",
        secondaryLighterColor: "#D5B9B215",
        buttonBGColor: "#A26769",
        buttonFGColor: "#fff"
    }
];
class Theme extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        let content = [];
        theme.forEach((t) => {
            content.push(<div key={t.primaryColor + t.secondaryColor} className="item" onClick={() => this.onSelectTheme(t)}>
                <div className="selected" style={{ display: (theme[0].primaryColor == t.primaryColor && theme[0].secondaryColor == t.secondaryColor) ? "block" : "none" }}></div>
                <div className="primary" style={{ background: t.primaryColor }}></div>
                <div className="secondary" style={{ border: `2px solid ${t.secondaryColor}` }}></div>
            </div>)
        });
        this.state = {
            visible: false,
            content: content,
            selectedTheme: theme[0]
        };
    }

    componentWillMount() {
        let content = [];
        if (this.props.ui.theme) {
            this.setState({ selectedTheme: this.props.ui.theme });
            theme.forEach((t) => {
                content.push(<div key={t.primaryColor + t.secondaryColor} className="item" onClick={() => this.onSelectTheme(t)}>
                    <div className="selected" style={{ display: (this.props.ui.theme.primaryColor == t.primaryColor && this.props.ui.theme.secondaryColor == t.secondaryColor) ? "block" : "none" }}></div>
                    <div className="primary" style={{ background: t.primaryColor }}></div>
                    <div className="secondary" style={{ border: `2px solid ${t.secondaryColor}` }}></div>
                </div>)
            });
            this.setState({
                content,
                visible: false
            })
        }
    }
    onSelectTheme = (selectedTheme) => {
        let content = [];
        let { theme: prevTheme } = store.getState().ui;
        theme.forEach((t) => {
            content.push(<div key={t.primaryColor + t.secondaryColor} className="item" onClick={() => this.onSelectTheme(t)}>
                <div className="selected" style={{ display: (selectedTheme.primaryColor == t.primaryColor && selectedTheme.secondaryColor == t.secondaryColor) ? "block" : "none" }}></div>
                <div className="primary" style={{ background: t.primaryColor }}></div>
                <div className="secondary" style={{ border: `2px solid ${t.secondaryColor}` }}></div>
            </div>)
        })
        this.setState({ selectedTheme, content });
        this.props.setTheme({ ...prevTheme, ...selectedTheme });
        this.onThemeToggle();
    }
    componentWillReceiveProps(props) {
        if (props.themeVisible != undefined && props.themeVisible != null) {
            this.setState({
                visible: props.themeVisible
            });
        }
    }

    onThemeToggle = () => {
        this.setState({
            visible: !this.state.visible
        })
    }
    render() {
        return (
            <Popover placement="bottom" title={text} content={<div style={{ height: "150px", width: "180px" }} className="theme">{this.state.content}</div>} visible={this.state.visible} >
                <div style={{ marginRight: "14px", cursor: "pointer" }} onClick={this.onThemeToggle}>
                    <img src={themeIcn} style={{ width: "30px", marginTop: "12px", cursor: "pointer" }} />
                </div>
            </Popover >
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
        ui: state.ui,
        task: state.task
    };
};
export default connect(mapStateToProps, { setTheme })(Theme);