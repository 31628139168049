import * as React from "react";
import styled from "styled-components";
import LeftIllustrationImage from "../../assets/images/left_illustration.svg";
import RightIllustrationImage from "../../assets/images/right_illustration.svg";
import DescriptionLogoImage from "../../assets/images/mail_icon.svg";
import InputLogoImage from "../../assets/images/password_icon.svg";
import AccountRecoveryCard from "./AccountRecoveryCard";
import { ForgotPasswordContext } from "../../App";
import { withRouter } from "react-router-dom";
import {
  resetPasswordVerifyOtp,
  resetPasswordSendOtp,
} from "../../actions/authActions";
import { deviceBreakpoint } from "../../utils/deviceBreakpoints";

export interface IVerificationCodeProps {
  history: any;
}

const Container = styled.div`
  position: relative;
  height: 100vh;
`;

const LeftIllustration = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;

  // Hide on Mobile
  @media ${deviceBreakpoint.laptop} {
    display: block;
  }
`;

const RightIllustration = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;

  // Hide on Mobile
  @media ${deviceBreakpoint.laptop} {
    display: block;
  }
`;

const Heading = styled.div`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #10002e;
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #10002e;
  margin-bottom: 44px;
  display: flex;
  align-items: center;
`;

const DescriptionLogo = styled.img`
  margin-right: 8px;
`;

const FormLabel = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: left;
  color: #10002e;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const InputLogo = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-73%);
  left: 15px;
`;

const Input = styled.input`
  height: 50px;
  border-radius: 2.5px;
  border: solid 0.5px ${(props) => (props.theme.error ? "#d30029" : "#95abd3")};
  background-color: #ffffff;
  max-width: 100%;
  width: 100%;
  padding: 0 12px 0 45px;
  margin-bottom: 8px;
  letter-spacing: 20px;

  &::-webkit-input-placeholder {
    /* Edge */
    letter-spacing: initial;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    letter-spacing: initial;
  }

  &::placeholder {
    letter-spacing: initial;
  }
`;

const InputError = styled.p`
  font-family: Muli;
  min-height: 12px;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #d30029;
  margin-bottom: 45px;
`;

const Button = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 2.5px;
  border: none;
  color: white;
  box-shadow: 0 4px 6.5px 0 rgba(0, 118, 255, 0.27);
  background-color: #0076ff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 34px;
`;

const CancelButton = styled.button`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #95abd3;
  background: white;
  border: none;
  outline: none;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
`;

const Instruction = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #10002e;

  span.timer {
    color: #d30029;
  }

  span.resend {
    color: #0076ff;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const secondsToMinutes = (seconds: number) =>
  Math.floor(seconds / 60) + ":" + ("0" + Math.floor(seconds % 60)).slice(-2);

function VerificationCode(props: IVerificationCodeProps) {
  const [timer, setTimer] = React.useState(180);
  const [otp, setOtp] = React.useState("");
  const [otpInstruction, setOtpInstruction] = React.useState(
    "OTP will be valid for"
  );
  const [expiredInstruction, setExpiredInstruction] = React.useState(false);
  const [otpError, setOtpError] = React.useState("");
  const context = React.useContext(ForgotPasswordContext);
  const { history } = props;
  React.useEffect(() => {
    if (!context.resetEmail) {
      history.push("/forgot-password");
    }
  }, [context.resetEmail]);

  React.useEffect(() => {
    if (timer > 0) {
      const counter = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      if (timer <= 0) {
        clearTimeout(counter);
        setExpiredInstruction(true);
      }
    } else {
      setExpiredInstruction(true);
    }
  }, [timer]);

  const handleResetOtp = async () => {
    try {
      const result = await resetPasswordSendOtp(context.resetEmail);
      setExpiredInstruction(false);
      if (result && result.code === "success") {
        setExpiredInstruction(false);
        setTimer(180);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = () => {
    history.push("/signin");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (otp) {
      try {
        const result = await resetPasswordVerifyOtp(context.resetEmail, otp);
        if (result.access_token) {
          context.setAccessToken(result.access_token);
          history.push("/reset-password");
        } else {
          setOtpError("Please enter valid verification code.");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Container>
      <LeftIllustration src={LeftIllustrationImage} alt="Left Illustartion" />
      <RightIllustration
        src={RightIllustrationImage}
        alt="Right Illustartion"
      />
      <AccountRecoveryCard>
        <Heading>Account recovery</Heading>
        <Description>
          <DescriptionLogo src={DescriptionLogoImage} alt="mail icon" />
          We sent you a verification code by email
        </Description>
        <form onSubmit={handleSubmit}>
          <FormLabel>Verification Code</FormLabel>
          <InputWrapper>
            <InputLogo src={InputLogoImage} alt="mail icon" />
            <Input
              theme={{ error: otpError }}
              value={otp}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setOtp(e.target.value);
                setOtpError("");
              }}
              type="number"
              placeholder="Enter verification code"
            />
          </InputWrapper>
          {!expiredInstruction && (
            <Instruction>
              {otpInstruction}{" "}
              <span className="timer">{secondsToMinutes(timer)}</span>
            </Instruction>
          )}
          {expiredInstruction && (
            <Instruction>
              <span className="timer">OTP got expired.</span> &nbsp;
              <span className="resend" onClick={handleResetOtp}>
                Resend it.
              </span>
            </Instruction>
          )}
          <InputError>{otpError}</InputError>
          <Button type="submit">Next</Button>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        </form>
      </AccountRecoveryCard>
    </Container>
  );
}

export default withRouter(VerificationCode);
