import React, { useMemo } from 'react'
import ChatMessages from "../chats/ChatMessages";

interface IProps {
  messages: any[];
  chatId: string
}

export default function MemoizedChatMessages(props: IProps) {
  const { messages, chatId } = props;

  return useMemo(() => {
    return <ChatMessages chatId={chatId} chats={messages}/>;
  }, [messages, chatId])
}
