import moment from "moment";
import "moment-timezone";
import { store } from "../store";

const generateUniqueId = (prefix) => {
  const rand = () => {
    return Math.floor((1 + getRandomNum()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return prefix + "_" + rand() + rand() + "-" + rand() + "-" + rand();
};

const getUserMetaInfo = (data) => {
  let module = {
    options: [],
    lang:
      (navigator.languages && navigator.languages[0]) || // Chrome / Firefox
      navigator.language || // All browsers
      navigator.userLanguage, // IE <= 10
    header: [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
      window.opera,
    ],
    dataos: [
      { name: "Windows Phone", value: "Windows Phone", version: "OS" },
      { name: "Windows", value: "Win", version: "NT" },
      { name: "iOS", value: "iPhone", version: "OS" },
      { name: "iOS", value: "iPad", version: "OS" },
      { name: "Kindle", value: "Silk", version: "Silk" },
      { name: "Android", value: "Android", version: "Android" },
      { name: "PlayBook", value: "PlayBook", version: "OS" },
      { name: "BlackBerry", value: "BlackBerry", version: "/" },
      { name: "Macintosh", value: "Mac", version: "OS X" },
      { name: "Linux", value: "Linux", version: "rv" },
      { name: "Palm", value: "Palm", version: "PalmOS" },
    ],
    databrowser: [
      { name: "Chrome", value: "Chrome", version: "Chrome" },
      { name: "Firefox", value: "Firefox", version: "Firefox" },
      { name: "Safari", value: "Safari", version: "Version" },
      { name: "Internet Explorer", value: "Trident", version: "Trident" },
      { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
      { name: "Edge", value: "Edge", version: "Edge" },
      { name: "Opera", value: "Opera", version: "Opera" },
      { name: "BlackBerry", value: "CLDC", version: "CLDC" },
      { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
    ],
    init: function () {
      let agent = this.header.join(" "),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser),
        lang = this.lang.split("-")[0];
      return { os: os, browser: browser, lang: lang };
    },
    getDeviceName: function (osName, osValue) {
      if (
        navigator.userAgent.toLocaleLowerCase().indexOf("android") > -1 &&
        navigator.userAgent.toLocaleLowerCase().indexOf("mobile") > -1
      ) {
        return "Phone";
      } else if (
        navigator.userAgent.toLocaleLowerCase().indexOf("android") > -1
      ) {
        return "Tab";
      } else if (
        navigator.userAgent.toLocaleLowerCase().indexOf("linux") > -1 ||
        navigator.userAgent.toLocaleLowerCase().indexOf("windows") > -1 ||
        navigator.userAgent.toLocaleLowerCase().indexOf("macintosh") > -1
      ) {
        return "System";
      } else if (
        navigator.userAgent.toLocaleLowerCase().indexOf("iphone") > -1
      ) {
        return "Phone";
      } else if (navigator.userAgent.toLocaleLowerCase().indexOf("ipad") > -1) {
        return "iPod";
      } else if (navigator.userAgent.toLocaleLowerCase().indexOf("ipod") > -1) {
        return "iPad";
      } else {
        return "Unknown";
      }
    },
    matchItem: function (string, data) {
      let i = 0,
        j = 0,
        regex,
        regexv,
        match,
        matches,
        version;

      for (i; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, "i");
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
          matches = string.match(regexv);
          version = "";
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + ".";
              } else {
                version += matches[j];
              }
            }
          } else {
            version = "0";
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
            value: data[i].value,
          };
        }
      }
      return { name: "unknown", version: 0 };
    },
  };
  let e = module.init();
  let userMetaInfo = {
    time: new Date(),
    email: data.email,
    agentId: data.agentId,
    name: data.agentName,
    isVirtualAgent: false,
    groups: data.groups,
    roles: data.roles,
    timezone: moment.tz.guess(),
    lang: e.lang,
    os: e.os.name,
    ipAddress: "",
    deviceName: module.getDeviceName(e.os.name, e.os.value),
    browserName: e.browser.name,
    browserVersion: e.browser.version.toString(),
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
  };
  return userMetaInfo;
};

const toDateTimeString = (date) => {
  date = date || new Date();
  let pattern = "hh:mm tt",
    HH = date.getHours(),
    mm = date.getMinutes(),
    ampm = HH > 11 ? "PM" : "AM",
    hh = HH % 12;
  hh = hh ? hh : 12;

  return (
    this.toShortDateString(date) +
    " " +
    pattern
      .replace("hh", this.twoDigitText(hh))
      .replace("mm", this.twoDigitText(mm))
      .replace("tt", ampm)
  );
};

const toTimeString = (date) => {
  date = date || new Date();
  let pattern = "hh:mm tt",
    HH = date.getHours(),
    mm = date.getMinutes(),
    ampm = HH > 11 ? "PM" : "AM",
    hh = HH % 12;
  hh = hh ? hh : 12;

  return pattern
    .replace("hh", this.twoDigitText(hh))
    .replace("mm", this.twoDigitText(mm))
    .replace("tt", ampm);
};

const twoDigitText = (number) => {
  return (number < 10 ? "0" : "") + number;
};

const toShortDateString = (date) => {
  date = date || new Date();
  let pattern = "MM/dd/yyyy";

  return pattern
    .replace("yyyy", date.getFullYear())
    .replace("dd", this.twoDigitText(date.getDate()))
    .replace("MM", this.twoDigitText(date.getMonth() + 1));
};

const toCustomDateTimeFormat = (timeStamp) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (timeStamp != null) {
    let msgDate = new Date(timeStamp);
    let today = new Date();
    if (today.toDateString() === msgDate.toDateString()) {
      let time = msgDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      return time;
    } else {
      let time = msgDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      let month = monthNames[msgDate.getMonth()];
      let day = msgDate.getUTCDate();
      return `${month} ${day},${time}`;
    }
  }
};

const toCustomDateFormat = (timeStamp) => {
  let calculatedTime = 0,
    timerLimit = 85000 /* 24 hours */,
    contactDuration = Math.abs(new Date() - timeStamp);
  contactDuration = Math.round(contactDuration / 1000);
  if (contactDuration < timerLimit) {
    calculatedTime = this.toTimeString(timeStamp);
  } else {
    calculatedTime = Math.floor(contactDuration / timerLimit);
    calculatedTime =
      calculatedTime === 1 ? calculatedTime + " Day" : calculatedTime + " Days";
    calculatedTime = calculatedTime + " ago";
  }
  return calculatedTime;
};

const secondsToTime = (seconds) => {
  let time = ":00",
    hours,
    mins,
    secs;

  if (
    seconds !== null &&
    seconds !== undefined &&
    typeof seconds === "number"
  ) {
    if (seconds <= 0) {
      return time;
    }

    hours = Math.floor(seconds / 3600);
    mins = Math.floor(seconds / 60) % 60;
    secs = seconds % 60;

    if (hours > 0) {
      time =
        hours +
        ":" +
        (mins > 9 ? mins : "0" + mins) +
        ":" +
        (secs > 9 ? secs : "0" + secs);
    } else if (mins <= 0) {
      time = ":" + (secs > 9 ? secs : "0" + secs);
    } else {
      time = mins + ":" + (secs > 9 ? secs : "0" + secs);
    }

    return time;
  }

  return time;
};

const isNotNullOrUndefinedString = (data) => {
  if (
    data === undefined ||
    data === null ||
    data.trim() === "" ||
    data === "undefined" ||
    data === "null"
  ) {
    return false;
  }
  return true;
};

const compareValues = (key, subKey, type, order = "asc") => {
  return (a, b) => {
    let varA = null,
      varB = null;
    if (!subKey) {
      if (!a || !a.hasOwnProperty(key) || !b || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
      if (type === "string") {
        varA = a[key].toUpperCase();
        varB = b[key].toUpperCase();
      } else if (type === "number") {
        varA = a[key];
        varB = b[key];
      } else if (type === "date") {
        varA = new Date(a[key]).getTime();
        varB = new Date(b[key]).getTime();
      }
    } else {
      if (!a[key].hasOwnProperty(subKey) || !b[key].hasOwnProperty(subKey)) {
        // property doesn't exist on either object
        return 0;
      }
      if (type === "string") {
        varA = a[key][subKey].toUpperCase();
        varB = b[key][subKey].toUpperCase();
      } else if (type === "number") {
        varA = a[key][subKey];
        varB = b[key][subKey];
      } else if (type === "date") {
        varA = new Date(a[key][subKey]).getTime();
        varB = new Date(b[key][subKey]).getTime();
      }
    }
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

const getRandomNum = () => {
  const crypto = window.crypto || window.msCrypto;
  var array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
}

const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


  return bytes.toFixed(dp) + ' ' + units[u];
}

function getMessages(chatId) {
  const activeRootTab =  store.getState().ui.activeRootTab
  const { assignedChats, chatHistory, monitoredChats, unmonitoredChats } = store.getState().chat
  let messages = [] 
  if(activeRootTab === "live" && assignedChats[chatId] && assignedChats[chatId].messages) {
    messages = assignedChats[chatId].messages
  } else if(activeRootTab === "history" && chatHistory[chatId] && chatHistory[chatId].messages) {
    messages = chatHistory[chatId].messages
  } else if(activeRootTab === "monitor") {
    const chats = { ...monitoredChats, ...unmonitoredChats }
    if(chats[chatId] && chats[chatId].messages)  {
      messages = chats[chatId].messages
    }
  }
  return messages
}

/* forms are of three types:
   - form-response (completed form)
   - form (yet to be completed form)
   - waiting form (placeholder form, used in timer, service nodes)

   getFormVariant figures out the type of form using the next 
   message in the chat transcript
*/
function getFormVariant(messages, mId) {
  const index = messages.findIndex((msg) => msg.mId === mId)
  if(index >= 0) {
    const nextMessage = messages[index + 1]
    if(nextMessage && nextMessage.msgType === "formResponse") {
      return "completed"
    }
    if(messages[index].nodeType === 'waitingForm' && Boolean(nextMessage)) {
      return "waiting"
    }
  }
  return null 
}

export {
  generateUniqueId,
  getUserMetaInfo,
  toCustomDateTimeFormat,
  isNotNullOrUndefinedString,
  compareValues,
  toDateTimeString,
  toTimeString,
  twoDigitText,
  toShortDateString,
  toCustomDateFormat,
  secondsToTime,
  getRandomNum,
  humanFileSize,
  getMessages,
  getFormVariant,
};
