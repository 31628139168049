import React from "react";
import { FaSync } from "react-icons/fa";

interface IResetChatProps {
  color: string;
  size: string;
}

export default function ResetChat(props: IResetChatProps) {
  return (
    <FaSync style={{ color: props.color, fontSize: props.size }} />
  );
}
