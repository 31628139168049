import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from "react-redux";
import '../../assets/sass/chatConversation.sass';
import { loadClientConversation } from '../../actions/uiAction';
import { CloseCircleOutlined } from "@ant-design/icons";
import {
    getChatById,
} from "../../actions/chatsAction";
import ChatMessages from './ChatMessages';
import styled from 'styled-components';
import { getRandomColor } from "../../utils/randomColor";
import { Tooltip } from 'antd';

const MessageWrapper = styled.div`
padding: 20px 0px;
background: white;
flex: 1;
max-height: calc(100vh - 58px - 152px);
overflow-y: auto;

&::-webkit-scrollbar {
  width: 5px;
}

&::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

&::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
`;

const Circle = styled.div`
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.color}24;
  border: ${(props) => `2px solid ${props.theme.color}`};
  width: ${(props) => props.theme.width};
  height: ${(props) => props.theme.height};
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const More = styled.div`
  margin-left: -20px;
  border-radius: 35px;
  border:  ${(props) => `2px solid ${props.theme.bColor}`};
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  background: #FFFFFFCF;
`;

const GroupName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: bold;
    border-radius: 15px;
    height: 32px;
    display: flex;
    align-items: center;
    min-width: 80px;
    max-width: 180px;
    justify-content: center;
    label {
      font-weight: 600;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      padding-top: 4px;
    }
`;

const array2Objects = (array = [], field) => {
    return array.reduce((a, c) => {
        a[c[field]] = c;
        return a;
    }, {});
};


const ChatConversation = (props) => {
    const clientConversation = useSelector((state: any) => state.ui.clientConversation);
    const { group: groupReducer, agent: agentReducer } = useSelector((state: any) => state);
    const groups = array2Objects(groupReducer.groups, "_id");
    const agents = array2Objects(agentReducer.agents, "_id");
    const [conversation, setConversation] = useState([]);
    const [chatInfo, setChatInfo] = useState(null);
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const { primaryColor, secondaryColor } = useSelector(
        (state: any) => state.ui.theme);
    useEffect(() => {
        if (props.clientConversation) {
            getChatById(props.clientConversation)().then((resp) => {
                debugger
                setChatInfo(resp);
                setConversation(resp.messages);
            });
        }
    }, [props.clientConversation]);

    const loadParticipants = (participants) => {
        let pCount = 0;
        let agentsList = [];
        let isMore = false;
        let title = [];
        let uniqueParticipants = [];
        uniqueParticipants = participants.filter(function (item, pos) {
            return participants.indexOf(item) == pos;
        })
        uniqueParticipants.forEach((pId) => {
            pCount++;
            let initial = agents[pId] && agents[pId].name.charAt(0).toUpperCase();
            let name = agents[pId] && agents[pId].name.charAt(0).toUpperCase() + agents[pId].name.slice(1);
            if (pCount >= 6) {
                isMore = true;
                title.push(<div key={0} style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px" }}>
                    <Circle theme={{ color: getRandomColor(pId), width: "22px", height: "22px", fontSize: "10px" }}>{initial}</Circle>
                    <div style={{ paddingLeft: "4px", fontSize: "14px" }}>{name}</div>
                </div>);
            } else if (!isMore) {
                agentsList.push(<div key={pId} style={{ marginLeft: "-20px", background: "#FFFFFFCF", borderRadius: "35px" }} >
                    <Tooltip placement="top" title={<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Circle theme={{ color: getRandomColor(pId), width: "22px", height: "22px", fontSize: "10px" }}>{initial}</Circle>
                        <div style={{ paddingLeft: "4px", fontSize: "14px" }}>{name}</div>
                    </div>}>
                        <Circle theme={{ color: getRandomColor(pId), width: "35px", height: "35px" }}>{initial}</Circle></Tooltip>
                </div >)
            }
        });
        if (isMore) {
            agentsList.push(<div key={0}><Tooltip placement="top" title={<div style={{ width: "100%" }}>{title}</div>}><More theme={{ bColor: primaryColor }}>+{(uniqueParticipants.length - 5)}</More></Tooltip></div>);
        }
        return agentsList;
    }
    return <div className="chat_conversation" style={{ width: props.clientConversation ? "38%" : "0px", transition: "width 0.5s", transitionDelay: props.clientConversation ? "0.5s" : "0s" }} >
        <div className="warpper">
            <div className="header" >
                <div className="title">Chat History</div>
                <CloseCircleOutlined style={{ color: secondaryColor, fontSize: "19px" }} onClick={() => props.loadClientConversation(null)}></CloseCircleOutlined>
            </div>
            {chatInfo && <div style={{ margin: "4px 0px", height: "46px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 20px" }}>
                    <GroupName style={{ background: primaryColor + "33", color: primaryColor }}>
                        <label title={chatInfo.groupId && groups[chatInfo.groupId] && groups[chatInfo.groupId].name}>
                            {chatInfo.groupId && groups[chatInfo.groupId] && groups[chatInfo.groupId].name}
                        </label>
                    </GroupName>
                    <div style={{ display: "flex" }}>
                        {loadParticipants(chatInfo.participants)}
                    </div>
                </div>
            </div>}
            <div style={{ whiteSpace: clientConversation ? "break-spaces" : "nowrap" }}>
                <MessageWrapper>
                    <ChatMessages chatId={props.clientConversation} chats={conversation} />
                    <div ref={messagesEndRef} />
                </MessageWrapper>
            </div>
        </div>
    </div>;
};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = {
    loadClientConversation
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatConversation);
