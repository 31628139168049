import * as React from "react";
import styled from "styled-components";
import Sidebar from "./components/Sidebar";
import Main from "./components/Main";
import Info from "./components/Info";
import { useSelector, useDispatch } from "react-redux";
import { clearPersistedChat } from "../../../actions/uiAction";
import { getGroups } from "../../../actions/groupsAction";
import { getAgents } from "../../../actions/agentsAction";
import ChatConversation from "../ChatConversation";

const Container = styled.div`
  display: flex;
  height: calc(100vh - 58px - 58px);
`;

export interface ILiveProps {
  chats: any[];
}

export const SelectedUserContext = React.createContext({
  selectedChat: "",
  setSelectedChat: (arg: any) => { },
});

export const LiveChatContext = React.createContext({
  userTextTyping: {},
  setUserTextTyping: (arg: any) => { },
});


export default function Live(props: ILiveProps) {
  const [userTextTyping, setUserTextTyping] = React.useState({});
  const clientConversation = useSelector((state: any) => state.ui.clientConversation);
  const [selectedChat, setSelectedChat] = React.useState("");
  const dispatch = useDispatch();
  const persistedChats = useSelector((state: any) => state.ui.chats);
  React.useEffect(() => {
    if (!persistedChats) {
      dispatch(clearPersistedChat());
    }
  }, []);
  const fetchGroups = React.useCallback(() => dispatch(getGroups()), [
    dispatch,
  ]);
  const fetchAgents = React.useCallback(() => dispatch(getAgents()), [
    dispatch,
  ]);
  React.useEffect(() => {
    fetchGroups();
    fetchAgents();
  }, []);

  return (
    <Container>
      <LiveChatContext.Provider value={{ userTextTyping, setUserTextTyping }}>
        <Sidebar />
        <Main />
        <ChatConversation clientConversation={clientConversation} />
        <Info clientConversation={clientConversation} />
      </LiveChatContext.Provider>
    </Container>
  );
}
