import * as React from "react";
import { Collapse, Input as InputAntd, message, Select } from "antd";
import styled from "styled-components";
import update from "immutability-helper";
import { ChromePicker } from "react-color";
import GradientPickerIcon from "../../../../../../../assets/icons/gradient.svg";
import { ThemeContext, SelectedPropertyContext } from "../../../../themeDesigner";
const Panel = Collapse.Panel;

const { Option } = Select;

export interface ITextProps {
    msgType: string
}

const GeneralPropertyWrapper = styled.div`
  padding: 0 15px;
`;

const Input = styled.input`
  width: ${(props) => props.theme.width}px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }

  &::placeholder {
    font-family: Muli;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #ccd0dd;
  }
`;

const AllWrapperRow = styled.div`
  margin-top: ${(props) => props.theme.marginTop}px;
  margin-bottom: ${(props) => props.theme.marginTop}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    margin-bottom: 5px;
  }
`;

const AllWrapper = styled.div`
  margin-top: ${(props) => props.theme.marginTop}px;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;

  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    margin-bottom: 5px;
  }
`;

const Label = styled.label`
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  margin: 14px 0;
`;

const Container = styled.div`
  /* margin-top: 14px; */
`;


const BackgroundColorContainer = styled.div`
  height: 59px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #aaa; */
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

function callback(key) {
    console.log(key);
}

export default function SimpleChoice(props: any) {
    const { selectedProperty } = React.useContext(SelectedPropertyContext);
    const [openOptBGPicker, setOpenOptBGPicker] = React.useState(false);
    const [openOptFRPicker, setOpenOptFRPicker] = React.useState(false)
    const [openOptHRPicker, setOpenOptHRPicker] = React.useState(false)
    const [openOptTextHRPicker, setOpenOptTextHRPicker] = React.useState(false);
    const { themeProps, setThemeProps } = React.useContext(ThemeContext);
    const {
        widget: { messages },
    } = themeProps;
    const {
        optionsBorder,
        optionsShadow,
        optionsRadius,
        optionsFontSize,
        optionsHeight,
        optionsFontWeight,
    } = messages["simpleChoice"][props.msgType];
    const [optBGColor, setOptBGColor] = React.useState(messages["simpleChoice"][props.msgType].optionsBGColor);
    const [optFRColor, setOptFRColor] = React.useState(messages["simpleChoice"][props.msgType].optionsFRColor);
    const [optHRColor, setOptHRColor] = React.useState(messages["simpleChoice"][props.msgType].optionsHRColor);
    const [optTextHRColor, setOptTextHRColor] = React.useState(messages["simpleChoice"][props.msgType].optionsTextHRColor);
    React.useEffect(() => {
        setOptBGColor(messages["simpleChoice"][props.msgType].optionsBGColor);
        setOptFRColor(messages["simpleChoice"][props.msgType].optionsFRColor);
        setOptHRColor(messages["simpleChoice"][props.msgType].optionsHRColor);
        setOptTextHRColor(messages["simpleChoice"][props.msgType].optionsTextHRColor);
    }, [selectedProperty]);

    const handleOptBGColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    simpleChoice: {
                        [props.msgType]: {
                            "optionsBGColor": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    };

    const handleOptFRColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    simpleChoice: {
                        [props.msgType]: {
                            "optionsFRColor": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    }

    const handleOptHRColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    simpleChoice: {
                        [props.msgType]: {
                            "optionsHRColor": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    }

    const handleOptTextHRColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    simpleChoice: {
                        [props.msgType]: {
                            "optionsTextHRColor": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    }

    const handleChange = (value: string, property: string) => {
        const updatedInput = update(themeProps, {
            widget: {
                messages: {
                    simpleChoice: {
                        [props.msgType]: {
                            [property]: { $set: value }
                        }
                    }
                },
            },
        });
        setThemeProps(updatedInput);
    };

    return (
        <Container>
            <GeneralPropertyWrapper>
                <AllWrapper>
                    <Label>OPTIONS STYLE</Label>
                    <BackgroundColorContainer>
                        <span className="text">Background</span>
                        <DisplayColor
                            theme={{ backgroundColor: optBGColor }}
                            className="picker"
                            onClick={() => setOpenOptBGPicker(!openOptBGPicker)}
                        />
                        {openOptBGPicker && (
                            <div className="color-picker">
                                <ChromePicker
                                    color={optBGColor}
                                    onChange={(color: any) => setOptBGColor(color.hex)}
                                    onChangeComplete={handleOptBGColorChange}
                                />
                            </div>
                        )}
                        <GradientPickerWrapper>
                            <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                        </GradientPickerWrapper>
                    </BackgroundColorContainer>
                    <BackgroundColorContainer>
                        <span className="text">Background Hover</span>
                        <DisplayColor
                            theme={{ backgroundColor: optHRColor }}
                            className="picker"
                            onClick={() => setOpenOptHRPicker(!openOptHRPicker)}
                        />
                        {openOptHRPicker && (
                            <div className="color-picker">
                                <ChromePicker
                                    color={optHRColor}
                                    onChange={(color: any) => setOptHRColor(color.hex)}
                                    onChangeComplete={handleOptHRColorChange}
                                />
                            </div>
                        )}
                        <GradientPickerWrapper>
                            <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                        </GradientPickerWrapper>
                    </BackgroundColorContainer>
                    <BackgroundColorContainer>
                        <span className="text">Text Color</span>
                        <DisplayColor
                            theme={{ backgroundColor: optFRColor }}
                            className="picker"
                            onClick={() => setOpenOptFRPicker(!openOptFRPicker)}
                        />
                        {openOptFRPicker && (
                            <div className="color-picker">
                                <ChromePicker
                                    color={optFRColor}
                                    onChange={(color: any) => setOptFRColor(color.hex)}
                                    onChangeComplete={handleOptFRColorChange}
                                />
                            </div>
                        )}
                        <GradientPickerWrapper>
                            <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                        </GradientPickerWrapper>
                    </BackgroundColorContainer>
                    <BackgroundColorContainer>
                        <span className="text">Text Hover Color</span>
                        <DisplayColor
                            theme={{ backgroundColor: optTextHRColor }}
                            className="picker"
                            onClick={() => setOpenOptTextHRPicker(!openOptTextHRPicker)}
                        />
                        {openOptTextHRPicker && (
                            <div className="color-picker">
                                <ChromePicker
                                    color={optTextHRColor}
                                    onChange={(color: any) => setOptTextHRColor(color.hex)}
                                    onChangeComplete={handleOptTextHRColorChange}
                                />
                            </div>
                        )}
                        <GradientPickerWrapper>
                            <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                        </GradientPickerWrapper>
                    </BackgroundColorContainer>
                    <AllWrapper theme={{ marginTop: 10 }}>
                        <span className="text">Border</span>
                        <Input
                            theme={{ optionsBorder, width: 210 }}
                            placeholder="1px solid black"
                            value={optionsBorder}
                            onChange={(e) => handleChange(e.target.value, "optionsBorder")}
                        />
                    </AllWrapper>
                    <AllWrapper theme={{ marginTop: 10 }}>
                        <span className="text">Shadow</span>
                        <Input
                            theme={{ optionsShadow, width: 210 }}
                            placeholder="0px 2px 5px 0px rgb(226 226 226)"
                            value={optionsShadow}
                            onChange={(e) => handleChange(e.target.value, "optionsShadow")}
                        />
                    </AllWrapper>
                    <AllWrapperRow>
                        <AllWrapper>
                            <span className="text">Border Radius</span>
                            <InputAntd
                                style={{ width: "88px" }}
                                maxLength={2}
                                type="number"
                                placeholder="15"
                                suffix={"px"}
                                value={optionsRadius}
                                onChange={(e) => { handleChange(e.target.value, "optionsRadius") }}
                            />
                        </AllWrapper>
                        <AllWrapper>
                            <span className="text">Height</span>
                            <InputAntd
                                placeholder="15px / auto"
                                style={{ width: "88px", paddingLeft: "2px", textAlign: "center" }} onChange={(e) => { handleChange(e.target.value, "optionsHeight") }} value={optionsHeight} />
                        </AllWrapper>
                    </AllWrapperRow>
                    <AllWrapperRow>
                        <AllWrapper>
                            <span className="text">Font Size</span>
                            <InputAntd
                                style={{ width: "88px" }}
                                maxLength={2}
                                type="number"
                                placeholder="14"
                                suffix={"px"}
                                value={optionsFontSize}
                                onChange={(e) => { handleChange(e.target.value, "optionsFontSize") }}
                            />
                        </AllWrapper>
                        <AllWrapper>
                            <span className="text">Font Weight</span>
                            <Select
                                defaultValue={optionsFontWeight}
                                style={{ height: "28px", width: "88px" }}
                                onChange={(value) => { handleChange(value, "optionsFontWeight") }}
                            >
                                <Option value={100}>100</Option>
                                <Option value={200}>200</Option>
                                <Option value={300}>300</Option>
                                <Option value={400}>400</Option>
                                <Option value={500}>500</Option>
                                <Option value={600}>600</Option>
                                <Option value={700}>700</Option>
                                <Option value={800}>800</Option>
                                <Option value={900}>900</Option>
                            </Select>
                        </AllWrapper>
                    </AllWrapperRow>
                </AllWrapper>
            </GeneralPropertyWrapper>
        </Container>
    );
}