import React from "react";
import "../../assets/sass/footer.sass";
import footerLogo from "../../assets/images/a8-footer-logo.svg";

export interface Props {
  label?: string;
}

const defaultLabel = "Powered By";

const Footer = ({ label = defaultLabel }: Props) => (
  <div className="footer">
    <div className="powered-by" data-testid="divPoweredBy">
      {label}
    </div>
    <a
      href="https://autonom8.com"
      target="_blank"
      className="logo"
      data-testid="footerLogoLink"
    >
      <img data-testid="footerLogo" src={footerLogo} />
    </a>
    <a
      href="https://autonom8.com/privacy-policy"
      target="_blank"
      className="privacy-policy"
    >
      Privacy Policy
    </a>
    {/* <div className="terms-of-use">Terms of Use</div> */}
  </div>
);

export default Footer;
