import * as React from "react";
import styled from "styled-components";

export interface IIncognitoProps {
  color?: string;
}

const Icon = styled.svg`
  width: 14.185px;
  height: 10.309px;
  transition: all 0.1s;
  cursor: pointer;
  fill: ${(props) => props.theme.color || "#ffffff"};
  stroke: ${(props) => props.theme.color || "#ffffff"};
  stroke-width: 0.5px;
`;

export default function Incognito(props: IIncognitoProps) {
  const { color } = props;
  return (
    <Icon theme={{ color }}>
      <g id="noun_Incognito_3162100" transform="translate(.25 .463)">
        <g id="Group_1502" data-name="Group 1502">
          <g
            id="Group_1493"
            data-name="Group 1493"
            transform="translate(.617 4.6)"
          >
            <g id="Group_1492" data-name="Group 1492">
              <path
                id="Path_5900"
                d="M37.1 258.933a2.2 2.2 0 1 1-1.767-2.156 2.228 2.228 0 0 1 1.767 2.156.144.144 0 0 0 .288 0 2.492 2.492 0 1 0-2 2.445 2.521 2.521 0 0 0 2-2.445.143.143 0 0 0-.288 0z"
                className="cls-1"
                data-name="Path 5900"
                transform="translate(-32.407 -256.443)"
              />
            </g>
          </g>
          <g
            id="Group_1495"
            data-name="Group 1495"
            transform="translate(8.095 4.6)"
          >
            <g id="Group_1494" data-name="Group 1494">
              <path
                id="Path_5901"
                d="M299.7 258.933a2.2 2.2 0 1 1-1.767-2.156 2.228 2.228 0 0 1 1.767 2.156.144.144 0 0 0 .288 0 2.492 2.492 0 1 0-2 2.445 2.521 2.521 0 0 0 2-2.445.143.143 0 0 0-.288 0z"
                className="cls-1"
                data-name="Path 5901"
                transform="translate(-295.007 -256.443)"
              />
            </g>
          </g>
          <g
            id="Group_1497"
            data-name="Group 1497"
            transform="translate(5.266 6.715)"
          >
            <g id="Group_1496" data-name="Group 1496">
              <path
                id="Path_5902"
                d="M195.489 324.316a2.45 2.45 0 0 1 1.323-.438 2.374 2.374 0 0 1 1.41.438c.153.107.3-.141.144-.248a2.627 2.627 0 0 0-1.554-.479 2.727 2.727 0 0 0-1.467.479c-.156.1-.012.349.144.248z"
                className="cls-1"
                data-name="Path 5902"
                transform="translate(-195.278 -323.589)"
              />
            </g>
          </g>
          <g
            id="Group_1499"
            data-name="Group 1499"
            transform="translate(0 3.569)"
          >
            <g id="Group_1498" data-name="Group 1498">
              <path
                id="Path_5903"
                d="M11.137 204.943H22.9c.541 0 1.085.023 1.626 0h.023a.144.144 0 0 0 0-.288H12.787c-.541 0-1.085-.023-1.626 0h-.023a.144.144 0 0 0 0 .288z"
                className="cls-1"
                data-name="Path 5903"
                transform="translate(-11 -204.644)"
              />
            </g>
          </g>
          <g id="Group_1501" data-name="Group 1501" transform="translate(2.56)">
            <g id="Group_1500" data-name="Group 1500">
              <path
                id="Path_5904"
                d="M76.272 74.856l1.236-3.364.175-.476-.106.072h5.266l-.106-.072 1.453 3.37.2.47c.071.164.283.02.212-.144l-1.453-3.37-.2-.47a.122.122 0 0 0-.106-.072h-5.265a.117.117 0 0 0-.106.072l-1.236 3.364-.175.476c-.059.159.15.314.212.144z"
                className="cls-1"
                data-name="Path 5904"
                transform="translate(-76.049 -70.8)"
              />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
}
