import * as React from "react";
import styled from "styled-components";
import update from "immutability-helper";
import { ChromePicker } from "react-color";

import GradientPickerIcon from "../../../../../../../../assets/icons/gradient.svg";
import { ThemeContext } from "../../../../../themeDesigner";

export interface IContentBackgroundProps { }

interface IColor {
  rgb: string;
}

const GeneralPropertyWrapper = styled.div`
  padding: 0 15px;
`;

const BackgroundColorContainer = styled.div`
  height: 59px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #aaa;
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

export default function ContentBackground(props: IContentBackgroundProps) {
  const [openPicker, setOpenPicker] = React.useState(false);
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: { content },
  } = themeProps;
  const [color, setColor] = React.useState(content.background.hex);

  const handleBackgroundColorChange = (color) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        content: {
          background: {
            hex: { $set: color.hex },
          },
        },
      },
    });
    setThemeProps(updatedGeneralProperties);
  };

  return (
    <GeneralPropertyWrapper>
      <BackgroundColorContainer>
        <span className="text">Background Color</span>
        <DisplayColor
          theme={{ backgroundColor: color }}
          className="picker"
          onClick={() => setOpenPicker(!openPicker)}
        />
        {openPicker && (
          <div className="color-picker">
            <ChromePicker
              color={color}
              onChange={(color: any) => setColor(color.hex)}
              onChangeComplete={handleBackgroundColorChange}
            />
          </div>
        )}
        <GradientPickerWrapper>
          <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
        </GradientPickerWrapper>
      </BackgroundColorContainer>
    </GeneralPropertyWrapper>
  );
}
