import * as React from "react";
import AdaptiveCard from "../../live/components/adaptiveCard";
import CardComponent from "../CardComponent";
import { Carousel } from "antd";
import "./style.css";
import { useSelector } from "react-redux";
import { sendChatMessage } from "../../../../helpers/mqttService";

export interface ICarouselProps {
  cards: any[];
  data: any;
  id: string;
  chats: any[];
  assignChatId?: string
  isPrivate?: boolean
  logs?: any[]
}

const renderContent = (
  card: any,
  index: number,
  data: any,
  id: string,
  chats: any[],
  shouldShowActions: boolean,
  handleCardButtonClick: (data: any, index: number) => void,
) => {

  switch (card.type) {
    case "adaptiveCard": {
      const { from, fromName } = data;
      const { template, data: cardData, isFeedBack } = card;

      return (
        <AdaptiveCard
          key={index}
          from={from}
          fromName={fromName}
          template={template}
          data={cardData}
          isFeedback={isFeedBack}
          id={id}
          type="carousel"
        />
      );
    }
    default: {
      return (
        <CardComponent
          key={index}
          card={card}
          index={index}
          handleCardButtonClick={handleCardButtonClick}
          shouldShowActions={shouldShowActions}
        />
      );
    }
  }
};

export default function CarouselComponent(props: ICarouselProps) {
  const activeRootTab = useSelector((state: any) => state.ui.activeRootTab)
  const { assignChatId, isPrivate, logs } = props
  const { cards, data, id, chats } = props;
  const lastMsg = logs[logs.length - 1]

  const shouldShowActions =
    lastMsg && (lastMsg.msgType === "carousel" || lastMsg.msgType === "cardCarousel") &&
    id === lastMsg.mId;

  const handleCardButtonClick = (payload: any, index: number) => {
    if(assignChatId && activeRootTab === "live") {
      switch (payload.type) {
        case "openUrl": {
          window.open(payload.value, "_blank");
          break;
        }
        case "messageBack": {
          const card = cards[index];
          sendChatMessage({
            isPrivate,
            chatId: assignChatId,
            msg: card,
            msgType: 'cardCarouselResponse'
          })
          break;
        }
      }
    }
  };

  return (
    <div style={{ width: "400px", marginLeft: "auto" }}>
      <Carousel dotPosition="bottom">
        {cards.map((card, index) =>
          renderContent(card, index, data, id, chats, shouldShowActions, handleCardButtonClick)
        )}
      </Carousel>
    </div>
  );
}
