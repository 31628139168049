import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PrivateRoute from "./helpers/PrivateRoute";
import { SignIn, ForgotPassword, VerificationCode, ResetPassword, ResetPasswordSuccess } from "./components/auth";
import NotFound from "./components/notFound/NotFound";
import "a8-uicomponents/dist/index.css";
import "antd/dist/antd.css";
import Dashboard from "./components/dashboard/Dashboard";
import Admin from "./components/admin/Settings";
import Resources from "./components/resources/Resources";
import Error from "./components/common/Error";
import Chats from "./components/chats/Chats";
import "./assets/css/app.css";
import "./assets/sass/style.sass";
import { store } from "../src/store";
import Main from "./components/common/Main";
import { signSuccess, sessionTokenSuccess } from "../src/actions/taskAction";
import { getRefreshToken } from "../src/actions/authActions";
import { getMyInfo } from "../src/actions/taskAction";
import { setTheme } from "../src/actions/uiAction";
import ThemeSwitcher from "react-css-vars";
// import ThemeDesigner from "./components/settings/general/themeDesigner";
import Settings from "./components/settings";
import { disconnect } from "./helpers/mqttService";
import { SoloWidget } from "./components/chats/SoloWidget";
import { initSoloFlow } from "./components/chats/SoloWidget/utils";
import { getDomain } from "./utils/helpers";

export const ForgotPasswordContext = React.createContext({
  resetEmail: "",
  setResetEmail: (arg) => { },
  accessToken: "",
  setAccessToken: (arg) => { },
});
let myInfoUpdated = false;
let refreshTokenTimer = null;
let theme = {
  primaryColor: "#1d7aff",
  secondaryColor: "#e97a43",
  secondaryLighterColor: "#ffe5d9",
  buttonBGColor: "#1d7aff",
  buttonFGColor: "#fff",
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      theme: theme,
      resetEmail: "",
      accessToken: "",
    };
  }
  setResetEmail = (resetEmail) => {
    this.setState({ resetEmail });
  };
  setAccessToken = (accessToken) => {
    this.setState({ accessToken });
  };
  async componentDidMount() {
    let userJson = localStorage.getItem("userInfo");
    let userInfo = userJson !== "" && userJson !== null ? JSON.parse(userJson) : null;
    if (userInfo) {
      delete userInfo.info.state;
      this.props.getMyInfo(userInfo.info.accessToken);
      this.props.signSuccess(userInfo.info);
      this.props.sessionTokenSuccess(userInfo.sessionToken);
    }
    // this.checkTokenExpTime(this.props);

    window.addEventListener("beforeunload", (event) => {
      console.log("MQTT disconencted");
      disconnect();
    });

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      console.log("Notifications are supported");
      Notification.requestPermission();
    }
  }

  loadSoloScript(domain, callback = () => {}) {
    const existingScript = document.getElementById('a8_solo_script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://${domain}/solo/main.bundle.js`;
      script.id = 'a8_solo_script';
      document.body.appendChild(script);
      script.onload = callback
    }
  }

  checkTokenExpTime = (props) => {
    clearTimeout(refreshTokenTimer);
    refreshTokenTimer = setTimeout(
      function () {
        let auth = store.getState().auth;
        let data = null;
        if (auth && auth.info) {
          let exp = auth.info.lastLoginTime - Math.round(new Date().getTime() / 1000);
          // console.log(auth.info.lastLoginTime - Math.round((new Date().getTime() / 1000)));
          if (exp < -1) {
            clearTimeout(refreshTokenTimer);
            console.log("Session Expired");
            data = {
              refreshToken: auth.info.refreshToken,
              orgId: auth.info.orgId,
              agentId: auth.info.agentId,
              roles: auth.info.roles,
            };
            props.getRefreshToken(data);
          } else {
            this.checkTokenExpTime(props);
          }
        } else {
          this.checkTokenExpTime(props);
        }
      }.bind(this),
      1000
    );
  };

  componentWillReceiveProps(props) {
    if (props.auth.refreshToken) {
      let userJson = localStorage.getItem("userInfo");
      let userInfo = userJson !== "" && userJson !== null ? JSON.parse(userJson) : null;
      if (userInfo) {
        userInfo.info.accessToken = props.auth.refreshToken.accessToken;
        userInfo.info.lastLoginTime =
          Math.round(new Date().getTime() / 1000) + (props.auth.refreshToken.expiresIn - 500);
        userInfo.info.refreshToken = props.auth.refreshToken.refreshToken;
        userInfo.info.roles = props.auth.refreshToken.roles;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        this.props.signSuccess(userInfo && userInfo.info);
      }
    } else if (props.task.myInfo) {
      let userJson = localStorage.getItem("userInfo");
      let userInfo = userJson !== "" && userJson !== null ? JSON.parse(userJson) : null;
      if (userInfo && props.task.myInfo.groups && myInfoUpdated === false) {
        myInfoUpdated = true;
        userInfo.info.groups = props.task.myInfo.groups
        userInfo.info.roles = props.task.myInfo.roles;
        userInfo.info.state = props.task.myInfo.state;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        this.props.signSuccess(userInfo && userInfo.info);
      }
    } else if (props.auth && !props.auth.error && props.auth.info && !props.auth.refreshToken) {
      this.loadSoloScript(props.auth.info.soloDomain, () => {
        // Initializing the widget with placeholder values to minimize the load time 
        // when the agent interacts with it
        setTimeout(() => {
          initSoloFlow({ appId: "", sessionId: ""})
        }, 3000)
      })
      this.checkTokenExpTime(props);
    } else if (props.auth.error || props.group.error || props.agent.error) {
      clearTimeout(refreshTokenTimer);
      this.setState({
        error: props.agent.error || props.auth.error || props.group.error,
      });
    }
    if (props.ui.theme) {
      this.setState({
        theme: props.ui.theme,
      });
    } else {
      this.props.setTheme(theme);
    }
  }

  render() {
    return (
      <Router>
        <ThemeSwitcher theme={this.state.theme}>
          <div className="a8-live-agent">
            <ForgotPasswordContext.Provider
              value={{
                resetEmail: this.state.resetEmail,
                setResetEmail: this.setResetEmail,
                accessToken: this.state.accessToken,
                setAccessToken: this.setAccessToken,
              }}
            >
              <Switch>
                <Route path="/signin" component={SignIn} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/verify-otp" component={VerificationCode} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/reset-password-success" component={ResetPasswordSuccess} />

                <Main>
                  <Switch>
                    <PrivateRoute path="/dashboard" component={Dashboard} />
                    <PrivateRoute path="/admin" component={Admin} />
                    <PrivateRoute path="/settings" component={Settings} />
                    <PrivateRoute path="/resources" component={Resources} />
                    <PrivateRoute path="/chats" component={Chats} />
                    <PrivateRoute path="/" component={Chats} />
                  </Switch>
                </Main>
                <Route component={NotFound} />
              </Switch>
            </ForgotPasswordContext.Provider>
          </div>
          <Error error={this.state.error}></Error>
          <SoloWidget />
        </ThemeSwitcher>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    ui: state.ui,
    task: state.task,
    auth: state.auth,
    agent: state.agent,
    group: state.group,
  };
};

export default connect(mapStateToProps, {
  signSuccess,
  sessionTokenSuccess,
  getRefreshToken,
  setTheme,
  getMyInfo,
})(App);
