import * as React from "react";
import styled from "styled-components";

export interface IAttachmentIconProps {
  onClick: () => void;
  className?: string;
}

const Icon = styled.svg`
  width: 18px;
  height: 18px;
  stroke: #a8abbd;
  transition: all 0.1s;
  cursor: pointer;

  &:hover {
    stroke: #ea895c;
  }

  &.active {
    stroke: #ea895c;
  }
`;

export default function AttachmentIcon(props: IAttachmentIconProps) {
  const { onClick, className } = props;
  return (
    <Icon onClick={onClick} className={className}>
      <path
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
        strokeWidth="1.3"
        d="M16.723 8.555l-6.962 6.962c-1.977 1.977-5.3 1.977-7.278 0h0c-1.977-1.978-1.977-5.3 0-7.278l6.17-6.171c1.425-1.424 3.64-1.424 5.064 0h0c1.424 1.424 1.424 3.639 0 5.063L8.02 12.748c-.791.79-2.057.79-2.769 0h0c-.79-.791-.79-2.057 0-2.769L10 5.232"
      />
    </Icon>
  );
}
