import React, { useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// import { ThemeContext } from "../../../../../themeDesigner";
import { ThemeContext } from "../../../settings/general/themeDesigner";

// import { clearBotSuggestions } from "../../../actions/userInputActions";

interface ISuggestionsProps {
  questions: string[];
  themeProps: any;
}

const SuggestionsContainer = styled.div`
  max-width: calc(
    ${(props) => props.theme.maxWidth} - ${(props) => props.theme.paddingLeft} - ${(props) => props.theme.paddingRight}
  );
  margin-right: 10px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #313448;
  }

  @media all and (-ms-high-contrast: none) {
    flex-grow: 1;
  }
`;

const Question = styled.div`
  border: ${(props) => props.theme.border};
  border-radius: ${(props) =>
    props.theme.borderRadius
      ? props.theme.borderRadius
      : `${props.theme.borderRadiusTopLeft} ${props.theme.borderRadiusTopRight} ${props.theme.borderRadiusBottomRight} ${props.theme.borderRadiusBottomLeft}`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  margin: 5px;
  cursor: pointer;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.backgroundColor};
  user-select: none;

  &:hover {
    background: ${(props: any) => props.theme.backgroundColorHover};
    color: ${(props: any) => props.theme.colorHover};
  }
`;

const SurfaceQuestion: React.FunctionComponent<ISuggestionsProps> = (props: ISuggestionsProps) => {
  const { questions } = props;
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const sliderRef = useRef(null);
  const { font } = themeProps.widget.dimension;
  const surfaceQuestionProps = themeProps.widget.surfaceQuestion;
  const contentStyles = themeProps.widget.content;
  const dispatch = useDispatch();
  // const clearSuggestions = useCallback(() => dispatch(clearBotSuggestions()), [dispatch]);
  let isPressed = false;
  let startX, scrollLeft;
  const handleMouseDown = (e) => {
    e.persist();
    isPressed = true;
    const slider = sliderRef.current;
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  };

  const handleMouseUp = (e) => {
    isPressed = false;
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (isPressed) {
      const slider = sliderRef.current;
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 1; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    }
  };

  const handleWheel = (e) => {
    const slider = sliderRef.current;
    scrollLeft = slider.scrollLeft;
    if (e.deltaY > 0) slider.scrollLeft += 20;
    else slider.scrollLeft -= 20;
  };

  const handleSubmit = (selectedText: string) => {
    // clearSuggestions();
    const msgPayload = {
      eventName: "response",
      msgType: "text",
      msg: {
        text: selectedText,
      },
    };

    window.parent.postMessage(msgPayload, "*");
  };

  const handleMouseLeave = (e) => {
    document.getElementById("endRef").scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <SuggestionsContainer
      ref={sliderRef}
    // theme={{
    //   maxWidth: widgetStyles.maxWidth,
    //   paddingLeft: contentStyles.paddingLeft,
    //   paddingRight: contentStyles.paddingRight,
    // }}
    >
      {questions.map((question, key) => (
        <Question
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onWheel={handleWheel}
          key={key}
          onClick={() => handleSubmit(question)}
          theme={{
            color: surfaceQuestionProps.color,
            colorHover: surfaceQuestionProps.colorHover,
            backgroundColor: surfaceQuestionProps.backgroundColor,
            backgroundColorHover: surfaceQuestionProps.backgroundColorHover,
            border: surfaceQuestionProps.border,
            borderRadius: surfaceQuestionProps.borderRadius,
            borderRadiusBottomLeft: surfaceQuestionProps.borderRadiusBottomLeft,
            borderRadiusBottomRight: surfaceQuestionProps.borderRadiusBottomRight,
            borderRadiusTopLeft: surfaceQuestionProps.borderRadiusTopLeft,
            borderRadiusTopRight: surfaceQuestionProps.borderRadiusTopRight,
            fontFamily: font
          }}
          className="suggestion-question"
        >
          {question}
        </Question>
      ))}
    </SuggestionsContainer>
  );
};

export default SurfaceQuestion;
