import axios from "axios";
import { store } from "../store";
import { notification } from 'antd';
import config from "../apiConfig";

export const actionNotification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
  setTimeout(function () {
    notification.close()
  }, 1000);
};

export const publishTheme = (botName, botHandle, payload) => async (dispatch) => {
  const json = JSON.stringify(payload);
  const payloadString = Buffer.from(json).toString("base64");
  const url = `${config.baseUrl}${config.org}${config.v1
    }${config.publishTheme.replace("{bot_handle}", botHandle)}`;
  const options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
    data: {
      themeV2: payloadString,
    },
  };

  try {
    const resp = await axios(url, options);
    if (resp && resp.data && resp.data.code === "success") {
      actionNotification("info", "Chat Widget Theme Settings", `${botName} theme updation successfully`);
    } else {
      actionNotification("error", "Chat Widget Theme Settings", `${botName} theme updation failure`);
    }
    return resp;
  } catch (e) {
    actionNotification("error", "Chat Widget Theme Settings", `${botName} theme updation failure`);
    console.log(e);
  }
};
