import * as React from "react";
import Notification from "./Notification";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import "../../assets/sass/header.sass";
import headerLogo from "../../assets/images/a8-header-logo.svg";
import DashboardMenu from "../dashboard/Menu";
import AdminMenu from "../admin/SettingMenu";
import ResourceMenu from "../resources/ResourceMenu";
import ChatMenu from "../chats/ChatMenu";
import Theme from "../common/Theme";
import SettingsMenu from "../settings/SettingsMenu";

export interface IState {
  ui: {
    activeRootNav: string;
    routingAlgorithm: string;
  };
  chat?: {
    chats: any[];
  };
}

const Header: React.FunctionComponent = () => {
  const { activeRootNav: headerName, routingAlgorithm } = useSelector((state: IState) => state.ui);
  const showNotification = routingAlgorithm !== "intelligentRouting";
  return (
    <Row className="header">
      <Col data-testid="menus" span={21} className="menus">
        {headerName === "admin" && <AdminMenu></AdminMenu>}
        {headerName === "settings" && <SettingsMenu></SettingsMenu>}
        {headerName === "resources" && <ResourceMenu></ResourceMenu>}
        {headerName === "chats" && <ChatMenu></ChatMenu>}
        {headerName === "dashboard" && <DashboardMenu></DashboardMenu>}
      </Col>
      <Col data-testid="theme" span={1}>
        <Theme></Theme>
      </Col>
      {showNotification && (
        <Col data-testid="notifications" span={1}>
          <Notification />
        </Col>
      )}
      <Col span={1} data-testid="headerLogo" className="header-logo">
        <img data-testid="headerLogoImage" src={headerLogo} alt="a8-console-header-logo" />
      </Col>
    </Row>
  );
};

export default Header;
