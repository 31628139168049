import * as React from "react";
import styled from "styled-components";
import update from "immutability-helper";
import { ChromePicker } from "react-color";
import { Select } from "antd";
import { ThemeContext } from "../../../../../themeDesigner";
import GradientPickerIcon from "../../../../../../../../assets/icons/gradient.svg";

const { Option } = Select;

export interface IGeneralProps { }

interface IColor {
  hex: string;
  rgb: any;
}

const GeneralPropertyWrapper = styled.div`
  padding: 0 15px;
`;

const InputWrapper = styled.div`
  height: 58.5px;
  padding: 14.5px 0 16px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #aaa;
  margin-bottom: 14px;

  .label {
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    position: absolute;
    right: 3px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Input = styled.input`
  width: ${(props) => props.theme.width}px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacingproperties: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;
  padding-right: 22px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }
`;

const ColorContainerWrapper = styled.div`
  margin-bottom: 14px;
`;

const BackgroundColorContainer = styled.div`
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

export default function General(props: IGeneralProps) {
  const [openPicker, setOpenPicker] = React.useState(false);
  const [openSecondaryPicker, setOpenSecondaryPicker] = React.useState(false);
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: { dimension },
  } = themeProps;
  const [color, setColor] = React.useState(dimension.primaryColor.hex);
  const [secondaryColor, setSecondaryColor] = React.useState(dimension.secondaryColor.hex);
  React.useEffect(() => {
    if (color !== dimension.primaryColor.hex) {
      setColor(dimension.primaryColor.hex)
    }
    if (secondaryColor !== dimension.secondaryColor.hex) {
      setSecondaryColor(dimension.secondaryColor.hex)
    }
  }, [dimension])
  const handlePropertyChange = (property: string) => (color: IColor) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        dimension: {
          [property]: {
            hex: { $set: color.hex },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };

  const handleFontChange = (font: string) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        dimension: {
          font: { $set: font },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };

  const handleChange = (property: string, value: any) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        dimension: {
          [property]: { $set: value },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };

  return (
    <GeneralPropertyWrapper>
      <div style={{ margin: "16px 0" }}>
        <p style={{ paddingBottom: "4px" }}>Bot Name</p>
        <Input
          value={dimension.botName}
          onChange={(e) => handleChange("botName", e.target.value)}
          theme={{ width: 220 }}
        />
      </div>

      <BackgroundColorContainer>
        <span className="text">Z Index</span>
        <Input
          value={dimension.zIndex}
          placeholder="9999"
          onChange={(e) => handleChange("zIndex", e.target.value)}
          theme={{ width: 85 }}
        />
      </BackgroundColorContainer>
      <InputWrapper>
        <div style={{ position: "relative" }}>
          <span className="label" title="Width">
            W
          </span>
          <Input
            placeholder="400px"
            value={dimension.maxWidth}
            onChange={(e) => handleChange("maxWidth", e.target.value)}
            theme={{ width: 85 }}
          />
        </div>
        <div style={{ position: "relative" }}>
          <span className="label" title="Height">
            H
          </span>
          <Input
            placeholder="85vh"
            value={dimension.minHeight}
            onChange={(e) => handleChange("minHeight", e.target.value)}
            theme={{ width: 85 }}
          />
        </div>
      </InputWrapper>
      <ColorContainerWrapper>
        <BackgroundColorContainer>
          <span className="text">Primary Color</span>
          <DisplayColor
            theme={{ backgroundColor: color }}
            className="picker"
            onClick={() => {
              setOpenPicker(!openPicker);
              setOpenSecondaryPicker(false);
            }}
          />
          {openPicker && (
            <div className="color-picker">
              <ChromePicker
                color={color}
                onChange={(color: IColor) => setColor(color.hex)}
                onChangeComplete={handlePropertyChange("primaryColor")}
              />
            </div>
          )}
          <GradientPickerWrapper>
            <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
          </GradientPickerWrapper>
        </BackgroundColorContainer>
        <BackgroundColorContainer>
          <span className="text">Secondary Color</span>
          <DisplayColor
            theme={{ backgroundColor: secondaryColor }}
            className="picker"
            onClick={() => {
              setOpenSecondaryPicker(!openSecondaryPicker);
              setOpenPicker(false);
            }}
          />
          {openSecondaryPicker && (
            <div className="color-picker">
              <ChromePicker
                color={secondaryColor}
                onChange={(secondaryColor: IColor) => setSecondaryColor(secondaryColor.hex)}
                onChangeComplete={handlePropertyChange("secondaryColor")}
              />
            </div>
          )}
          <GradientPickerWrapper>
            <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
          </GradientPickerWrapper>
        </BackgroundColorContainer>
      </ColorContainerWrapper>
      <DropdownWrapper>
        <span className="text">Fonts</span>

        <Select
          value={dimension.font}
          style={{ width: 161, height: 28 }}
          onChange={handleFontChange}
          size="small"
        >
          <Option value="AthleticsBold">Athletics</Option>
          <Option value="GothamBook">Gotham</Option>
          <Option value="Montserrat">Montserrat</Option>
          <Option value="Muli">Muli</Option>
          <Option value="OpenSans">OpenSans</Option>
          <Option value="sans-serif">Sans Serif</Option>
          <Option value="Raleway">Raleway</Option>
        </Select>
      </DropdownWrapper>
    </GeneralPropertyWrapper>
  );
}
