/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type from "../types/actionTypes";
import axios from "axios";
import config from "../apiConfig";
import { store } from "../store";
import { notification } from "antd";

const actionNotification = (type, title, message) => {
    notification[type]({
        message: title,
        description: message,
    });
    setTimeout(function () {
        notification.close();
    }, 1000);
};

export const getAPIScopes = () => {
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
        },
    };
    return async (dispatch) => {
        try {
            let response = await axios(`${config.baseUrl}${config.org}${config.v1}${config.getAPIScopesUrl}`, options);
            return response.data;
        } catch (error) {
            dispatch({
                type: type.ERROR,
                data: error.response,
            });
        }
    };
}

export const getAPICredentials = () => {
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
        },
    };
    return async (dispatch) => {
        try {
            let response = await axios(`${config.baseUrl}${config.org}${config.v1}${config.getAPICredentialsUrl}`, options);
            return response.data;
        } catch (error) {
            dispatch({
                type: type.ERROR,
                data: error.response,
            });
        }
    };
}

export function generateToken(data) {
    const options = {
        method: "POST",
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
        },
    };
    return async (dispatch) => {
        await axios(
            `${config.baseUrl}${config.org}${config.v1}${config.generateTokenUrl}`,
            options
        )
            .then(function (resp) {
                dispatch({
                    type: type.GENERATE_TOKEN_SUCCESS,
                    data: resp.data,
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    dispatch({
                        type: type.ERROR,
                        data: error.response.data,
                    });
                } else {
                    Object.assign(data, { error: error.response.data.message });
                    dispatch({
                        type: type.GENERATE_TOKEN_ERROR,
                        data: { generateToken: data },
                    });
                }
            });
    };
}

export function removeToken(data, originalData) {
    const options = {
        method: "PUT",
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
        },
    };
    return async (dispatch) => {
        await axios(
            `${config.baseUrl}${config.org}${config.v1}${config.removeTokenUrl}`,
            options
        )
            .then(function (resp) {
                dispatch({
                    type: type.REMOVE_TOKEN_SUCCESS,
                    data: true,
                });
                actionNotification("info", "REMOVE TOKEN", `Remove token successfully`);
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    dispatch({
                        type: type.ERROR,
                        data: error.response.data,
                    });
                } else {
                    Object.assign(originalData, { error: error.response.data.message });
                    dispatch({
                        type: type.REMOVE_TOKEN_ERROR,
                        data: { remvoeToken: originalData },
                    });
                }
            });
    };
}
