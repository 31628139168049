import * as React from "react";
import styled from "styled-components";

export interface IDeleteProps {
  color?: string;
  onClick?: (arg: any) => void;
}

const Icon = styled.svg`
  width: 20px;
  height: 20px;
  transition: all 0.1s;
  cursor: pointer;
  fill: ${(props) => props.theme.color || "#ccd0dd"};
  stroke: ${(props) => props.theme.color || "#ccd0dd"};
  stroke-width: 0.3px;
`;

export default function Delete(props: IDeleteProps) {
  const { color, onClick } = props;
  return (
    <Icon theme={{ color }} onClick={onClick}>
      <g id="noun_Delete_1640155">
        <g id="Group_24" data-name="Group 24">
          <g
            id="Group_15"
            data-name="Group 15"
            transform="translate(2.76 5.033)"
          >
            <g id="Group_14" data-name="Group 14">
              <path
                id="Path_55"
                d="M1.006.528V13.06a.723.723 0 0 0 0 .074c0-.005 0 .071.005.071s-.02-.118 0-.034.042.147.057.221-.015-.053-.02-.045.017.042.02.047c.02.042.04.081.063.121s.037.058.055.087c.03.05-.027-.042-.027-.039a.463.463 0 0 0 .042.053l.067.074c.022.024.093.105.048.047s-.008-.005.015.011.027.018.04.029a.951.951 0 0 0 .085.053l.06.031c.035.018.038 0 0 0s-.033-.013 0 0 .042.016.062.021c.038.011.075.021.115.029a.155.155 0 0 1 .05.01s-.1-.008-.048-.008.083.005.123.005h10.827c.02 0 .133-.013.053 0s.043-.011.068-.016.075-.021.113-.034c.11-.037-.058.034.02-.005l.088-.047a.573.573 0 0 0 .082-.055.536.536 0 0 0 .053-.039c0 .005-.083.074-.025.018s.1-.113.15-.158-.037.039-.027.039.025-.037.027-.042a1.1 1.1 0 0 0 .068-.118 1.016 1.016 0 0 1 .045-.092c.027-.053-.023.045-.018.045s.015-.045.018-.05c.012-.039.022-.079.033-.118s.018-.147.015-.071 0-.053 0-.074V.526a.518.518 0 0 1 .5-.525.523.523 0 0 1 .5.525v12.521a1.979 1.979 0 0 1-.783 1.576 2.274 2.274 0 0 1-1.5.344H1.867a1.831 1.831 0 0 1-1.21-.447A1.959 1.959 0 0 1 0 13.042V.526A.518.518 0 0 1 .5 0a.528.528 0 0 1 .505.528z"
                data-name="Path 55"
              />
            </g>
          </g>
          <g id="Group_17" data-name="Group 17" transform="translate(0 2.837)">
            <g id="Group_16" data-name="Group 16">
              <path
                id="Path_56"
                d="M.5 0h18.993a.526.526 0 0 1 0 1.051H.507A.521.521 0 0 1 0 .525.523.523 0 0 1 .5 0z"
                data-name="Path 56"
              />
            </g>
          </g>
          <g id="Group_19" data-name="Group 19" transform="translate(5.378)">
            <g id="Group_18" data-name="Group 18">
              <path
                id="Path_57"
                d="M8.2 3.362V1.45v-.147c0-.008-.005-.037 0-.039-.01.008.032.129 0 .034a.558.558 0 0 1-.018-.068c-.005.011.06.11.011.029-.013-.021-.024-.042-.037-.06-.037-.066.018.008.018.021 0-.016-.052-.055-.063-.066-.092-.087.071.037-.037-.029-.021-.013-.042-.026-.063-.037S7.928 1.059 8 1.074s-.053-.01-.076-.018c-.089-.026.016.008.031 0a.356.356 0 0 1-.074 0H3.106c-.617 0-1.235-.013-1.849 0h-.053c-.068 0 .1-.032.037 0a.269.269 0 0 1-.066.018c-.053.005.047-.01.047-.024l-.113.066c.039.013.079-.076.031-.021l-.034.034c-.026.029-.016.016.031-.042 0 0-.071.113-.068.113s.039-.129.016-.032l-.018.066a.436.436 0 0 0-.013.068c.008 0 .005-.11.008-.053v2.112a.53.53 0 0 1-.525.525.536.536 0 0 1-.525-.525V2.1A2.606 2.606 0 0 1 .251.528 1.251 1.251 0 0 1 1.273 0h6.133A3.637 3.637 0 0 1 8.2.042a1.363 1.363 0 0 1 1.052 1.224c.008.142 0 .286 0 .431v1.668a.53.53 0 0 1-.525.525.539.539 0 0 1-.527-.528z"
                data-name="Path 57"
              />
            </g>
          </g>
          <g
            id="Group_21"
            data-name="Group 21"
            transform="translate(11.666 5.256)"
          >
            <g id="Group_20" data-name="Group 20">
              <path
                id="Path_58"
                d="M1.055.526v9.248c0 .425.011.851 0 1.277v.018a.53.53 0 0 1-.525.525.536.536 0 0 1-.53-.525V1.821C0 1.4-.006.97 0 .544V.526A.53.53 0 0 1 .53 0a.534.534 0 0 1 .525.525z"
                data-name="Path 58"
              />
            </g>
          </g>
          <g
            id="Group_23"
            data-name="Group 23"
            transform="translate(7.216 5.256)"
          >
            <g id="Group_22" data-name="Group 22">
              <path
                id="Path_59"
                d="M1.055.526v9.248c0 .425.01.851 0 1.277v.018a.53.53 0 0 1-.525.525.536.536 0 0 1-.53-.525V1.821C0 1.4-.006.97 0 .544V.526A.53.53 0 0 1 .53 0a.536.536 0 0 1 .525.525z"
                data-name="Path 59"
              />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
}
