import * as React from "react";
import { Collapse, Select } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import update from "immutability-helper";

import ImageUploadIcon from "../../../../../../../../assets/images/image-upload.svg";
import GradientPickerIcon from "../../../../../../../../assets/icons/gradient.svg";
import { ThemeContext } from "../../../../../themeDesigner";
import { ChromePicker } from "react-color";

const Panel = Collapse.Panel;

const { Option } = Select;

function callback(key) {
  console.log(key);
}

export interface IFooterPropertiesProps {}
interface IState {
  ui: IUiTheme;
}

interface IUiTheme {
  theme: ITheme;
}
interface ITheme {
  primaryColor: string;
  secondaryColor: string;
}
interface IColor {
  hex: string;
  rgb: any;
}

const Container = styled.div`
  margin-top: 14px;
`;

const Input = styled.input`
  width: 100%;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const BackgroundColorContainer = styled.div`
  height: 39px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.color};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

const ColorPicker = styled.div`
  .chrome-picker {
    width: 100% !important;
  }
`;

export default function FooterProperties(props: IFooterPropertiesProps) {
  const { secondaryColor: outlineColor } = useSelector(
    (state: IState) => state.ui.theme
  );
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: { userInput },
  } = themeProps;
  const [openPicker, setOpenPicker] = React.useState(false);
  const [color, setColor] = React.useState(userInput.background.hex);
  const [text, setText] = React.useState(userInput.footer.text);
  const handleChange = (value: string) => {
    const updatedInputProperties = update(themeProps, {
      widget: {
        userInput: {
          footer: {
            size: { $set: value },
          },
        },
      },
    });
    setThemeProps(updatedInputProperties);
  };
  const handleInputBlur = (value: string) => {
    const updatedInputProperties = update(themeProps, {
      widget: {
        userInput: {
          footer: {
            text: { $set: value },
          },
        },
      },
    });
    setThemeProps(updatedInputProperties);
  };
  const handleBackgroundColorChange = (color: any) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        userInput: {
          background: {
            hex: { $set: color.hex },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };
  return (
    <Container>
      <Collapse defaultActiveKey={["1"]} onChange={callback}>
        <Panel header="Footer" key="1">
          <Input
            theme={{ outlineColor }}
            placeholder="Footer text"
            value={text}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setText(e.target.value)
            }
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputBlur(e.target.value)
            }
          />
          <BackgroundColorContainer>
            <span className="text">Color</span>
            <DisplayColor
              theme={{ color }}
              className="picker"
              onClick={() => setOpenPicker(!openPicker)}
            />
            <GradientPickerWrapper>
              <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
            </GradientPickerWrapper>
          </BackgroundColorContainer>
          <DropdownWrapper>
            <span className="text">Size</span>

            <Select
              defaultValue={userInput.footer.size}
              style={{ width: 161, height: 28 }}
              onChange={handleChange}
              size="small"
            >
              <Option value="9">Small</Option>
              <Option value="13">Medium</Option>
              <Option value="17">Large</Option>
            </Select>
          </DropdownWrapper>
        </Panel>
      </Collapse>
      {openPicker && (
        <ColorPicker className="color-picker">
          <ChromePicker
            color={color}
            onChange={(color: IColor) => setColor(color.hex)}
            onChangeComplete={handleBackgroundColorChange}
          />
        </ColorPicker>
      )}
    </Container>
  );
}
