import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Drawer, Switch, Row, Col, Input, Button } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { updateCategory } from "../../../actions/faqAction";
import { isNotNullOrUndefinedString } from "../../../helpers/util";
import '../../../assets/sass/faq.sass';

const { TextArea } = Input;
class Save extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isSubmited: false,
            error: "",
            selectedCategory: this.props.faqEditDetails && this.props.faqEditDetails.selectedCategory,
            question: this.props.faqEditDetails && this.props.faqEditDetails.question,
            answer: this.props.faqEditDetails && this.props.faqEditDetails.answer,
            isActive: this.props.faqEditDetails && this.props.faqEditDetails.isActive,
            id: this.props.faqEditDetails && this.props.faqEditDetails.id ? this.props.faqEditDetails.id : null
        }
    }

    onSubmit = () => {
        let error = "";
        if (!isNotNullOrUndefinedString(this.state.question) && !isNotNullOrUndefinedString(this.state.answer)) {
            error = "Please enter the all fields";
        } else if (!isNotNullOrUndefinedString(this.state.question)) {
            error = "Please enter the question";
        } else if (!isNotNullOrUndefinedString(this.state.answer)) {
            error = "Please enter the answer";
        }
        this.setState({ error });
        if (!isNotNullOrUndefinedString(error)) {
            if (typeof this.props.onSave == "function" && !this.state.isSubmited) {
                this.setState({
                    isSubmited: true
                });
                let data = {
                    question: this.state.question,
                    answer: this.state.answer,
                    isActive: this.state.isActive
                }
                let categoryId = this.state.selectedCategory._id;
                let questionId = this.state.id ? this.state.id : null;
                this.props.onSave(this.props.faqEditDetails.action.toLowerCase(), categoryId, questionId, data);
            }
        }
    }

    render() {
        let faqEditDetails = this.props.faqEditDetails
            ? this.props.faqEditDetails
            : null;
        return (
            <Drawer
                placement="right"
                closable={false}
                width={600}
                visible={faqEditDetails}
                className="faq"
            >

                <div className="save">
                    <Row>
                        <Col
                            style={{
                                fontSize: "24px",
                                color: "#212332",
                                fontWeight: 600,
                            }}
                            span={24}
                        >
                            <ArrowLeftOutlined
                                onClick={this.props.onClose}
                                style={{
                                    cursor: "pointer",
                                    color: "#a8abbd",
                                    position: "absolute",
                                    marginTop: "6px",
                                }}
                            />
                            <span style={{ marginLeft: "32px" }}>{faqEditDetails && faqEditDetails.action} Q & A</span>
                        </Col>
                    </Row>
                    <Row className="detials">
                        <Col span={1}></Col>
                        <Col span={22}>
                            <div className="form-group">
                                <div className="lab">QUESTION</div>
                                <div className="val">
                                    <TextArea
                                        rows={4}
                                        className="form-control question"
                                        defaultValue={this.state.question}
                                        onChange={(event) =>
                                            this.setState({ question: event.target.value })
                                        }
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="lab">ANSWER</div>
                                <div className="val">
                                    <TextArea
                                        rows={14}
                                        className="form-control answer"
                                        defaultValue={this.state.answer}
                                        onChange={(event) =>
                                            this.setState({ answer: event.target.value })
                                        }
                                    />
                                </div>
                            </div>
                            {faqEditDetails && faqEditDetails.action.toLowerCase() == "update" && <div className="form-group">
                                <div className="val">
                                    <Switch
                                        defaultChecked={this.state && this.state.isActive}
                                        onChange={(checked) => this.setState({ isActive: checked })}
                                    />
                                    <span
                                        style={{
                                            marginLeft: "10px",
                                            fontSize: "12px",
                                            fontWeight: 700,
                                        }}
                                    >
                                        {this.state && this.state.isActive ? "ACTIVE" : "INACTIVE"}
                                    </span>
                                </div>
                            </div>}
                            <div className="form-group">
                                <div style={{ color: "red", fontWeight: 800 }}>
                                    {this.state.error}
                                </div>
                            </div>
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={22}>
                            <Button
                                className="button button-primary submit"
                                onClick={this.onSubmit}
                            >{faqEditDetails && faqEditDetails.action.toLowerCase() == "add" ? "SAVE" : "UPDATE"} INFO</Button>
                        </Col>
                        <Col span={1}></Col>

                    </Row>
                </div>
            </Drawer>
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("Update FAQ Details mapStateToProps: ", state);
    return {
        ui: state.ui,
        task: state.task
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {},
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Save);
