import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

export interface IPopupProps {
  isVisible: boolean;
  ref: React.RefObject<any>;
  children: any;
  width?: string;
  height?: string;
  title: string;
  right?: string;
}

const Container = styled.div`
  position: absolute;
  bottom: 60px;
  right: ${(props) => props.theme.right || "27px"};
  display: ${(props) => (props.theme.isVisible ? "block" : "none")};
`;

const HeaderWrapper = styled.div`
  background: #212332;/* ${(props) => props.theme.background}; */
  width: ${(props) => props.theme.width || "300px"};
  height: 41px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const Header = styled.p`
  padding-left: 20px;
  font-family: inherit;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const ContentWrapper = styled.div`
  position: relative;
  width: ${(props) => props.theme.width || "300px"};
  height: ${(props) => props.theme.height || "95px"};
  background-color: #ffffff;
  box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 20px;
`;

const Arrow = styled.span`
  border-top: 10px solid #ffffff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  position: absolute;
  bottom: -10px;
  right: 60px;
`;

const Popup = React.forwardRef((props: IPopupProps, ref) => {
  const themeProps = useSelector((state: any) => state.ui.theme);
  const { isVisible, children, title, width, height, right } = props;
  return (
    <Container theme={{ isVisible, right }}>
      <HeaderWrapper theme={{ width, background: themeProps.primaryColor }}>
        <Header>{title}</Header>
      </HeaderWrapper>
      <ContentWrapper theme={{ width, height }}>
        {children}
        <Arrow></Arrow>
      </ContentWrapper>
    </Container>
  );
});

export default Popup;
