/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";
import styled from "styled-components";
import { SelectedPropertyContext, ThemeContext } from "../../../themeDesigner";
import { useDrop } from "react-dnd";
import { renderWidgets } from "../../../../../../utils/helpers";
import {
  // TextMessage,
  Document,
  Video,
} from "a8chat-uikit/lib";
import TextMessage from "../../../../../chats/ChatMessages/TextMessage";
import SingleChoice from "../../../../../chats/ChatMessages/SimpleChoice";
import CardChoice from "../../../../../chats/ChatMessages/CardChoice";
import CarouselComponent from "../../../../../chats/ChatMessages/Carousel";
import Chart from "../../../../../chats/ChatMessages/Chart";
import DataTable from "../../../../../chats/ChatMessages/Datatable";
import { PictureWidget } from "../../../../../chats/ChatMessages";
import HTMLReactParser from "html-react-parser";
import SurfaceQuestion from "../../../../../chats/ChatMessages/SurfaceQuestion";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChatContentProps { }

const defaultAcceptableItems = [
  "oAuth",
  "autoComplete",
  "cardChoice",
  "carousel",
  "chart",
  "dataTable",
  "document",
  "feedback",
  "image",
  "simpleChoice",
  "agentTextMessage",
  "userTextMessage",
  "video",
  "loading",
];

const Container = styled.div`
  position: relative;
  flex: 1 1 0%;
  background: ${(props) => props.theme.backgroundColor};
  overflow: hidden auto;
  display: grid;
  grid-template-rows: 1fr 40px;
  padding: ${(props) => props.theme.padding};
  padding-top: ${(props) => props.theme.paddingTop};
  padding-right: ${(props) => props.theme.paddingRight};
  padding-bottom: ${(props) => props.theme.paddingBottom};
  padding-left: ${(props) => props.theme.paddingLeft};
  margin: ${(props) => props.theme.margin};
  margin-top: ${(props) => props.theme.marginTop};
  margin-right: ${(props) => props.theme.marginRight};
  margin-bottom: ${(props) => props.theme.marginBottom};
  margin-left: ${(props) => props.theme.marginLeft};
`;

const getFontSize = (fontStr: string) => {
  switch (fontStr) {
    case "small":
      return "14px";
    case "medium":
      return "18px";
    case "large":
      return "22px";
    case "xl":
      return "26px";
    case "huge":
      return "32px";
  }
};

const renderMessage = (payload, themeProps, chatWidgets) => {
  const {
    widget: {
      dimension,
      messages: { text, globalSettings },
    },
  } = themeProps;
  const { setSelectedProperty } = React.useContext(SelectedPropertyContext);
  switch (payload.msgType) {
    case "text": {
      const isUser = payload.from.userId.includes("u");
      return (
        <TextMessage
          onHandelClick={(e) => {
            e.stopPropagation();
            setSelectedProperty(isUser ? "userTextMsg" : "agentTextMsg");
          }}
          isChatIconVisible={dimension.chatIcon.isVisible}
          icon={dimension.chatIcon.icon}
          isUser={isUser}
          fontSize={getFontSize(themeProps.text.size)}
          color={globalSettings[isUser ? "user" : "agent"].color}
          backgroundColor={globalSettings[isUser ? "user" : "agent"].backgroundColor}
          borderRadius={globalSettings[isUser ? "user" : "agent"].borderRadius}
          borderTopLeft={globalSettings[isUser ? "user" : "agent"].borderTopLeft}
          borderTopRight={globalSettings[isUser ? "user" : "agent"].borderTopRight}
          borderBottomLeft={globalSettings[isUser ? "user" : "agent"].borderBottomLeft}
          borderBottomRight={globalSettings[isUser ? "user" : "agent"].borderBottomRight}
          border={globalSettings[isUser ? "user" : "agent"].border}
          outlineLeft={globalSettings[isUser ? "user" : "agent"].outlineLeft}
          outlineRight={globalSettings[isUser ? "user" : "agent"].outlineRight}
          outlineBottom={globalSettings[isUser ? "user" : "agent"].outlineBottom}
          outlineTop={globalSettings[isUser ? "user" : "agent"].outlineTop}
          outlineColor={globalSettings[isUser ? "user" : "agent"].outlineColor}
          paddingLeft={text[isUser ? "user" : "agent"].paddingLeft}
          paddingRight={text[isUser ? "user" : "agent"].paddingRight}
          paddingBottom={text[isUser ? "user" : "agent"].paddingBottom}
          paddingTop={text[isUser ? "user" : "agent"].paddingTop}
          padding={text[isUser ? "user" : "agent"].padding}
          message={HTMLReactParser(payload.msg.text)}
          fontFamily={dimension.font}
        />
      );
    }

    case "simpleChoice": {
      const isUser = payload.from.userId.includes("u");
      const { text, choices } = payload.msg.msg;
      return (
        <SingleChoice
          onHandelClick={(e: any) => {
            e.stopPropagation();
            setSelectedProperty(isUser ? "userSimpleChocieMsg" : "agentSimpleChocieMsg");
          }}
          isUser={isUser}
          isChatIconVisible={dimension.chatIcon.isVisible}
          icon={dimension.chatIcon.icon}
          isFeedback={payload.msg.isFeedBack}
          primaryColor={"#ffffff00"}
          title={text}
          shouldShowActions={true}
          choices={choices}
          handleOptionSelect={() => { }}
          fontFamily={dimension.font}
          backgroundColor={globalSettings[isUser ? "user" : "agent"].backgroundColor}
          textColor={globalSettings[isUser ? "user" : "agent"].color}
        />
      );
    }

    case "cardChoice": {
      const isUser = payload.from.userId.includes("u");
      const { text, choices } = payload.msg.msg;
      return (
        <CardChoice
          onHandelClick={(e: any) => {
            e.stopPropagation();
            setSelectedProperty(isUser ? "userCardChocieMsg" : "agentCardChocieMsg");
          }}
          isChatIconVisible={dimension.chatIcon.isVisible}
          isUser={false}
          icon={dimension.chatIcon.icon}
          primaryColor={dimension.primaryColor.hex}
          label={text}
          fontFamily={dimension.font}
          shouldShowActions={true}
          choices={choices}
          handleOptionSelect={() => { }}
          backgroundColor={globalSettings[isUser ? "user" : "agent"].backgroundColor}
          color={globalSettings[isUser ? "user" : "agent"].color}
        />
      );
    }

    case "chart": {
      return <Chart align="start" width="100%" data={payload.msg} handleClick={() => { }} />;
    }

    case "datatable": {
      return <DataTable cols={payload.msg.msg.header} rows={payload.msg.msg.rows} limit={2} />;
    }

    case "pdf": {
      return (
        <Document
          isUser={false}
          isChatIconVisible={dimension.chatIcon.isVisible}
          icon={dimension.chatIcon.icon}
          title="Document"
          subtitle="subtitle"
          onClick={() => { }}
        />
      );
    }

    case "image": {
      return (
        <div style={{ minHeight: "300px" }}>
          <PictureWidget theme={{ url: payload.msg.url }} onClick={() => { }} />
        </div>
      );
    }

    case "video": {
      return (
        <Video
          isChatIconVisible={dimension.chatIcon.isVisible}
          icon={dimension.chatIcon.icon}
          title={payload.msg.desc}
          subtitle="Click here to view"
          color={dimension.chatTextColor.hex}
          url={payload.msg.url}
          onClick={() => { }}
        />
      );
    }

    case "loading": {
      return (
        <div
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            background: "#fffffffa",
            zIndex: 5,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedProperty("container");
          }}
        >
          <div style={{
            position: "relative",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <div>
              <img src={dimension.loading.url} style={{ height: dimension.loading.size + "px" }} />
            </div>
          </div>
        </div>
      );
    }
  }
};

export default function ChatContent(props: IChatContentProps) {
  const { setSelectedProperty } = React.useContext(SelectedPropertyContext);
  const [chatWidgets, setChatWidgets] = React.useState([]);
  const { themeProps } = React.useContext(ThemeContext);
  const {
    widget: { content, surfaceQuestion },
  } = themeProps;
  const {
    background,
    margin,
    marginRight,
    marginBottom,
    marginLeft,
    marginTop,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  } = content;

  const onDrop = (e) => {
    let updatedChatWidgets = chatWidgets.concat([renderWidgets(e.type)]);
    if (e.type !== "loading") {
      updatedChatWidgets = updatedChatWidgets.filter((item) => item.msgType !== "loading");
    }
    setChatWidgets(updatedChatWidgets);
  };

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: defaultAcceptableItems,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  let backgroundColor = background.hex;
  if (isActive) {
    backgroundColor = "#e4ffe0";
  } else if (canDrop) {
    backgroundColor = "#e6e7ee";
  }

  return (
    <Container
      className="chat-main-content"
      ref={drop}
      theme={{
        backgroundColor,
        margin,
        marginRight,
        marginBottom,
        marginLeft,
        marginTop,
        padding,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
      }}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedProperty("content");
      }}
    >
      <div>
        {chatWidgets.map((item, index) => (
          <div key={index} style={{ paddingBottom: "8px" }}>
            {renderMessage(item, themeProps, chatWidgets)}
          </div>
        ))}
      </div>
      <SurfaceQuestion questions={["Hello", "World"]} themeProps={themeProps} />
    </Container>
  );
}
