import * as React from "react";
import { useSelector } from "react-redux";
import { sendChatMessage } from "../../../../helpers/mqttService";
import { useTheme } from "../../../../utils/useTheme";
import TextMessage from "../TextMessage";
import HTMLReactParser from "html-react-parser";
import { md, TextWrapper, isClean } from "..";
import _ from "lodash";
import { decodeEntities, htmlToReactEl } from "./utils";

type Props = {
  assignChatId: string
  msg: {
    title: string;
    text?: string
    style: { orientation: "V" | "H" };
    choices: { id: number; label: string }[];
  };
  fromId: string
  fromName: string;
  mId: string;
  isPrivate: boolean
  logs: any[]
};

export function SimpleChoice(props: Props) {
  const activeRootTab = useSelector((state: any) => state.ui.activeRootTab)
  const { assignChatId, msg, isPrivate, logs, fromId } = props
  const liOptions = React.useRef<HTMLLIElement[]>([]);
  const theme = useTheme(props.fromId)
  const messageProps = theme.widget.messages;
  const isUser = fromId.includes("u/");
  const simpleChoiceTitle = msg.text ? msg.text : msg.title
  const lastMsg = logs[logs.length - 1]

  const layoutStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "flex-end",
  };

  const shouldShowActions =
    lastMsg && lastMsg.msgType === "simpleChoice" && (props.mId === lastMsg.mId) && activeRootTab === 'live';

  const optionListContainerStyle: React.CSSProperties = {
    listStyle: "none",
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 0,
    // maxWidth: "250px",
    alignContent: "center",
  };
  const containerStyle: React.CSSProperties = {
    marginBottom: false ? "25px" : "0px",
    border: messageProps.globalSettings.agent.border,
    color: messageProps.globalSettings.agent.color,
    backgroundColor: messageProps.globalSettings.agent.backgroundColor,
    borderRadius: messageProps.globalSettings.agent.borderRadius
      ? messageProps.globalSettings.agent.borderRadius
      : `${messageProps.globalSettings.agent.borderTopLeft} ${messageProps.globalSettings.agent.borderTopRight} ${messageProps.globalSettings.agent.borderBottomRight} ${messageProps.globalSettings.agent.borderBottomLeft}`,
  };
  const outline: React.CSSProperties = {
    padding: "16px",
    borderBottom: `${
      messageProps.globalSettings.agent.outlineBottom ? messageProps.globalSettings.agent.outlineBottom : 0
      } solid ${messageProps.globalSettings.agent.outlineColor}`,
    borderTop: `${
      messageProps.globalSettings.agent.outlineTop ? messageProps.globalSettings.agent.outlineTop : 0
      } solid ${messageProps.globalSettings.agent.outlineColor}`,
    borderLeft: `${
      messageProps.globalSettings.agent.outlineLeft ? messageProps.globalSettings.agent.outlineLeft : 0
      } solid ${messageProps.globalSettings.agent.outlineColor}`,
    borderRight: `${
      messageProps.globalSettings.agent.outlineRight ? messageProps.globalSettings.agent.outlineRight : 0
      } solid ${messageProps.globalSettings.agent.outlineColor}`,
    borderRadius: messageProps.globalSettings.agent.borderRadius
      ? messageProps.globalSettings.agent.borderRadius
      : `${messageProps.globalSettings.agent.borderTopLeft} ${messageProps.globalSettings.agent.borderTopRight} ${messageProps.globalSettings.agent.borderBottomRight} ${messageProps.globalSettings.agent.borderBottomLeft}`,
  };
  const titleStyle: React.CSSProperties = {
    fontSize: messageProps.globalSettings.agent.fontSize ? messageProps.globalSettings.agent.fontSize + "px" : "14px",
    fontWeight: shouldShowActions ? 600 : 600,
    marginBottom: "8px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: messageProps.globalSettings.agent.color,
  };

  const optionListStyle: React.CSSProperties = {
    // minWidth: "45%",
    width: msg.style.orientation === "V" ? "100%" : "auto",
    margin: "5px",
    padding: "8px 20px",
    cursor: "pointer",
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize:
      messageProps.simpleChoice && messageProps.simpleChoice.agent && messageProps.simpleChoice.agent.optionsFontSize
        ? messageProps.simpleChoice.agent.optionsFontSize + "px"
        : "15px",
    fontWeight:
      messageProps.simpleChoice && messageProps.simpleChoice.agent && messageProps.simpleChoice.agent.optionsFontWeight
        ? messageProps.simpleChoice.agent.optionsFontWeight
        : "normal",
    height:
      messageProps.simpleChoice && messageProps.simpleChoice.agent && messageProps.simpleChoice.agent.optionsHeight
        ? messageProps.simpleChoice.agent.optionsHeight
        : "auto",
    textAlign: "center",
    fontFamily: "inherit",
    wordWrap: "break-word",
    color:
      messageProps.simpleChoice && messageProps.simpleChoice.agent && messageProps.simpleChoice.agent.optionsFRColor,
    borderRadius:
      messageProps.simpleChoice &&
      messageProps.simpleChoice.agent &&
      messageProps.simpleChoice.agent.optionsRadius + "px",
    boxShadow:
      messageProps.simpleChoice && messageProps.simpleChoice.agent && messageProps.simpleChoice.agent.optionsShadow,
    border:
      messageProps.simpleChoice && messageProps.simpleChoice.agent && messageProps.simpleChoice.agent.optionsBorder,
    background:
      messageProps.simpleChoice && messageProps.simpleChoice.agent && messageProps.simpleChoice.agent.optionsBGColor,
    // minWidth: 104
  };
  const optionStyle: React.CSSProperties = {
    wordBreak: "break-word",
    fontFamily: "inherit",
  };

  const optionHover = (liOption: HTMLLIElement, isFlag: boolean) => {
    if (shouldShowActions && liOption) {
      liOption.style.background = isFlag
        ? messageProps.simpleChoice.agent.optionsHRColor
        : messageProps.simpleChoice.agent.optionsBGColor;
      liOption.style.color = isFlag
        ? messageProps.simpleChoice.agent.optionsTextHRColor
        : messageProps.simpleChoice.agent.optionsFRColor;
    }
  };

  const handleOptionSelect = (data: any) => {
    if (activeRootTab === "live") {
      sendChatMessage({
        msgType: "simpleChoiceResponse",
        msg: data,
        chatId: assignChatId,
        isPrivate,
      });
    }
  };

  if (!shouldShowActions) {
    return (
      <TextWrapper>
        <TextMessage
          isUser={!isUser}
          fontSize="16px"
          color="#212332"
          backgroundColor={isUser ? "#e8e9f1" : "#FBEBE3"}
          message={HTMLReactParser(
            isClean(md.render(_.unescape(simpleChoiceTitle.trim())))
              .replace(/[\r|\n|\r\n]$/, "")
              .replace(/\n/g, "<br />")
          )}
        />
      </TextWrapper>
    )
  }

  return (
    <div style={layoutStyle}>
      <div style={containerStyle}>
        <div style={outline}>
          <div style={titleStyle}>{htmlToReactEl(msg.title)}</div>
          <ul style={optionListContainerStyle}>
            {msg.choices &&
              msg.choices.map((choice, index) => {
                return (
                  <li
                    ref={(el) => {
                      if (liOptions.current && el) {
                        liOptions.current[index] = el;
                      }
                    }}
                    onMouseEnter={() => optionHover(liOptions.current[index], true)}
                    onMouseLeave={() => optionHover(liOptions.current[index], false)}
                    style={optionListStyle}
                    title={decodeEntities(choice.label)}
                    key={choice.id || index}
                    onClick={() => handleOptionSelect(choice)}
                  >
                    <span style={optionStyle}>{htmlToReactEl(choice.label)}</span>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}
