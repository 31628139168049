import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Input, Divider, Button } from "antd";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { getQuickResp } from "../../actions/quickRespAction";
import { compareValues } from "../../helpers/util";

import "../../assets/sass/quickResp.sass";
import agentIcon from "../../assets/images/agent-icon.svg";

interface IReduxState {
  auth: any;
  ui: any;
  task: any;
  quickResp: any;
}

class QuickRespPopup extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      quickResp: [],
      search: "",
    };
    this.props.getQuickResp();
  }
  componentWillReceiveProps(props) {
    if (props.quickResp && props.quickResp.quickResp) {
      props.quickResp.quickResp.sort(compareValues("shortCode", null, "string", "asc"));
      this.setState({
        quickResp: props.quickResp.quickResp,
      });
    }
    if (props.getQuickRespByCode) {
      this.getQuickRespByCode(props.getQuickRespByCode);
    }
  }
  onSelect = (item) => {
    if (item && item.isActive && typeof this.props.onSelect == "function") {
      this.props.onSelect(item);
    }
  };
  getQuickRespByCode(shortCode) {
    const quickResp = this.props.quickResp && this.props.quickResp.quickResp ? this.props.quickResp.quickResp : [];
    const index = quickResp.findIndex((item: any) => `/${item.shortCode.toLowerCase()}` === shortCode.toLowerCase());
    this.onSelect(quickResp[index]);
  }
  onSearch = (e) => {
    const quickResp = this.props.quickResp && this.props.quickResp.quickResp ? this.props.quickResp.quickResp : [];
    const filterQuickResp = quickResp.filter(function (item) {
      return item.response.toLowerCase().includes(e.target.value.toLowerCase());
    });
    filterQuickResp.sort(compareValues("shortCode", null, "string", "asc"));
    this.setState({ quickResp: filterQuickResp });
  };
  render() {
    const { visible, typingText } = this.props;
    return (
      <Row className="quick-resp">
        <Col span={24} className="popup" style={{ height: visible ? "360px" : "0px" }}>
          <Row className="header">
            <Col span={24}>
              <div className="title">QUICK RESPONSES</div>
              <Input
                onChange={(e) => this.onSearch(e)}
                className="search"
                placeholder={"Search..."}
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
          <Row className="content">
            <Col span={24}>
              <div className="warp">
                {this.state.quickResp.map((item, index) => {
                  return (
                    item.isActive && (
                      <Row
                        className="item"
                        key={item._id}
                        onClick={() => {
                          this.onSelect(item);
                        }}
                      >
                        <Col span={window.innerWidth > 1700 ? 1 : 2} className="shortcut">
                          /{item.shortCode}
                        </Col>
                        <Col span={window.innerWidth > 1700 ? 23 : 22} className="resp">
                          {item.response}
                        </Col>
                        {index !== this.state.quickResp.length - 1 && (
                          <Divider
                            className={
                              typingText.toLowerCase() === `/${item.shortCode.toLowerCase()}` ? "select" : "unselect"
                            }
                          />
                        )}
                      </Row>
                    )
                  );
                })}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state: IReduxState) => {
  return {
    auth: state.auth,
    ui: state.ui,
    task: state.task,
    quickResp: state.quickResp,
  };
};
export default connect(mapStateToProps, {
  getQuickResp,
})(QuickRespPopup);
