import type from "../types/actionTypes";
import axios from "axios";
import config from "../apiConfig";
import { store } from "../store";
import { notification } from "antd";

const actionNotification = (notificationType, title, message) => {
  notification[notificationType]({
    message: title,
    description: message,
  });
  setTimeout(function () {
    notification.close();
  }, 1000);
};

export function getAgents() {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    try {
      let response = await axios(`${config.baseUrl}${config.org}${config.v1}${config.agentsUrl}`, options);
      dispatch({
        type: type.AGENTS_GET_SUCCESS,
        data: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: type.ERROR,
        data: error.response,
      });
    }
  };
}

export function createAgent(data, originalData) {
  const options = {
    method: "POST",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    axios(`${config.baseUrl}${config.org}${config.v1}${config.agentsUrl}`, options)
      .then(function (resp) {
        let response = resp;
        dispatch({
          type: type.AGENT_SAVE_SUCCESS,
          data: response.data,
        });
        actionNotification("info", "NEW AGENT CREATED", "Agent successfully created");
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          Object.assign(originalData, { error: error.response.data.message });
          dispatch({
            type: type.AGENT_ERROR,
            data: { settingSaveDetails: originalData },
          });
        }
      });
  };
}

export function updateAgent(data, agentId, originalData) {
  const options = {
    method: "PUT",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    await axios(
      `${config.baseUrl}${config.org}${config.v1}${config.updateAgentUrl.replace("{agentId}", agentId)}`,
      options
    )
      .then(function (resp) {
        let response = resp;
        dispatch({
          type: type.AGENT_SAVE_SUCCESS,
          data: response.data,
        });
        actionNotification("info", "AGENT UPDATED", `Agent ${data.name} updated successfully`);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          Object.assign(originalData, { error: error.response.data.message });
          dispatch({
            type: type.AGENT_ERROR,
            data: { settingSaveDetails: originalData },
          });
        }
      });
  };
}

export const getLiveAgentSettings = () => async (dispatch) => {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  const url = `${config.baseUrl}${config.org}${config.v1}${config.settings}${config.liveAgent}`;

  try {
    let response = await axios(url, options);
    dispatch({
      type: type.SET_AGENT_REASONS_LIST,
      data: response.data.unavailableReasons,
    });
    dispatch({
      type: type.SET_CHAT_ROUTING_ALGORITHM,
      data: response.data,
    });
  } catch (error) {
    dispatch({
      type: type.ERROR,
      data: error.response,
    });
  }
};

export const changeAgentState = (currentState, preState) => async (dispatch) => {
  const options = {
    method: "PUT",
    data: { ...currentState, ...{ sessionId: store.getState().auth.sessionToken.sessionId } },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  const url = `${config.baseUrl}${config.org}${config.v1}${config.changeAgentState}`;

  try {
    await axios(url, options);
    dispatch({
      type: type.SET_AGENT_STATE,
      data: { ...currentState, ...{ isAPI: true } },
    });
  } catch (error) {
    actionNotification("error", "Status Changes", "Please try again later");
    dispatch({
      type: type.SET_AGENT_STATE,
      data: { ...preState, ...{ isAPI: true } },
    });
    dispatch({
      type: type.ERROR,
      data: error.response,
    });
  }
};

export const updateAgentSettings = (data) => async (dispatch) => {
  dispatch({
    type: type.SETTINGS_ERROR,
    data: "",
  });
  const options = {
    method: "PUT",
    data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  const url = `${config.baseUrl}${config.org}${config.v1}${config.settings}${config.liveAgent}`;
  try {
    const resp = await axios(url, options);
    if (data.unavailableReasons) {
      dispatch({
        type: type.SET_AGENT_REASONS_LIST,
        data: resp.data.updated.unavailableReasons,
      });
    } else if (data.routingAlgorithm) {
      dispatch({
        type: type.SET_CHAT_ROUTING_ALGORITHM,
        data: resp.data.updated,
      });
    }
  } catch (error) {
    dispatch({
      type: type.SETTINGS_ERROR,
      data: error.response || "Error occurred while saving changes",
    });
  }
};

export const updateAgentInformation = (agentInfo) => {
  return ({
    type: type.UPDATE_AGENT_INFO,
    data: agentInfo
  })
}


export const forceLogout = (agentId, agentName) => async (dispatch) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  const url = `${config.baseUrl}${config.org}${config.v1}${config.forceLogoutUrl.replace("{agent_id}", agentId)}`;
  debugger
  try {
    const resp = await axios(url, options);
    if (resp && resp.data && resp.data.code === 'success') {
      actionNotification("info", "AGENT FORCE LOGOUT", `Agent ${agentName} logout successfully`);
      dispatch({
        type: type.FORCE_LOGOUT_SUCCESS,
        data: true,
      });
    } else {
      dispatch({
        type: type.FORCE_LOGOUT_ERROR,
        data: "Error occurred while force logout",
      });
    }
  } catch (error) {
    dispatch({
      type: type.FORCE_LOGOUT_ERROR,
      data: error.response || "Error occurred while force logout",
    });
  }
};
