import * as React from "react";
import styled from "styled-components";
import { decodeEntities, LightenDarkenColor } from "../../../../utils/helpers";
import { useSelector } from "react-redux";

export interface ICardProps {
  handleCardButtonClick: (payload: any, index: number) => any;
  shouldShowActions: boolean;
  card: any;
  index: number;
}

const Card = styled.div`
  width: 80%;
  box-shadow: 0 2px 4px 0 #e8e9f1;
  border-radius: 0 0 15px 8px;
  display: flex;
  flex: 0 0 auto;
  float: right;
  margin-right: 50%;
  transform: translateX(50%);
  flex-direction: column;
  transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
  margin-bottom: 60px;
  flex-direction: column;
`;
const CardImageContainer = styled.div`
  width: 100%;
  height: 160px;
`;

const CardContent = styled.div`
  padding: 5px 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: white;
  min-height: 130px;
`;

const InfoContainer = styled.div`
  flex-grow: 1;
`;

const CardTitle = styled.div`
  padding: 8px 0;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #212332;
`;

const CardSubtitle = styled.div`
  font-size: 0.9em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #2f3245;

  & .primary {
    margin: 5px 0;
  }
`;

const ButtonGroup = styled.div`
  margin-bottom: 10px;
`;

const ButtonPrimary = styled.button`
  height: 30px;
  width: 100%;
  border-radius: 15px;
  background-color: ${(props) => props.theme.background};
  border: none;
  color: white;
  margin-bottom: 5px;
  outline: none;
  text-transform: uppercase;
  cursor: pointer;
`;

const ButtonSecondary = styled.button`
  cursor: pointer;
  height: 30px;
  width: 100%;
  border: none;
  border-radius: 15px;
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.color};
  outline: none;
  text-transform: uppercase;
`;

const ImageContainer = styled.div`
  background: url("${(props) => props.theme.imageUrl}");
  width: 100%;
  height: 100%;
  border-radius: 15px 8px 0 0;
  background-size: cover;
`;

const truncateLabel = (label: string) => {
  return `${label.substr(0, 15)}${label.length > 15 ? "..." : ""}`;
};

const CardComponent = ({ shouldShowActions, handleCardButtonClick, card, index }: ICardProps) => {
  const imageUrl = card.url;
  // const themeProps = useSelector((state: any) => state.theme.widget.dimension);
  const theme = useSelector((state: any) => state.ui.theme);
  const handlePictureClick = (picture: string) => {
    const event = {
      eventName: "open-modal",
      picture,
      description: "Random description",
      type: "image",
    };
    window.parent.postMessage(event, "*");
  };

  return (
    <Card>
      {imageUrl ? (
        <CardImageContainer>
          <ImageContainer theme={{ imageUrl }} onClick={() => handlePictureClick(imageUrl)} />
        </CardImageContainer>
      ) : null}
      <CardContent>
        <InfoContainer>
          <CardTitle
            dangerouslySetInnerHTML={{
              __html: decodeEntities(card.title, 30, false),
            }}
            title={card.title}
          />
          <CardSubtitle>
            <div
              className="primary"
              dangerouslySetInnerHTML={{
                __html: decodeEntities(card.subtitle, 140, true),
              }}
            ></div>
            <div
              className="primary"
              dangerouslySetInnerHTML={{
                __html: decodeEntities(card.description, 140, true),
              }}
            ></div>
          </CardSubtitle>
        </InfoContainer>

        {shouldShowActions && card.actions ? (
          <ButtonGroup>
            {card.actions.primary ? (
              <ButtonPrimary
                theme={{ background: theme.primaryColor }}
                title={card.actions.primary.label.length > 15 ? card.actions.primary.label : null}
                onClick={() => handleCardButtonClick(card.actions.primary, index)}
              >
                {truncateLabel(card.actions.primary.label)}
              </ButtonPrimary>
            ) : null}
            {card.actions.secondary ? (
              <ButtonSecondary
                theme={{
                  background: LightenDarkenColor(theme.primaryColor, 120),
                  color: theme.primaryColor,
                }}
                title={card.actions.secondary.label.length > 15 ? card.actions.secondary.label : null}
                onClick={() => handleCardButtonClick(card.actions.secondary, index)}
              >
                {truncateLabel(card.actions.secondary.label)}
              </ButtonSecondary>
            ) : null}
          </ButtonGroup>
        ) : null}
      </CardContent>
    </Card>

  );
};

export default CardComponent;
