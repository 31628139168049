import React, { Component } from 'react';

class NotFound extends Component {
    state = {}
    render() {
        return (
            <h1>Resource Not Found</h1>
        );
    }
}

export default NotFound;