import axios from "axios";
import config from "../apiConfig";
import type from "../types/actionTypes";

export const settingSaveDetails = (data) => {
  return {
    type: type.SETTING_UPDATE_DETAILS,
    data,
  };
};

export const resourceSaveDetails = (data) => {
  return {
    type: type.RESOURCE_UPDATE_DETAILS,
    data,
  };
}

export const signoutModal = (data) => {
  return {
    type: type.HANDLE_SIGNOUT_MODAL,
    data,
  };
};

export const sessionTokenSuccess = (data) => {
  return {
    type: type.SESSION_TOKEN_SUCCESS,
    data: data,
  };
};

export const signSuccess = (data) => {
  return {
    type: type.SIGNIN_SUCCESS,
    data: data,
  };
};

export const clearQuickRespSaveSuccess = () => {
  return {
    type: type.QUICK_RESP_SAVE_SUCCESS,
    data: null,
  };
}

export const clearQuickRespDeleteSuccess = () => {
  return {
    type: type.QUICK_RESP_DELETE_SUCCESS,
    data: null,
  };
}

export const clearAgentSaveSuccess = () => {
  return {
    type: type.AGENT_SAVE_SUCCESS,
    data: null,
  };
};

export const clearAgentForceLogout = () => {
  return {
    type: type.FORCE_LOGOUT_SUCCESS,
    data: null,
  };
};

export const clearGroupSaveSuccess = () => {
  return {
    type: type.GROUP_SAVE_SUCCESS,
    data: null,
  };
};

export const clearBulkuploadSuccess = () => {
  return {
    type: type.BULKUPLOAD_SUCCESS,
    data: null,
  };
};

export const clearAgentError = () => {
  return {
    type: type.AGENT_ERROR,
    data: null,
  };
};


export const clearQuickRespError = (data) => {
  return {
    type: type.QUICK_RESP_ERROR,
    data: null,
  };
};

export const clearFaqNewCategory = (data) => {
  return {
    type: type.FAQ_CATEGORY_SAVE_SUCCESS,
    data: null,
  };
};

export const clearFaqDeleteCategory = (data) => {
  return {
    type: type.FAQ_CATEGORY_DELETE_SUCCESS,
    data: null,
  };
};

export const clearGroupError = (data) => {
  return {
    type: type.GROUP_ERROR,
    data: null,
  };
};

export const clearError = () => {
  return {
    type: type.ERROR,
  };
};

export const signOut = () => {
  return {
    type: type.SIGNOUT_SUCCESS,
  };
};

export const clearRemoveTokenSuccess = () => {
  return {
    type: type.REMOVE_TOKEN_SUCCESS,
    data: false
  };
};

export const clearGenerateTokenSuccess = () => {
  return {
    type: type.GENERATE_TOKEN_SUCCESS,
    data: null
  };
};

export const openMonitoredChatsTab = (panelNumber) => {
  return {
    type: type.OPEN_MONITORED_CHATS_TAB,
    data: panelNumber,
  };
};

export const setSelectedChatMonitorGroup = (monitorGroup) => {

  return {
    type: type.SELECTED_CHAT_MONITOR_GROUP,
    data: monitorGroup,

  }
}

export function getMyInfo(accessToken) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return async (dispatch) => {
    try {
      let response = await axios(`${config.baseUrl}${config.org}${config.v1}${config.meUrl}`, options);
      dispatch({
        type: type.ME_GET_SUCCESS,
        data: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: type.ERROR,
        data: error.response,
      });
    }
  };
}