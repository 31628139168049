import * as React from "react";
import styled from "styled-components";
import UserCard from "./UserCard";
import { useSelector, useDispatch } from "react-redux";
import searchIcon from "../../../../assets/images/ico-search.svg";
import { filterObject } from "../../../../utils/filterObject";
import { getAssignedChats } from "../../../../actions/chatsAction";

export interface ISidebarProps { }

const Container = styled.div`
  width: 300px;
  border-right: 1px solid rgba(168, 171, 189, 0.4);
  padding: 20px 20px 20px 15px;
  max-height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  &:nth-child(2) {
    margin-top: 20px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 66px;
  position: fixed;
  background: white;
  display: flex;
  align-items: center;
  top: 59px;
  z-index: 100;
`;

const Search = styled.input`
  width: 269px;
  padding: 0 12px 0 40px;
  height: 30px;
  border-radius: 20px;
  border: solid 2px #e8e9f1;
  background-color: #ffffff;
  outline: none;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const SeachIconContainer = styled.div`
  position: absolute;
  left: 15px;
`;

const CardContainer = styled.div`
  margin-top: 50px;
`;

const renderUserCard = (message: any, lastChatElement) => {
  const {
    messages,
    clientData: { fromName, userInfo },
    _id,
  } = message;

  const msg = messages[messages.length - 1];
  return <UserCard ref={lastChatElement} key={_id} id={_id} message={msg} name={fromName} clientId={userInfo.clientId} />;
};

export default function Sidebar(props: ISidebarProps) {
  const dispatch = useDispatch();
  const { assignedChats, isLoading, selectedLiveChatId } = useSelector((state: any) => state.chat);
  const [lastTouched, setLastTouched] = React.useState("");
  const [limit, setLimit] = React.useState(10);
  const [chats, setChats] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const observer: any = React.useRef();
  const lastChatElement = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isLoading && limit <= Object.keys(assignedChats).length) {
          setLimit((prevLimit) => prevLimit + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, assignedChats]
  );

  React.useEffect(() => {
    setChats(assignedChats);
    if (assignedChats && Object.keys(assignedChats).length > 0) {
      setLastTouched(
        new Date(
          assignedChats[Object.keys(assignedChats)[Object.keys(assignedChats).length - 1]].lastTouched
        ).toISOString()
      );
    }
  }, [assignedChats]);

  const fetchAssignedChats = React.useCallback(
    (limit: number) => dispatch(getAssignedChats(limit, "me", lastTouched, selectedLiveChatId)),
    [dispatch]
  );

  React.useEffect(() => {
    if (Object.keys(assignedChats).length === 0) {
      fetchAssignedChats(limit);
    }
  }, [limit]);

  const handleSearch = (e) => {
    const { value } = e.target;
    // Todo: Fix this for better performance
    // const updatedChats = filterObject(assignedChats, (chat) =>
    //   chat.clientData.fromName.toLowerCase().includes(value.toLowerCase())
    // );
    // setChats(updatedChats);
    setSearchText(value);
  };
  const filteredChats =
    (chats &&
      filterObject(chats, (chat) => chat && chat.clientData && chat.clientData.fromName.toLowerCase().includes(searchText.toLowerCase()))) ||
    {};
  return (
    <Container>
      <SearchContainer>
        <SeachIconContainer>
          <img src={searchIcon} alt="search icon" />
        </SeachIconContainer>
        <Search value={searchText} onChange={handleSearch} placeholder="Search" />
      </SearchContainer>
      <CardContainer>
        {chats &&
          Object.keys(chats) &&
          Object.keys(chats).length > 0 &&
          Object.keys(filteredChats) &&
          Object.keys(filteredChats).map((id: any, index: number) => {
            const {
              messages = [],
              clientData: { fromName, userInfo },
              _id,
              isUnread,
            } = chats[id];
            const msg = messages.length ? messages[messages.length - 1] : {}
            if (Object.keys(chats).length === index + 1) {
              return (
                <UserCard ref={lastChatElement} key={_id} id={_id} message={msg} name={fromName} isUnread={isUnread} clientId={userInfo.clientId} />
              );
            }
            return <UserCard key={_id} id={_id} message={msg} name={fromName} isUnread={isUnread} clientId={userInfo.clientId} />;
          })}
      </CardContainer>
    </Container>
  );
}
