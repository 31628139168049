import * as React from "react";
import styled from "styled-components";

interface IAdaptiveCardResponseProps {
  msg: any;
  isUser: boolean
}

const Container = styled.div`
  min-width: 33%;
  max-width: 500px;
  box-shadow: 0 2px 4px 0 #E8E9F1;
  background: #E8E9F1;
  color: #212332;
  border-radius: 10px 10px 35px 10px;
  padding: 8px 12px;
  margin-bottom: 16px;
  white-space: pre-line;
`;

const renderTable = (data: any) => {
  delete data["argsOrder"];
  return (
    <table>
      <tbody>
        {Object.keys(data).map((item: any, index: number) => {
          return (
            <tr key={index}>
              {item && <td style={{ fontWeight: "bold" }}>{item}</td>}
              <td style={{ paddingLeft: "8px" }}>{data[item]}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const renderTitle = (title: string) => {
  return <p>{title}</p>;
};

const AdaptiveCardResponse: React.FunctionComponent<IAdaptiveCardResponseProps> = ({ msg, isUser }) => {
  const { data, title } = msg;
  return (
    <div style={{
      flexDirection: isUser ? "row" : "row-reverse",
      display: "flex",
    }}>
      <Container className="message user">
        {Object.keys(data).length > 0 ? renderTable(data) : renderTitle(title)}
      </Container>
    </div>
  );
};

export default AdaptiveCardResponse;
