import * as React from "react";
import styled from "styled-components";
import ChatHeader from "./Components/Header";
import ChatContent from "./Components/Content";
import ChatUserInput from "./Components/UserInput";
import { ThemeContext } from "../../themeDesigner";

export interface IChatWidgetProps { }


const WidgetContainer = styled.div`
  width: ${(props) => props.theme.maxWidth};
  height: calc(${(props) => props.theme.minHeight} - 58px - 25px);
  /* margin: 0 auto; */
  max-height: 100%;
  display: flex;
  flex-direction: column;
  font-family: OpenSans;
  /* margin: auto; */
  background: transparent;
`;

const Container = styled.div`
    display: flex;
    align-items: flex-end;
    margin: auto;
    justify-content: space-evenly;
`;

export default function ChatWidget(props: IChatWidgetProps) {
  const {
    themeProps: {
      widgetIcon,
      widget: {
        dimension: { maxWidth, minHeight },
      },
    },
  } = React.useContext(ThemeContext);
  let { size, icon, color: widgetIconColor } = widgetIcon;
  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <img src={icon} style={{ height: `${size}px`, width: "auto", marginBottom: "12px" }} />
          {/* <span style={{ fontWeight: 800 }}>Chat Icon</span> */}
        </div>
      </div>
      <WidgetContainer theme={{ maxWidth, minHeight }}>
        <ChatHeader />
        <ChatContent />
        <ChatUserInput />
      </WidgetContainer>
    </Container >
  );
}
