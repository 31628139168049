import React from "react";
import { connect } from "react-redux";
import { Select as A8_SELECT } from "a8-uicomponents";
import { AppstoreOutlined, MenuOutlined } from "@ant-design/icons";
import { Input, Row, Col } from "antd";
import {
  clearAgentForceLogout,
  clearAgentSaveSuccess,
  clearBulkuploadSuccess,
} from "../../../actions/taskAction";
import {
  agentViewMode,
  searchAgent,
  filterAgent,
  filterAgentGroupList,
  filterAgentSort,
  filterAgentType,
} from "../../../actions/uiAction";
import { getAgents } from "../../../actions/agentsAction";
import { getGroups } from "../../../actions/groupsAction";
import "../../../assets/sass/agent.sass";
import AgentCardList from "./AgentCardList";
import AgentTableList from "./AgentTableList";
import { compareValues } from "../../../helpers/util";

const { Search } = Input;
let agentResp = [];
let groupResp = [];
let agentAction = {
  _id: null,
  action: true,
  isActive: true,
};
let types = [
  {
    _id: "all",
    name: "All",
  },
  {
    _id: "agent",
    name: "Agent",
  },
  {
    _id: "bot",
    name: "Bot",
  },
];
let sorts = [
  {
    _id: "asc",
    name: "ASC",
  },
  {
    _id: "desc",
    name: "DESC",
  },
];
let selectAgentOptions = {
  defaultValue: [],
  optionValue: "_id",
  isSearch: true,
  title: "name",
  multiSelect: false,
  placeholder: "Filter By Agent",
  primaryColor: null,
};

let selectGroupOptions = {
  defaultValue: [],
  optionValue: "_id",
  isSearch: false,
  displayLimit: 3,
  title: "name",
  multiSelect: true,
  placeholder: "Filter By Groups",
  primaryColor: null,
};
let selectSortOptions = {
  defaultValue: [],
  optionValue: "_id",
  title: "name",
  multiSelect: false,
  primaryColor: null,
};
let selectTypeOptions = {
  defaultValue: [],
  optionValue: "_id",
  title: "name",
  multiSelect: false,
  primaryColor: null,
};
let theme = null;
class Agent extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      roles: this.props.roles,
      agentList: [],
      groupList: [],
      viewMode: "card",
      name: "",
      agentId: [],
      groupIds: [],
      sort: [sorts[0]._id],
      type: [types[0]._id],
      windowHeight: window.innerHeight,
    };
  }

  displayWindowSize() {
    this.setState({
      windowHeight: window.innerHeight,
    });
  }

  async componentDidMount() {
    await this.initialLoad();

    window.addEventListener("resize", () => {
      this.displayWindowSize();
    });
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.displayWindowSize();
    });
  }

  async initialLoad() {
    types = [{
      _id: "all",
      name: "All",
    },
    {
      _id: "agent",
      name: "Agent",
    },
    {
      _id: "bot",
      name: "Bot",
    }];
    groupResp = [];
    agentResp = [];
    let groupList = await this.props.getGroups();
    if (groupList) {
      if (this.props.roles.includes("supervisor") && !this.props.roles.includes("admin")) {
        for (let j in groupList.groups) {
          for (let i = 0; i < this.props.auth.info.groups.length; i++) {
            if (this.props.auth.info.groups[i]._id == groupList.groups[j]._id) {
              groupResp.push(groupList.groups[j]);
              break;
            }
          }
        }
        this.setState({ groupList: groupResp });
      } else {
        groupResp = groupList.groups;
      }
    }
    let agentList = await this.props.getAgents();
    if (agentList && agentList.agents) {
      if (this.props.roles.includes("supervisor") && !this.props.roles.includes("admin")) {
        for (let j in agentList.agents) {
          for (let i = 0; i < this.props.auth.info.groups.length; i++) {
            if (!agentList.agents[j].isVirtualAgent && agentList.agents[j].groupIds.includes(this.props.auth.info.groups[i]._id)) {
              // if (!agentList.agents[j].isVirtualAgent && agentList.agents[j].roles.length == 1 && agentList.agents[j].roles.includes("agent") && agentList.agents[j].groupIds.includes(this.props.auth.info.groups[i]._id)) {
              agentResp.push(agentList.agents[j]);
              break;
            }
          }
        }
      } else {
        agentResp = agentList.agents;
      }
    }
    let agents = JSON.parse(JSON.stringify(agentResp));
    agents.sort(compareValues("name", null, "string", "asc"));
    if (agentResp) {
      agents.splice(0, 0, agentAction);
      this.setState({ agentList: agents });
    }

    if (this.props.ui.agentViewMode) {
      this.setState({
        viewMode: this.props.ui.agentViewMode,
      });
    }
    if (this.props.ui.searchAgent) {
      this.setState({
        name: this.props.ui.searchAgent,
      });
    }
    if (this.props.ui.filterAgent) {
      this.setState({
        agentId: this.props.ui.filterAgent || [],
      });
    }
    if (this.props.ui.filterAgentGroupList) {
      this.setState({
        groupIds: this.props.ui.filterAgentGroupList || [],
      });
    }
    if (this.props.ui.filterAgentSort) {
      this.setState({
        sort: this.props.ui.filterAgentSort,
      });
    }
    if (this.props.ui.filterAgentType) {
      this.setState({
        type: this.props.ui.filterAgentType,
      });
    }
    if (this.props.ui.theme) {
      theme = this.props.ui.theme;
    }
    selectTypeOptions.primaryColor = theme.primaryColor;
    selectTypeOptions.defaultValue = this.state.type;
    selectSortOptions.primaryColor = theme.primaryColor;
    selectSortOptions.defaultValue = this.state.sort;
    selectGroupOptions.defaultValue = this.state.groupIds;
    selectGroupOptions.primaryColor = theme.primaryColor;
    selectAgentOptions.defaultValue = this.state.agentId;
    selectAgentOptions.primaryColor = theme.primaryColor;
    if (agentList && agentList.agents && this.props.roles.includes("supervisor") && !this.props.roles.includes("admin")) {
      types = [{
        _id: "agent",
        name: "Agent",
      }];
      selectTypeOptions = {
        defaultValue: ["agent"],
        optionValue: "_id",
        title: "name",
        multiSelect: false,
        primaryColor: null,
      };
    }
    this.applyFilter();
  }

  onFilterChange = (key, value) => {
    this.onPersist(key, value);
    this.setState({ [key]: value }, () => this.applyFilter());
  };

  applyFilter = () => {
    if (agentResp) {
      let filterAgents = [];
      let agentName = this.state.name
        ? this.state.name.toLocaleLowerCase()
        : null;
      let filterNameAndAgent = false;
      let filterType = false;
      let filterGroups = false;

      for (let a in agentResp) {
        filterNameAndAgent = false;
        filterType = false;
        filterGroups = false;
        if (
          (agentName == null ||
            agentResp[a].name.toLocaleLowerCase().includes(agentName)) &&
          (this.state.agentId.length == 0 ||
            (this.state.agentId.length > 0 &&
              agentResp[a]._id == this.state.agentId[0]))
        ) {
          filterNameAndAgent = true;
        }
        if (this.state.type.length > 0 && this.state.type[0] != "all") {
          if (
            (this.state.type[0] == "bot" && agentResp[a].isVirtualAgent == true) ||
            (this.state.type[0] == "agent" && !agentResp[a].isVirtualAgent)
          ) {
            filterType = true;
          }
        } else {
          filterType = true;
        }
        if (this.state.groupIds && this.state.groupIds.length > 0) {
          for (let g in agentResp[a].groupIds) {
            if (this.state.groupIds.indexOf(agentResp[a].groupIds[g]) > -1) {
              filterGroups = true;
            }
          }
        } else {
          filterGroups = true;
        }
        if (filterNameAndAgent && filterType && filterGroups) {
          filterAgents.push(agentResp[a]);
        }
      }
      filterAgents.sort(
        compareValues("name", null, "string", this.state.sort[0])
      );
      filterAgents.splice(0, 0, agentAction);
      this.setState({
        agentList: filterAgents,
      });
    }
  };

  onViewMode = (mode) => {
    this.setState({
      viewMode: mode,
    });
    this.props.agentViewMode(mode);
  };

  async componentWillReceiveProps(props) {
    if (props.agent.agentSaveSuccess || props.agent.agentForceLogout) {
      this.props.clearAgentSaveSuccess();
      this.props.clearAgentForceLogout();
      await this.initialLoad();
    }
    if (
      props.agent.bulkuploadSuccess &&
      props.agent.bulkuploadSuccess.docType == "agents"
    ) {
      this.props.clearBulkuploadSuccess();
      await this.initialLoad();
    }
    if (props.ui.theme) {
      theme = props.ui.theme;
      selectTypeOptions.primaryColor = theme.secondaryColor;
      selectSortOptions.primaryColor = theme.secondaryColor;
      selectGroupOptions.primaryColor = theme.secondaryColor;
      selectAgentOptions.primaryColor = theme.secondaryColor;
    }
  }

  onPersist = (key, value) => {
    if (key == "name") {
      this.props.searchAgent(value);
    } else if (key == "agentId") {
      this.props.filterAgent(value);
    } else if (key == "groupIds") {
      this.props.filterAgentGroupList(value);
    } else if (key == "sort") {
      this.props.filterAgentSort(value);
    } else if (key == "type") {
      this.props.filterAgentType(value);
    }
  };

  isLoadAction = (item) => {
    let isLoaded = true;
    let { roles } = this.state;
    if (roles && roles.includes("supervisor") && item.action) {
      isLoaded = false;
      if (roles.includes("admin")) {
        isLoaded = true;
      }
    }
    return isLoaded;
  }

  render() {
    return (
      <div className="page-wrap page-fixed-nav agents">
        <div className="page-head filter">
          <Row>
            <Col span={6} className="view-mode">
              <span
                onClick={() => this.onViewMode("card")}
                className="card-icon"
                style={{
                  color:
                    this.state.viewMode == "card"
                      ? theme && theme.secondaryColor
                        ? theme.secondaryColor
                        : "#e97a43"
                      : "#d7d7d7",
                }}
              >
                <AppstoreOutlined />
              </span>
              <span
                onClick={() => this.onViewMode("table")}
                className="table-icon"
                style={{
                  color:
                    this.state.viewMode == "table"
                      ? theme && theme.secondaryColor
                        ? theme.secondaryColor
                        : "#e97a43"
                      : "#d7d7d7",
                }}
              >
                <MenuOutlined />
              </span>
              <Search
                defaultValue={this.props.ui.searchAgent}
                style={{ backgroundColor: "#e8e9f1" }}
                className="option search"
                placeholder="search text"
                onChange={(e) => this.onFilterChange("name", e.target.value)}
                onSearch={(value) => this.onFilterChange("name", value)}
              />
            </Col>
            <Col span={15} style={{ marginTop: "3px" }}>
              <Row
                style={{
                  marginLeft: "8px",
                  fontSize: "12px !important",
                  width: "98%",
                }}
                className="option"
              >
                <Col span={1}></Col>
                <Col span={7} className="agent">
                  <span className="lab">Agent</span>
                  <A8_SELECT
                    data={agentResp}
                    options={selectAgentOptions}
                    onChange={(selectIds) =>
                      this.onFilterChange("agentId", selectIds)
                    }
                  ></A8_SELECT>
                </Col>
                <Col span={11} className="groups">
                  <span className="lab">Groups</span>
                  <A8_SELECT
                    data={groupResp}
                    options={selectGroupOptions}
                    onChange={(selectIds) =>
                      this.onFilterChange("groupIds", selectIds)
                    }
                  ></A8_SELECT>
                </Col>
                <Col span={4}>
                  <span className="lab">Type</span>
                  <A8_SELECT
                    data={types}
                    options={selectTypeOptions}
                    onChange={(selectIds) =>
                      this.onFilterChange("type", selectIds)
                    }
                  ></A8_SELECT>
                </Col>
                <Col span={1}></Col>
              </Row>
            </Col>
            <Col span={3} className="sort" style={{ marginTop: "3px" }}>
              <div className="option">
                <span style={{ paddingLeft: "18px" }} className="lab">
                  Sort by
                </span>
                <A8_SELECT
                  data={sorts}
                  options={selectSortOptions}
                  onChange={(selectIds) =>
                    this.onFilterChange("sort", selectIds)
                  }
                ></A8_SELECT>
              </div>
            </Col>
          </Row>
        </div>
        {this.state.viewMode == "card" && (
          <div
            className="page-content-main"
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              height: this.state.windowHeight - 200 + "px",
              marginTop: "64px",
            }}
          >
            <div className="container-fluid">
              <div className="page-list-main">
                <div className="flex-row">
                  {this.state.agentList.map((item) => {
                    return this.isLoadAction(item) && <AgentCardList roles={this.props.roles} agent={item} key={item._id} />;
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.viewMode == "table" && this.state.agentList && (
          <div
            style={{
              height: this.state.windowHeight - 246 + "px",
              margin: "34px 20px",
            }}
          >
            <AgentTableList roles={this.props.roles} agents={this.state.agentList} />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("Agent mapStateToProps: ", state);
  return {
    group: state.group,
    agent: state.agent,
    auth: state.auth,
    ui: state.ui,
  };
}

export default connect(mapStateToProps, {
  agentViewMode,
  getAgents,
  getGroups,
  clearAgentForceLogout,
  clearAgentSaveSuccess,
  clearBulkuploadSuccess,
  searchAgent,
  filterAgent,
  filterAgentGroupList,
  filterAgentSort,
  filterAgentType,
})(Agent);
