import * as React from 'react';
import styled from 'styled-components';

export interface IChatRoutingProps {
  color?: string;
}

const Icon = styled.svg`
  width: 16.58px;
  height: 16.58px;
  transition: all 0.1s;
  cursor: pointer;
  fill: ${(props) => props.theme.color || '#ffffff'};
  stroke: ${(props) => props.theme.color || '#ffffff'};
  stroke-width: 0.5px;
`;

export default function ChatRouting(props: IChatRoutingProps) {
  const { color } = props;
  return (
    <Icon theme={{ color }}>
      <g id="noun_chat_2403705" transform="translate(-14.642 -47.837)">
        <g
          id="noun_wifi_chat_821577"
          fill="#7f90af"
          data-name="noun_wifi chat_821577"
          transform="translate(6.48 41.256)"
        >
          <path
            id="Path_7238"
            stroke="#7f90af"
            strokeWidth="0.4px"
            d="M16.551 7.536a7.245 7.245 0 0 1 0 14.49 7.143 7.143 0 0 1-4.221-1.37.749.749 0 0 0-.748-.077l-1.714.77.724-1.909a.756.756 0 0 0-.074-.683 7.249 7.249 0 0 1 6.033-11.22m0-.755A8 8 0 0 0 9.889 19.17L8.52 22.781l3.369-1.514a7.987 7.987 0 1 0 4.662-14.486z"
            data-name="Path 7238"
          />
          <path
            id="Path_7239"
            d="M33.7 33.717a3.564 3.564 0 0 0-4.831 0c-.826.824.1 1.627.537 1.214a2.6 2.6 0 0 1 3.778 0c.454.456 1.383-.39.516-1.214zm1.693-2.055c-2.6-2.234-5.616-2.234-8.053-.022-1.135 1.041.062 1.974.929 1.041a4.314 4.314 0 0 1 6.111 0c.929.911 1.982.021 1.012-1.019zm-4.07 3.568a1.055 1.055 0 0 0-1.033 1.106 1.023 1.023 0 1 0 2.045 0 1.066 1.066 0 0 0-1.012-1.106z"
            data-name="Path 7239"
            transform="translate(-14.806 -18.91)"
          />
        </g>
      </g>
    </Icon>
  );
}
