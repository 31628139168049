import * as React from "react";
import styled from "styled-components";

export interface ICredentialsProps {
}

export default function credentials(props: ICredentialsProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.58" height="21.58" viewBox="0 0 31.803 34.64">
            <defs>
                <filter id="5btn2cckea" width="25.08" height="34.64" x="0" y="0" filterUnits="userSpaceOnUse">
                    <feOffset />
                    <feGaussianBlur result="blur" stdDeviation=".5" />
                    <feFlood flood-color="#212332" flood-opacity=".275" />
                    <feComposite in2="blur" operator="in" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g>
                <g>
                    <g>
                        <path fill="#d7d9e5" fill-rule="evenodd" d="M23.063.125a6.928 6.928 0 0 0-7.293 3.282c-.091 0-3.646-.091-3.646-.091l-1.459 1.458-1.641-1.641-1.64 1.55-1.55-1.641L4.1 4.592 2.552 2.951.091 5.321A21.407 21.407 0 0 0 0 7.691 31.712 31.712 0 0 0 3.282 9.7c.091 0 10.027.182 11.759.182h.459a6.8 6.8 0 0 0 7.019 3.92c3.555-.456 5.743-2.917 6.2-6.472.451-3.285-2.466-6.658-5.656-7.205zm-10.21 7.931l-9.024.091c-.547 0-.547-.82-.091-.82l9.116-.091a.41.41 0 1 1-.001.82zm13.4.456a2.129 2.129 0 1 1 .091-3.1 2.173 2.173 0 0 1-.091 3.099z" transform="translate(.722 1.5) rotate(141 13.058 12.514)" />
                    </g>
                </g>
                <g>
                    <g filter="url(#5btn2cckea)" transform="translate(.722 1.5) rotate(108 9.873 10.447) rotate(-108 9.674 11.466)">
                        <path fill="#666980" fill-rule="evenodd" d="M23.063.125a6.928 6.928 0 0 0-7.293 3.282c-.091 0-3.646-.091-3.646-.091l-1.459 1.458-1.641-1.641-1.64 1.55-1.55-1.641L4.1 4.592 2.552 2.951.091 5.321A21.407 21.407 0 0 0 0 7.691 31.711 31.711 0 0 0 3.282 9.7c.091 0 10.027.182 11.759.182h.459a6.8 6.8 0 0 0 7.019 3.92c3.555-.456 5.743-2.917 6.2-6.472.451-3.285-2.466-6.658-5.656-7.205zm-10.21 7.931l-9.024.091c-.547 0-.547-.82-.091-.82l9.116-.091a.41.41 0 0 1-.001.82zm13.4.456a2.129 2.129 0 1 1 .091-3.1 2.173 2.173 0 0 1-.091 3.099z" transform="rotate(108 9.696 11.451)" />
                    </g>
                </g>
            </g>
        </svg>
    );
}
