import * as React from "react";
import LeftNav from "./LeftNav";
import Footer from "./Footer";
import { connect } from "react-redux";
// import Container from "react-bootstrap/Container";
import { Row, Col } from "antd";
import Header from "./Header";
import { getUnassignedChats } from "../../actions/chatsAction";
import { onConnectMQTT } from "../../helpers/mqttService";
// import AlertSound from "../common/AlertSound";
// import { newTransferChat } from "../../helpers/rxServices";

class Main extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      innerHeight: window.innerHeight,
    };
  }
  componentDidMount() {
    if (this.props.auth && this.props.auth.info) {
      onConnectMQTT();
      this.props.getUnassignedChats();
    }
    window.addEventListener("resize", () => {
      this.displayWindowSize();
    });
  }

  displayWindowSize() {
    this.setState({
      innerHeight: window.innerHeight,
    });
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.displayWindowSize();
    });
  }

  render() {
    // console.log(`MAIN: ${this.props.ui.activeRootNav}`)
    return (
      <>
        <div
          style={{
            backgroundColor: "#0000003b",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 1001,
            display: this.props.ui.isBlockLayer ? "block" : "none",
          }}
        ></div>
        <div style={{ display: "flex" }}>
          <div>
            <LeftNav />
          </div>
          <div style={{ width: "100%" }}>
            <Row
              style={{
                height: "59px",
                borderBottom: "1px solid #d7d9e5",
                margin: "0px",
              }}
            >
              <Col span={24}>
                {this.props.auth && this.props.auth.info && <Header></Header>}
              </Col>
            </Row>
            <Row
              style={{
                overflow: "hidden",
                height: this.state.innerHeight - 118,
                margin: "0px",
              }}
            >
              <Col span={24}>{this.props.children}</Col>
            </Row>
            <Row
              style={{
                height: "59px",
                borderTop: "1px solid #d7d9e5",
                marginLeft: "-95px",
              }}
            >
              <Col span={24}>
                <Footer />
              </Col>
            </Row>
          </div>
          {/* <div style={{ bottom: "0px", position: "absolute", width: "100%" }}>
                        <Footer />
                    </div> */}
        </div>
        {/* <AlertSound></AlertSound> */}
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    user: state.user,
    ui: state.ui,
    task: state.task,
  };
};
export default connect(mapStateToProps, { getUnassignedChats })(Main);
