import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Input, Divider, Button } from "antd";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import { getFaqCategories } from "../../actions/faqAction";
import { compareValues } from "../../helpers/util";
import enterIcon from "../../assets/images/ico-read-more-orange.svg";

import "../../assets/sass/faqResp.sass";
import styled from "styled-components";

const Arrow = styled.span`
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-top: 14px solid #fff;
  position: absolute;
  right: 144px;
`;

class FAQPopup extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      faqResp: null,
      questions: [],
      search: "",
      innerHeight: window.innerHeight,
    };
  }
  async componentDidMount() {
    await this.initialLoad();
  }
  async initialLoad() {
    let questions = [];
    let faqResp = await this.props.getFaqCategories();
    for (let i in faqResp.faqCategories) {
      if (
        faqResp.faqCategories[i].isActive &&
        faqResp.faqCategories[i].questions &&
        faqResp.faqCategories[i].questions.length > 0
      ) {
        questions = questions.concat(
          faqResp.faqCategories[i].questions.filter(function (item) {
            item.name = faqResp.faqCategories[i].name;
            return item.isActive == true;
          })
        );
      }
    }
    this.setState({ questions, faqResp });
  }
  onSelect = (item) => {
    if (item && item.isActive && typeof this.props.onSelect == "function") {
      this.props.onSelect(item);
    }
  };
  onSearch = (e) => {
    let faqResp = this.state.faqResp ? this.state.faqResp : [];
    let questions = [];
    for (let i in faqResp.faqCategories) {
      if (
        faqResp.faqCategories[i].isActive &&
        faqResp.faqCategories[i].questions &&
        faqResp.faqCategories[i].questions.length > 0
      ) {
        let filterQuestion = faqResp.faqCategories[i].questions.filter(function (item) {
          return item.question.toLowerCase().includes(e.target.value.toLowerCase());
        });
        questions = questions.concat(filterQuestion);
      }
    }
    this.setState({ questions });
  };
  render() {
    return (
      <Row className="faq-resp">
        <Col span={24} className="popup" style={{ height: this.state.innerHeight - 300 + "px" }}>
          <Row className="header">
            <Col span={24}>
              <div className="title">FAQ</div>
              <Input
                onChange={(e) => this.onSearch(e)}
                className="search"
                placeholder={"Search by question"}
                prefix={<SearchOutlined />}
              />
            </Col>
          </Row>
          <Row className="content">
            <Col span={24}>
              <div className="warp" style={{ height: this.state.innerHeight - 440 + "px" }}>
                {this.state.questions &&
                  this.state.questions.map((item, index) => {
                    return (
                      item.isActive && (
                        <Row
                          className="item"
                          key={item.id}
                          onMouseEnter={() => this.setState({ hoverItem: item.id })}
                          onClick={() => {
                            this.onSelect(item);
                          }}
                        >
                          <Col span={24} className="question">
                            <div style={{ width: "90%", float: "left" }}>
                              <div style={{ float: "left" }}>{item.question}</div>
                              <div
                                className="category"
                                style={{
                                  display: this.state.hoverItem == item.id ? "block" : "none",
                                }}
                              >
                                {item.name}
                              </div>
                            </div>
                            <div style={{ float: "right", textAlign: "right", width: "10%" }}>
                              <img src={enterIcon} />
                            </div>
                          </Col>
                          <Col span={24} className="answer">
                            <div style={{ width: "90%" }}>{item.answer}</div>
                          </Col>
                          {index != this.state.questions.length - 1 && <Divider className="unselect" />}
                        </Row>
                      )
                    );
                  })}
              </div>
            </Col>
          </Row>
        </Col>
        <Arrow></Arrow>
      </Row>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    ui: state.ui,
    task: state.task,
    quickResp: state.quickResp,
  };
};
export default connect(mapStateToProps, {
  getFaqCategories,
})(FAQPopup);
