import * as React from "react";
import styled from "styled-components";

export interface IChatRoutingProps {
  color?: string;
}

const Icon = styled.svg`
  width: 16.58px;
  height: 16.58px;
  transition: all 0.1s;
  cursor: pointer;
  fill: ${(props) => props.theme.color || "#0948b2"};
  stroke: ${(props) => props.theme.color || "#0948b2"};
  stroke-width: 0.3px;
`;

export default function ChatRouting(props: IChatRoutingProps) {
  const { color } = props;
  return (
    <Icon theme={{ color }}>
      <g id="noun_Reason_3118542" transform="translate(-16.479 -13.958)">
        <g id="noun_user_delete_710797" transform="translate(16.479 13.958)">
          <path
            id="Path_7237"
            d="M33.947 29.67a3.67 3.67 0 0 0-1.579.355 6.761 6.761 0 0 0-1.85-.965 4.441 4.441 0 1 0-4.388.017 6.7 6.7 0 0 0-4.529 6.31h.952a5.8 5.8 0 0 1 8.969-4.8 3.575 3.575 0 0 0-1.227 2.691 3.654 3.654 0 1 0 3.653-3.6zm-9.127-4.409a3.49 3.49 0 1 1 3.488 3.439 3.47 3.47 0 0 1-3.489-3.439zm9.127 10.68a2.666 2.666 0 1 1 2.7-2.666 2.687 2.687 0 0 1-2.7 2.665z"
            transform="translate(-21.6 -20.879)"
          />
          <path
            id="Rectangle_699"
            d="M0 0H3.864V0.997H0z"
            transform="translate(10.24 11.88)"
          />
        </g>
      </g>
    </Icon>
  );
}
