import * as React from "react";
import styled from "styled-components";
import update from "immutability-helper";
import { Switch } from "antd";
import { ChromePicker } from "react-color";

import GradientPickerIcon from "../../../../../../../../assets/icons/gradient.svg";
import { ThemeContext } from "../../../../../themeDesigner";

export interface IGeneralHeaderPropertiesProps { }
interface Itoggle {
  id?: number;
  label?: string;
  name?: string;
  checked?: boolean;
}

interface IColor {
  hex: string;
}

const GeneralPropertyWrapper = styled.div`
  padding: 0 15px;
`;

const BackgroundColorContainer = styled.div`
  height: 59px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #aaa;
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

const ToggleContainer = styled.div`
  margin-top: 14px;
`;

const ToggleWrapper = styled.div`
  display: flex;

  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

export default function GeneralHeaderProperties(props: IGeneralHeaderPropertiesProps) {
  const [openPicker, setOpenPicker] = React.useState(false);
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  let header = themeProps.widget.header;
  const toggleIconsList: Itoggle[] = [
    {
      id: 1,
      label: "Avatar",
      name: "avatar",
      checked: header.avatar.isVisible,
    },
    {
      id: 2,
      label: "Maximize",
      name: "maximize",
      checked: header.actions[0].isVisible,
    },
    {
      id: 3,
      label: "Close",
      name: "close",
      checked: header.actions[1].isVisible,
    },
    {
      id: 4,
      label: "Static",
      name: "title",
      checked: header.title ? header.title.isVisible : true,
    },
    {
      id: 5,
      label: "Sub Title",
      name: "subTitle",
      checked: header.subTitle ? header.subTitle.isVisible : true,
    },
    {
      id: 6,
      label: "Reset Chat",
      name: "resetChat",
      checked: header.actions[2].isVisible,
    },
    {
      id: 7,
      label: "Enable Close Event",
      name: "enableCloseEvent",
      checked: header.enableCloseEvent,
    }
  ];

  React.useEffect(() => {
    const { widget } = themeProps;
    header = widget.header;
    setColor(header.background.hex);
  }, [themeProps]);

  const [color, setColor] = React.useState(header.background.hex);
  const handleToggleChange = (checked: boolean, label: string) => {
    if (label === "maximize") {
      const updatedGeneralProperties = update(themeProps, {
        widget: {
          header: {
            actions: {
              [0]: {
                isVisible: { $set: checked },
              },
            },
          },
        },
      });
      setThemeProps(updatedGeneralProperties);
    } else if (label === "close") {
      const updatedGeneralProperties = update(themeProps, {
        widget: {
          header: {
            actions: {
              [1]: {
                isVisible: { $set: checked },
              },
            },
          },
        },
      });
      setThemeProps(updatedGeneralProperties);
    } else if (label === "resetChat") {
      const updatedGeneralProperties = update(themeProps, {
        widget: {
          header: {
            actions: {
              [2]: {
                isVisible: { $set: checked },
              },
            },
          },
        },
      });
      setThemeProps(updatedGeneralProperties);
    } else if (label === "enableCloseEvent") {
      debugger
      const updatedGeneralProperties = update(themeProps, {
        widget: {
          header: {
            enableCloseEvent: { $set: checked }
          }
        }
      });
      debugger
      setThemeProps(updatedGeneralProperties);
    } else {
      const updatedGeneralProperties = update(themeProps, {
        widget: {
          header: {
            [label]: {
              isVisible: { $set: checked },
            },
          },
        },
      });
      setThemeProps(updatedGeneralProperties);
    }
  };

  const handleBackgroundColorChange = (color: IColor) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        header: {
          background: {
            hex: { $set: color.hex },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };

  return (
    <GeneralPropertyWrapper>
      <BackgroundColorContainer>
        <span className="text">Background Color</span>
        <DisplayColor
          theme={{ backgroundColor: color }}
          className="picker"
          onClick={() => setOpenPicker(!openPicker)}
        />
        {openPicker && (
          <div className="color-picker">
            <ChromePicker
              color={color}
              onChange={(color: IColor) => setColor(color.hex)}
              onChangeComplete={handleBackgroundColorChange}
            />
          </div>
        )}
        <GradientPickerWrapper>
          <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
        </GradientPickerWrapper>
      </BackgroundColorContainer>
      {toggleIconsList.map((item: Itoggle) => (
        <ToggleContainer key={item.id}>
          <ToggleWrapper>
            <span className="text">{item.label}</span>
            <Switch
              size="small"
              defaultChecked={item.checked}
              checked={item.checked}
              onChange={(checked) => handleToggleChange(checked, item.name)}
            />
          </ToggleWrapper>
        </ToggleContainer>
      ))}
    </GeneralPropertyWrapper>
  );
}
