import type from "../types/actionTypes";
const initialState = {
  groups: [],
};
export default function groupsReducers(state = initialState, action) {
  switch (action.type) {
    case type.GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.data.groups,
      };
    case type.GROUP_SAVE_SUCCESS:
      return {
        ...state,
        groupSaveSuccess: action.data,
      };
    case type.GROUP_ERROR:
      return {
        ...state,
        groupError: action.data,
      };

    case type.BULKUPLOAD_SUCCESS:
      return {
        ...state,
        bulkuploadSuccess: action.data,
      };
    case type.ERROR:
      return {
        ...state,
        error: action.data,
      };
    default:
      return state;
  }
}
