import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
class Menu extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Row className="menu">
                <Col span="1" style={{ marginRight: "16px" }} className="title">
                    DASHBOARD
                </Col>
                <Col span="23">
                </Col>
            </Row>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth,
        ui: state.ui,
    };
}

export default connect(mapStateToProps, {})(Menu);
