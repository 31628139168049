import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import timezone from "moment-timezone";
import "../../assets/sass/daterangepicker.sass";
import { CheckOutlined } from "@ant-design/icons";
import RangePicker from "react-range-picker";
import { store } from "../../store";
import { setTheme } from "../../actions/uiAction";

const timeZones = timezone.tz.names();
let offsetTmz = [];
const fullTime = ["days"];
offsetTmz.push({
    default: true,
    offset: moment().tz(moment.tz.guess()).format('Z'),
    abbreviation: moment.tz.guess()
});
for (let i in timeZones) {
    offsetTmz.push({ offset: moment.tz(timeZones[i]).format('Z'), abbreviation: timeZones[i] });
}
const daterangepickerRef: any = React.createRef();

class CDateRangePicker extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        let startDate = (props.defaultValue && props.defaultValue.startDate) ? props.defaultValue.startDate : new Date(),
            endDate = (props.defaultValue && props.defaultValue.endDate) ? props.defaultValue.endDate : new Date(),
            dateRangeSelection = props.dateRangeSelection || null;
        let { theme } = store.getState().ui;
        this.state = {
            errors: [],
            postion: props.position,
            consoleTheme: { ...theme, ...{ dtRangeOffsetTop: null, dtRangeOffsetLeft: null, dtRangeTheme: props.theme ? props.theme : "dark" } },
            relativeTimeRange: [{
                key: "last5Minutes",
                label: "Last 5 minutes",
                type: "minutes",
                value: -5
            }, {
                key: "last15minutes",
                label: "Last 15 minutes",
                type: "minutes",
                value: -15
            }, {
                key: "last30minutes",
                label: "Last 30 minutes",
                type: "minutes",
                value: -30
            }, {
                key: "last1hour",
                label: "Last 1 hour",
                type: "hours",
                value: -1
            }, {
                key: "last3hour",
                label: "Last 3 hours",
                type: "hours",
                value: -3
            }, {
                key: "last6hour",
                label: "Last 6 hours",
                type: "hours",
                value: -6
            }, {
                key: "last12hour",
                label: "Last 12 hours",
                type: "hours",
                value: -12
            }, {
                key: "last24hour",
                label: "Last 24 hours",
                type: "hours",
                value: -24
            }, {
                key: "last2day",
                label: "Last 2 days",
                type: "days",
                value: -2
            }, {
                key: "last7day",
                label: "Last 7 days",
                type: "days",
                value: -7
            }, {
                key: "last30day",
                label: "Last 30 days",
                type: "days",
                value: -30
            }, {
                key: "last90day",
                label: "Last 90 days",
                type: "days",
                value: -90
            }],
            dateSelection: dateRangeSelection || {
                type: "AbsoluteTimeRange",
                range: null
            },
            startDate,
            endDate,
            focus: null,
            showTimezone: false,
            filterTimezone: "",
            selectedTimezone: offsetTmz[0]
        };
        this.pickerWithCustomePlaceholder = this.pickerWithCustomePlaceholder.bind(this);
        this.init();
    };

    init = () => {
        let { theme } = store.getState().ui;
        this.setState({
            consoleTheme: theme
        });
    }

    handleFocusChange = (focus) => {
        this.setState({ focus: focus || null })
    }

    selectRelativeDate = (range) => {
        let { dateSelection } = this.state;
        dateSelection.type = "RelativeTimeRange";
        dateSelection.range = range;
        this.setState({
            dateSelection,
            startDate: null,
            endDate: null
        })
    }

    onCancel = () => {
        this.props.onCancel();
    }

    onApply = () => {
        if (typeof this.props.onChange == "function") {
            let errors = [];
            let startDate = null, endDate = null;
            if (this.state.dateSelection.range) {
                startDate = fullTime.indexOf(this.state.dateSelection.range.type) > -1 ? moment().add(this.state.dateSelection.range.value, this.state.dateSelection.range.type).startOf('day').toISOString() :
                    moment().add(this.state.dateSelection.range.value, this.state.dateSelection.range.type).toISOString();
                endDate = fullTime.indexOf(this.state.dateSelection.range.type) > -1 ? moment().endOf('day').toISOString() : moment().toISOString();
            } else if (this.state.dateSelection.range == null) {
                if (this.state.startDate == null) {
                    errors.push("startdate");
                }
                if (this.state.endDate == null) {
                    errors.push("enddate");
                }
                if(this.state.startDate !== null && this.state.endDate !== null) {
                    const start = moment(this.state.startDate);
                    const end = moment(this.state.endDate);
                    const diffInDays = end.diff(start, 'days');

                    if(Math.abs(diffInDays) > 2) {
                        errors.push("invalidTimeRange");
                    }
                }
                if (errors.length == 0) {
                    startDate = moment(new Date(this.state.startDate)).toISOString();
                    endDate = moment(new Date(this.state.endDate)).toISOString();
                }
                // startDate = moment(new Date(this.state.startDate).setHours(0, 0, 0, 0)).toISOString();
                // endDate = moment(new Date(this.state.endDate).setHours(23, 59, 59, 59)).toISOString();
            }
            if (errors.length == 0) {
                this.props.onChange({ startDate, endDate, zone: this.state.selectedTimezone, dateRangeSelection: this.state.dateSelection });
            } else {
                this.setState({ errors })
            }
        }
    }

    setCalPostion = () => {
        var res: any = new Object();
        res.x = 0;
        res.y = 0;
        const dateRangePos = 281;
        if (daterangepickerRef !== null && daterangepickerRef.current !== null) {
            if (daterangepickerRef.current.getBoundingClientRect) {
                var viewportElement = document.documentElement;
                var box = daterangepickerRef.current.getBoundingClientRect();
                var scrollLeft = viewportElement.scrollLeft;
                var scrollTop = viewportElement.scrollTop;
                res.x = box.left + scrollLeft;
                res.y = box.top + scrollTop;
            }
        }
        let { consoleTheme } = this.state;
        if (consoleTheme.dtRangeOffsetTop != `${res.y}px` || consoleTheme.dtRangeOffsetLeft != `${res.x - dateRangePos}px`) {
            consoleTheme.dtRangeOffsetTop = `${res.y}px`;
            consoleTheme.dtRangeOffsetLeft = `${res.x - dateRangePos}px`; //- 190
            this.setState({
                consoleTheme
            });
            this.props.setTheme(consoleTheme);
        }
    }

    pickerWithCustomePlaceholder = () => {
        const placeholder = ({ startDate, endDate }) => {
            this.setCalPostion();
            return (
                <div className="date-picker-app-wrapper">
                    <div>
                        <div className="from">
                            From
                        </div>
                        <div className="input" style={{ borderColor: this.state.errors.includes("startdate") || this.state.errors.includes("invalidTimeRange") ? "#c70d0d" : "#e6e6e6" }}>
                            {this.state.dateSelection && this.state.dateSelection.type === "RelativeTimeRange" && this.state.dateSelection.range && <div>{this.state.dateSelection.range.label}</div>}
                            {this.state.dateSelection && this.state.dateSelection.type === "AbsoluteTimeRange" && this.state.startDate && <div>{moment(new Date(this.state.startDate)).format("DD-MM-YYYY")}</div>}
                        </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <div className="to">
                            To
                        </div>
                        <div className="input" style={{ borderColor: this.state.errors.includes("enddate") || this.state.errors.includes("invalidTimeRange") ? "#c70d0d" : "#e6e6e6" }}>
                            {this.state.dateSelection && this.state.dateSelection.type === "RelativeTimeRange" && <div>Now</div>}
                            {this.state.dateSelection && this.state.dateSelection.type === "AbsoluteTimeRange" && this.state.endDate && <div>{moment(new Date(this.state.endDate)).format("DD-MM-YYYY")}</div>}
                        </div>
                    </div>
                </div>
            );
        };
        return (
            <RangePicker
                placeholder={placeholder}
                defaultValue={{ startDate: new Date(this.state.startDate), endDate: new Date(this.state.endDate) }}
                selectTime
                onDateSelected={(startDate, endDate) => {
                    this.setState(state => ({ errors: state.errors.filter(error => error != "invalidTimeRange") }));
                    let { dateSelection } = this.state;
                    dateSelection.type = "AbsoluteTimeRange"
                    dateSelection.range = null
                    this.setState({
                        dateSelection,
                        startDate,
                        endDate
                    })
                }}
                onClose={() => {
                    console.log(" closed ");
                }}
            />
        );
    };

    render() {
        return (
            <div className="daterangepicker" ref={daterangepickerRef}>
                <div className="container">
                    <div className="time" onClick={() => this.setState({ showTimezone: false })}>
                        <div className="absolute-range">
                            <div className={(this.state.startDate || this.state.endDate) ? "header active" : "header"}>Absolute time range</div>
                            <div className="content">
                                {this.pickerWithCustomePlaceholder()}
                                <div className="action">
                                    <div className="apply" onClick={this.onApply}>Apply</div>
                                    <div className="apply" onClick={this.onCancel}>Cancel</div>
                                </div>
                                {this.state.dateSelection.range &&
                                    <div className="relative-dt">
                                        <div>{
                                            fullTime.indexOf(this.state.dateSelection.range.type) > -1 ? moment().add(this.state.dateSelection.range.value, this.state.dateSelection.range.type).startOf('day').format("DD-MM-YYYY HH:mm:ss") :
                                                moment().add(this.state.dateSelection.range.value, this.state.dateSelection.range.type).format("DD-MM-YYYY HH:mm:ss")
                                        }</div>
                                        <div>{fullTime.indexOf(this.state.dateSelection.range.type) > -1 ? moment().endOf('day').format("DD-MM-YYYY HH:mm:ss") : moment().format("DD-MM-YYYY HH:mm:ss")}</div>
                                    </div>}
                                {this.state.dateSelection.range == null && <div className="relative-dt">
                                    {/* <div>{moment(new Date(this.state.startDate).setHours(0, 0, 0, 0)).format("DD-MM-YYYY HH:mm:ss")}</div>
                                    <div>{moment(new Date(this.state.endDate).setHours(23, 59, 59, 59)).format("DD-MM-YYYY HH:mm:ss")}</div> */}
                                    {this.state.startDate != null && <div>{moment(new Date(this.state.startDate)).format("DD-MM-YYYY HH:mm:ss")}</div>}
                                    {this.state.endDate != null && <div>{moment(new Date(this.state.endDate)).format("DD-MM-YYYY HH:mm:ss")}</div>}

                                </div>}

                                {this.state.errors.includes("invalidTimeRange") && <p style={{ paddingTop: "16px", color: "tomato" }}>Maximum time range of 2 days exceeded. Select again.</p>}
                            </div>
                        </div>
                        <div className="relative-range">
                            <div className={this.state.dateSelection.range ? "header active" : "header"}>Relative time range</div>
                            <div className="content">
                                <ul>
                                    {this.state.relativeTimeRange.map((range, idx) => {
                                        if(idx <= 8) {
                                        return <li onClick={() => this.selectRelativeDate(range)} key={range.key}><div> {range.label}</div> <div>{this.state.dateSelection.range && this.state.dateSelection.range.key == range.key && <CheckOutlined className="checked" />}</div> </li>
                                        }
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {!this.state.showTimezone && <div className="timezone" style={{ flexDirection: this.state.showTimezone ? "column" : "row" }}>
                        <div className="selected-zone">
                            <div className="brower-time">{this.state.selectedTimezone ? this.state.selectedTimezone.abbreviation : "Browser Time"}</div>
                            <div></div>
                            <div className="utc">UTC{this.state.selectedTimezone ? this.state.selectedTimezone.offset : ""}</div>
                        </div>
                        <div className="change-zone" onClick={() => this.setState({ showTimezone: true })} >Change timezone</div>
                    </div>
                    }
                    {this.state.showTimezone && <div className="timezone" style={{ flexDirection: this.state.showTimezone ? "column" : "row" }}>
                        <div className="search"><input type="text" onChange={(e) => this.setState({ filterTimezone: e.target.value })} /></div>
                        <div className="list">
                            {offsetTmz.map((item, i) => {
                                return (this.state.filterTimezone.trim().length == 0 || item.offset.toLowerCase().indexOf(this.state.filterTimezone.toLowerCase()) > -1 || item.abbreviation.toLowerCase().indexOf(this.state.filterTimezone.toLowerCase()) > -1) &&
                                    <div onClick={() => this.setState({ selectedTimezone: item, showTimezone: false })} className={this.state.selectedTimezone.abbreviation == item.abbreviation ? "offset active" : "offset"} key={i}>
                                        <div>{item.default ? "Default" : item.abbreviation}</div>
                                        <div>UTC{item.offset}</div>
                                    </div>
                            })}
                        </div>
                    </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("DateRangePicker mapStateToProps: ", state);
    return {
        task: state.task,
    };
}

export default connect(mapStateToProps, { setTheme })(CDateRangePicker);
