import React from "react";
import { connect } from "react-redux";
import { Input, Row, Col } from "antd";
import { Select as A8_SELECT } from "a8-uicomponents";
import {
  searchGroup,
  filterGroup,
  filterGroupAgent,
  filterGroupSort,
  filterGroupType,
} from "../../../actions/uiAction";
import {
  clearGroupSaveSuccess,
  clearBulkuploadSuccess,
} from "../../../actions/taskAction";
import { getAgents } from "../../../actions/agentsAction";
import { getGroups } from "../../../actions/groupsAction";
import "../../../assets/sass/group.sass";
import GroupList from "./GroupList";
import { compareValues } from "../../../helpers/util";

const { Search } = Input;
let groupResp = [];
let groupAction = {
  _id: null,
  action: true,
  isActive: true,
};

let types = [
  {
    _id: "all",
    name: "All",
  },
  {
    _id: "agent",
    name: "Agent",
  },
  {
    _id: "bot",
    name: "Bot",
  },
];
let sorts = [
  {
    _id: "asc",
    name: "ASC",
  },
  {
    _id: "desc",
    name: "DESC",
  },
];
let selectAgentOptions = {
  defaultValue: [],
  optionValue: "_id",
  isSearch: true,
  title: "name",
  multiSelect: false,
  placeholder: "Filter By Agent",
  primaryColor: null,
};

let selectGroupOptions = {
  defaultValue: [],
  optionValue: "_id",
  isSearch: false,
  displayLimit: 3,
  title: "name",
  multiSelect: false,
  placeholder: "Filter By Group",
  primaryColor: null,
};
let selectSortOptions = {
  defaultValue: [],
  optionValue: "_id",
  title: "name",
  multiSelect: false,
  primaryColor: null,
};
let selectTypeOptions = {
  defaultValue: [],
  optionValue: "_id",
  title: "name",
  multiSelect: false,
  primaryColor: null,
};

let theme = null;

class Group extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      roles: this.props.roles,
      agentList: [],
      groupList: [],
      sort: [sorts[0]._id],
      type: [types[0]._id],
      windowHeight: window.innerHeight,
    };
  }

  displayWindowSize() {
    this.setState({
      windowHeight: window.innerHeight,
    });
  }

  async componentDidMount() {
    await this.initialLoad();
    window.addEventListener("resize", () => {
      this.displayWindowSize();
    });
  }

  async componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.displayWindowSize();
    });
  }

  async initialLoad() {
    types = [{
      _id: "all",
      name: "All",
    },
    {
      _id: "agent",
      name: "Agent",
    },
    {
      _id: "bot",
      name: "Bot",
    }];
    let agentResp = [];
    let agentList = await this.props.getAgents();
    if (agentList && agentList.agents) {
      if (this.state.roles.includes("supervisor") && !this.state.roles.includes("admin")) {
        for (let j in agentList.agents) {
          for (let i = 0; i < this.props.auth.info.groups.length; i++) {
            if (!agentList.agents[j].isVirtualAgent && agentList.agents[j].roles.length == 1 && agentList.agents[j].roles.includes("agent") && agentList.agents[j].groupIds.includes(this.props.auth.info.groups[i]._id)) {
              agentResp.push(agentList.agents[j]);
              break;
            }
          }
        }
      } else {
        agentResp = agentList.agents;
      }
    }
    groupResp = [];
    let groupList = await this.props.getGroups();
    if (groupList) {
      if (this.state.roles.includes("supervisor") && !this.state.roles.includes("admin")) {
        for (let j in groupList.groups) {
          for (let i = 0; i < this.props.auth.info.groups.length; i++) {
            if (this.props.auth.info.groups[i]._id == groupList.groups[j]._id) {
              groupResp.push(groupList.groups[j]);
              break;
            }
          }
        }
        this.setState({ groupList: groupResp });
      } else {
        groupResp = groupList.groups;
      }
    }


    let groups = JSON.parse(JSON.stringify(groupResp));
    groups.sort(compareValues("name", null, "string", "asc"));
    if (agentResp) {
      this.setState({ agentList: agentResp });
    }
    if (groupResp) {
      groups.splice(0, 0, groupAction);
      this.setState({ groupList: groups });
    }

    if (this.props.ui.searchGroup) {
      this.setState({
        name: this.props.ui.searchGroup,
      });
    }
    if (this.props.ui.filterGroup) {
      this.setState({
        groupId: this.props.ui.filterGroup,
      });
    }
    if (this.props.ui.filterGroupAgent) {
      this.setState({
        agentId: this.props.ui.filterGroupAgent,
      });
    }
    if (this.props.ui.filterGroupSort) {
      this.setState({
        sort: this.props.ui.filterGroupSort,
      });
    }
    if (this.props.ui.filterGroupType) {
      this.setState({
        type: this.props.ui.filterGroupType,
      });
    }
    if (this.props.ui.theme) {
      theme = this.props.ui.theme;
    }
    selectTypeOptions.defaultValue = this.state.type;
    selectTypeOptions.primaryColor = theme.primaryColor;
    selectSortOptions.defaultValue = this.state.sort;
    selectSortOptions.primaryColor = theme.primaryColor;
    selectGroupOptions.defaultValue = this.state.groupId;
    selectGroupOptions.primaryColor = theme.primaryColor;
    selectAgentOptions.defaultValue = this.state.agentId;
    selectAgentOptions.primaryColor = theme.primaryColor;
    if (agentList && agentList.agents && this.state.roles.includes("supervisor") && !this.state.roles.includes("admin")) {
      types = [{
        _id: "agent",
        name: "Agent",
      }];
      selectTypeOptions = {
        defaultValue: ["agent"],
        optionValue: "_id",
        title: "name",
        multiSelect: false,
        primaryColor: null,
      };
    }
    if (groupResp) {
      this.applyFilter();
    }
  }

  onFilterChange = (key, value) => {
    this.onPersist(key, value);
    this.setState({ [key]: value }, () => this.applyFilter());
  };

  applyFilter = () => {
    let groups = groupResp;
    let agentList = this.state.agentList;
    let filterGroups = [];
    let groupName = this.state.name
      ? this.state.name.toLocaleLowerCase()
      : null;
    let isFilterNameAndGroup = false;
    let isFilterType = false;
    let isFilterAgent = false;

    for (let g in groups) {
      isFilterNameAndGroup = false;
      isFilterType = false;
      isFilterAgent = false;
      if (
        ((groupName == null ||
          groups[g].name.toLocaleLowerCase().includes(groupName) || groupName == null ||
          groups[g]._id.includes(groupName))) &&
        (!this.state.groupId ||
          this.state.groupId.length == 0 ||
          (this.state.groupId.length > 0 &&
            groups[g]._id == this.state.groupId[0]))
      ) {
        isFilterNameAndGroup = true;
      }
      if (
        this.state.type &&
        this.state.type.length > 0 &&
        this.state.type[0] != "all"
      ) {
        if (this.state.type[0] == "bot") {
          for (let a in agentList) {
            if (
              agentList[a].groupIds.indexOf(groups[g]._id) > -1 &&
              agentList[a].isVirtualAgent == true
            ) {
              isFilterType = true;
            }
          }
        } else if (this.state.type[0] == "agent") {
          for (let a in agentList) {
            if (agentList[a].isVirtualAgent != true) {
              isFilterType = true;
            }
          }
        }
      } else {
        isFilterType = true;
      }
      if (this.state.agentId && this.state.agentId.length > 0) {
        for (let a in agentList) {
          if (
            agentList[a].groupIds.indexOf(groups[g]._id) > -1 &&
            this.state.agentId[0] == agentList[a]._id
          ) {
            isFilterAgent = true;
          }
        }
      } else {
        isFilterAgent = true;
      }
      if (isFilterNameAndGroup && isFilterType && isFilterAgent) {
        filterGroups.push(groups[g]);
      }
    }
    filterGroups.sort(
      compareValues("name", null, "string", this.state.sort[0])
    );
    filterGroups.splice(0, 0, groupAction);
    this.setState({
      groupList: filterGroups,
    });
  };

  async componentWillReceiveProps(props) {
    if (props.group.groupSaveSuccess) {
      this.props.clearGroupSaveSuccess();
      await this.initialLoad();
    }
    if (
      props.group.bulkuploadSuccess &&
      props.group.bulkuploadSuccess.docType == "groups"
    ) {
      this.props.clearBulkuploadSuccess();
      await this.initialLoad();
    }
  }

  onPersist = (key, value) => {
    if (key == "name") {
      this.props.searchGroup(value);
    } else if (key == "agentId") {
      this.props.filterGroupAgent(value);
    } else if (key == "groupId") {
      this.props.filterGroup(value);
    } else if (key == "sort") {
      this.props.filterGroupSort(value);
    } else if (key == "type") {
      this.props.filterGroupType(value);
    }
  };

  isLoadAction = (item) => {
    let isLoaded = true;
    let { roles } = this.state;
    if (roles.includes("supervisor") && item.action) {
      isLoaded = false;
      if (roles.includes("admin")) {
        isLoaded = true;
      }
    }
    return isLoaded;
  }

  render() {
    let groups = this.state.groupList;
    return (
      <div className="page-wrap page-fixed-nav groups">
        <div className="page-head filter">
          <Row>
            <Col span={6}>
              <Search
                defaultValue={this.props.ui.searchGroup}
                style={{ backgroundColor: "#e8e9f1" }}
                className="option search"
                placeholder="search text"
                onChange={(e) => this.onFilterChange("name", e.target.value)}
                onSearch={(value) => this.onFilterChange("name", value)}
              />
            </Col>
            <Col span={15}>
              <Row style={{ fontSize: "12px !important" }} className="option">
                <Col span={9} className="agent" style={{ padding: "0px 14px" }}>
                  <span className="lab">Agents</span>
                  <A8_SELECT
                    data={this.state.agentList}
                    options={selectAgentOptions}
                    onChange={(selectIds) =>
                      this.onFilterChange("agentId", selectIds)
                    }
                  ></A8_SELECT>
                </Col>
                <Col span={1}></Col>
                <Col span={8} className="group" style={{ padding: "0px 14px" }}>
                  <span className="lab">Group</span>
                  <A8_SELECT
                    data={groupResp}
                    options={selectGroupOptions}
                    onChange={(selectIds) =>
                      this.onFilterChange("groupId", selectIds)
                    }
                  ></A8_SELECT>
                </Col>
                <Col span={1}></Col>
                <Col span={5} style={{ padding: "0px 14px" }}>
                  <span className="lab" >Type</span>
                  <A8_SELECT
                    data={types}
                    options={selectTypeOptions}
                    onChange={(selectIds) =>
                      this.onFilterChange("type", selectIds)
                    }
                  ></A8_SELECT>
                </Col>
              </Row>
            </Col>
            <Col span={3} className="sort">
              <div className="option">
                <span style={{ paddingLeft: "14px" }} className="lab">
                  Sort by
                </span>
                <A8_SELECT
                  data={sorts}
                  options={selectSortOptions}
                  onChange={(selectIds) =>
                    this.onFilterChange("sort", selectIds)
                  }
                ></A8_SELECT>
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="page-content-main"
          style={{
            overflowX: "hidden",
            overflowY: "auto",
            height: this.state.windowHeight - 200 + "px",
            marginTop: "64px",
          }}
        >
          <div className="container-fluid">
            <div className="page-list-main">
              <div className="flex-row">
                {groups.map((item) => {
                  return this.isLoadAction(item) && <GroupList roles={this.state.roles} group={item} key={item._id} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("Agent mapStateToProps: ", state);
  return {
    group: state.group,
    agent: state.agent,
    auth: state.auth,
    ui: state.ui,
  };
}

export default connect(mapStateToProps, {
  getAgents,
  getGroups,
  clearBulkuploadSuccess,
  clearGroupSaveSuccess,
  searchGroup,
  filterGroup,
  filterGroupAgent,
  filterGroupSort,
  filterGroupType,
})(Group);
