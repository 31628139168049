import * as React from "react";
import { useDrag } from "react-dnd";
import styled from "styled-components";
import AutocompleteIcon from "../../../../../assets/images/autocomplete_icon.svg";
import CardChoiceIcon from "../../../../../assets/images/Card_icon.svg";
import ChartIcon from "../../../../../assets/images/chart_icon.svg";
import DataTableIcon from "../../../../../assets/images/dataTable_icon.svg";
import DocumentIcon from "../../../../../assets/images/Document_icon.svg";
import ImageIcon from "../../../../../assets/images/Image_icon.svg";
import SimpleChoiceIcon from "../../../../../assets/images/simpletick_icon.svg";
import TextMessageIcon from "../../../../../assets/images/textMessage_icon.svg";
import VideoIcon from "../../../../../assets/images/Video_icon.svg";
import SuggestionIcon from "../../../../../assets/images/suggestion_icon.svg";
import HourGlassIcon from "../../../../../assets/images/hour-glass.svg";

const BoxContainer = styled.p`
  padding-bottom: 8px;
  margin: 0;
  margin-bottom: 8px;
  font-size: 14px;
  opacity: ${(props) => props.theme.opacity};
  border-bottom: ${(props) => (!props.theme.isLastChild ? "1px solid #d3daef" : "none")};
`;

const renderIcon = (type: string) => {
  switch (type.toLowerCase()) {
    case "autocomplete":
      return <img src={AutocompleteIcon} style={{ marginRight: "18px" }} />;
    case "cardchoice":
      return <img src={CardChoiceIcon} style={{ marginRight: "18px" }} />;
    case "chart":
      return <img src={ChartIcon} style={{ marginRight: "18px" }} />;
    case "datatable":
      return <img src={DataTableIcon} style={{ marginRight: "18px" }} />;
    case "document":
      return <img src={DocumentIcon} style={{ marginRight: "18px" }} />;
    case "image":
      return <img src={ImageIcon} style={{ marginRight: "18px" }} />;
    case "simplechoice":
      return <img src={SimpleChoiceIcon} style={{ marginRight: "18px" }} />;
    case "agenttextmessage":
      return <img src={TextMessageIcon} style={{ marginRight: "18px" }} />;
    case "usertextmessage":
      return <img src={TextMessageIcon} style={{ marginRight: "18px" }} />;
    case "video":
      return <img src={VideoIcon} style={{ marginRight: "18px" }} />;
    case "loading":
      return <img src={HourGlassIcon} style={{ marginRight: "18px" }} />;
    default:
      break;
  }
};

const Box = (item) => {
  const [{ opacity }, drag] = useDrag({
    item,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });
  return (
    <BoxContainer ref={drag} theme={{ isLastChild: item.isLastChild, opacity }} className="box">
      <div style={{ width: "32px", float: "left" }}>{renderIcon(item.type)}</div>
      {item.title}
    </BoxContainer>
  );
};

export default Box;
