import * as React from "react";
import styled from "styled-components";

export interface INoteProps {
  onClick: any
  isSelected?: boolean
}

const Icon = styled.svg`
  width: 18px;
  height: 18px;
  stroke: ${(props) => (props.theme.isSelected ? "#ea895c" : "#a8abbd")};
  transition: all 0.1s;
  cursor: pointer;

  &:hover {
    stroke: #ea895c;
  }
`;

export default function Note(props: INoteProps) {
  return (
    <Icon onClick={props.onClick} theme={{ isSelected: props.isSelected }}>
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <path
          strokeLinecap="square"
          strokeWidth="1.3"
          d="M3.052 3.826L9.835 3.826M3.052 6.609L9.835 6.609M3.052 9.391L7.122 9.391M11.191 15.652H1.696c-.75 0-1.357-.622-1.357-1.391V1.739C.34.97.946.348 1.696.348h9.495c.75 0 1.357.622 1.357 1.391v12.522c0 .769-.607 1.391-1.357 1.391z"
        />
        <circle cx="6.443" cy="12.87" r="1" fill="#A8ABBD" fillRule="nonzero" />
      </g>
    </Icon>
  );
}
