import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { useSelector } from "react-redux";
import apiConfig from "../../../../apiConfig";
import { actionNotification } from "../../../../actions/themeActions";
import { Select as A8_SELECT } from "a8-uicomponents";

const luisEnvOptions = [
  { name: "Staging", value: "staging" },
  { name: "Production", value: "production" },
]

const nluProviderOptions = [
  { name: "Luis", value: "luis" },
]

const StyledConfiguration = styled.div`
  width: 100%;
  padding: 16px 24px;

  .config-title {
    color: #2f3245;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
    margin-top: 21px;
  }

  .config-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-top: 20px;
  }

  .ant-row.selected {
    height: auto !important;
  }

  .ant-row.selected .ant-col.text {
    padding: 6px 12px !important;
    font-size: 14px !important;
    color: #212332;
  }

  div[data-conf-disabled=true] .ant-row.selected .ant-col.text {
    color: #21233280;
  }

  .config-label {
    display: block;
    color: #6e747c;
    font-size: 13px;
  }


  .config-error {
    color: red;
    font-size: 12px;
    display: block;
    margin-top: 6px;
  }

  div[data-conf-disabled=true] {
    cursor: not-allowed;
 }

  div[data-conf-disabled=true] .ant-row.selected {
    pointer-events: none;
    border: 1px solid #7f90af50;
  }

  div[data-conf-disabled=false] .ant-row.selected {
    border: 1px solid #7f90af;
  }

  .anticon-down svg, .anticon-up svg {
    position: relative !important;
    top: -6px !important;
    right: 10px !important;
    width: 12px !important;
    height: 12px !important;
  }
}
`;

const StyledHeader = styled.header`
  display: flex;
  align-items: start;
  justify-content: space-between;

  .config-breadcrumb {
    color: #010f34;
    font-size: 12px;
    margin: 0;
    margin-bottom: 8px;
  }

  .config-header-title {
    color: #1d7aff;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .config-actions {
    display: flex;
    gap: 16px;
  }

  .config-btn {
    border-radius: 6px;
    padding: 4px 16px;
    border: none;
    cursor: pointer;
  }

  .config-save-btn {
    background: #1d7aff;
    color: white;
  }

  .config-cancel-btn {
    border: 1px solid #6e747c;
    color: #6e747c;
    background: white;
  }
`;

const labelMap = {
  provider: "Provider",
  appId: "App ID",
  programmaticKey: "Programmatic Key",
  appSecret: "App Secret",
  appRegion: "App Region",
  spellcheckerApikey: "Spellchecker Api Key",
  intentScore: "Intent Score",
  luisEnvironment: "Luis Environment"
};

type Props = {
  botHandle: string;
  initialValue: Record<string, string>
};

export function NLUConfiguration(props: Props) {
  const { botHandle, initialValue = {} } = props
  const auth = useSelector((state: any) => state.auth);
  const [canEdit, setCanEdit] = useState(false);
  const [config, setConfig] = useState({
    provider: initialValue.provider,
    appId: initialValue.appId,
    programmaticKey: initialValue.programmaticKey,
    appSecret: initialValue.appSecret,
    appRegion: initialValue.appRegion,
    spellcheckerApikey: initialValue.spellcheckerApikey,
    intentScore: initialValue.intentScore,
    luisEnvironment: initialValue.luisEnvironment,
  });

  const [errors, setErrors] = useState({
    provider: "",
    appId: "",
    programmaticKey: "",
    appSecret: "",
    appRegion: "",
    spellcheckerApikey: "",
    intentScore: "",
    luisEnvironment: ""
  });

  const selectConfig = {
    defaultValue: [config.luisEnvironment],
    optionValue: "value",
    isSearch: false,
    title: "name",
    multiSelect: false,
    primaryColor: null,
  };

  const selectProvider = {
    defaultValue: [config.provider],
    optionValue: "value",
    isSearch: false,
    title: "name",
    multiSelect: false,
    primaryColor: null,
  };

  function onChange(name: string, value: string) {
    setConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  function changeProvider(value: any) {
    const provider = value[0]
    if (!provider) {
      setConfig({
        provider: undefined,
        appId: undefined,
        programmaticKey: undefined,
        appSecret: undefined,
        appRegion: undefined,
        spellcheckerApikey: undefined,
        intentScore: undefined,
        luisEnvironment: undefined,
      })
      setErrors({
        provider: "",
        appId: "",
        programmaticKey: "",
        appSecret: "",
        appRegion: "",
        spellcheckerApikey: "",
        intentScore: "",
        luisEnvironment: "",
      })
    } else {
      setConfig(prev => ({...prev, provider: provider}))
    }
  }

  async function handleSave() {
    const errors: Record<string, string> = {};
    if (config.provider === "luis") {
      Object.entries(config).forEach(([key, value]) => {
        if (!value) {
          errors[key] = `Enter ${labelMap[key]}`;
        } else if (typeof value === "string" && !value.trim().length) {
          errors[key] = `Enter ${labelMap[key]}`;
        } else if (key === "intentScore") {
          const num = typeof value === "number" ? value : Number(value.trim())
          if(isNaN(num) || num < 1 || num > 100) {
            errors[key] = `Enter valid ${labelMap[key]}`;
          }
        } else if (key === "luisEnvironment" && !(value === "staging" || value === "production")) {
          errors[key] = `Enter valid ${labelMap[key]}`;
        }
      });
    }
    // if we have no errors
    if (!Object.keys(errors).length) {
      const options = {
        method: "POST",
        data: config,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.info.accessToken}`,
        },
      };
      try {
        const path = `${apiConfig.baseUrl}${apiConfig.org}${apiConfig.v1}${apiConfig.nluConfiguration}`;
        await axios.put(
          path.replace("{botHandle}", botHandle),
          { ...config, intentScore: parseInt(config.intentScore) || undefined },
          options
        );
        setCanEdit(false)
        actionNotification("success", "NLU Configuration", `Configuration saved successfully`);
      } catch (err) {
        actionNotification("error", "NLU Configuration", `Failed to save`);
      }
    } else {
      setErrors((prev) => ({ ...prev, ...errors }));
    }
  }

  return (
    <StyledConfiguration>
      <StyledHeader>
        <div>
          <p className="config-breadcrumb">{`General > Configuration`}</p>
          <p className="config-header-title">Configuration</p>
        </div>
        <div className="config-actions">
          {canEdit ? (
            <>
              <button className="config-btn config-cancel-btn" onClick={() => {
                setConfig({
                  provider: initialValue.provider,
                  appId: initialValue.appId,
                  programmaticKey: initialValue.programmaticKey,
                  appSecret: initialValue.appSecret,
                  appRegion: initialValue.appRegion,
                  spellcheckerApikey: initialValue.spellcheckerApikey,
                  intentScore: initialValue.intentScore,
                  luisEnvironment: initialValue.luisEnvironment,
                })
                setCanEdit(false)
              }}>
                Cancel
              </button>
              <button className="config-btn config-save-btn" onClick={handleSave}>
                Save
              </button>
            </>
          ) : (
            <button className="config-btn config-save-btn" onClick={() => setCanEdit(true)}>
              Edit
            </button>
          )}
        </div>
      </StyledHeader>

      <hr style={{ borderTop: "1px solid #D7D9E5", margin: 0, marginTop: 6 }} />

      <p className="config-title">Setup Your App: Key & Configuration Details</p>

      <div className="config-list">
        <div data-conf-disabled={!canEdit}>
          <label className="config-label">{labelMap.provider}</label>
          <A8_SELECT
            data={nluProviderOptions}
            options={selectProvider}
            onChange={changeProvider}
          ></A8_SELECT>
          {errors.provider && <span className="config-error">{errors.provider}</span>}
        </div>
        {config.provider == "luis" && <>
          <Input
            onChange={onChange}
            value={config.appId}
            error={errors.appId}
            disabled={!canEdit}
            id="appId"
            label={labelMap["appId"]}
          />
          <Input
            onChange={onChange}
            value={config.programmaticKey}
            error={errors.programmaticKey}
            disabled={!canEdit}
            id="programmaticKey"
            label={labelMap["programmaticKey"]}
          />
          <Input
            value={config.appSecret}
            error={errors.appSecret}
            disabled={!canEdit}
            id="appSecret"
            label={labelMap["appSecret"]}
            onChange={onChange}
          />
          <Input
            value={config.appRegion}
            error={errors.appRegion}
            disabled={!canEdit}
            id="appRegion"
            label={labelMap["appRegion"]}
            onChange={onChange}
          />
          <Input
            value={config.spellcheckerApikey}
            error={errors.spellcheckerApikey}
            disabled={!canEdit}
            id="spellcheckerApikey"
            label={labelMap["spellcheckerApikey"]}
            onChange={onChange}
          />
          <Input
            type="number"
            onChange={onChange}
            value={config.intentScore}
            error={errors.intentScore}
            disabled={!canEdit}
            id="intentScore"
            label={labelMap["intentScore"]}
          />
          <div data-conf-disabled={!canEdit}>
            <label className="config-label">{labelMap.luisEnvironment + " *"}</label>
            <A8_SELECT
              data={luisEnvOptions}
              options={selectConfig}
              onChange={(value: any) => {
                setConfig(prev => ({...prev, luisEnvironment: value[0]}))
                setErrors(prev => ({...prev, luisEnvironment: ""}))
              }}
            ></A8_SELECT>
            {errors.luisEnvironment && <span className="config-error">{errors.luisEnvironment}</span>}
          </div>
        </>}
      </div>

    </StyledConfiguration>
  );
}

type InputProps = {
  id: string;
  label: string;
  required?: boolean;
  disabled: boolean;
  onChange: (name: string, value: string) => void;
  value: string;
  type?: "text" | "number";
  error: string;
};

const StyledInput = styled.div`
  .config-input {
    border-radius: 6px;
    border: 1px solid #7f90af;
    background: #fff;
    width: 100%;
    padding: 6px 12px;
    color: #212332;
    font-size: 14px;
    font-weight: 500;
  }

  .config-input:focus {
    outline: none;
  }

  .config-input[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function Input(props: InputProps) {
  const { id, label, required = true, disabled, value, onChange, type = "text", error } = props;
  return (
    <StyledInput>
      <label className="config-label" htmlFor={id}>{`${label} ${" "} ${required ? "*" : ""}`}</label>
      <input
        value={value}
        onChange={(e) => onChange(id, e.target.value)}
        className="config-input"
        id={id}
        type={type}
        placeholder={`Enter ${label}`}
        disabled={disabled}
      />
      {error && <span className="config-error">{error}</span>}
    </StyledInput>
  );
}
