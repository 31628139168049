import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import '../../assets/sass/resources.sass';
import ResourceSaveDetails from "./ResourceSaveDetails";
import FAQ from "./faq";
import QuickResponse from './quickResponse';

class Resources extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    onSubmitHandler = (event: any) => {
        event.preventDefault();
        this.props.sign(this.state);
    }

    async componentDidUpdate() {
    }

    render() {
        let headerName = this.props.ui.activeRootNav
        let tabName = this.props.ui.activeRootTab
        return (
            <div className="resources" >
                <Row>
                    <Col span={24}>
                        {headerName == "resources" && tabName == "quickresponse" && <QuickResponse></QuickResponse>}
                        {headerName == "resources" && tabName == "faq" && <FAQ></FAQ>}
                    </Col>
                </Row>
                <ResourceSaveDetails></ResourceSaveDetails>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("Resources mapStateToProps: ", state)
    return {
        auth: state.auth,
        ui: state.ui
    }
}

export default connect(mapStateToProps, {})(Resources);
