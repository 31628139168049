import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ThemeDesigner from "../general/themeDesigner";
import { useSelector, useDispatch } from "react-redux";
import { getAgents } from "../../../actions/agentsAction";
import fbIcon from "../../../assets/images/fb.jpg";
import gupshupIcon from "../../../assets/images/gupshup.jpg";
import twillioIcon from "../../../assets/images/twilio.jpg";
import whatsappIcon from "../../../assets/images/whatsapp.jpg";
import twitterIcon from "../../../assets/images/twitter.svg";
import moment from "moment";

export interface IBotsProps { }
type Agent = {
  bot: string;
  botHandle: string;
  name: string;
  _id: string;
  channel: any[];
  lastTouched: any;
  configuration: any;
};

export const SelectedBotContext = React.createContext({
  selectedBot: "",
  setSelectedBot: (arg: string) => { },
  selectedBotId: "",
  setSelectedBotId: (arg: string) => { },
  selectedBotName: "",
  setSelectedBotName: (arg: string) => { },
  botTheme: "",
  setBotTheme: (arg: any) => { },
  bots: [],
});
const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
`;
const BotContainer = styled.div`
  position: relative;
  width: 235px;
  height: 135px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px #d7d9e5;
  background-color: #ffffff;
  padding: 15px 18px;
  margin: 0 16px 16px 0;
`;
const Label = styled.div`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  text-transform: uppercase;
  a {
    text-decoration: none;
  }
`;
const IconContainer = styled.div`
  position: absolute;
  bottom: 8px;
`;
const LastTouched = styled.p`
  font-family: Muli;
  font-size: 10px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #7f90af;
`;
const getChannelIcon = (channelName: string) => {
  switch (channelName) {
    case "facebook":
      return <img src={fbIcon} alt={channelName} style={{ marginRight: "8px", width: "30px" }} />;
    case "gupshup":
      return <img src={gupshupIcon} alt={channelName} style={{ marginRight: "8px", width: "30px" }} />;
    case "whatsapp":
      return <img src={whatsappIcon} alt={channelName} style={{ marginRight: "8px", width: "30px" }} />;
    case "twillio":
      return <img src={twillioIcon} alt={channelName} style={{ marginRight: "8px", width: "30px" }} />;
    case "twitter":
      return <img src={twitterIcon} alt={channelName} style={{ marginRight: "8px", width: "30px" }} />;
    default:
      return null;
  }
};
export default function Bots(props: IBotsProps) {
  const [selectedBot, setSelectedBot] = React.useState("");
  const [selectedBotId, setSelectedBotId] = React.useState("");
  const [selectedBotName, setSelectedBotName] = React.useState("");
  const [botTheme, setBotTheme] = React.useState("");
  const [bots, setBots] = React.useState([]);
  const agents = useSelector((state: any) => state.agent.agents);
  const dispatch = useDispatch();
  const fetchAgents = React.useCallback(() => dispatch(getAgents()), [dispatch]);
  React.useEffect(() => {
    if (!agents.length) {
      fetchAgents();
    } else {
      const savedBots = agents.filter((agent: Agent) => agent.bot && Object.keys(agent.bot).length);

      setBots(savedBots);
    }
  }, [agents]);

  return (
    <SelectedBotContext.Provider
      value={{
        selectedBot,
        selectedBotId,
        setSelectedBotId,
        setSelectedBot,
        selectedBotName,
        setSelectedBotName,
        botTheme,
        setBotTheme,
        bots
      }}
    >
      {selectedBot === "" ? (
        <>
          <div style={{ height: window.innerHeight - 120 + "px", overflowY: "scroll" }}>
            <Container>
              {bots.map((bot: Agent) => {
                return <BotContainer
                  key={bot._id}
                  onClick={() => {
                    setSelectedBot(bot.botHandle);
                    setSelectedBotId(bot._id);
                    setSelectedBotName(bot.name);
                    setBotTheme(bot.configuration.themeV2);
                  }}
                >
                  <Label>{bot.name}</Label>
                  <LastTouched>Last Edited {moment(bot.lastTouched * 1000).format("DD-MM-YYYY h:mm a")}</LastTouched>
                  <IconContainer>
                    {bot.channel && Object.keys(bot.channel).map((item) => getChannelIcon(item))}
                  </IconContainer>
                </BotContainer>
              })
              }
            </Container>
          </div>
        </>
      ) : (
        <ThemeDesigner />
      )}
    </SelectedBotContext.Provider>
  );
}
