import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import { clearError, signOut } from "../../actions/taskAction";
import "../../assets/sass/error.sass";
import { disconnect } from "../../helpers/mqttService";
import { clearSession } from "../../utils/helpers";

const Error = (props) => {
  const [state, setState] = useState({
    visible: false,
    title: "",
    content: null,
  });

  const dispatch = useDispatch();
  const { chat, agent, group, auth } = useSelector((state: any) => state);

  const handleLogoutSession = () => {
    let userInfo = localStorage.getItem("userInfo");
    if (!Boolean(userInfo)) {
      disconnect();
      handleOk();
    }
  };

  const handleOk = () => {
    dispatch(clearError());
    dispatch(signOut());
    setState((prevState) => ({ ...prevState, visible: false }));
    props.history.push('/signin')
  };

  useEffect(() => {
    let error: any = null;

    if (chat.error || agent.error || group.error || auth.error) {
      if (chat.error) {
        error = chat.error;
      } else if (agent.error) {
        error = agent.error;
      } else if (group.error) {
        error = group.error;
      } else if (auth.error) {
        error = auth.error;
      }
      console.error(`Error: ${error}`);
    }

    if (!state.visible && error && (error.code === "access_denied" || error.status == 401)) {
      props.history.push("/signin")
      disconnect();
      clearSession();

      setState({
        visible: true,
        title: "SESSION EXPIRED",
        content: (
          <div>
            <div className="content">Your session is expired, Please login again</div>
            <div className="action">
              <Button className="btn-primary" onClick={handleOk}>
                OK
              </Button>{" "}
            </div>
          </div>
        ),
      });
    }

    if(error === null) {
      setState(prevState => ({ ...prevState, visible: false }))
    }

  }, [chat.error, agent.error, group.error, auth.error])

  return (
    <Modal
      className="error"
      title={state.title}
      visible={state.visible}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <p className="error-content">{state.content}</p>
    </Modal>
  );
};

export default withRouter(Error);
