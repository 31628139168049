import * as React from "react";
import { Modal } from "antd";
import "../../assets/sass/modal.scss";
import styled from "styled-components";

interface IModalProps {
  title: string;
  open: boolean;
  onClose: (e: any) => void;
  content: string;
  children: React.ReactNode;
}

const ModalContent = styled.div`
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.72;
  letter-spacing: normal;
  text-align: left;
  color: #2b2b2b;
  margin-bottom: 60px;
`;

const ModalComponent: React.FunctionComponent<IModalProps> = (props: IModalProps) => {
  const { children, content, open, onClose, title } = props;
  return (
    <Modal
      title={title}
      width="439px"
      className="a8-console-modal-container"
      visible={open}
      onCancel={onClose}
      footer={null}
    >
      <ModalContent className="chat-modal-content" data-testid="modalContent">
        {content}
      </ModalContent>
      <div className="action">{children}</div>
    </Modal>
  );
};

export default ModalComponent;
