import * as React from "react";
import axios from "axios";

export interface IVideoProps {
  url: string;
}

interface IInfo {
  html: string;
}

export default function Video(props: IVideoProps) {
  const [info, setInfo] = React.useState<IInfo>({
    html: "",
  });
  const { url } = props;
  React.useEffect(() => {
    const getMetaData = async () => {
      const resp = await axios.get(`https://noembed.com/embed?url=${url}`);
      setInfo(resp.data);
    };
  }, []);
  return <div>{info.html && <div>{info.html}</div>}</div>;
}
