import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { FiUsers } from "react-icons/fi";
import HTMLReactParser from "html-react-parser";
import { IState } from "../../../settings/general/themeDesigner";
import ChannelIcon from "../../../../assets/images/info-channel.svg";
import BrowserIcon from "../../../../assets/images/info-browser.svg";
import OsIcon from "../../../../assets/images/info-os.svg";
import LinuxIcon from "../../../../assets/images/ico-linux.svg";
import WindowsIcon from "../../../../assets/images/ico-windows.svg";
import DomainIcon from "../../../../assets/images/info-domain.svg";
import FreshdeskIcon from "../../../../assets/images/img-logo-freshdesk.png";
import agentIcon from "../../../../assets/images/agent-icon.svg";
import botIcon from "../../../../assets/images/a8chat_icon.svg";
import Accordian from "../../../common/Accordian";
import { toCustomDateTimeFormat } from "../../../../helpers/util";
import { loadClientConversation } from "../../../../actions/uiAction";
import { getChatsByClientId } from "../../../../actions/chatsAction";
import AttachmentIcon from "../../../../assets/images/attachments.svg";
import { CreditCardOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { getRandomColor } from "../../../../utils/randomColor";

export interface IInfoProps {
  clientConversation: any
}

const Container = styled.div`
  padding:  ${(props) => props.theme.clientConversation ? "0px" : "20px"};;
  white-space: nowrap;
  background-color: rgba(232, 233, 241, 0.5);
  transition: width 0.5s;
  transition-delay: ${(props) => props.theme.clientConversation ? "0s" : "0.5s"};
  @media screen and (min-width: 900px) and (max-width: 1400px) {
    width: ${(props) => props.theme.clientConversation ? "0px" : "300px"};
  }
  @media screen and (min-width: 1400px){
    width: ${(props) => props.theme.clientConversation ? "0px" : "350px"};  
  }
`;

const Wrapper = styled.div`
  background: white;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 2px 6px 0 rgba(168, 171, 189, 0.3);
  border: solid 1px rgba(168, 171, 189, 0.3);
`;

const ChatInfo = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 82%;
  width: 98%;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

const NameWrapper = styled.div`
  padding: 20px 0 8px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:38px;
`;

const Name = styled.div`
 text-transform: capitalize;
  font-size: 18px;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  color: #212332;
`;

const EmailWraper = styled.div`
 padding: 0px 5px 5px 20px;
 display: flex;
 align-items: center;
 justify-content: space-between;
`;

const Email = styled.div`
  width: 3px;
  font-size: 12px;
  align-items: center;
  height: 20px;
  padding-left: 1px;
`;


const OnlineStatus = styled.div`
  width: 63px;
  height: 30px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: rgba(70, 178, 117, 0.2);
  color: #46b275;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const IconWrapper = styled.div`
//   padding: 0 20px;
//   display: flex;
//   font-size: 14px;
//   font-weight: 600;
//   color: #a8abbd;
//   margin: 11px;
//   margin-bottom: 1px;
//   & .icon {
//     margin-right: 12px;
//     size: 10px
//   }
//   & .os{
//     padding-top: 4px;
//     margin-bottom: 3px;
//   }
//   & .change{
//     margin-bottom: 3px;
//   }
// `;
const IconicWraper = styled.div`
  display: flex;
  padding-left: 21px;
  font-size: 13px;
  font-weight: 600;
  color: #a8abbd;
  padding-top: 2px;
`;
const Iconic = styled.div`
  padding-left:10px;
  padding-top: 2px;
`;

const LastSeenWrapper = styled.div`
  padding: 6px 0 10px 2px;
  font-size: 12px;
  color: #a8abbd;
  border-bottom: 1px solid rgba(168, 171, 189, 0.4);
  margin: 0 20px;
  justify-content: center;
`;

const AccordianWrapper = styled.div`
  padding: 15px 20px 0;
  margin-bottom:1px;
`;

const Textarea = styled.textarea`
  height: 159px;
  border-radius: 5px;
  border: solid 1px #e8e9f1;
  outline: none;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  padding: 15px;
  color: #a8abbd;
  width: 100%;
  outline: none;
`;

const NoteButton = styled.button`
  border: none;
  background: white;
  color: #1d7aff;
  border: 1px solid #1d7aff;
  width: 100%;
  border-radius: 6px;
  font-size: 12px;
  height: 30px;
  font-weight: bold;
`;

const ParticipantContainer = styled.p`
  display: flex;
  align-items: center;
`;

const UserId = styled.p`
  font-size: 14px;
  padding: 0 20px;
  height: 10px;
`;

const Circle = styled.div`
  color: ${(props) => props.theme.color};
  background: ${(props) => props.theme.color}24;
  border: ${(props) => `2px solid ${props.theme.color}`};
  width: ${(props) => props.theme.width};
  height: ${(props) => props.theme.height};
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Message = styled.div`
    word-break: break-all;
    background: ${(props) => props.theme.isUser ? "#E8E9F1" : "#FBEBE3"} ;
    padding: 7px 10px;
    height: 58px;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
`;

const More = styled.div`
  margin-left: -20px;
  border-radius: 35px;
  border:  ${(props) => `2px solid ${props.theme.bColor}`};
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  background: #FFFFFFCF;
`;

const GroupName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: bold;
    border-radius: 15px;
    height: 32px;
    display: flex;
    align-items: center;
    width: 80px;
    justify-content: center;
    label {
      text-align: center;
      padding-top: 4px;
      font-weight: 600;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
`;

const array2Objects = (array = [], field) => {
  return array.reduce((a, c) => {
    a[c[field]] = c;
    return a;
  }, {});
};

export default function Info(props: IInfoProps) {
  const [cusFields, setCusFields] = useState([]);
  const [clientChats, setClientChats] = useState([]);
  const dispatch = useDispatch();
  const { primaryColor: highlightAgent, secondaryColor } = useSelector(
    (state: IState) => state.ui.theme
  );
  const { selectedMonitorChatId, unMonitoredChats, monitoredChats } = useSelector((state: any) => state.chat);
  const chats = { ...unMonitoredChats, ...monitoredChats };
  const clientData = selectedMonitorChatId && chats[selectedMonitorChatId] && chats[selectedMonitorChatId].clientData;
  const { group: groupReducer, agent: agentReducer } = useSelector((state: any) => state);
  const groups = array2Objects(groupReducer.groups, "_id");
  const agents = array2Objects(agentReducer.agents, "_id");

  const handleClientConversation = useCallback((payload: any) => dispatch(loadClientConversation(payload)), [dispatch]);

  const getChatListByClientId = async (clientId) => {
    let resp: any = await dispatch(getChatsByClientId(clientId));
    let filterClientChats = [];
    for (let index in resp) {
      if (selectedMonitorChatId && selectedMonitorChatId !== resp[index]._id && resp[index].messages.length > 0) {
        filterClientChats.push(resp[index]);
      }
    }
    setClientChats(filterClientChats);
  }


  useEffect(() => {
    setCusFields([]);
    if (clientData && clientData.userInfo && clientData.userInfo.customFields) {
      loopingObj(clientData.userInfo.customFields);
    }
  }, [clientData && clientData.userInfo && clientData.userInfo.customFields, secondaryColor]);

  const createCusField = (key, value) => {
    let isLink = new RegExp("^(http|https)://", "i");
    let newField = [];
    newField.push(<div style={{
      background: secondaryColor + "24",
      padding: "10px 10px",
      borderRadius: "10px",
      margin: "10px 0px"
    }}>
      <div style={{
        color: secondaryColor,
        fontWeight: 800,
        textTransform: "capitalize",
        textOverflow: "ellipsis",
        overflow: "hidden"
      }}>{key}</div>
      {isLink.test(value) ? <a target="_bank" style={{ wordBreak: "break-all", whiteSpace: "break-spaces", textDecoration: "underline" }} href={value}>{value}</a> : <div style={{ wordBreak: "break-all", whiteSpace: "break-spaces" }}>{value}</div>}
    </div>)
    setCusFields(cusFields => [...cusFields, ...newField])
  }

  const loopingObj = (customFields) => {
    for (let cf in customFields) {
      if (typeof customFields[cf] === "string" || typeof customFields[cf] === "number") {
        createCusField(cf, customFields[cf]);
      } else if (typeof customFields[cf] === "object") {
        loopingObj(customFields[cf])
      }
    }
  }

  useEffect(() => {
    if (chats && selectedMonitorChatId && chats[selectedMonitorChatId] && chats[selectedMonitorChatId].clientId) {
      getChatListByClientId(chats[selectedMonitorChatId].clientId);
    } else {
      setClientChats([]);
    }
  }, [selectedMonitorChatId, chats[selectedMonitorChatId] && chats[selectedMonitorChatId].clientId])

  let userInfo, tags, groupId, partipantsId, handlingAgent, lastTouched;
  if (chats && selectedMonitorChatId && Object.keys(chats).length > 0 && chats[selectedMonitorChatId]) {
    if (chats[selectedMonitorChatId].groupId) {
      groupId = chats[selectedMonitorChatId].groupId;
    }
    if (chats[selectedMonitorChatId].clientData) {
      if (chats[selectedMonitorChatId].clientData.userInfo) {
        userInfo = chats[selectedMonitorChatId].clientData.userInfo;
      }
      if (chats[selectedMonitorChatId].tags) {
        tags = chats[selectedMonitorChatId].tags;
      }
      if (chats[selectedMonitorChatId].participants) {
        partipantsId = chats[selectedMonitorChatId].participants;
      }
      if (chats[selectedMonitorChatId].handlingAgent) {
        handlingAgent = chats[selectedMonitorChatId].handlingAgent;
      }
      if (chats[selectedMonitorChatId].lastTouched) {
        lastTouched = chats[selectedMonitorChatId].lastTouched;
      }
    }
  }

  let deviceInfo = null;
  let fromName = null;
  if (chats && selectedMonitorChatId && Object.keys(chats).length > 0 && chats[selectedMonitorChatId].clientData) {
    deviceInfo = chats[selectedMonitorChatId].clientData.deviceInfo;
    fromName = chats[selectedMonitorChatId].clientData.fromName;
  }

  const getMessage = (type, msg) => {
    if (type == "simpleChoice" || type == "text") {
      let text = msg && msg.text || (msg.msg && msg.msg.text) || "Rich Text";
      return HTMLReactParser(text.substr(0, 70));
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CreditCardOutlined />&nbsp;&nbsp;<span>Rich Text</span>
        </div>
      );
    }
  };

  const loadParticipants = (participants) => {
    let pCount = 0;
    let agentsList = [];
    let isMore = false;
    let title = [];
    let uniqueParticipants = [];
    uniqueParticipants = participants.filter(function (item, pos) {
      return participants.indexOf(item) == pos;
    })
    uniqueParticipants.forEach((pId) => {
      pCount++;
      let initial = agents[pId] && agents[pId].name.charAt(0).toUpperCase();
      let name = agents[pId] && agents[pId].name.charAt(0).toUpperCase() + agents[pId].name.slice(1);
      if (pCount >= 3) {
        isMore = true;
        title.push(<div key={0} style={{ display: "flex", flexDirection: "row", alignItems: "center", margin: "10px" }}>
          <Circle theme={{ color: getRandomColor(pId), width: "22px", height: "22px", fontSize: "10px" }}>{initial}</Circle>
          <div style={{ paddingLeft: "4px", fontSize: "14px" }}>{name}</div>
        </div>);
      } else if (!isMore) {
        agentsList.push(<div key={pId} style={{ marginLeft: "-20px", background: "#FFFFFFCF", borderRadius: "35px" }} >
          <Tooltip placement="top" title={<div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Circle theme={{ color: getRandomColor(pId), width: "22px", height: "22px", fontSize: "10px" }}>{initial}</Circle>
            <div style={{ paddingLeft: "4px", fontSize: "14px" }}>{name}</div>
          </div>}>
            <Circle theme={{ color: getRandomColor(pId), width: "35px", height: "35px" }}>{initial}</Circle></Tooltip>
        </div >)
      }
    });
    if (isMore) {
      agentsList.push(<div key={0}><Tooltip placement="top" title={<div style={{ width: "100%" }}>{title}</div>}><More theme={{ bColor: highlightAgent }}>+{(uniqueParticipants.length - 2)}</More></Tooltip></div>);
    }
    return agentsList;
  }

  const getOsIcon = () => {
    const { os } = deviceInfo;
    switch (os && os.toLowerCase()) {
      case "unix":
        return LinuxIcon;
      case "windows":
        return WindowsIcon;
      default:
        return OsIcon;
    }
  };

  return (
    <Container theme={{ clientConversation: props.clientConversation }} >
      <Wrapper>
        {userInfo && (
          <>
            <NameWrapper>
              <Name title={fromName}>{fromName}</Name>
              <OnlineStatus>Active</OnlineStatus>
            </NameWrapper>
            <UserId>{userInfo.userId}</UserId>
            {/* <EmailWraper>
              <Email>{userInfo.email}</Email>
            </EmailWraper> */}
            <ChatInfo>
              {deviceInfo && (
                <>
                  <IconicWraper>
                    <img src={ChannelIcon} />
                    <Iconic>
                      <span className="img">{deviceInfo.channel}</span>
                    </Iconic>
                  </IconicWraper>
                  <IconicWraper>
                    <img src={BrowserIcon} />
                    <Iconic>
                      <span>{deviceInfo.browser && deviceInfo.browser.name}</span>
                    </Iconic>
                  </IconicWraper>
                  <IconicWraper>
                    <img src={getOsIcon()} />
                    <Iconic>
                      <span className="img">{deviceInfo.os}</span>
                    </Iconic>
                  </IconicWraper>
                  <IconicWraper><img src={DomainIcon} className="icon" alt="domain" />
                    <Iconic>
                      <a className="img" target="blank"
                        href={deviceInfo.browser && deviceInfo.browser && deviceInfo.browser.domain}> Show Origin </a>
                    </Iconic>
                  </IconicWraper>
                </>
              )}
              {lastTouched && <LastSeenWrapper>Last Seen: {toCustomDateTimeFormat(lastTouched)}</LastSeenWrapper>}
              <AccordianWrapper>
                <Accordian title="Participants">
                  {partipantsId.map((item: any, index: number) => (
                    <ParticipantContainer key={index}>
                      {agents && agents[item] && <img width="24px" height="auto" src={agents[item].isVirtualAgent ? botIcon : agentIcon} alt="Participant Icon" />}
                      &nbsp;
                      <span style={{ fontWeight: handlingAgent == item ? 900 : "inherit", color: handlingAgent == item ? highlightAgent : "#000" }} >{agents[item] && agents[item].name}</span>
                    </ParticipantContainer>
                  ))}
                </Accordian>
              </AccordianWrapper>
              {groupId && (
                <AccordianWrapper>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FiUsers />
                    <div title={groups && groups[groupId] && groups[groupId].name} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      &nbsp;{groups && groups[groupId] && groups[groupId].name}
                    </div>
                  </div>
                </AccordianWrapper>
              )}
              <AccordianWrapper>
                <Accordian title="Custom Fields">
                  <div>
                    {cusFields.length > 0 && cusFields || <div>No Details Available</div>}
                  </div>
                </Accordian>
              </AccordianWrapper>
              <AccordianWrapper>
                <Accordian title="Chat History">
                  <div>
                    {clientChats && clientChats.length > 0 ? clientChats.map((chat, i) => {
                      return <div style={{ cursor: "pointer" }} key={chat._id} onClick={() => handleClientConversation(chat._id)}>
                        <div style={{ fontWeight: "bold", fontSize: "12px", color: "#A8ABBD", marginBottom: "10px" }}>{chat.messages[0].fromName}</div>
                        <Message theme={{ isUser: chat.messages[0].fromId.includes("u/") }}>
                          {getMessage(chat.messages[0].msgType, chat.messages[0].msg)}
                        </Message>
                        <div style={{ fontWeight: "bold", fontSize: "12px", color: "#A8ABBD", marginTop: "10px", textAlign: "right" }}>
                          {toCustomDateTimeFormat(chat.messages[0].sentAt)}
                        </div>
                        <div style={{ marginTop: "14px" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <GroupName style={{ background: highlightAgent + "33", color: highlightAgent }}>
                              <label title={chat.groupId && groups && groups[chat.groupId] && groups[chat.groupId].name}>
                                {chat.groupId && groups && groups[chat.groupId] && groups[chat.groupId].name}
                              </label>
                            </GroupName>
                            <div style={{ display: "flex" }}>
                              {loadParticipants(chat.participants)}
                            </div>
                          </div>
                        </div>
                        {clientChats.length - 1 > i && <div style={{ border: `1px solid ${secondaryColor}`, margin: "10px 0px" }}></div>}
                      </div>
                    }) : <div>No record found</div>}
                  </div>
                </Accordian>
              </AccordianWrapper>
            </ChatInfo>
          </>
        )}
      </Wrapper>
    </Container >
  );
}
