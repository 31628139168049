import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import "../../assets/sass/settings.sass";
// import SettingSaveDetail from "./SettingSaveDetail";
// import Agent from "./agents/Agent";
// import Group from "./groups/Group";
import General from "./general";
import Bots from "./bots";

class Settings extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight
    };
  }
  async componentDidUpdate() { }

  render() {
    let headerName = this.props.ui.activeRootNav;
    let tabName = this.props.ui.activeRootTab;
    return (
      <div>
        <div className="settings" >
          <Row>
            <Col span={24}>
              {headerName == "settings" && tabName == "general" && <General />}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {headerName == "settings" && tabName == "bots" && <Bots />}
              {/* {headerName == "admin" && tabName == "agents" && <Agent></Agent>}
            {headerName == "admin" && tabName == "groups" && <Group></Group>} */}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("Settings mapStateToProps: ", state)
  return {
    auth: state.auth,
    ui: state.ui,
  };
}

export default connect(mapStateToProps, {})(Settings);
