import * as React from "react";
import { Collapse, Select } from "antd";
import styled from "styled-components";
import GradientPickerIcon from "../../../../../../../../assets/icons/gradient.svg";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { IState } from "../../..";
import { ThemeContext } from "../../../../../themeDesigner";
import { TwitterPicker, ChromePicker } from "react-color";

const Panel = Collapse.Panel;

const { Option } = Select;

function callback(key) {
  console.log(key);
}

export interface ILabelProps { }
interface IColor {
  hex: string;
}

const Input = styled.input`
  width: 161px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const ColorContainer = styled.div`
  height: 59px;
  padding: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #d3daef;
  margin: 20px 0px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 14px;
  .text {
    flex: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const ColorPicker = styled.div`
  .chrome-picker {
    width: 100% !important;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 800;
`;

export default function LabelProperties(props: ILabelProps) {
  const { secondaryColor: outlineColor } = useSelector(
    (state: IState) => state.ui.theme
  );
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const [openTitlePicker, setOpenTitlePicker] = React.useState(false);
  const [openSubTitlePicker, setOpenSubTitlePicker] = React.useState(false);
  let {
    widget: {
      header: { title, subTitle },
    },
  } = themeProps;
  const [titleText, setTitleText] = React.useState(title.text);
  const [subTitleText, setSubTitleText] = React.useState(subTitle.text);

  const [colorTitle, setColorTitle] = React.useState(title.color.hex);
  const [colorSubTitle, setColorSubTitle] = React.useState(subTitle.color.hex);

  const handleTitleChange = (value: string) => {
    const updatedHeader = update(themeProps, {
      widget: {
        header: {
          title: {
            size: { $set: value },
          },
        },
      },
    });
    setThemeProps(updatedHeader);
  };
  const handleTitleColorChange = (color: any) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        header: {
          title: {
            color: {
              hex: { $set: color.hex },
            },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };
  const handleTitleInputBlur = (text: string) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        header: {
          title: {
            text: { $set: text },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };

  const handleSubTitleChange = (value: string) => {
    const updatedHeader = update(themeProps, {
      widget: {
        header: {
          subTitle: {
            size: { $set: value },
          },
        },
      },
    });
    setThemeProps(updatedHeader);
  };

  const handleSubTitleColorChange = (color: any) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        header: {
          subTitle: {
            color: {
              hex: { $set: color.hex },
            },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };

  const handleSubTitleInputBlur = (text: string) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        header: {
          subTitle: {
            text: { $set: text },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };

  return (
    <>
      <Collapse defaultActiveKey={["1"]} onChange={callback}>
        <Panel header="Label" key="1">
          <Title>Title</Title>
          <ColorContainer>
            <span className="text">Color</span>
            <DisplayColor
              theme={{ backgroundColor: colorTitle }}
              className="picker"
              onClick={() => setOpenTitlePicker(!openTitlePicker)}
            />

            <GradientPickerWrapper>
              <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
            </GradientPickerWrapper>
          </ColorContainer>
          {openTitlePicker && (
            <ColorPicker>
              <ChromePicker
                color={colorTitle}
                onChange={(color: IColor) => setColorTitle(color.hex)}
                onChangeComplete={handleTitleColorChange}
              />
            </ColorPicker>
          )}
          <DropdownWrapper>
            <span className="text">Size</span>

            <Select
              defaultValue={title.size}
              style={{ width: 161, height: 28 }}
              onChange={handleTitleChange}
              size="small"
            >
              <Option value="small">Small</Option>
              <Option value="medium">Medium</Option>
              <Option value="large">Large</Option>
            </Select>
          </DropdownWrapper>
          <InputContainer>
            <span className="text">Text</span>
            <Input
              theme={{ outlineColor }}
              placeholder="Hello! {username}"
              value={titleText}
              onChange={(e) => setTitleText(e.target.value)}
              onBlur={(e) => handleTitleInputBlur(e.target.value)}
            />
          </InputContainer>
          <Divider></Divider>
          <Title>Sub Title</Title>
          <ColorContainer>
            <span className="text">Color</span>
            <DisplayColor
              theme={{ backgroundColor: colorSubTitle }}
              className="picker"
              onClick={() => setOpenSubTitlePicker(!openSubTitlePicker)}
            />
            <GradientPickerWrapper>
              <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
            </GradientPickerWrapper>
          </ColorContainer>
          {openSubTitlePicker && (
            <ColorPicker>
              <ChromePicker
                color={colorSubTitle}
                onChange={(color: IColor) => setColorSubTitle(color.hex)}
                onChangeComplete={handleSubTitleColorChange}
              />
            </ColorPicker>
          )}
          <DropdownWrapper>
            <span className="text">Size</span>

            <Select
              defaultValue={title.size}
              style={{ width: 161, height: 28 }}
              onChange={handleSubTitleChange}
              size="small"
            >
              <Option value="small">Small</Option>
              <Option value="medium">Medium</Option>
              <Option value="large">Large</Option>
            </Select>
          </DropdownWrapper>
          <InputContainer>
            <span className="text">Text</span>
            <Input
              theme={{ outlineColor }}
              placeholder="Sub Title"
              value={subTitleText}
              onChange={(e) => setSubTitleText(e.target.value)}
              onBlur={(e) => handleSubTitleInputBlur(e.target.value)}
            />
          </InputContainer>
        </Panel>
      </Collapse>
    </>
  );
}
