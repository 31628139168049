import * as React from "react";
import { Collapse, Select } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import update from "immutability-helper";

import ImageUploadIcon from "../../../../../../../../assets/images/image-upload.svg";
import { ThemeContext } from "../../../../../themeDesigner";

const Panel = Collapse.Panel;

const { Option } = Select;

function callback(key) {
  console.log(key);
}

export interface IAvatarPropertiesProps {}
interface IState {
  ui: IUiTheme;
}

interface IUiTheme {
  theme: ITheme;
}
interface ITheme {
  primaryColor: string;
  secondaryColor: string;
}

const Container = styled.div`
  margin-top: 14px;
`;

const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;
  margin-bottom: ${props => props.theme.marginBottom ? props.theme.marginBottom : "14px"};

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const ImageUploadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;

  .text {
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const Button = styled.button`
  width: 89px;
  height: 22px;
  border: none;
  border-radius: 2px;
  background-color: #e5e5e5;
  font-family: Muli;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #7f90af;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
  .text {
    flex: 1;
  }
`;

export default function AvatarProperties(props: IAvatarPropertiesProps) {
  const { secondaryColor: outlineColor } = useSelector(
    (state: IState) => state.ui.theme
  );
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: {
      header: { avatar },
    },
  } = themeProps;
  const [avatarSize, setAvatarSize] = React.useState("");
  const [customHeight, setCustomHeight] = React.useState("");
  const [url, setUrl] = React.useState(avatar.icon);
  const handleChange = (value: string) => {
    if (value !== "custom") {
      const updatedAvatarProperties = update(themeProps, {
        widget: {
          header: {
            avatar: {
              size: { $set: value },
            },
          },
        },
      });
      setThemeProps(updatedAvatarProperties);
    } else {
      setAvatarSize(value);
    }
  };
  const handleInputBlur = (value: string) => {
    const updatedAvatarProperties = update(themeProps, {
      widget: {
        header: {
          avatar: {
            icon: { $set: value },
          },
        },
      },
    });
    setThemeProps(updatedAvatarProperties);
  };
  React.useEffect(() => {
    if (avatar.size) {
      setAvatarSize(avatar.size);
    }
  }, [avatar]);
  return (
    <Container>
      <Collapse defaultActiveKey={["1"]} onChange={callback}>
        <Panel header="Avatar" key="1">
          <Input
            theme={{ marginBottom: "14px", outlineColor }}
            placeholder="Image URL"
            value={url}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setUrl(e.target.value)
            }
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputBlur(e.target.value)
            }
          />
          <DropdownWrapper>
            <span className="text">Size</span>

            <Select
              defaultValue={avatar.size}
              style={{ width: 161, height: 28 }}
              onChange={handleChange}
              size="small"
              value={avatarSize}
            >
              <Option value="31px">Small</Option>
              <Option value="40px">Medium</Option>
              <Option value="50px">Large</Option>
              <Option value="custom">Custom</Option>
            </Select>
          </DropdownWrapper>
          {avatarSize === "custom" && (
            <InputContainer>
              <span className="text">Height</span>
              <Input
                theme={{ marginBottom: "0px", outlineColor }}
                placeholder="Custom Height"
                value={customHeight}
                onChange={(e) => setCustomHeight(e.target.value)}
                onBlur={(e) => handleChange(e.target.value)}
                width="161px"
              />
            </InputContainer>
          )}
          <ImageUploadWrapper>
            <span className="text">Image</span>
            <img src={ImageUploadIcon} alt={"upload"} />
            <Button>Upload Image</Button>
          </ImageUploadWrapper>
        </Panel>
      </Collapse>
    </Container>
  );
}
