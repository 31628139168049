import React from "react";
import { connect } from "react-redux";
import { Tabs, Row, Col } from "antd";
import { rootSelectedTab } from "../../actions/uiAction";
const { TabPane } = Tabs;
const SettingMenuList = ["groups", "agents"];
class SettingMenu extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentWillMount() {
    let activeRootTab = "agents";
    if (SettingMenuList.indexOf(this.props.ui.activeRootTab) > -1) {
      activeRootTab = this.props.ui.activeRootTab;
    }
    this.setState({
      selectedMenu: activeRootTab,
    });
    this.props.rootSelectedTab(activeRootTab);
  }

  onTabChange = (key) => {
    this.props.rootSelectedTab(key);
  };

  render() {
    return (
      <Row className="menu">
        <Col span="1" style={{ marginRight: "16px" }} className="title">
          ADMIN
        </Col>
        <Col span="19">
          <Tabs
            defaultActiveKey={this.state.selectedMenu}
            onChange={this.onTabChange}
          >
            <TabPane tab="Agents" key="agents"></TabPane>
            <TabPane tab="Groups" key="groups"></TabPane>
          </Tabs>
        </Col>
        <Col span="4"></Col>
      </Row>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("Setting Menu mapStateToProps: ", state);
  return {
    auth: state.auth,
    ui: state.ui,
  };
}

export default connect(mapStateToProps, { rootSelectedTab })(SettingMenu);
