import * as React from "react";
import sanitizeHtml from "sanitize-html";
// import { TextMessage, SingleChoice, Document, Picture } from "a8chat-uikit";
import TextMessage from "./TextMessage";
import Markdown from "markdown-it";
import styled from "styled-components";
import HTMLReactParser from "html-react-parser";
import { getFormVariant, toCustomDateTimeFormat, getMessages } from "../../../helpers/util";
import AdaptiveCard from "../live/components/adaptiveCard";
import Chart from "./Chart";
import { Modal } from "antd";
import AdaptiveCardResponse from "./AdativeCard/Response";
import DataTable from "./Datatable";
import CarouselComponent from "./Carousel";
import DocumentComponent from "./Document";
import Video from "./Video";
import CarouselResponse from "./CarouselResponse";
import CardChoiceResponse from "./CardChoiceResponse";
import _ from "lodash";
import { parseAdaptiveCard } from '../../../utils/helpers';
import { Form } from "./Form";
import { SimpleChoice, Autocomplete, CardChoice } from "./runtime";
import { useSelector } from "react-redux";

export const md = new Markdown({ html: true });

const ChatContainer = styled.div`
  padding: 16px;
  display: flex; 
  flex-direction: column; 
  gap: 8px;

  & blockquote {
    margin-bottom: unset;
  }

  & p {
    margin-bottom: unset;
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 8px;
  position: relative;
`;

const AdaptiveCardWrapper = styled.div`
  margin-bottom: 8px;
  position: relative;
  // max-width: 500px;
`;

const Timestamp = styled.p`
  margin-top: 4px;
  font-family: Muli;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a8abbd;
  text-align: ${(props) => (props.theme.isUser ? "left" : "right")};
`;

const HoverTimestamp = styled.div`
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #a8abbd;
  position: absolute;
  top: 6px;
  right: ${(props) => !props.theme.isUser && "10px"};
  left: ${(props) => props.theme.isUser && "10px"};
`;

const CircularSeparator = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #a8abbd;
`;
const HyperLinkContainer = styled.div`
  margin-bottom: 8px;
`;

export const PictureWidget = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: 33%;
  padding: 16px;
  box-shadow: 0 0 0 0 #e8e9f1;
  border-radius: 10px 10px 35px 10px;
  /* max-height: 300px; */
  background: ${(props) => (props.theme.isPrivate ? "#2b2b2b" : "#fbebe3")};
  display: flex;
  justify-content: center;

  .container {
    width: 100%;
    text-align: center;
  }

  img {
    max-height: 100%;
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  `;

export interface IChatMessagesProps {
  chats: any;
  chatId: string
}

const DisplayMessage = (props: any) => {
  const { message, chats, chatId } = props;
  const [openModal, setModalOpen] = React.useState(false);
  const [modalConfig, setModalConfig] = React.useState({
    image: false,
  });
  const [showHoverTimesamp, setHoverTimestamp] = React.useState(false);
  return (
    <div id={message.mId}>
      {renderMessages(
        chats,
        message,
        showHoverTimesamp,
        setHoverTimestamp,
        openModal,
        setModalOpen,
        modalConfig,
        setModalConfig,
        chatId,
      )}
    </div>
  );
};

export const isClean = (dirty: any) => {
  return sanitizeHtml(dirty, {
    allowedTags: [
      "b",
      "i",
      "em",
      "strong",
      "a",
      "input",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "p",
      "span",
      "code",
      "pre",
      "br",
      "ol",
      "li",
      "ul",
      "blockquote",
    ],
    selfClosing: ["img", "br", "hr", "area", "base", "basefont", "input", "link", "meta"],
    allowedAttributes: {
      a: ["href", "target"],
      input: ["type", "value", "onclick"],
    },
    allowedIframeHostnames: [],
    disallowedTagsMode: "escape",
  });
};

const renderMessages = (
  chats: any[],
  message: any,
  showHoverTimesamp: boolean,
  setHoverTimestamp: (arg: boolean) => void,
  openModal: boolean,
  setModalOpen: any,
  modalConfig: any,
  setModalConfig: any,
  assignChatId: string,
) => {
  const activeRootTab = useSelector((state: any) => state.ui.activeRootTab)
  const { msgType, msg, fromId, mId, sentAt, isPrivate } = message;
  const isUser = fromId.includes("u/");
  const logs = getMessages(assignChatId)
  const lastMsg = logs[logs.length - 1]

  switch (msgType) {
    case "adaptiveCardResponse":
      return (
        <div>
          <AdaptiveCardResponse msg={msg} isUser={isUser} />
        </div>
      );
    case "hyperLink":
      return (
        <TextWrapper
          key={mId}
          onMouseLeave={() => setHoverTimestamp(!showHoverTimesamp)}
          onMouseEnter={() => setHoverTimestamp(!showHoverTimesamp)}
        >
          <HyperLinkContainer>
            <TextMessage
              isUser={!isUser}
              fontSize="16px"
              color="#212332"
              backgroundColor={isUser ? "#e8e9f1" : "#FBEBE3"}
              message={HTMLReactParser((msg && msg.msg && msg.msg.text) || "")}
            />
          </HyperLinkContainer>
          {!message.shouldShowTimestamp && showHoverTimesamp && (
            <HoverTimestamp theme={{ isUser }}>
              {new Date(sentAt).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </HoverTimestamp>
          )}
          {msg.links.map((item, index: number) => (
            <HyperLinkContainer key={index}>
              <TextMessage
                isUser={!isUser}
                fontSize="16px"
                color="#212332"
                backgroundColor={isUser ? "#e8e9f1" : "#FBEBE3"}
                message={HTMLReactParser(`<a href=${item.url}>${item.title}</a>`)}
              />
            </HyperLinkContainer>
            // <p key={index}>
            //   <a href={item.url}>{item.title}</a>
            // </p>
          ))}
        </TextWrapper>
      );
    case "text":
    case "simpleChoiceResponse":
    case "markdown": {
      const text = msg.text ? msg.text : msg.markdownText ? msg.markdownText : msg.label
      return (
        <TextWrapper
          key={mId}
          onMouseLeave={() => setHoverTimestamp(!showHoverTimesamp)}
          onMouseEnter={() => setHoverTimestamp(!showHoverTimesamp)}
        >
          <TextMessage
            isUser={!isUser}
            fontSize="16px"
            color={isPrivate ? "#FFFFFF" : "#212332"}
            backgroundColor={isPrivate ? "#2b2b2b" : isUser ? "#e8e9f1" : "#FBEBE3"}
            markdownText={msg.markdownText}
            message={HTMLReactParser(
              isClean(md.render(_.unescape(text.trim())))
                .replace(/[\r|\n|\r\n]$/, "")
                .replace(/\n/g, "<br />")
            )}
          />
          {!message.shouldShowTimestamp && showHoverTimesamp && (
            <HoverTimestamp theme={{ isUser }}>
              {new Date(sentAt).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </HoverTimestamp>
          )}
        </TextWrapper>
      );
    }
    case "datatable":
      return (
        <div key={mId}>
          <div style={{ maxWidth: "500px", marginLeft: "auto", marginBottom: "16px" }}>
            <DataTable cols={msg.msg.header} rows={msg.msg.rows} limit={2} />

            <button onClick={() => setModalOpen(true)}>Show More</button>
          </div>
          <Modal
            title="Data Table"
            visible={openModal}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            width={1000}
            footer={null}
            centered
            closable
          >
            <DataTable cols={msg.msg.header} rows={msg.msg.rows} />
          </Modal>
        </div>
      );
    case "pdf":
      const pdfDesc = msg.desc ? msg.desc : msg.description
      return (
        <div key={mId}>
          <DocumentComponent
            isUser={isUser}
            desc={pdfDesc}
            isPrivate={isPrivate}
            handleDocumentClick={() => setModalOpen(true)}
          />
          <Modal
            title={message.msg.fileName}
            visible={openModal}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            width={1000}
            footer={null}
            centered
            closable
          >
            <object data={`${msg.url}`} type="application/pdf" width="100%" height="600px">
              <p>
                This browser does not support inline PDFs. Please download the PDF to view it:{" "}
                <a href={`${msg.url}`}>Download PDF</a>
              </p>
            </object>
          </Modal>
        </div>
      );

    case "oauthList":
      return (
        <TextWrapper
          key={mId}
          onMouseLeave={() => setHoverTimestamp(!showHoverTimesamp)}
          onMouseEnter={() => setHoverTimestamp(!showHoverTimesamp)}
        >
          <TextMessage
            isUser={!isUser}
            fontSize="16px"
            color="#212332"
            backgroundColor={isUser ? "#e8e9f1" : "#FBEBE3"}
            message={HTMLReactParser(msg.msg.text)}
          />
          {!message.shouldShowTimestamp && showHoverTimesamp && (
            <HoverTimestamp theme={{ isUser }}>
              {new Date(sentAt).toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </HoverTimestamp>
          )}
        </TextWrapper>
      );

    case "adaptiveCard":
      const {
        from,
        fromName,
      } = message;
      const parsedData = parseAdaptiveCard(message.msg);
      const { template, data, isFeedBack } = parsedData;

      if(parsedData.msgType === "card-carousel") {
        return <CarouselComponent chats={chats} cards={parsedData.cards} data={message} id={mId} logs={logs} assignChatId={assignChatId} isPrivate={isPrivate} />
      }

      return (
        <AdaptiveCardWrapper key={mId} theme={{ backgroundColor: isUser ? "#e8e9f1" : "#FBEBE3" }}>
          <AdaptiveCard
            template={template}
            data={{
              ...data,
              isForm: activeRootTab === "live" && lastMsg && lastMsg.mId !== mId
            }}
            from={from}
            fromName={fromName}
            isFeedback={isFeedBack}
            id={mId}
            assignChatId={assignChatId}
          />
        </AdaptiveCardWrapper>
        // <TextWrapper key={mId}>
        //   <TextMessage
        //     isUser={!isUser}
        //     isFeedback={false}
        //     layout={{
        //       fontSize: "16px",
        //       color: "#212332",
        //       backgroundColor: isUser ? "#e8e9f1" : "#FBEBE3",
        //     }}
        //     isChatIconVisible={false}
        //     message={HTMLReactParser(md.render(data.title))}
        //     icon={""}
        //   />
        // </TextWrapper>
      );
    case "simpleChoice":
      return <SimpleChoice assignChatId={assignChatId} {...message} logs={logs} />
    case "image":
      const imageDesc = msg.desc ? msg.desc : msg.description
      return (
        <div
          key={mId}
          style={{
            width: "100%",
            maxWidth: "380px",
            marginBottom: "12px",
            marginLeft: !isUser && "auto",
            marginRight: isUser && "auto",
          }}
        >
          <PictureWidget
            theme={{ url: msg.url, isPrivate }}
            onClick={() => setModalConfig({ ...modalConfig, image: true })}
          >
            <div className="container">
              <img src={msg.url} alt="Attachment" />
              <p style={{ wordBreak: "break-all", paddingTop: "12px" }}>{imageDesc}</p>
            </div>
          </PictureWidget>
          <Modal
            title="Image"
            visible={modalConfig.image}
            onCancel={() => setModalConfig({ ...modalConfig, image: false })}
            width={1000}
            footer={null}
            centered
            closable
          >
            <img src={msg.url} width="100%" height="100%" />
          </Modal>
        </div>
      );
    case "video": {
      return <Video url={msg.url} key={mId} />;
    }
    case "chart":
      const encodedUrl = encodeURIComponent(JSON.stringify(msg));
      const baseUrl = "https://quickchart.io/chart?bkg=white&c=";
      const url = `${baseUrl}${encodedUrl}`;
      return (
        <div key={mId}>
          <Chart data={msg} handleClick={() => setModalConfig({ ...modalConfig, image: true })} />
          <Modal
            title="Chart"
            visible={modalConfig.image}
            onCancel={() => setModalConfig({ ...modalConfig, image: false })}
            width={1000}
            footer={null}
            centered
            closable
          >
            <img src={url} width="100%" height="100%" />
          </Modal>
        </div>
      );
    case "autocomplete":
    case "autoComplete":
      return (
        <Autocomplete assignChatId={assignChatId} {...message} logs={logs} />
      );
    case "carousel":
    case "cardCarousel":
      const payloadData = parseAdaptiveCard(message.msg);

      return (
        <div
          key={mId}
          style={{
            maxWidth: "400px",
            marginLeft: "auto",
            padding: "20px 0",
          }}
        >
          <CarouselComponent chats={chats} cards={payloadData.cards ? payloadData.cards : payloadData} data={message} id={mId} logs={logs} assignChatId={assignChatId} isPrivate={isPrivate} />
        </div>
      );
    case "carousel-response":
    case "cardCarouselResponse":
      return <CarouselResponse key={mId} card={msg} isUser={isUser} />;
    case "autocompleteResponse":
    case "autoCompleteResponse":
      const { payload } = msg;
      const response = payload.map((item) => item.label).join(", ");
      return (
        <TextWrapper key={mId}>
          <TextMessage
            isUser={!isUser}
            fontSize="16px"
            color="#212332"
            backgroundColor={isUser ? "#e8e9f1" : "#FBEBE3"}
            message={response}
          />
        </TextWrapper>
      );
    case "cardChoice": {
      return <CardChoice assignChatId={assignChatId} {...message} logs={logs} />
    }
    case "cardChoiceResponse": {
      const { label } = msg;
      return <CardChoiceResponse isUser={isUser} label={label} subText={msg.subText ? msg.subText : msg.description} />;
    }
    case "laoding": {
      return <Loading></Loading>
    }
    case "form":
    case "formResponse": {
      return <Form isUser={isUser} sentAt={message.sentAt} msg={msg as any} />
    }
  }
};

export default function ChatMessages(props: IChatMessagesProps) {
  const messages = props.chats ? props.chats : []
  const storeMessages = getMessages(props.chatId)
  return (
    <ChatContainer>
      {messages.map((item: any, index: number) => {
          const isUser = item.fromId.includes("u/");
          const isFormMsg = item.msgType === 'form' || item.msgType === 'formResponse'
          if(item.msgType === "form") {
            const formVariant = getFormVariant(storeMessages, item.mId)
            if(formVariant === "completed" || formVariant === "waiting") {
              return null
            }
          }
          let shouldShowTimestamp = true;
          if (index < messages.length - 1) {
            const newDate = new Date(messages[index + 1].sentAt);
            const oldDate = new Date(messages[index].sentAt);
            const isTimeDiff =
              newDate.getFullYear() !== oldDate.getFullYear() ||
              newDate.getMonth() !== oldDate.getMonth() ||
              newDate.getDay() !== oldDate.getDay() ||
              newDate.getHours() !== oldDate.getHours() ||
              newDate.getMinutes() - oldDate.getMinutes() > 3;

            shouldShowTimestamp = messages[index + 1].fromId !== item.fromId || isTimeDiff;
          }
          return (
            <div key={item.mId}>
              <DisplayMessage chatId={props.chatId} chats={messages} message={{ ...item, shouldShowTimestamp }} />
              {shouldShowTimestamp && (
                <Timestamp theme={{ isUser }}>
                  <span>{item.fromName}</span>
                  {!isFormMsg && (
                    <>
                      &nbsp; &bull; &nbsp;
                      <span>{toCustomDateTimeFormat(item.sentAt)}</span>
                    </>
                  )}
                </Timestamp>
              )}
            </div>
          );
        })}
    </ChatContainer>
  );
}
