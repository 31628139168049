import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Drawer } from "antd";
import AgentSaveDetails from "./agents/AgentSaveDetails";
import GroupSaveDetails from "./groups/GroupSaveDetails";
import { createAgent, updateAgent, forceLogout } from "../../actions/agentsAction";
import {
  updateUnassignedAgents,
  updateAssignedAgents,
  createGroup,
  updateGroup,
} from "../../actions/groupsAction";
import {
  settingSaveDetails,
  clearAgentError,
  clearGroupError,
} from "../../actions/taskAction";
import { updateSettingShow, toggleBulkUpload } from "../../actions/uiAction";
import BulkUpload from "../common/BulkUpload";

class SettingSaveDetail extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      toggleBulkUpload: {
        isEnable: false,
        type: null,
      },
    };
  }

  componentWillMount() {
    if (this.props.ui.toggleBulkUpload) {
      this.setState({
        toggleBulkUpload: this.props.ui.toggleBulkUpload,
      });
    }
  }

  showDrawer = () => {
    this.setState({
      visible: true,
      toggleBulkUpload: {
        isEnable: false,
        type: null,
      },
    });
  };

  onForceLogout = (agentId, agentName) => {
    this.props.forceLogout(agentId, agentName);
    this.onClose();
  }

  onSubmit = (data) => {
    let originalData = JSON.parse(JSON.stringify(data));
    delete data.error;
    if (data.type == "agent") {
      delete data.type;
      if (data._id == null || data._id == 0) {
        delete data.action;
        delete data._id;
        delete data.isActive;
        this.props.createAgent(data, originalData);
      } else {
        let agentId = data._id;
        let payload = {
          email: data.email,
          groupIds: data.groupIds,
          name: data.name,
          roles: data.roles,
          isActive: data.isActive
        };
        if (data.isVirtualAgent) {
          payload['botHandle'] = data.botHandle;
        }
        console.log(payload)
        this.props.updateAgent(payload, agentId, originalData);
      }
    } else if (data.type == "group") {
      let groupId = data._id;
      delete data.type;
      if (data._id == null || data._id == 0) {
        delete data.unAssignedAgents;
        delete data.isActive;
        delete data.action;
        delete data._id;
        delete data.originalAgentIds;
        delete data.agentIds;
        this.props.createGroup(data, originalData);
      } else {
        // if (data.agentIds.length > 0 && (data.unAssignedAgents.length == 0 && data.originalAgentIds.length != data.agentIds.length)) {
        this.props.updateAssignedAgents(
          { agentIds: data.agentIds },
          groupId,
          originalData
        );
        // }
        if (data.unAssignedAgents.length > 0) {
          this.props.updateUnassignedAgents(
            { agentIds: data.unAssignedAgents },
            groupId,
            originalData
          );
        }
        let payload = {
          isActive: data.isActive,
          name: data.name
        }
        this.props.updateGroup(payload, groupId, originalData);
      }
    }
    this.onClose();
  };

  onBulkUpload = (type) => {
    this.onClose();
    this.setState({
      toggleBulkUpload: {
        isEnable: true,
        type,
      },
    });
    this.props.toggleBulkUpload({ isEnable: true, type });
  };

  onCloseBulkUpload = () => {
    this.setState({
      toggleBulkUpload: {
        isEnable: false,
        type: null,
      },
    });
    this.props.toggleBulkUpload({ isEnable: false, type: null });
  };

  componentWillReceiveProps(props) {
    if (props.agent && props.agent.agentError) {
      this.props.clearAgentError();
      this.setState({
        visible: true,
        settingSaveDetails: props.agent.agentError.settingSaveDetails,
      });
    } else if (props.group && props.group.groupError) {
      this.props.clearGroupError();
      this.setState({
        visible: true,
        settingSaveDetails: props.group.groupError.settingSaveDetails,
      });
    } else if (props.task && props.task.settingSaveDetails) {
      this.setState({
        visible: true,
        settingSaveDetails: props.task.settingSaveDetails,
      });
    }
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.setState({
      settingSaveDetails: null,
    });
    this.props.settingSaveDetails({ agent: null });
    this.props.updateSettingShow({ updateSettingShow: false });
  };

  render() {
    let data = this.state.settingSaveDetails
      ? this.state.settingSaveDetails
      : null;
    return (
      <>
        {data && (
          <Drawer
            placement="right"
            closable={false}
            width={600}
            visible={this.state.visible || this.props.ui.showUpdateSetting}
          >
            {data && data.type == "agent" && (
              <AgentSaveDetails
                onClose={this.onClose}
                onForceLogout={this.onForceLogout}
                onSubmit={this.onSubmit}
                onBulkUpload={this.onBulkUpload}
                agentDetails={data}
              ></AgentSaveDetails>
            )}
            {data && data.type == "group" && (
              <GroupSaveDetails
                onClose={this.onClose}
                onSubmit={this.onSubmit}
                onBulkUpload={this.onBulkUpload}
                groupDetails={data}
              ></GroupSaveDetails>
            )}
          </Drawer>
        )}
        {this.state.toggleBulkUpload &&
          this.state.toggleBulkUpload.isEnable && (
            <BulkUpload
              toggleBulkUpload={this.state.toggleBulkUpload}
              onCloseBulkUpload={this.onCloseBulkUpload}
            />
          )}
      </>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("Update Setting Details mapStateToProps: ", state);
  return {
    ui: state.ui,
    task: state.task,
    auth: state.auth,
    agent: state.agent,
    group: state.group,
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      settingSaveDetails,
      updateSettingShow,
      createAgent,
      updateAgent,
      updateAssignedAgents,
      updateUnassignedAgents,
      createGroup,
      updateGroup,
      clearAgentError,
      clearGroupError,
      toggleBulkUpload,
      forceLogout
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingSaveDetail);
