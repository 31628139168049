import * as React from "react";
import styled from "styled-components";
import "../../../assets/sass/toast.scss";
import { useSelector, useDispatch } from "react-redux";
import { getRandomColor } from "../../../utils/randomColor";
import { toCustomDateTimeFormat } from "../../../helpers/util";
import update from "immutability-helper";
import { onPickupChat } from "../../../helpers/mqttService";
import { clearToast } from "../../../actions/toastAction";
import { updateUnassignedChats } from "../../../actions/chatsAction";

export interface IToastProps {
  // toastList: any[];
  position?: string;
  // deleteToast: (arg: number) => void;
  // autoDelete?: boolean;
  autoDeleteTime?: number;
  onClick?: () => void;
  // children: any;
}

const NotificationContainer = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
`;

const Statics = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.background + "26"};
  color: ${(props) => props.theme.background};
  border: 1px solid ${(props) => props.theme.background};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  font-size: 20px;
`;

const Name = styled.div`
  flex: 1;
  font-size: 16px;
  color: #a8abbd;
  font-family: Muli;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  word-break: break-all;
  margin-right: 16px;
  text-transform: capitalize;
`;

const Time = styled.div`
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #a8abbd;
`;

const Message = styled.div`
  color: ${(props) => (props.theme.isSelected ? "#2f3245" : "#a8abbd")};
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  padding: 6px 0px 0px 40px;
  width: 264px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

const Container = styled.div`
  min-height: 70px;
  /* max-height: 100px; */
  margin-top: 20px;
  margin-bottom: 20px;
`;

const PickupButton = styled.button`
  padding: 4px 8px;
  /* width: 60px; */
  font-size: 13px;
  border: none;
  background: #1c7aff;
  color: white;
  font-weight: 600;
  font-family: "Muli";
  border-radius: 6px;
  outline: none;
  height: 26px;
  bottom: 22px;
  position: absolute;
  right: 6px;
`;

const PickupButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default function Toast(props: IToastProps) {
  const { position = "top-right", autoDeleteTime = 6000, onClick } = props;
  const dispatch = useDispatch();
  const emptyToast = React.useCallback(() => dispatch(clearToast()), [dispatch]);
  const updateChats = React.useCallback((chats) => dispatch(updateUnassignedChats(chats)), [dispatch]);
  const { chats } = useSelector((state: any) => state.chat);
  const {
    toast: { data },
    chat: { assignedChats },
  } = useSelector((state: any) => state);
  const [list, setList] = React.useState([]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (data.length && list.length) {
        const oldList = [...list];
        oldList.shift();
        setList(oldList);
        emptyToast();
      }
    }, autoDeleteTime);
    return () => {
      clearInterval(interval);
    };
  }, [list]);

  React.useEffect(() => {
    setList(data);
    // debugger;
  }, [data]);

  const handlePickup = (id: string) => {
    // onClick();
    onPickupChat(id);
    const modifiedChats = chats.filter((item) => item.chatId !== id);
    updateChats(modifiedChats);
    emptyToast();
  };

  return (
    <NotificationContainer className={`toast-notification-container ${position}`}>
      {list.map((toast, i) => (
        <div key={i} className={`toast-notification toast ${position}`}>
          <button className="close" onClick={emptyToast}>
            &times;
          </button>
          <Container>
            <Statics>
              <Icon theme={{ background: getRandomColor(toast.chatId) }}>{toast.clientData.fromName[0]}</Icon>
              <Name>{toast.clientData.fromName}</Name>
              <Time>{toCustomDateTimeFormat(toast.createdAt)}</Time>
            </Statics>
            <PickupButtonContainer>
              {toast.messages.length > 0 && toast.messages[0].msgType == "text" && (
                <Message>{toast.messages[0].msg.text}</Message>
              )}
              <PickupButton onClick={() => handlePickup(toast.chatId)}>Pick Up</PickupButton>
            </PickupButtonContainer>
          </Container>
        </div>
      ))}
    </NotificationContainer>
  );
}
