import * as React from "react";
import styled from "styled-components";
import UserCard from "./UserCard";
import { useSelector, useDispatch } from "react-redux";
import searchIcon from "../../../../assets/images/ico-search.svg";
import { getChatHistory } from "../../../../actions/chatsAction";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
import moment from "moment";
import "../../../../assets/sass/history.sass";

import "react-datepicker/dist/react-datepicker.css";
import { SelectedChatHistoryMessageContext } from "..";
import { filterObject } from "../../../../utils/filterObject";

export interface ISidebarProps { }

const Container = styled.div`
  width: 300px;
  border-right: 1px solid rgba(168, 171, 189, 0.4);
  padding: 20px 20px 20px 15px;
  max-height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  &:nth-child(2) {
    margin-top: 20px;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 37px;
  position: fixed;
  background: white;
  display: flex;
  align-items: center;
  top: 110px;
`;

const Search = styled.input`
  width: 269px;
  padding: 0 12px 0 40px;
  height: 30px;
  border-radius: 20px;
  border: solid 2px #e8e9f1;
  background-color: #ffffff;
  outline: none;
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const SeachIconContainer = styled.div`
  position: absolute;
  left: 15px;
`;

const CardContainer = styled.div`
  margin-top: 75px;
`;

const DateWrapper = styled.div`
  position: fixed;
  background: white;
  width: 269px;
  top: 60px;
  z-index: 99;
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 33px;
  background: white;
  align-items: center;
  background-color: #ffffff;
  margin-top: 15px;
`;

const dateFormat = "YYYY/MM/DD";

export default function Sidebar(props: ISidebarProps) {
  const context = React.useContext(SelectedChatHistoryMessageContext);
  const [limit, setLimit] = React.useState(10);
  const [dateLimits, setDateLimits] = React.useState([
    new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString(),
    new Date(Date.now()).toISOString(),
  ]);
  const chatReducer = useSelector((state: any) => state.chat);
  const { chatHistory, isLoading } = chatReducer;
  const [chats, setChats] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const observer: any = React.useRef();
  const lastChatElement = React.useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          !isLoading &&
          limit <= Object.keys(chatHistory).length
        ) {
          setLimit((oldLimit) => oldLimit + 10);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, chatHistory]
  );

  React.useEffect(() => {
    /* Crashing when default selection happens */
    // if (Object.keys(chatHistory).length != 0) {
    //   context.setSelectedChat(Object.keys(chatHistory)[0]);
    // }
    // else if (Object.keys(chatHistory).length == 0) {
    //   if (Object.keys(chatHistory).length != 0) {
    //     context.setSelectedChat(Object.keys(chatHistory)[0]);
    //   } else {
    //   context.setSelectedChat("");
    //   }
    // }
    const updatedChats = filterObject(chatHistory, (chat) => chat && chat.clientData && chat.clientData.fromName.toLowerCase().includes(searchText.toLowerCase()));
    setChats(updatedChats);
  }, [chatReducer.chatHistory]);

  const dispatch = useDispatch();
  const fetchChatHistory = React.useCallback(
    (payload) => dispatch(getChatHistory(payload)),
    [dispatch]
  );

  React.useEffect(() => {
    // context.setSelectedChat(Object.keys(chatHistory)[0]);
    if (!chatHistory.length) {
      // context.setSelectedChat(Object.keys(chatHistory)[0]);
      fetchChatHistory({
        limit,
        startTime: `${dateLimits[0].split("T")[0]}T00:00:00`,
        endTime: `${dateLimits[1].split("T")[0]}T23:59:00`,
      });
    }
  }, [limit, dateLimits]);

  React.useEffect(() => {
    // context.setSelectedChat(Object.keys(chatHistory)[0]);
    context.setSelectedChat("");
  }, [limit, dateLimits]);

  const handleSearch = (e) => {
    const { value } = e.target;
    const updatedChats = filterObject(chatHistory, (chat) =>
      chat.clientData.fromName.toLowerCase().includes(value.toLowerCase())
    );
    setChats(updatedChats);
    setSearchText(value);
  };

  return (
    <Container className="history">
      <DateWrapper>
        <DateContainer>
          <RangePicker
            defaultValue={[
              moment(dateLimits[0], dateFormat),
              moment(dateLimits[1], dateFormat),
            ]}
            format={dateFormat}
            style={{ borderRadius: "40px" }}
            onChange={(e, d) => {
              // context.setSelectedChat(Object.keys(chatHistory)[0]);
              const startDate =
                (e && e[0] && e[0].format()) ||
                new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString();
              // if (Object.keys(chatHistory).length != 0) {
              //   context.setSelectedChat(Object.keys(chatHistory)[0]);
              // }
              // context.setSelectedChat("");
              const endDate =
                (e && e[1] && e[1].format()) ||
                new Date(Date.now()).toISOString();
              // if (Object.keys(chatHistory).length != 0) {
              //   context.setSelectedChat(Object.keys(chatHistory)[0]);
              // }
              // context.setSelectedChat("");
              setDateLimits([startDate, endDate]);
            }}
            value={[
              moment(dateLimits[0], dateFormat),
              moment(dateLimits[1], dateFormat),
            ]}
            className="date-range-picker"
          />
        </DateContainer>
      </DateWrapper>
      <SearchContainer>
        <SeachIconContainer>
          <img src={searchIcon} alt="search icon" />
        </SeachIconContainer>
        <Search
          value={searchText}
          onChange={handleSearch}
          placeholder="Search"
        />
      </SearchContainer>
      <CardContainer>
        {Object.keys(chats).length > 0 &&
          Object.keys(chats).map((id: any, index: number) => {
            const {
              messages,
              clientData: { fromName },
              _id,
            } = chats[id];

            const msg = messages[messages.length - 1];
            if (Object.keys(chats).length === index + 1) {
              return (
                <UserCard
                  ref={lastChatElement}
                  key={_id}
                  id={_id}
                  message={msg}
                  name={fromName}
                />
              );
            }
            return (
              <UserCard key={_id} id={_id} message={msg} name={fromName} />
            );
          })}
      </CardContainer>
    </Container>
  );
}
