import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Drawer } from "antd";
import QuickRespSave from "./quickResponse/Save";
import { createQuickResp, updateQuickResp } from "../../actions/quickRespAction";
import {
    resourceSaveDetails, clearQuickRespError
} from "../../actions/taskAction";
import { updateResourceShow } from "../../actions/uiAction";

class ResourceSaveDetail extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false
        };
    }

    showDrawer = () => {
        this.setState({
            visible: true
        });
    };

    onSubmit = (data) => {
        let originalData = JSON.parse(JSON.stringify(data));
        if (data.action) {
            this.props.createQuickResp({ response: data.response, shortCode: data.shortCode, groupIds: data.groupIds })
        } else if (data._id) {
            this.props.updateQuickResp({ response: data.response, shortCode: data.shortCode, groupIds: data.groupIds, isActive: data.isActive }, data._id, originalData)
        }
        this.onClose();
    };

    componentWillReceiveProps(props) {
        if (props.quickResp && props.quickResp.quickRespError) {
            this.props.clearQuickRespError();
            this.setState({
                visible: true,
                resourceSaveDetails: props.quickResp.quickRespError.resourceSaveDetails,
            });
        } else if (props.task && props.task.resourceSaveDetails) {
            this.setState({
                visible: true,
                resourceSaveDetails: props.task.resourceSaveDetails,
            });
        }
    }

    onClose = () => {
        this.setState({
            visible: false,
        });
        this.setState({
            resourceSaveDetails: null,
        });
        this.props.resourceSaveDetails({ info: null });
        this.props.updateResourceShow({ updateResourceShow: false });
    };

    render() {
        let data = this.state.resourceSaveDetails
            ? this.state.resourceSaveDetails
            : null;
        return (
            <>
                {data && (
                    <Drawer
                        placement="right"
                        closable={false}
                        width={600}
                        visible={this.state.visible || this.props.ui.showUpdateResource}
                    >
                        {data && data.type == "quickresp" && (
                            <QuickRespSave
                                onClose={this.onClose}
                                onSubmit={this.onSubmit}
                                quickResp={data}
                            ></QuickRespSave>
                        )}
                    </Drawer>
                )}
            </>
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("Update Resource Details mapStateToProps: ", state);
    return {
        ui: state.ui,
        task: state.task,
        auth: state.auth,
        group: state.group,
        quickResp: state.quickResp
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            resourceSaveDetails,
            updateResourceShow,
            createQuickResp,
            updateQuickResp,
            clearQuickRespError
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceSaveDetail);
