import type from "../types/actionTypes";
const initialState = {
  //if any credentials error
  error: null,
};

export default function credentialReducers(state = initialState, action) {
  // console.log(action)
  switch (action.type) {
    case type.REMOVE_TOKEN_SUCCESS:
      return {
        ...state,
        removeTokenSuccess: action.data
      };
    case type.REMOVE_TOKEN_ERROR:
      return {
        ...state,
        removeTokenError: action.data
      };
    case type.GENERATE_TOKEN_SUCCESS:
      return {
        ...state,
        generateTokenSuccess: action.data
      };
    case type.GENERATE_TOKEN_ERROR:
      return {
        ...state,
        generateTokenError: action.data
      };
    case type.SCOPES_GET_ERROR:
      return {
        ...state,
        getScopesError: action.data
      };
    case type.CREDENTIALS_GET_ERROR:
      return {
        ...state,
        getCredentialsError: action.data
      };
    default:
      return state;
  }
}
