import { Table, Tag } from "antd";
import React from "react";

interface IDataTableProps {
  cols: any[];
  rows: any[];
  limit?: number;
}

const renderCol = (col, limit: number) => {
  return col.slice(0, limit).map((item, index) => {
    return {
      title: item.name,
      dataIndex: index,
      key: index,
      align: "left",
    };
  });
};

const renderRow = (rows, limit: number) => {
  return rows.slice(0, limit).map((item, index) => {
    const { data } = item;
    return data.reduce((a, c, i) => {
      a[i] = c;
      return a;
    }, {});
  });
};

const DataTable = (props: IDataTableProps) => {
  const { cols, rows } = props;
  const { limit = cols.length } = props;
  const coldata = renderCol(cols, limit);
  const rowData = renderRow(rows, limit);
  return <Table pagination={false} columns={coldata} dataSource={rowData} />;
};

export default DataTable;
