import type from "../types/actionTypes";
import axios from "axios";
import config from "../apiConfig";
import { store } from "../store";
import { notification } from "antd";

const actionNotification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
  setTimeout(function () {
    notification.close();
  }, 1000);
};

export function getFaqCategories() {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    try {
      let response = await axios(`${config.baseUrl}${config.org}${config.v1}${config.faqCategorieUrl}`, options);
      dispatch({
        type: type.FAQ_GET_SUCCESS,
        data: response.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: type.FAQ_GET_ERROR,
        data: {
          error: error.response.data,
          message: error.response.data.message,
        },
      });
    }
  };
}

export function createCategory(data) {
  const options = {
    method: "POST",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    axios(`${config.baseUrl}${config.org}${config.v1}${config.faqCategorieUrl}`, options)
      .then(function (resp) {
        dispatch({
          type: type.FAQ_CATEGORY_SAVE_SUCCESS,
          data: true,
        });
        actionNotification("info", "NEW CATEGORY CREATED", "Category successfully created");
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          dispatch({
            type: type.FAQ_CATEGORY_SAVE_ERROR,
            data: { faqSaveDetails: { error: error.response.data.message } },
          });
        }
      });
  };
}

export function updateCategory(data, faqCategorieId, originalData) {
  const options = {
    method: "PUT",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  return async (dispatch) => {
    await axios(
      `${config.baseUrl}${config.org}${config.v1}${config.updateFaqCategorieUrl.replace(
        "{faqCategorieId}",
        faqCategorieId
      )}`,
      options
    )
      .then(function (resp) {
        // let response = resp;
        dispatch({
          type: type.FAQ_CATEGORY_SAVE_SUCCESS,
          data: true,
        });
        actionNotification("info", "FAQ CATEGORY UPDATED", `FAQ Category ${data.name} updated successfully`);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          Object.assign(originalData, { error: error.response.data.message });
          dispatch({
            type: type.FAQ_CATEGORY_SAVE_ERROR,
            data: { faqSaveDetails: originalData },
          });
        }
      });
  };
}

export function createQuestionByCategory(faqCategorieId, data) {
  const options = {
    method: "POST",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  return async (dispatch) => {
    axios(
      `${config.baseUrl}${config.org}${config.v1}${config.newFaqQuestionsUrl.replace(
        "{faqCategoryId}",
        faqCategorieId
      )}`,
      options
    )
      .then(function (resp) {
        dispatch({
          type: type.FAQ_QUESTION_SAVE_SUCCESS,
          data: true,
        });
        actionNotification("info", "NEW QUESTION CREATED", "Question successfully created");
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          dispatch({
            type: type.FAQ_QUESTION_SAVE_ERROR,
            data: { faqSaveDetails: { error: error.response.data.message } },
          });
        }
      });
  };
}

export function updateQuestionByCategory(faqCategorieId, faqQuestionId, data) {
  const options = {
    method: "PUT",
    data: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  return async (dispatch) => {
    axios(
      `${config.baseUrl}${config.org}${config.v1}${config.updateFaqQuestionsUrl
        .replace("{faqCategoryId}", faqCategorieId)
        .replace("{FaqQuestionId}", faqQuestionId)}`,
      options
    )
      .then(function (resp) {
        dispatch({
          type: type.FAQ_QUESTION_SAVE_SUCCESS,
          data: true,
        });
        actionNotification("info", "FAQ QUESTION UPDATED", `FAQ Category Q & A updated successfully`);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          dispatch({
            type: type.FAQ_QUESTION_SAVE_ERROR,
            data: { faqSaveDetails: { error: error.response.data.message } },
          });
        }
      });
  };
}

export function deleteQuestionByCategory(faqCategorieId, faqQuestionId, data) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  return async (dispatch) => {
    axios(
      `${config.baseUrl}${config.org}${config.v1}${config.updateFaqQuestionsUrl
        .replace("{faqCategoryId}", faqCategorieId)
        .replace("{FaqQuestionId}", faqQuestionId)}`,
      options
    )
      .then(function (resp) {
        dispatch({
          type: type.FAQ_QUESTION_SAVE_SUCCESS,
          data: true,
        });
        actionNotification("info", "FAQ QUESTION DELETE", `FAQ Category Q & A deleted successfully`);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          dispatch({
            type: type.FAQ_QUESTION_SAVE_ERROR,
            data: { faqSaveDetails: { error: error.response.data.message } },
          });
        }
      });
  };
}

export function deleteCategory(faqCategorieId, data) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  return async (dispatch) => {
    axios(
      `${config.baseUrl}${config.org}${config.v1}${config.updateFaqCategorieUrl.replace(
        "{faqCategorieId}",
        faqCategorieId
      )}`,
      options
    )
      .then(function (resp) {
        dispatch({
          type: type.FAQ_CATEGORY_DELETE_SUCCESS,
          data: true,
        });
        actionNotification("info", "FAQ CATEGORY DELETE", `FAQ category ${data.name} deleted successfully`);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          dispatch({
            type: type.ERROR,
            data: error.response.data,
          });
        } else {
          dispatch({
            type: type.FAQ_CATEGORY_DELETE_ERROR,
            data: { faqSaveDetails: { error: error.response.data.message } },
          });
        }
      });
  };
}
