import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSessionToken, sign } from "../../actions/authActions";
import { clearError } from "../../actions/taskAction";
import LeftIllustrationImage from "../../assets/images/left_illustration.svg";
import RightIllustrationImage from "../../assets/images/right_illustration.svg";
import styled from "styled-components";
import AccountRecoveryCard from "./AccountRecoveryCard";
import InputLogoImage from "../../assets/images/password_icon.svg";
import MainIconImage from "../../assets/images/mail_icon.svg";
import { deviceBreakpoint } from "../../utils/deviceBreakpoints";
import { Link, RouteComponentProps } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { getUserMetaInfo } from "../../helpers/util";
import { allowAccess, pathToRedirect } from "../../utils/helpers";

const Container = styled.div`
  position: relative;
  height: 100vh;
`;

const LeftIllustration = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;

  // Hide on Mobile
  @media ${deviceBreakpoint.laptop} {
    display: block;
  }
`;

const RightIllustration = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;

  // Hide on Mobile
  @media ${deviceBreakpoint.laptop} {
    display: block;
  }
`;

const Heading = styled.div`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #10002e;
  margin-bottom: 30px;
`;

const FormLabel = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: left;
  color: #10002e;
  margin: 40px 0 15px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const InputLogo = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-45%);
  left: 15px;
`;

const Input = styled.input`
  height: 50px;
  border-radius: 2.5px;
  border: solid 0.5px ${(props) => (props.theme.error ? "#d30029" : "#95abd3")};
  background-color: #ffffff;
  max-width: 100%;
  width: 100%;
  padding: 0 12px 0 45px;

  &::-webkit-input-placeholder {
    /* Edge */
    letter-spacing: initial;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    letter-spacing: initial;
  }

  &::placeholder {
    letter-spacing: initial;
  }
`;

const InputError = styled.p`
  font-family: Muli;
  min-height: 15px;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #d30029;
  margin-top: 2px;
`;

const PasswordShowIconWrapper = styled.div`
  position: absolute;
  top: 49%;
  right: 15px;
  transform: translateY(-50%);
`;

const ForgotPasswordContainer = styled.div`
  margin-top: 7.5px;
  text-align: right;
`;

const ForgotPasswordLink = styled(Link)`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #fa7412;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
`;

const Button = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 2.5px;
  border: none;
  color: white;
  box-shadow: 0 4px 6.5px 0 rgba(0, 118, 255, 0.27);
  background-color: #0076ff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
`;

export interface IState {
  auth: {
    error: string;
    info: {
      email: string;
      lastLoginTime: number;
      roles: string[];
      expiresIn: number;
    };
    sessionToken: {
      eventLoggerUpdated: boolean;
      sessionId: string;
    };
  };
}

const SignIn: React.FunctionComponent<RouteComponentProps> = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: IState) => state.auth);
  const { error: authError } = auth;
  const [showPassword, setShowPassword] = useState(true);
  const [values, setValues] = useState({
    email: "",
    password: "",
    result: null,
    btnLoading: false,
    error: authError,
  });

  const { email, password, error } = values;

  const fetchSessionToken = useCallback((payload) => dispatch(getSessionToken(payload)), [dispatch]);

  const signIn = useCallback((payload) => dispatch(sign(payload)), [dispatch]);

  const handleChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValues({ ...values, [name]: value, error: "" });
  };

  const handleSubmit = (e: React.FormEvent) => {
    dispatch(clearError())
    e.preventDefault();
    signIn(values);
  };

  useEffect(() => {
    if (auth.info && email) {
      const updatedAuth = { ...auth };
      updatedAuth.info.email = email;
      updatedAuth.info.lastLoginTime = Math.round(new Date().getTime() / 1000) + (updatedAuth.info.expiresIn - 500);
      if (updatedAuth.sessionToken) {
        const { history } = props;
        localStorage.setItem("userInfo", JSON.stringify(updatedAuth));
        let path = "/chats";
        if (updatedAuth && updatedAuth.info) {
          path = pathToRedirect(updatedAuth.info.roles)
        }
        history.push(path);
      } else {
        fetchSessionToken(getUserMetaInfo(auth.info));
      }
    }
  }, [auth]);

  useEffect(() => {
    setValues({ ...values, error: authError });
  }, [authError]);

  useEffect(() => {
    const storageListener = () => {
      const userInfo = localStorage.getItem("userInfo")
      if (userInfo) {
        const { info, sessionToken } = JSON.parse(userInfo)
        if (info && sessionToken) {
          const path = pathToRedirect(info.roles)
          dispatch({
            type: "SIGNIN_SUCCESS",
            data: info
          })
          dispatch({
            type: "SESSION_TOKEN_SUCCESS",
            data: sessionToken
          })
          dispatch(clearError())
          props.history.push(path)
        }
      }
      allowAccess(props.history, false)
    }

    window.addEventListener("storage", storageListener)
    return () => window.removeEventListener("storage", storageListener)
  }, [])

  const userInfo = localStorage.getItem("userInfo")
  if (userInfo) {
    const { info, sessionToken } = JSON.parse(userInfo)
    if (info && sessionToken) {
      dispatch({
        type: "SIGNIN_SUCCESS",
        data: info
      })
      dispatch({
        type: "SESSION_TOKEN_SUCCESS",
        data: sessionToken
      })
      props.history.push("/chats")
    }
  }
  // Only show this route, when there is no `userInfo`
  // is present in the localStorage
  allowAccess(props.history, true)

  return (
    <Container>
      <LeftIllustration data-testid="leftIllustration" src={LeftIllustrationImage} alt="Left Illustartion" />
      <RightIllustration data-testid="rightIllustration" src={RightIllustrationImage} alt="Right Illustartion" />
      <AccountRecoveryCard>
        <Heading data-testid="heading">Login</Heading>
        <form onSubmit={handleSubmit}>
          <FormLabel data-testid="emailLabel">Email</FormLabel>
          <InputWrapper>
            <InputLogo data-testid="mailIcon" src={MainIconImage} alt="mail icon" />
            <Input
              data-testid="emailInput"
              theme={{ error }}
              value={email}
              onChange={handleChange("email")}
              type="email"
              placeholder="Enter your email"
              className="email-input"
            />
          </InputWrapper>
          <InputError className="email-error" data-testid="emailError">
            {typeof error === "string" && error}
          </InputError>
          <FormLabel data-testid="passwordLabel">Password</FormLabel>
          <InputWrapper>
            <InputLogo data-testid="passwordIcon" src={InputLogoImage} alt="password icon" />
            <Input
              data-testid="passwordInput"
              theme={{ error }}
              value={password}
              onChange={handleChange("password")}
              type={showPassword ? "password" : "text"}
              placeholder="Enter your password"
            />
            <PasswordShowIconWrapper data-testid="showPasswordIcon" onClick={() => setShowPassword(!showPassword)}>
              {!showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </PasswordShowIconWrapper>
          </InputWrapper>

          <ForgotPasswordContainer>
            <ForgotPasswordLink data-testid="forgotPasswordLink" to="/forgot-password">
              Forgot Password?
            </ForgotPasswordLink>
          </ForgotPasswordContainer>

          <ButtonContainer>
            <Button data-testid="loginButton" className="login-button" type="submit">
              Login
            </Button>
          </ButtonContainer>
        </form>
      </AccountRecoveryCard>
    </Container>
  );
};

export default SignIn;
