import type from "../types/actionTypes";

export const updateToast = (payload) => {
  return {
    type: type.TOAST_UPDATE,
    data: payload,
  };
};

export const updateToastError = (error) => {
  return {
    type: type.TOAST_UPDATE,
    data: error,
  };
};

export const clearToast = () => {
  return {
    type: type.TOAST_UPDATE,
    data: [],
  };
};
