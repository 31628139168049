import React, { useContext, useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal } from "antd";
import importIcon from "../../../../assets/images/import.svg";
import exportIcon from "../../../../assets/images/export.svg";
import shareIcon from "../../../../assets/images/share.svg";
import Back from "../../../../assets/images/Back.png";
import { ThemeContext } from "../themeDesigner";
import { publishTheme } from "../../../../actions/themeActions";
import TextArea from "antd/lib/input/TextArea";
import { SelectedBotContext } from "../../bots";
import { getAgents } from "../../../../actions/agentsAction";

interface IProps {
  slug: string;
}

const Menu = styled.header`
  background: #ffffff;
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 25px 0 42px;
`;

const Breadcrumb = styled.p`
  margin-bottom: 5px;
  height: 15px;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  display: flex;
  color: #010f34;
`;

const PageTitle = styled.p`
  margin: 0;
  width: 48px;
  height: 18px;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #1d7aff;
`;

const NavButtonContainer = styled.div`
  flex: 1;
  text-align: end;
`;

const NavButton = styled.button`
  width: 100px;
  height: 30px;
  border-radius: 3px;
  background-color: #d3daef;
  border: none;
  margin: 0 7.5px;
`;

const Navbar = (props: IProps) => {
  const { themeProps, setThemeProps } = useContext(ThemeContext);
  const { selectedBotName, setSelectedBot, selectedBot } = useContext(SelectedBotContext);
  const [importModal, openImportModal] = useState(false);
  const [existingTheme, setExistingTheme] = useState("");

  const downloadFile = async () => {
    const fileName = "theme";
    const json = JSON.stringify(themeProps);
    const objJsonB64 = Buffer.from(json).toString("base64");
    const blob = new Blob([objJsonB64], { type: "string" });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".txt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const dispatch = useDispatch();
  // const updateTheme = useCallback(
  //   (payload) => dispatch(publishTheme(selectedBotName, selectedBot, payload)),
  //   [dispatch]
  // );
  const updateTheme = async (payload: any) => {
    const resp: any = await dispatch(publishTheme(selectedBotName, selectedBot, payload));
    if (resp && resp.data.code === "success") {
      dispatch(getAgents());
    }
  }

  const gotoBots = () => {
    setSelectedBot("");
  }
  return (
    <Menu>
      <div style={{ margin: 0, display: "flex", flexDirection: "column" }}>
        <Breadcrumb><div style={{ cursor: "pointer" }} onClick={gotoBots}>Bots</div> &gt; {selectedBotName}</Breadcrumb>
        <PageTitle>THEME</PageTitle>
      </div>
      <NavButtonContainer>
        {/* <Button onClick={downloadFile}>Generate</Button> */}
        <NavButton onClick={() => openImportModal(true)}>
          <img src={importIcon} />
          &nbsp;Import
        </NavButton>
        <NavButton onClick={() => updateTheme(themeProps)}>
          <img src={exportIcon} />
          &nbsp;Publish
        </NavButton>
        <NavButton onClick={downloadFile}>
          <img src={shareIcon} />
          &nbsp;Export
        </NavButton>
      </NavButtonContainer>
      <Modal
        title="Import Theme"
        visible={importModal}
        onOk={() => {
          if (existingTheme) {
            setThemeProps(JSON.parse(atob(existingTheme)));
            openImportModal(false);
          }
        }}
        onCancel={() => {
          openImportModal(false);
        }}
      >
        <TextArea
          value={existingTheme}
          onChange={(e) => setExistingTheme(e.target.value)}
          placeholder="Place your code here..."
        />
      </Modal>
    </Menu>
  );
};

const NavButtons = () => {
  return <button>Import</button>;
};

export default Navbar;
