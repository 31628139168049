import type from "../types/actionTypes";
import axios from "axios";
import config from "../apiConfig";
import { store } from "../store";
// import { notification } from 'antd';
import _ from "lodash";
import { subscribeTypesense } from "../helpers/mqttService";

// const actionNotification = (type, title, message) => {
//     notification[type]({
//         message: title,
//         description: message,
//     });
//     setTimeout(function () {
//         notification.close()
//     }, 1000);
// };

export function uploadFile(file) {
  const options = {
    method: "PUT",
    data: file,
    headers: {
      "Content-Type": file.type,
    },
  };
  return async (dispatch) => {
    try {
      let resp = await axios(`${file.uploadUrl}`, options);
      return resp.data;
    } catch (error) {
      dispatch({
        type: type.ERROR,
        data: { code: "access_denied" },
      });
    }
  };
}

export function getFileUploadUrl(fileExt) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
    data: {
      fileExt: fileExt,
      clientId: "uuid4",
    },
  };

  return async (dispatch) => {
    try {
      let response = await axios(
        `${config.baseUrl}${config.org}${config.v1}${config.attachmentUrl}?fileExt=${fileExt}&orgId=${store.getState().auth.info.orgId
        }`,
        options
      );
      return response.data;
    } catch (error) {
      if (!error || !error.response || error.response.status === 401) {
        let data = null;
        if (error && error.response) {
          data = error.response.data;
        } else {
          data = {
            code: "access_denied",
          };
        }
        dispatch({
          type: type.ERROR,
          data,
        });
      }
    }
  };
}

export function getAssignedChats(limit, assignedTo, lastTouched, selectedLiveChatId) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    dispatch({ type: type.CHAT_LOADING, data: true });
    try {
      let url = config.assignedChatToUrl.replace("{limit}", limit);
      url = url.replace("{assignedTo}", assignedTo);
      if (lastTouched) {
        url = url + `{lastTouched: ${lastTouched}}`;
      }
      let response = await axios(`${config.baseUrl}${config.org}${config.v2}${url}`, options);

      dispatch({
        type: type.UPDATE_ASSIGNED_CHATS,
        data: response.data.chats,
        selectedLiveChatId,
      });
      dispatch({ type: type.CHAT_LOADING, data: false });
      return response.data;
    } catch (error) {
      dispatch({ type: type.CHAT_LOADING, data: false });
      console.log(error);
      if (!error || !error.response || error.response.status === 401) {
        let data = null;
        if (error && error.response && !error.response.status === 401) {
          data = error.response.data;
        } else if (error && error.response && error.response.status === 401) {
          data = {
            code: "access_denied",
          };
        }
        dispatch({
          type: type.ERROR,
          data,
        });
      } else {
        dispatch({
          type: type.ASSIGNED_GET_CHATS_ERROR,
          data: {
            error: error.response.data,
            message: error.response.data.message,
          },
        });
      }
    }
  };
}

export const resetAssignedChats = () => (dispatch) => {
  return dispatch({
    type: type.RESET_ASSIGNED_CHATS,
  });
};

export function getUnassignedChats() {
  // console.log(store.getState().auth)
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    let url = `${config.baseUrl}${config.org}${config.v2}${config.unassignedChatsUrl.replace("{limit}", 50)}`;
    try {
      let response = await axios(url, options);
      // console.log(response)
      if (response && response.data && response.data.chats) {
        let chat = _.last(response.data.chats);
        if (chat) {
          Object.assign(response.data, { lastTouched: chat.lastTouched });
        }
      }
      // console.log(response.data)
      dispatch({
        type: type.UNASSIGNED_GET_CHATS_SUCCESS,
        data: response.data,
      });
      return response.data;
    } catch (error) {
      if (!error || !error.response || error.response.status === 401) {
        let data = null;
        if (error && error.response) {
          data = error.response.data;
        } else {
          data = {
            code: "access_denied",
          };
        }
        dispatch({
          type: type.ERROR,
          data,
        });
      } else {
        dispatch({
          type: type.UNASSIGNED_GET_CHATS_ERROR,
          data: {
            error: error.response.data,
            message: error.response.data.message,
          },
        });
      }
    }
  };
}

export function getChatTranscript(chatId) {
  // console.log(store.getState().auth)
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async () => {
    const url = `${config.baseUrl}${config.org}${config.v1}${config.chatTranscript.replace("{chat_id}", chatId)}`;
    try {
      const response = await axios(url, options);
      return response.data;
    } catch (error) {
      if (!error || !error.response || error.response.status === 401) {
        let data = null;
        if (error && error.response) {
          if (error.response.status === 401) {
            data = {
              code: "access_denied",
            };
          }
        }
        console.error(error);
        console.log(data)
      }
      return [];
    }
  };
}

export function getChatById(chatId) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async () => {
    const url = `${config.baseUrl}${config.org}${config.v1}${config.getChatTranscript.replace("{chatId}", chatId)}`;
    try {
      const response = await axios(url, options);
      return response.data;
    } catch (error) {
      // console.log(error);
      // return [];

      if (!error || !error.response || error.response.status === 401) {
        let data = null;
        if (error && error.response) {
          data = error.response.data;
        } else {
          data = {
            code: "access_denied",
          };
          console.log(data);
        }
      }
      return [];
    }
  };
}

export function getChatHistory({ limit, lastTouched, startTime, endTime }) {
  // const params = `limit=${limit}&lastTouched=${lastTouched}&startTime=${startTime}&endTime=${endTime}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
    params: {
      limit,
      lastTouched,
      startTime,
      endTime,
    },
  };

  return async (dispatch) => {
    dispatch({ type: type.CHAT_LOADING, data: true });
    let url = `${config.baseUrl}${config.org}${config.v1}${config.getChatHostory}`;
    try {
      let response = await axios(url, options);
      if (response && response.data && response.data.chats) {
        let chat = _.last(response.data.chats);
        if (chat) {
          Object.assign(response.data, { lastTouched: chat.lastTouched });
        }
      }

      dispatch({
        type: type.CHAT_HISTORY_SUCCESS,
        data: response.data.chats,
      });
      dispatch({ type: type.CHAT_LOADING, data: false });
    } catch (error) {
      dispatch({ type: type.CHAT_LOADING, data: false });
      if (!error || !error.response || error.response.status === 401) {
        let data = null;
        if (error && error.response) {
          data = error.response.data;
        } else {
          data = {
            code: "access_denied",
          };
        }
        dispatch({
          type: type.CHAT_HISTORY_ERROR,
          data,
        });
      } else {
        dispatch({
          type: type.CHAT_HISTORY_ERROR,
          data: {
            error: error.response.data,
            message: error.response.data.message,
          },
        });
      }
    }
  };
}

export const testFunction = (val) => {
  return {
    type: "TEST",
  };
};

export const updateChatById = (chatId, payload, isActiveAgent, isSelected) => {
  return {
    type: type.UPDATE_CHAT_BY_ID,
    chatId,
    payload,
    isActiveAgent,
    isSelected,
  };
};

export const updateAssignedChatMessageById = (chatId, payload) => {
  if (chatId) {
    return {
      type: type.UPDATE_ASSIGNED_CHAT_MESSAGE_BY_ID,
      chatId,
      payload,
    };
  }
};

export const updateUnassignedChats = (payload) => {
  return {
    type: type.UPDATED_UNASSIGNED_CHATS,
    payload,
  };
};

export const assignedChatById = (chatId, payload) => {
  if (chatId) {
    return {
      type: type.ASSIGN_NEW_CHAT_BY_ID,
      chatId,
      payload,
    };
  }
};

export const updateSelectedLiveChatId = (chatId, clientId) => {
  clientId && subscribeTypesense(clientId);
  return {
    type: type.UPDATE_SELECTED_LIVE_CHAT_ID,
    data: chatId,
  };
};

export const updateSelectedHistoryChatId = (chatId) => {
  return {
    type: type.UPDATE_SELECTED_HISTORY_CHAT_ID,
    data: chatId,
  };
};

export const updateAgentNotification = (data) => {
  return {
    type: type.UPDATE_AGENT_NOTIFICATION,
    data,
  };
};

export const clearSelectedMonitorChat = () => {
  return {
    type: type.CLEAR_SELECTED_MONITOR_CHAT,
  };
};

export const updateSelectedMonitorChatId = (chatId) => {
  return {
    type: type.SET_SELECTED_MONITOR_CHAT_ID,
    data: chatId,
  };
};

export const updateSelectedMonitorChatMessages = (chatId, payload, monitorGroup, participants, groupId, clientData, clientId) => {
  if (chatId) {
    return {
      type: type.UPDATE_SELECTED_MONITOR_CHAT_MESSAGES,
      chatId,
      payload,
      groupId,
      monitorGroup,
      participants,
      clientData,
      clientId
    };
  }
};

export const updateMonitoredChats = (chatId, payload) => {
  return {
    type: type.UPDATE_MONITORED_CHATS,
    chatId,
    payload,
  };
};

export const updateUnmonitoredChats = (chatId, payload) => {
  return {
    type: type.UPDATE_UNMONITORED_CHATS,
    chatId,
    payload,
  };
};

export const updateUserChatMsgPreview = (payload) => {
  return {
    type: type.UPDATE_USER_CHAT_MSG_PREVIEW,
    payload,
  };
};

export const updateMonitorChatById = (chatId, payload) => {
  return {
    type: type.UPDATE_MONITOR_CHAT_BY_ID,
    chatId,
    payload,
  };
};

export const removeUnmonitorChatOnComplete = (chatId, isSelected) => {
  return {
    type: type.REMOVE_UNMONITOR_ON_COMPLETE,
    chatId,
    isSelected,
  };
};

export const removeChatFromMonitor = (chatId) => {
  return {
    type: type.REMOVE_CHAT_FROM_MONITOR,
    chatId,
  };
};

export function getMonitorChats(limit, monitorState, lastTouched) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    try {
      let url = config.moniterChats.replace("{limit}", limit);
      url = url.replace("{assignedTo}", "group");
      url = url.replace("{monitorState}", monitorState);
      url = url.replace("{lastTouched}", lastTouched);

      const { selectedMonitorChatId } = store.getState().chat;

      let response = await axios(`${config.baseUrl}${url}`, options);
      if (!monitorState) {
        dispatch({
          type: type.SET_UNMONITORED_CHATS,
          data: response.data.chats,
          selectedMonitorChatId: selectedMonitorChatId,
        });
      } else {
        dispatch({
          type: type.SET_MONITORED_CHATS,
          data: response.data.chats,
          selectedMonitorChatId: selectedMonitorChatId,
        });
      }

      dispatch({ type: type.CHAT_LOADING, data: false });
      return response.data;
    } catch (error) {
      dispatch({ type: type.CHAT_LOADING, data: false });
      if (error && error.response && error.response.status === 401) {
        let data = null;
        if (error && error.response) {
          data = error.response.data;
        } else {
          data = {
            code: "access_denied",
          };
        }
        dispatch({
          type: type.ERROR,
          data,
        });
      } else if (error && error.response && error.response.data) {
        dispatch({
          type: type.ASSIGNED_GET_CHATS_ERROR,
          data: {
            error: error.response.data,
            message: error.response.data.message,
          },
        });
      } else {
        console.log(error);
      }
    }
  };
}


export const getChatsByClientId = (clientId) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    }
  };
  return async (dispatch) => {
    try {
      let url = `${config.baseUrl}${config.org}${config.v1}${config.getClientChatsUrl.replace("{clientId}", clientId)}`;
      let resp = await axios(url, options);
      return (resp && resp.data && resp.data.chats) || [];
    } catch (error) {
      if (!error || !error.response || error.response.status === 401) {
        let data = null;
        if (error && error.response) {
          data = error.response.data;
        } else {
          data = {
            code: "access_denied",
          };
        }
        dispatch({
          type: type.ERROR,
          data,
        });
      }
    }
  };
};

export const addToUnmonitoredChatIds = chatId => {
  return {
    type: type.ADD_TO_UNMONITORED_CHAT_IDS,
    payload: chatId
  }
}

export const removeFromUnmonitoredChatIds = (chatId) => {
  return {
    type: type.REMOVE_FROM_UNMONITORED_CHAT_IDS,
    payload: chatId
  }
}

export const addToCompletedChats = chatId => {
  return {
    type: type.ADD_TO_COMPLETED_CHATS,
    payload: chatId
  }
}
