import React, { useContext, useCallback, useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { humanFileSize, generateUniqueId } from "../../../../helpers/util";
import config from "../../../../apiConfig";
import completeChatIcon from "../../../../assets/images/ico-complete-chat.svg";
import { SelectedUserContext } from "..";
import { useSelector, useDispatch } from "react-redux";
import { FiDownloadCloud } from "react-icons/fi";
import moreOption from "../../../../assets/images/moreOption.svg";

import {
  getChatTranscript,
  updateAgentNotification,
  getFileUploadUrl,
  uploadFile,
  getChatById,
  updateAssignedChatMessageById,
} from "../../../../actions/chatsAction";

import { useComponentVisible, useOnScreen } from "../../../../utils/customHooks";
import Transfer from "../../../common/Transfer";
import { sendChatMessage, autoPilot, sendTyping, transferChat } from "../../../../helpers/mqttService";
import { Upload, Switch, Alert, message, Popover, Modal, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import "../../../../assets/sass/live.sass";
import { persistChat, clearPersistedChat } from "../../../../actions/uiAction";
import QuickRespPopup from "../../../common/QuickRespPopup";
import Spinner from "../../../common/Spinner";
import { getQuickResp } from "../../../../actions/quickRespAction";
import FAQPopup from "../../../common/FAQPopup";
import MemoizedChatMessages from "../../../common/MemoizedChatMessages";
import TypingArea from "./TypingArea";
import { AssistFlowAlertDialog } from "./AssistFlowDialog";

export interface IMainProps {}

const AlertWrapper = styled.div`
  position: absolute;
  top: 15px;
  z-index: 10;
  min-width: 600px;
`;

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageWrapper = styled.div`
  padding: 20px 0px;
  background: white;
  flex: 1;
  max-height: calc(100vh - 58px - 130px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

const InputContianer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const TypingDots = styled.div`
  margin-left: 18px;
  margin-top: 10px;
  position: relative;
  left: -9999px;
  width: 2px;
  height: 2px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  box-shadow: 9984px 0 0 0 ${(props) => props.theme.primaryColor}, 9992px 0 0 0 ${(props) => props.theme.primaryColor},
    9999px 0 0 0 ${(props) => props.theme.primaryColor};
  animation: dotTyping 1.5s infinite linear;
  @keyframes dotTyping {
    0% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.primaryColor},
        9992px 0 0 0 ${(props) => props.theme.primaryColor}, 9999px 0 0 0 ${(props) => props.theme.primaryColor};
    }
    16.667% {
      box-shadow: 9984px -6px 0 0 ${(props) => props.theme.primaryColor},
        9992px 0 0 0 ${(props) => props.theme.primaryColor}, 9999px 0 0 0 ${(props) => props.theme.primaryColor};
    }
    33.333% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.primaryColor},
        9992px 0 0 0 ${(props) => props.theme.primaryColor}, 9999px 0 0 0 ${(props) => props.theme.primaryColor};
    }
    50% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.primaryColor},
        9992px -6px 0 0 ${(props) => props.theme.primaryColor}, 9999px 0 0 0 ${(props) => props.theme.primaryColor};
    }
    66.667% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.primaryColor},
        9992px 0 0 0 ${(props) => props.theme.primaryColor}, 9999px 0 0 0 ${(props) => props.theme.primaryColor};
    }
    83.333% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.primaryColor},
        9992px 0 0 0 ${(props) => props.theme.primaryColor}, 9999px -6px 0 0 ${(props) => props.theme.primaryColor};
    }
    100% {
      box-shadow: 9984px 0 0 0 ${(props) => props.theme.primaryColor},
        9992px 0 0 0 ${(props) => props.theme.primaryColor}, 9999px 0 0 0 ${(props) => props.theme.primaryColor};
    }
  }
`;

const InputWrapper = styled.form`
  height: 72px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(168, 171, 189, 0.4);
  background: ${(props) => (props.theme.privateChat ? "#2b2b2b" : "#FFFFFF")};
`;

const Input = styled.input`
  border: none;
  background: none;
  resize: none;
  outline: none;
  padding: 0px 29px 0 8px;
  flex: 1;
  color: ${(props) => (props.theme.privateChat ? "#ffffff" : "#000000")};
`;

const PrivateNoteWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.theme.privateChat ? "#2b2b2b" : "#FFFFFF")};
`;

const IconWrapper = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
`;

const SendIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  box-shadow: 0 4px 8px 0 ${(props) => props.theme.primaryColor};
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  padding: 20px 20px 0 20px;
  display: flex;
`;

const NameContainer = styled.p`
  flex: 1;
`;

const DownloadWrapper = styled.div`
  cursor: pointer;
  font-size: 1.6rem;
  transition: font-size 0.5s;
  &:hover {
    font-size: 1.65rem;
  }
`;

const CompleteChatWrapper = styled.div`
  cursor: pointer;
  font-size: 1.6rem;
  margin: 0 10px;
`;

const AttachmentHeader = styled.p`
  font-size: 14px;
  color: #212332;
`;

const AttachmentContent = styled.span`
  color: #212332;
  font-size: 14px;
  font-weight: bold;
  padding-left: 13px;
`;

const ToggleText = styled.span`
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #7f90af;
  margin-left: 10px;
`;

const MsgPreviewPanel = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  bottom: -1px;
  transition: max-height 0.3s;
  max-height: ${(props) => props.theme.maxHeight}px;
  overflow: hidden;
  background: #f5f5f5;
`;

export const MainChatContext = React.createContext({
  chatMessage: {},
  setChatMessage: (arg: any) => {},
});

export default function Main(props: IMainProps) {
  const usertypingRef = useRef(null);
  const [enableQuickResp, setEnableQuickResp] = useState(false);
  const [enableFAQ, setEnableFAQ] = useState(false);
  const [shortCode, setShortCode] = useState(null);
  const [confirmCompleteChat, setConfirmCompleteChat] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chatMessage, setChatMessage] = useState({});
  const [inputMsg, setInputMsg] = useState("");
  const [privateChat, setPrivateChat] = useState(false);
  const [messagesStartRef, messagesStartVisible] = useOnScreen({ threshold: 0.5 });
  const [chatPosition, setChatPosition] = useState({ position: 0 });
  const [recentMessages, setRecentMessages] = useState([]);
  const [inbox, setInbox] = useState([]);
  const [isMoreOptionVisible, setIsMoreOptionVisible] = useState(false);
  const [transferChatOption, setTransferChatOption] = useState(false);
  const [assistFlow, setAssistFlow] = useState<{
    show: boolean
    sessionId: string
  }>({
    sessionId: "",
    show: false,
  })
  const dispatch = useDispatch();
  const { theme, chats: persistedChats } = useSelector((state: any) => state.ui);
  const [autopilotToggle, setAutopilotToggle] = useState(false);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const usertypingToBottom = () => {
    if (usertypingRef && usertypingRef.current) {
      usertypingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const content = (
    <div className="more-option-panel">
      <p onClick={() => downloadChatTranscript(chatId)}>
        <label>Chat Transcript</label>
        <div className="action">
          <FiDownloadCloud color="#a5a6ae" size="22px" />
        </div>
      </p>
      <p
        onClick={() => {
          setTransferChatOption(true);
          setIsMoreOptionVisible(false);
        }}
      >
        <label>Chat Transfer</label>

        <div className="action">
          <SendOutlined style={{ fontSize: "20px", color: "#a5a6ae" }} />
        </div>
      </p>
    </div>
  );
  const validFileContentType = {
    image: ["image/jpg", "image/png", "image/jpeg"],
    // video: ['video/mp4'],
    // audio: ['audio/mp3'],
    pdf: ["application/pdf"],
    // doc: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    // txt: ['text/plain'],
    // xls: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'csv', 'application/vnd.ms-excel'],
    // ppt: ['application/vnd.openxmlformats-officedocument.presentationml.presentation']
  };
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const {
    ref: shortcutRef,
    isComponentVisible: isShortcutVisible,
    setIsComponentVisible: setShortcutVisible,
  } = useComponentVisible(false);
  const downloadChatTranscript = (payload: string) => {
    setIsMoreOptionVisible(false);
    getChatTranscript(payload)()
      .then((resp) => {
        const csvString = resp.split("\n").join("\n");
        const universalBOM = "\uFEFF";
        const a = window.document.createElement("a");
        a.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent(universalBOM + csvString));
        a.setAttribute("download", `${selectedLiveChatId}.csv`);
        window.document.body.appendChild(a);
        a.click();
      })
      .catch((e) => console.log(e));
  };
  // const context = useContext(SelectedUserContext);
  const { selectedLiveChatId, userChatMsgPreview } = useSelector((state: any) => state.chat);
  const { assignedChats: chats, agentNotification } = useSelector((state: any) => state.chat);
  const messages = (selectedLiveChatId && chats[selectedLiveChatId] && chats[selectedLiveChatId].messages) || [];
  const clientData = selectedLiveChatId && chats[selectedLiveChatId] && chats[selectedLiveChatId].clientData;
  const chatId = selectedLiveChatId && chats[selectedLiveChatId] && chats[selectedLiveChatId]._id;
  useEffect(scrollToBottom, [inbox, messages, selectedLiveChatId]);
  let userTypingText =
    userChatMsgPreview && userChatMsgPreview.msg && userChatMsgPreview.msg.text ? userChatMsgPreview.msg.text : "";
  useEffect(usertypingToBottom, [userTypingText]);
  useEffect(() => {
    if (selectedLiveChatId) {
      setAutopilotToggle(
        selectedLiveChatId &&
          chats[selectedLiveChatId] &&
          chats[selectedLiveChatId].autoPilot &&
          chats[selectedLiveChatId].autoPilot.isOn
      );
      setLoading(true);
      getChatById(selectedLiveChatId)()
        .then((data) => {
          // if the chat is in a flow session and `assistFlow` field is `null`,
          // show a dialog for the agent to decide whether to assist or not
          if(
            data.sessionInfo && 
            data.sessionInfo.assistFlow === null
          ) {
            setAssistFlow({ show: true, sessionId: data.sessionInfo.sessionId }) 
          } else {
            setAssistFlow({ show: false, sessionId: '' }) 
          }

          if (data.sessionInfo && data.sessionInfo.waitingForm) {
            const waitingForm = data.sessionInfo.waitingForm
            data.messages.push({
              mId: generateUniqueId(),
              fromId: waitingForm.fromId,
              msg: waitingForm.msg,
              sentAt: waitingForm.sentAt,
              msgType: 'form',
              fromName: waitingForm.fromName,
              nodeType: 'waitingForm',
            })
          }

          const messagesCount = data.messages.length;
          let position = messagesCount - 50;
          if (position < 0) {
            position = 0;
          }
          setChatPosition({ position });
          setRecentMessages(data.messages.slice(position));
          setLoading(false);
          setInbox([]);
          dispatch(
            updateAssignedChatMessageById(selectedLiveChatId, {
              messages: data.messages,
              participants: data.participants,
              clientData: data.clientData,
              clientId: data.clientId,
            })
          );
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    } else {
      // where there is no `selectedLiveChatId`, remove the messages from the DOM
      setRecentMessages([]);
      setLoading(false);
      setInbox([]);
    }
  }, [selectedLiveChatId]);

  useEffect(() => {
    setAutopilotToggle(
      selectedLiveChatId &&
        chats[selectedLiveChatId] &&
        chats[selectedLiveChatId].autoPilot &&
        chats[selectedLiveChatId].autoPilot.isOn
    );
  }, [chats && chats[selectedLiveChatId] && chats[selectedLiveChatId].autoPilot]);

  useEffect(() => {
    const info = persistedChats && persistedChats[selectedLiveChatId];
    if (info) {
      setPrivateChat(info.isPrivate);
    } else {
      setPrivateChat(false);
    }
  }, [persistedChats, selectedLiveChatId]);

  useEffect(() => {
    if (chats && chats[selectedLiveChatId] && chats[selectedLiveChatId].messages) {
      const messagesCount = chats[selectedLiveChatId].messages.length;
      const lastMessage = chats[selectedLiveChatId].messages[messagesCount - 1];
      if (lastMessage.fromInbox) {
        setInbox((prevState) => [...prevState, lastMessage]);
      }
    }
  }, [chats && chats[selectedLiveChatId] && chats[selectedLiveChatId].messages]);

  useEffect(() => {
    if (!loading && messagesStartVisible && selectedLiveChatId) {
      setChatPosition((prevState) => {
        let newPosition = prevState.position - 50;
        if (newPosition < 0) {
          newPosition = 0;
        }
        setRecentMessages(messages.slice(newPosition));
        const temp = messages.find((message, index) => index === prevState.position) || "";
        const node = document.getElementById(temp.mId);
        if (node && prevState.position !== 0) {
          node.scrollIntoView();
        }
        return { ...prevState, position: newPosition };
      });
    }
  }, [messagesStartVisible]);

  const handleSubmit = (e) => {
    if (chatMessage && chatMessage[selectedLiveChatId].charAt(0) == "/") {
      setShortCode(chatMessage[selectedLiveChatId]);
      return;
    }

    e.preventDefault();
    if (uploadFileProps.fileList.length > 0) {
      uploadFile(uploadFileProps.fileList[0])().then(() => {
        let msg = {
          url: uploadFileProps.fileList[0].uploadUrl.split("?")[0],
          fileName: uploadFileProps.fileList[0].name,
          fileSize: uploadFileProps.fileList[0].size,
          desc: (chatMessage && chatMessage[selectedLiveChatId]) || "",
        };
        const payload = {
          msgType: uploadFileProps.fileList[0].msgType,
          msg,
          chatId: selectedLiveChatId,
          isPrivate: privateChat,
        };
        setChatMessage({ ...chatMessage, [selectedLiveChatId]: "" });
        sendChatMessage(payload);
        setIsComponentVisible(false);
        setUploadFileProps({
          ...uploadFileProps,
          fileList: [],
        });
      });
    } else if (chatMessage && chatMessage[selectedLiveChatId] && chatMessage[selectedLiveChatId].trim()) {
      const payload = {
        msgType: "text",
        msg: { text: chatMessage[selectedLiveChatId].replace(/(?:\r\n|\r|\n)/g, "<br>").trim() },
        chatId: selectedLiveChatId,
        isPrivate: privateChat,
      };
      setChatMessage({ ...chatMessage, [selectedLiveChatId]: "" });
      sendChatMessage(payload);
    }
  };
  const handleAutoPilot = (flag: boolean) => {
    autoPilot(chatId, flag);
  };

  const handleCompleteChat = () => {
    setConfirmCompleteChat(false);
    const selectedChat = (selectedLiveChatId && chats[selectedLiveChatId]) || {};
    transferChat(chatId, selectedChat);
  };

  const handleNotificationClose = useCallback(() => dispatch(updateAgentNotification("")), [dispatch]);

  const handlePrivateChat = useCallback(
    (checked: boolean, selectedLiveChatId: string) => dispatch(persistChat(selectedLiveChatId, { isPrivate: checked })),
    [dispatch]
  );

  const beforeUpload = (file: any) => {
    let isValidType = false;
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (isLt2M) {
      for (var i in validFileContentType) {
        if (validFileContentType[i].indexOf(file.type) > -1) {
          isValidType = true;
          file.msgType = i;
        }
      }
      if (!isValidType) {
        message.error(`The ${file.name} file extension is not able to attached in chat.`);
      }
    } else {
      message.error(
        `File size limit to be attached in email is less than 2MB. ${file.name} size is ${humanFileSize(
          file.size,
          true
        )}`
      );
    }

    if (isValidType && isLt2M) {
      const fileDetails = file.name.split(/[\s.]+/);
      const fileExt = fileDetails[fileDetails.length - 1];
      setUploadFileProps({
        ...uploadFileProps,
        fileList: [file],
      });
      getFileUploadUrl(fileExt)().then((resp) => {
        if (resp.url) {
          file.uploadUrl = resp.url;
        }
        setUploadFileProps({
          ...uploadFileProps,
          fileList: [file],
        });
      });
    }
    return false;
  };

  const onRemove = () => {
    setUploadFileProps({
      ...uploadFileProps,
      fileList: [],
    });
  };

  const [uploadFileProps, setUploadFileProps] = useState({
    multiple: false,
    accept: "image/png, image/jpeg, image/jpg,.pdf,.doc,.docx",
    processData: false,
    fileList: [],
    beforeUpload: beforeUpload,
    onRemove: onRemove,
  });

  const onCheckChar = (val) => {
    if (val.charAt(0) === "/") {
      setEnableFAQ(false);
      setEnableQuickResp(true);
    } else {
      setEnableQuickResp(false);
    }
  };

  const onSelectQuickResp = (item) => {
    setShortCode(null);
    if (item) {
      setEnableQuickResp(false);
      if (item && item.response) {
        setChatMessage({ ...chatMessage, [selectedLiveChatId]: item.response });
      }
    }
  };

  const onSelectFAQ = (item) => {
    if (item) {
      setEnableFAQ(false);
      if (item && item.answer) {
        setChatMessage({ ...chatMessage, [selectedLiveChatId]: item.answer });
      }
    }
  };

  return (
    <MainChatContext.Provider value={{ chatMessage, setChatMessage }}>
      <Spinner loading={loading}/>
      <Container className="live" style={{ filter: loading ? "blur(3px)" : "" }}>
        {clientData && clientData.fromName && (
          <HeaderContainer>
            {agentNotification && (
              <AlertWrapper>
                <Alert
                  message="Error"
                  description={agentNotification}
                  type="error"
                  closable
                  onClose={handleNotificationClose}
                />
              </AlertWrapper>
            )}
            <NameContainer>
              <strong>You are chatting with {clientData.fromName}</strong>
            </NameContainer>
            <CompleteChatWrapper
              title="Complete Chat"
              onClick={() => {
                setConfirmCompleteChat(true);
              }}
            >
              <img style={{ width: "25px" }} src={completeChatIcon} alt="complete chat" />
            </CompleteChatWrapper>
            <Popover placement="bottomRight" visible={isMoreOptionVisible} content={content} trigger="click">
              <div className="more" onClick={() => setIsMoreOptionVisible(!isMoreOptionVisible)}>
                <img src={moreOption} className="icon" />
              </div>
            </Popover>
          </HeaderContainer>
        )}
        {assistFlow.show && selectedLiveChatId && <AssistFlowAlertDialog setAssistFlow={setAssistFlow} sessionId={assistFlow.sessionId}/>}
        <MessageWrapper>
          <div ref={messagesStartRef as React.LegacyRef<HTMLDivElement>} />
          <MemoizedChatMessages chatId={chatId} messages={recentMessages} />
          <MemoizedChatMessages chatId={chatId} messages={inbox} />
          <div ref={messagesEndRef} />
        </MessageWrapper>
        {transferChatOption && (
          <Transfer
            chatId={selectedLiveChatId}
            visible={transferChatOption}
            onCloseTransferChatOption={() => setTransferChatOption(false)}
          />
        )}
        {selectedLiveChatId && (
          <QuickRespPopup
            visible={enableQuickResp}
            getQuickRespByCode={shortCode}
            typingText={(chatMessage && chatMessage[selectedLiveChatId]) || ""}
            onSelect={onSelectQuickResp}
          />
        )}
        {userChatMsgPreview &&
          userChatMsgPreview.chatId == selectedLiveChatId &&
          userChatMsgPreview.msg.text.length > 0 && (
            <MsgPreviewPanel theme={{ maxHeight: userChatMsgPreview.msg.text.length > 0 ? 130 : 0 }}>
              {/* <div style={{ padding: 10, fontWeight: 800, color: theme.primaryColor, marginBottom: 8 }}>{userChatMsgPreview.fromName} typing...</div> */}
              <div
                style={{
                  height: 56,
                  color: "#a8abbd",
                  marginBottom: 8,
                  backgroundColor: "#212332",
                  fontFamily: "Muli",
                  fontSize: 12,
                  padding: 20,
                  display: "flex",
                  alignItems: "center",
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                }}
              >
                <div style={{ color: theme.secondaryColor, fontSize: 14, fontWeight: "bold", marginRight: 10 }}>
                  {userChatMsgPreview.fromName}
                </div>{" "}
                typing
                <TypingDots />
              </div>
              <div
                ref={usertypingRef}
                style={{
                  padding: "0px 10px 10px 10px",
                  maxHeight: 60,
                  wordBreak: "break-all",
                  overflowY: "auto",
                  background: "#f5f5f5",
                }}
              >
                {userChatMsgPreview.msg.text}
              </div>
            </MsgPreviewPanel>
          )}
        {selectedLiveChatId && enableFAQ && <FAQPopup onSelect={onSelectFAQ} />}
        {selectedLiveChatId && (
          <InputContianer>
            <TypingArea
              selectedLiveChatId={selectedLiveChatId}
              setChatMessage={setChatMessage}
              privateChat={privateChat}
              handlePrivateChat={handlePrivateChat}
              enableFAQ={enableFAQ}
              setEnableFAQ={setEnableFAQ}
              setEnableQuickResp={setEnableQuickResp}
              uploadFileProps={uploadFileProps}
              setIsComponentVisible={setIsComponentVisible}
              isComponentVisible={isComponentVisible}
              onRemove={onRemove}
              theme={theme}
              ref={ref}
              uploadFile={uploadFile}
              setUploadFileProps={setUploadFileProps}
              setShortCode={setShortCode}
              clientData={clientData}
              onCheckChar={onCheckChar}
            />
            {/* <PrivateNoteWrapper theme={{ privateChat }}>
            <div className="agent-details" style={{ marginLeft: "27px" }}>
              <Switch
                checked={privateChat}
                defaultChecked={privateChat}
                onChange={(checked) => handlePrivateChat(checked, selectedLiveChatId)}
              />
              <ToggleText>Private Chat</ToggleText>
            </div>
          </PrivateNoteWrapper> */}
          </InputContianer>
        )}
        <Modal
          title="COMPLETE CHAT"
          width="444px"
          className="confirm-complete-chat"
          visible={confirmCompleteChat}
          onCancel={() => {
            setConfirmCompleteChat(false);
          }}
          footer={null}
        >
          <div>
            <div className="desc">Are you sure you want to complete the chat?</div>
            <div className="action">
              <Button className="yes" onClick={handleCompleteChat}>
                YES
              </Button>
              <Button
                className="no"
                onClick={() => {
                  setConfirmCompleteChat(false);
                }}
              >
                NO
              </Button>
            </div>
          </div>
        </Modal>
      </Container>
    </MainChatContext.Provider>
  );
}
