import MarkdownIt from "markdown-it";
import { store } from "../store";
import { rootSelectedMenu, rootSelectedTab } from "../actions/uiAction";
import { signSuccess, sessionTokenSuccess } from "../actions/taskAction";
import type from "../types/actionTypes";
import { setShowSoloWidget, initSoloSession } from "../actions/soloWidgetActions";
import { cleanupSoloSession } from "../components/chats/SoloWidget/utils";

export const decodeEntities: any = (encodedString: string, maxLimit = 140, shouldTruncate = false) => {
  const md = new MarkdownIt({ html: true });
  if (encodedString) {
    const newStr = encodedString.replace(/\\n/g, "<br />");
    // if (shouldTruncate) {
    //   const caret = newStr.length > maxLimit ? "..." : "";
    //   newStr = newStr.substr(0, maxLimit) + caret;
    // }
    const textArea = document.createElement("textarea");
    textArea.innerHTML = newStr;
    const val = textArea.value;
    const markdownOutput = md.render(val);
    if (shouldTruncate) {
      const caret = markdownOutput.length > maxLimit ? "..." : "";
      return markdownOutput.substr(0, maxLimit) + caret;
    }
    return markdownOutput;
  }
  return encodedString;
};
export const LightenDarkenColor = (col: string, amt: number) => {
  let usePound = false;
  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }
  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
};

export const clearSession = () => {
  store.dispatch(rootSelectedMenu(null));
  store.dispatch(rootSelectedTab(null));
  store.dispatch(signSuccess({ accessToken: null }));
  store.dispatch(sessionTokenSuccess(null));
  store.dispatch({ type: "SIGNOUT_SUCCESS" });
  store.dispatch({ type: "AGENTS_GET_SUCCESS", data: { agents: [] } });
  localStorage.setItem("userInfo", "");
  localStorage.setItem("persist:primary", "");
  sessionStorage.setItem("clientId", "");

  /* cleaning up solo widget related things */
  store.dispatch(setShowSoloWidget(false))
  store.dispatch(initSoloSession(null))
  cleanupSoloSession() 
};


export const renderWidgets = (widgetType: string) => {
  const payload = {
    fromName: "A8Bot 0101",
    from: {
      userId: "a/5c4715011667670fb10be6a4",
    },
    mId: Date.now() + getRandomID(),
    id: Date.now() + getRandomID(),
    timestamp: Date.now(),
    getFeedback: false,
  };

  switch (widgetType) {
    case "userTextMessage":
      payload["from"]["userId"] = "u/5c4715011667670fb10be6a4";
      payload["msgType"] = "text";
      payload["msg"] = {
        text: "Hello!",
      };
      return payload;
    case "agentTextMessage":
      payload["msgType"] = "text";
      payload["msg"] = {
        text: "Hello! **How** are you doing?",
      };
      return payload;
    case "simpleChoice":
      payload["msgType"] = "simpleChoice";
      payload["msg"] = {
        msgType: "simpleChoice",
        msg: {
          text: "Bummer! Let me see if I can help. What type of stain is it?",
          style: {
            orientation: "V",
          },
          choices: [
            {
              id: "1",
              label: "Oil",
              masked: true,
            },
            {
              id: "2",
              label: "Sambhar",
              masked: true,
            },
          ],
        },
      };
      return payload;
    case "cardChoice":
      payload["msgType"] = "cardChoice";
      payload["msg"] = {
        msg: {
          text: "Is your query relating to one of the NEGATIVE accounts as listed below?",
          style: {
            orientation: "V",
          },
          choices: [
            {
              id: 1,
              label: "FULLERTON - Post Written Off Settled",
              masked: true,
              subText: "XXXXXXXXXXXX5950",
            },
            {
              id: 2,
              label: "HDFC Bank - Charge Off/Written Off",
              masked: true,
              subText: "XXXXX9159",
            },
          ],
        },
      };
      return payload;
    case "autoComplete":
      payload["msgType"] = "autocomplete";
      payload["msg"] = {
        msgType: "autocomplete",
        isMulti: true,
        isClearable: true,
        isCreatable: true,
        msg: {
          title: "Which account do you want to choose",
          options: [
            {
              id: "1",
              label: "Axis Bank",
              value: "axisBank",
            },
            {
              id: "2",
              label: "Canara Bank",
              value: "canaraBank",
            },
            {
              id: "3",
              label: "State Bank of India",
              value: "sbi",
            },
            {
              id: "4",
              label: "Andhra Bank",
              value: "andhraBank",
            },
            {
              id: "5",
              label: "HDFC Bank",
              value: "hdfcBank",
            },
          ],
        },
      };
      return payload;
    case "carousel":
      payload["msgType"] = "carousel";
      payload["msg"] = {
        msgType: "card-carousel",
        desc: "PFA the image",
        cards: [
          {
            id: "0",
            url:
              "https://images.unsplash.com/photo-1574204991250-d36f8c1fb580?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max",
            title: "Classic Shirt",
            subtitle:
              "Handmode product Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            description: "$25",
            actions: {
              primary: {
                label: "Submit",
                type: "messageBack",
                value: {
                  property: "propertyValue",
                },
              },
              secondary: {
                label: "Visit us",
                type: "openUrl",
                value: "https://msdn.microsoft.com/en-us/microsoft-teams/tabs",
              },
            },
          },
          {
            id: "1",
            url: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample87.jpg",
            title: "Some random product",
            subtitle: "Charcoal",
            description: "$25",
            actions: {
              primary: {
                label: "Submit",
                type: "messageBack",
                value: {
                  property: "propertyValue",
                },
              },
              secondary: {
                label: "Visit us",
                type: "openUrl",
                value: "https://msdn.microsoft.com/en-us/microsoft-teams/tabs",
              },
            },
          },
          {
            id: "2",
            url: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample119.jpg",
            title: "Faded cloth",
            subtitle: "green",
            description: "$15",
            actions: {
              primary: {
                label: "Submit",
                type: "messageBack",
                value: {
                  property: "propertyValue",
                },
              },
              secondary: {
                label: "Visit us",
                type: "openUrl",
                value: "https://msdn.microsoft.com/en-us/microsoft-teams/tabs",
              },
            },
          },
          {
            id: "3",
            url: "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample120.jpg",
            title: "Classic Tshirt",
            subtitle: "Charcoal Color",
            description: "$25",
            actions: {
              primary: {
                label: "Submit",
                type: "messageBack",
                value: {
                  property: "propertyValue",
                },
              },
              secondary: {
                label: "Visit us",
                type: "openUrl",
                value: "https://msdn.microsoft.com/en-us/microsoft-teams/tabs",
              },
            },
          },
          {
            id: "4",
            url:
              "https://images.unsplash.com/photo-1575026584098-81500f6835d6?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max",
            title: "Shoes",
            subtitle: "Brown",
            description: "$225",
            actions: {
              primary: {
                label: "Submit",
                type: "messageBack",
                value: {
                  property: "propertyValue",
                },
              },
              secondary: {
                label: "Visit us",
                type: "openUrl",
                value: "https://msdn.microsoft.com/en-us/microsoft-teams/tabs",
              },
            },
          },
        ],
      };
      return payload;
    case "chart":
      payload["msgType"] = "chart";
      payload["msg"] = {
        type: "bar",
        data: {
          labels: ["January", "February", "March", "April", "May", "June", "July"],
          datasets: [
            {
              label: "Dataset 1",
              backgroundColor: "red",
              borderColor: "red",
              borderWidth: 1,
              data: [53, 39, -39],
            },
            {
              label: "Dataset 2",
              backgroundColor: "#E980FC",
              borderColor: "#E980FC",
              borderWidth: 1,
              data: [10, 20, 30, 40],
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Chart.js Bar Chart",
          },
        },
      };
      return payload;
    case "dataTable":
      payload["msgType"] = "datatable";
      payload["msg"] = {
        msgType: "datatable",
        msg: {
          text: "",
          subText: "",
          header: [
            {
              name: "Land Use",
            },
            {
              name: "Rent Rate ",
            },
            {
              name: "Land Use",
            },
            {
              name: "Base Rate ",
            },
            {
              name: "House Use",
            },
            {
              name: "Base Rate ",
            },
          ],
          rows: [
            {
              data: [
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
              ],
            },
            {
              data: ["new", "4800", "new", "4800", "new", "4800"],
            },
            {
              data: ["Idly", "5600", "", "", ""],
            },
            {
              data: [
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
              ],
            },
            {
              data: [
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
              ],
            },
            {
              data: [
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
              ],
            },
            {
              data: [
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
              ],
            },
            {
              data: [
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
                "Industrial use / Rate",
                "3200&4000",
              ],
            },
            {
              data: ["new", "4800", "value", "4800", "value", "4800"],
            },
            {
              data: ["Idly", "5600"],
            },
          ],
        },
      };
      return payload;
    case "document":
      payload["msgType"] = "pdf";
      payload["msg"] = {
        msgType: "pdf",
        url: "https://researchtorevenue.files.wordpress.com/2015/04/1r41ai10801601_fong.pdf",
        desc: "PFA the document",
        fileSize: 554982,
        fileName: "research",
      };
      return payload;
    case "feedback":
      payload["msgType"] = "feedback";
      payload["msg"] = {
        type: "rating-experience",
        title: "Leave Feedback",
        subtitle: "Please rate your experience, so that we can make it better for other customers",
        actions: [
          {
            text: "Positive",
            value: "1",
          },
          {
            text: "Neutral",
            value: "0",
          },
          {
            text: "Negative",
            value: "-1",
          },
        ],
      };
      return payload;
    case "image":
      payload["msgType"] = "image";
      payload["msg"] = {
        msgType: "image",
        desc: "PFA the image",
        fileName: "Screenshot 2018-11-08 at 11.52.03 AM.png",
        fileSize: 554982,
        url: "https://picsum.photos/600",
      };
      return payload;
    case "oAuth":
      payload["msgType"] = "oauthList";
      payload["msg"] = {
        msg: {
          list: [
            {
              iconURL: "https://a8live.s3.ap-south-1.amazonaws.com/5da00c7cf0a2974409825026/office.png",
              id: 0,
              label: "Sign in with Office 365",
              oauthURL: "https://livechat-testing.autonom8.com/oauth/azure?botHandle=maheshbot",
            },
            {
              iconURL:
                "https://a8live.s3.ap-south-1.amazonaws.com/5da00c7cf0a2974409825026/Okta_Logo_BrightBlue_Medium.png",
              id: 1,
              label: "Sign in with Okta",
              oauthURL: "https://livechat-testing.autonom8.com/oauth/okta?botHandle=maheshbot",
            },
            {
              iconURL: "https://cdn.poststatus.com/wp-content/uploads/2018/09/1024px-Google__G__Logo.png",
              id: 2,
              label: "Sign in with Google",
              oauthURL: "https://livechat-testing.autonom8.com/oauth/google?botHandle=maheshbot",
            },
            {
              iconURL:
                "https://a8live.s3.ap-south-1.amazonaws.com/5da00c7cf0a2974409825026/Twitter_Social_Icon_Circle_Color.png",
              id: 3,
              label: "Sign in with Twitter",
              oauthURL: "https://livechat-testing.autonom8.com/oauth/twitter?botHandle=maheshbot",
            },
          ],
          style: {
            orientation: "V",
          },
          text: "Which account do you want to choose?",
        },
        msgType: "oauthList",
        handlingAgent: "5dea289cd0acf564c5a8114e",
        sentAt: 1584939918000,
        state: "assigned",
        autoPilot: {},
      };
      return payload;
    case "video":
      payload["msgType"] = "video";
      payload["msg"] = {
        msgType: "video",
        desc: "PFA the image",
        fileName: "Screenshot 2018-11-08 at 11.52.03 AM.png",
        fileSize: 554982,
        url: "https://www.youtube.com/watch?v=7sncuRJtWQI",
      };
      return payload;
    case "suggestions":
      payload["msgType"] = "botSuggestions";
      payload["questions"] = ["Suggestion 1", "Hello", "there", "hi", "Autonom8"];
      return payload;
    case "loading":
      payload["msgType"] = "loading";
      payload["msg"] = {
        text: "Loading",
      };
      return payload;
    default:
      return payload;
  }
};

const getRandomID = () => {
  const crypto = window.crypto;
  var array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0].toString(36);
};

export const parseAdaptiveCard = (msg: any) => {
  if(msg.payload) {
    try {
      return JSON.parse(atob(msg.payload)) 
    } catch(err) {
      return msg.payload
    }
  }
  return msg
};

export const pathToRedirect = (roles) =>
  roles.includes("admin") ? "/settings" : roles.includes("supervisor") ? "/resources" : "/chats";

export const allowAccess = (history, push) => {
  const userInfo = localStorage.getItem("userInfo");
  if (userInfo) {
    const { info, sessionToken } = JSON.parse(userInfo);
    if (info && sessionToken) {
      const path = pathToRedirect(info.roles);
      store.dispatch({
        type: type.SIGNIN_SUCCESS,
        data: info,
      });
      store.dispatch({
        type: type.SESSION_TOKEN_SUCCESS,
        data: sessionToken,
      });
      store.dispatch({
        type: type.ERROR,
      });
      if (push) {
        history.push(path);
      }
    }
  }
};

