import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import "../../assets/sass/settings.sass";
import SettingSaveDetail from "./SettingSaveDetail";
import Agent from "./agents/Agent";
import Group from "./groups/Group";
// import General from "./general";

class Admin extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  onSubmitHandler = (event: any) => {
    event.preventDefault();
    this.props.sign(this.state);
  };

  async componentDidUpdate() { }

  render() {
    let headerName = this.props.ui.activeRootNav;
    let tabName = ["agents", "groups"].indexOf(this.props.ui.activeRootTab) > -1 ? this.props.ui.activeRootTab : "agents";
    let roles = this.props.auth && this.props.auth.info && this.props.auth.info.roles;
    return (
      <div className="settings">
        <Row>
          <Col span={24}>
            {/* {headerName == "settings" && tabName == "general" && <General />} */}
            {headerName == "admin" && tabName == "agents" && <Agent roles={roles}></Agent>}
            {headerName == "admin" && tabName == "groups" && <Group roles={roles}></Group>}
          </Col>
        </Row>
        <SettingSaveDetail></SettingSaveDetail>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("Settings mapStateToProps: ", state)
  return {
    auth: state.auth,
    ui: state.ui,
  };
}

export default connect(mapStateToProps, {})(Admin);
