import React from "react";
import styled from "styled-components";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Button } from "./Button";
import { formatText, htmlToReactEl } from "./utils";
import { useTheme } from "../../../../utils/useTheme";
import TextMessage from "../TextMessage";
import HTMLReactParser from "html-react-parser";
import { TextWrapper } from "..";
import _ from "lodash";
import { useSelector } from "react-redux";
import { sendChatMessage } from "../../../../helpers/mqttService";

type Props = {
  assignChatId: string
  msg: any
  fromId: string
  fromName: string;
  mId: string;
  isPrivate: boolean
  logs: any[]
}

export function Autocomplete(props: Props) {
  const activeRootTab = useSelector((state: any) => state.ui.activeRootTab)
  const { msg, logs, assignChatId, isPrivate } = props
  const theme = useTheme(props.fromId)
  const globalSettings = theme.widget.messages.globalSettings
  const {
    fontSize,
    backgroundColor,
    color,
    border,
    borderRadius,
    borderTopLeft,
    borderTopRight,
    borderBottomRight,
    borderBottomLeft,
    outlineColor,
    outlineTop,
    outlineRight,
    outlineBottom,
    outlineLeft,
  } = globalSettings["agent"];
  const isUser = props.fromId.includes("u/");
  const lastMsg = logs[logs.length - 1]

  const selectOptions = msg.options.map((option) => {
    // the `id` field will be used as the `value` in select component
    // for the new autocomplete message schema
    if (!("value" in option)) {
      return { ...option, value: option.id }
    }
    return option
  })

  const [selected, setSelected] = React.useState<any[]>([]);
  const dimensionProps = theme.widget.dimension
  const handleChange = (selectedVal: any) => {
    let selectedArray: any[] = [];
    if (msg.isMulti && Array.isArray(selectedVal)) {
      selectedArray = selectedVal;
    } else {
      selectedArray = [selectedVal];
    }
    setSelected(selectedArray);
  };
  const handleSubmit = () => {
    if (!selected.length) {
      return
    }
    if (activeRootTab === "live") {
      sendChatMessage({
        msgType: "autoCompleteResponse",
        msg: {
          payload: selected.map((option) => {
            const payloadOption: Record<string, any> = {}
            payloadOption.id = option.__isNew__ ? option.value : option.id
            payloadOption.label = option.label
            if (option.__isNew__) {
              payloadOption.__isNew__ = true
            }
            return payloadOption
          })
        },
        chatId: assignChatId,
        isPrivate,
      });
    }
  };

  const shouldShowActions =
    lastMsg && lastMsg.msgType.toLowerCase() === "autocomplete" && (props.mId === lastMsg.mId) && activeRootTab === 'live';

  if (!shouldShowActions) {
    return (
      <TextWrapper>
        <TextMessage
          isUser={!isUser}
          fontSize="16px"
          color="#212332"
          backgroundColor={isUser ? "#e8e9f1" : "#FBEBE3"}
          message={HTMLReactParser(_.unescape(msg.title))}
        />
      </TextWrapper>
    )
  }

  return (
    <Wrapper theme={{ justify: "flex-end" }}>
      <div
        className="autocomplete-dropdown"
        style={{
          minWidth: "50%",
          background: backgroundColor,
          color,
          border: border,
          borderRadius: borderRadius
            ? borderRadius
            : `${borderTopLeft} ${borderTopRight} ${borderBottomRight} ${borderBottomLeft}`,
        }}
      >
        <Outline
          theme={{
            outlineColor,
            outlineTop,
            outlineRight,
            outlineBottom,
            outlineLeft,
          }}
          style={{
            borderRadius: borderRadius
              ? borderRadius
              : `${borderTopLeft} ${borderTopRight} ${borderBottomRight} ${borderBottomLeft}`,
          }}
        >
          <Title
            theme={{
              font: dimensionProps.font,
              color: color,
              fontSize: fontSize
            }}
          >
            {htmlToReactEl(formatText(msg.title))}
          </Title>
          <>
            {msg.isCreatable ? (
              <CreatableSelect
                isMulti={msg.isMulti}
                isClearable={msg.isCreatable}
                options={selectOptions}
                onChange={handleChange}
              />
            ) : (
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      font: dimensionProps.font,
                      color,
                      fontSize: fontSize + "px"
                    })
                  }}
                  isMulti={msg.isMulti}
                  isClearable={msg.isCreatable}
                  options={selectOptions}
                  onChange={handleChange}
                />
              )}
            <Button handleClick={handleSubmit} align="left" label="Submit" fromId={props.fromId} />
          </>
        </Outline>
      </div>
    </Wrapper >
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.theme.justify};
`;

const Title = styled.div`
  font-family: ${(props) => props.theme.font};
  font-size: ${(props) => props.theme.fontSize ? props.theme.fontSize + "px" : "16px"};
  color: ${(props) => props.theme.color};  
  font-weight: 500;
  margin-bottom: 8px;
`;

const Outline = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  border-bottom: ${(props) =>
    (props.theme.outlineBottom ? props.theme.outlineBottom : 0) +
    ` solid ${props.theme.outlineColor}`};
  border-top: ${(props) =>
    (props.theme.outlineTop ? props.theme.outlineTop : 0) +
    ` solid ${props.theme.outlineColor}`};
  border-left: ${(props) =>
    (props.theme.outlineLeft ? props.theme.outlineLeft : 0) +
    ` solid ${props.theme.outlineColor}`};
  border-right: ${(props) =>
    (props.theme.outlineRight ? props.theme.outlineRight : 0) +
    ` solid ${props.theme.outlineColor}`};
`;
