import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tooltip, Popover, Switch, Modal, Radio, Button, Divider } from "antd";
import "../../assets/sass/leftNavmodule.sass";
import { rootSelectedMenu, rootSelectedTab } from "../../actions/uiAction";
import { signSuccess, sessionTokenSuccess } from "../../actions/taskAction";
import { disconnect } from "../../helpers/mqttService";
import { clearSession } from "../../utils/helpers";
import { signout } from "../../actions/authActions";
import { getLiveAgentSettings, changeAgentState } from "../../actions/agentsAction";
import { resetAssignedChats } from "../../actions/chatsAction";

import dashboardIcon from "../../assets/images/dashboard.svg";
import createchatIcon from "../../assets/images/createchat.svg";
import chatsIcon from "../../assets/images/chats.svg";
import resourcesIcon from "../../assets/images/resources.svg";
import settingsIcon from "../../assets/images/settings.svg";
import dbIcon from "../../assets/images/user-profile.svg";
import adminIcon from "../../assets/images/admin-icon.svg";
import styled from "styled-components";
import _ from "lodash";

interface IRadioGroupOption {
  label: string;
  value: string;
}

const Username = styled.span`
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  text-align: left;
  color: #0948b2;
  padding-right: 10px;
`;

const ToggleText = styled.span`
  font-family: Muli;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  margin-right: 15px;
`;

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};

class LeftNav extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loadMore: false,
      groups: [],
      selectedView: null,
      isAgentOnline: false,
      updateStatusModal: false,
      unavailableReason: "",
      unavailableReasonsList: [],
    };
  }

  onSelectedView = (key) => {
    // console.log(`Menu Change to  ${key}`)
    this.setState({ selectedView: key });
    this.props.rootSelectedMenu(key);
  };

  onSignout = () => {
    const { resetAssignedChats, signout } = this.props;
    this.props.history.push("/signin")
    resetAssignedChats();
    disconnect();
    signout();
    clearSession();
  };

  loadTitle = (username) => {
    const { isAgentOnline } = this.state;
    return (
      <div>
        <Username>{username && username.toLocaleUpperCase()}</Username>
        <span style={{ color: isAgentOnline ? "#00c926" : "#ff9800" }}>&bull;</span>
      </div>
    );
  };

  handleToggleChange = (checked: boolean) => {
    const { unavailableReason } = this.state;
    const agentCurrentState = {
      name: checked ? "available" : "unavailable",
      reason: "",
    };
    this.setState((prevState) => ({
      // isAgentOnline: checked,
      updateStatusModal: !checked ? true : false,
      unavailableReason: "",
    }));
    if (checked) {
      this.props.changeAgentState(agentCurrentState, { name: "unavailable", reason: unavailableReason });
    }
  };

  handleUpdateBtnClick = () => {
    const { isAgentOnline, unavailableReasonsList, unavailableReason } = this.state;
    if (this.state.unavailableReason !== "") {
      const agentCurrentState = {
        name: "unavailable",
        reason: unavailableReason,
      };
      this.setState({
        updateStatusModal: false,
      });
      this.props.changeAgentState(agentCurrentState, { name: "available", reason: "" });
    }
  };

  renderPopupContent = () => {
    const { isAgentOnline, unavailableReason, unavailableReasonsList } = this.state;
    return (
      <>
        {this.loadTitle(this.props.auth && this.props.auth.info && this.props.auth.info.agentName)}
        <div className="agent-status">
          <div
            className="active"
            style={{
              width: "273px",
              // padding: "0 16px 7px 16px",
            }}
          >
            <ToggleText>{isAgentOnline ? "Available" : "Unavailable"}</ToggleText>
            <Switch
              style={{ float: "right", marginRight: "6px" }}
              checked={isAgentOnline}
              defaultChecked={isAgentOnline}
              onChange={this.handleToggleChange}
            />
          </div>
          {unavailableReason !== "" && unavailableReason !== -1 && !isAgentOnline && (
            <>
              <div
                className="unavailable-reason"
                style={{
                  padding: "3px 17px 0",
                  border: "1px solid #ccd0dd",
                  height: "30px",
                  margin: "8px 0",
                  cursor: "pointer",
                }}
                onClick={() => unavailableReasonsList.length > 0 && this.setState({ updateStatusModal: true })}
              >
                {unavailableReason}
              </div>
            </>
          )}
        </div>
        <div className="dp-divider" />
        <div className="agent-groups">
          <div className="title">GROUP</div>
          <div
            className="values"
            style={{
              overflow: this.state.loadMore ? "auto" : "hidden",
              maxHeight: this.state.loadMore ? window.innerHeight - 420 + "px" : "100px",
            }}
          >
            {this.props.auth &&
              this.props.auth.info &&
              this.props.auth.info.groups &&
              this.props.auth.info.groups.map((item, index) => {
                return (
                  <div key={item._id} className="item ellipsis" style={{ width: "263px" }} title={item.name}>
                    {item.name}
                  </div>
                );
              })}
          </div>
          {this.props.auth &&
            this.props.auth.info &&
            this.props.auth.info.groups &&
            this.props.auth.info.groups.length > 4 && (
              <div className="load-more" onClick={() => this.setState({ loadMore: !this.state.loadMore })}>
                {this.state.loadMore ? "Load Less..." : "Load More..."}
              </div>
            )}
        </div>
        <div className="dp-divider" />
        <div className="logout-container" style={{ textDecoration: "none" }} key="exit" onClick={this.onSignout}>
          <p style={{ margin: "8px 0 0", cursor: "pointer" }}>Logout</p>
        </div>
      </>
    );
  };

  storageListener = () => {
    const userInfo = localStorage.getItem("userInfo")
    if(!userInfo) {
      clearSession();
      this.props.history.push("/signin")
    }
  }

  componentDidMount() {
    let key = "";
    if (this.props.auth && this.props.auth.info && this.props.auth.info.roles && !this.state.selectedView) {
      this.props.getLiveAgentSettings();
      if (this.props.auth.info.roles.length == 1 && this.props.auth.info.roles.indexOf("admin") > -1) {
        key = "dashboard";
      } else if (this.props.auth.info.roles.length == 1 && this.props.auth.info.roles.indexOf("supervisor") > -1) {
        key = "resources";
      } else if (this.props.auth.info.roles.length == 1 && this.props.auth.info.roles.indexOf("agent") > -1) {
        key = "chats";
      } else if (
        this.props.auth.info.roles.length == 2 &&
        this.props.auth.info.roles.indexOf("supervisor") > -1 &&
        this.props.auth.info.roles.indexOf("admin") > -1
      ) {
        key = "resources";
      } else if (
        this.props.auth.info.roles.length == 2 &&
        this.props.auth.info.roles.indexOf("supervisor") > -1 &&
        this.props.auth.info.roles.indexOf("agent") > -1
      ) {
        key = "chats";
      } else if (
        this.props.auth.info.roles.length == 2 &&
        this.props.auth.info.roles.indexOf("admin") > -1 &&
        this.props.auth.info.roles.indexOf("agent") > -1
      ) {
        key = "settings";
      } else if (
        this.props.auth.info.roles.indexOf("supervisor") > -1 &&
        this.props.auth.info.roles.indexOf("admin") > -1 &&
        this.props.auth.info.roles.indexOf("agent") > -1
      ) {
        key = "chats";
      }
      this.setState({
        selectedView: key,
      });
      if (key != this.props.ui.activeRootNav) {
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>> ActiveRootNav", key);
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>> UI ActiveRootNav", this.props.ui.activeRootNav);
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>> UI ActiveRootTab", this.props.ui.activeRootTab);
        key = !this.props.ui.activeRootNav ? key : this.props.ui.activeRootNav;
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>> Set Key", key);
        this.props.rootSelectedMenu(key);
        if (key == "resources") {
          this.props.rootSelectedTab("quickresponse");
        } else if (key == "chats") {
          this.props.rootSelectedTab(this.props.auth.info.roles.indexOf("supervisor") > -1 ? "monitor" : "live");
        } else if (key == "settings") {
          this.props.rootSelectedTab("general");
        } else {
          this.props.rootSelectedTab("dashboard");
        }
        this.props.history.push("/" + key);
      }
      if (key) {
        this.setState({
          selectedView: key,
        });
        this.props.rootSelectedMenu(key);
        this.props.history.push("/" + key);
      }
      const {
        ui: { agentState },
      } = this.props;

      this.setState({
        isAgentOnline: agentState && agentState.currentState === "available",
        unavailableReasonsList: agentState && agentState.reasonsList ? agentState.reasonsList : [],
        unavailableReason: agentState && agentState.currentReason ? agentState.currentReason : "",
      });
    }

    window.addEventListener("storage", this.storageListener)
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.storageListener)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.ui, prevProps.ui)) {
      const {
        ui: { agentState },
      } = this.props;
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 5 L N CU", JSON.stringify(agentState));
      let userJson = localStorage.getItem("userInfo");
      let userInfo = userJson !== "" && userJson !== null ? JSON.parse(userJson) : null;
      if (userInfo && userInfo.info && userInfo.info.state) {
        userInfo.info.state = { name: agentState.currentState, reason: agentState.currentReason };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
      }
      this.setState({
        isAgentOnline: agentState && agentState.currentState === "available",
        unavailableReasonsList: agentState ? agentState.reasonsList : [],
        unavailableReason: (agentState && agentState.currentReason) || "",
      });
    }
  }

  handleStatusChange = (e: any) => {
    this.setState({ unavailableReason: e.target.value });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let updateProps: any = {};
    if (nextProps.ui.agentState && nextProps.ui.agentState.currentState) {
      let { currentState, currentReason, reasonsList } = nextProps.ui.agentState;
      if (prevState.isAgentOnline !== true && nextProps.ui.agentState.isAPI) {
        updateProps.isAgentOnline = currentState === "available";
        updateProps.unavailableReason = currentReason || " ";
      }
      updateProps.unavailableReasonsList = reasonsList ? reasonsList : [];
    }
    return updateProps;
  }

  // NOTE: componentWillReceiveProps is depricated
  // async componentWillReceiveProps(props) {
  // if (props.auth && props.auth.info && !this.state.selectedView) {
  //   let key = props.auth.info.roles.indexOf("admin") > -1 ? "settings" : props.auth.info.roles.indexOf("maintainer") > -1 ? "resources" : "chats";
  //   this.setState({
  //     selectedView: key
  //   });
  //   this.props.rootSelectedMenu(key)
  // }
  // }

  render() {
    // console.log("<<<<<<<<<<<<<<<< LeftNav: ", this.props.auth)
    let agentName = "",
      groups = [],
      roles = [];

    if (this.props.auth && this.props.auth.info) {
      agentName = this.props.auth.info.agentName;
      groups = this.props.auth.info.groups;
      roles = this.props.auth.info.roles;
    }

    return (
      <div>
        <div className="dash-nav-main">
          <nav className="dash-nav">
            <ul>
              <li className={this.state.selectedView === "dashboard" ? "active" : ""}>
                <Tooltip placement="right" title="DASHBOARD">
                  <Link to="/dashboard" onClick={this.onSelectedView.bind(this, "dashboard")}>
                    <img className="icon-dashboard" src={dashboardIcon} />
                  </Link>
                </Tooltip>
              </li>
              <li className={this.state.selectedView === "chats" ? "active" : ""}>
                {roles && (roles.includes("agent") || roles.includes("supervisor")) && (
                  <Tooltip placement="right" title="CHATS">
                    <Link to="/chats" onClick={this.onSelectedView.bind(this, "chats")}>
                      <img className="icon-chats" src={chatsIcon} />
                    </Link>
                  </Tooltip>
                )}
              </li>
              <li className={this.state.selectedView === "admin" ? "active" : ""}>
                {roles && (roles.includes("admin") || roles.includes("supervisor")) && (
                  <Tooltip placement="right" title="ADMIN">
                    <Link to="/admin" onClick={this.onSelectedView.bind(this, "admin")}>
                      <img className="icon-admin" src={adminIcon} />
                    </Link>
                  </Tooltip>
                )}
              </li>
              <li className={this.state.selectedView === "resources" ? "active" : ""}>
                {roles && roles.includes("supervisor") && (
                  <Tooltip placement="right" title="RESOURCES">
                    <Link to="/resources" onClick={this.onSelectedView.bind(this, "resources")}>
                      <img className="icon-resources" src={resourcesIcon} />
                    </Link>
                  </Tooltip>
                )}
              </li>
              <li className={this.state.selectedView === "settings" ? "active" : ""}>
                {roles && roles.includes("admin") && (
                  <Tooltip placement="right" title="SETTINGS">
                    <Link to="/settings" onClick={this.onSelectedView.bind(this, "settings")}>
                      <img className="icon-createchat" src={settingsIcon} />
                    </Link>
                  </Tooltip>
                )}
              </li>
            </ul>
          </nav>
          <div className="icon-dp">
            <Popover
              className="title"
              placement="topLeft"
              // title={this.loadTitle(agentName)}
              content={this.renderPopupContent()}
              trigger="click"
            >
              <div
                style={{
                  height: "56px",
                  width: "56px",
                  margin: "0px auto",
                }}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    border: "solid 4px #ffffff",
                    position: "absolute",
                    height: "56px",
                    width: "56px",
                    zIndex: 1,
                  }}
                />
                <span className={this.state.isAgentOnline ? "status available" : "status unavailable"}></span>
                <img src={dbIcon} />
                {/* <div style={{
                  background: `url("${dbIcon}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "fixed",
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px", border: "solid 4px #ffffff"
                }} /> */}
              </div>
            </Popover>
          </div>
        </div>
        <Modal
          title="UPDATE STATUS"
          width="439px"
          className="confirm-transfer"
          visible={this.state.updateStatusModal}
          maskClosable={false}
          onCancel={() => {
            const {
              agentState: { currentState, currentReason },
            } = this.props.ui;
            this.setState(() => ({
              updateStatusModal: false,
              isAgentOnline: currentState === "available",
              unavailableReason: currentReason,
            }));
          }}
          footer={null}
        >
          <Radio.Group onChange={this.handleStatusChange} value={this.state.unavailableReason}>
            {this.state.unavailableReasonsList &&
              this.state.unavailableReasonsList.map((item: IRadioGroupOption, index: number) => (
                <Radio key={index} style={radioStyle} value={item}>
                  {item}
                </Radio>
              ))}
          </Radio.Group>
          <div className="action">
            <Button className="yes" onClick={this.handleUpdateBtnClick}>
              UPDATE
            </Button>
            <Button
              className="no"
              onClick={() => {
                const {
                  agentState: { currentState, currentReason },
                } = this.props.ui;
                this.setState(() => ({
                  updateStatusModal: false,
                  isAgentOnline: currentState === "available",
                  unavailableReason: currentReason,
                }));
              }}
            >
              CANCEL
            </Button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  // console.log("Leftnav mapStateToProps: ", state);
  return {
    auth: state.auth,
    ui: state.ui,
    agentState: state.ui.agentState,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    rootSelectedMenu,
    rootSelectedTab,
    signSuccess,
    sessionTokenSuccess,
    getLiveAgentSettings,
    changeAgentState,
    signout,
    resetAssignedChats,
  })(LeftNav)
);
