import type from "../types/actionTypes";

const initialState = {
  /**
   * This prop response for add active
   * class to root nav current selection
   */
  settingSaveDetails: null,
  currentActiveMonitorTab: 2,
  selectedChatMonitorGroup: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.SETTING_UPDATE_DETAILS:
      return {
        ...state,
        settingSaveDetails: action.data.info,
      };
    case type.RESOURCE_UPDATE_DETAILS:
      return {
        ...state,
        resourceSaveDetails: action.data.info,
      };
    case type.OPEN_MONITORED_CHATS_TAB:
      return {
        ...state,
        currentActiveMonitorTab: action.data,
      };

    case type.SELECTED_CHAT_MONITOR_GROUP:
      return {
        ...state,
        selectedChatMonitorGroup: action.data,
      };
    case type.ME_GET_SUCCESS:
      return {
        ...state,
        myInfo: action.data,
      };
    default:
      return state;
  }
};
