import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Table, Tag, Modal, Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getQuickResp, deleteQuickResp } from "../../../actions/quickRespAction";
import { getGroups } from "../../../actions/groupsAction";
import { resourceSaveDetails, clearQuickRespSaveSuccess, clearQuickRespDeleteSuccess } from "../../../actions/taskAction";
import { updateResourceShow, searchQuickResp, filterGroupQuickResp, filterSortQuickResp } from "../../../actions/uiAction";
import Header from "./Header";
import { compareValues } from "../../../helpers/util";

import "../../../assets/sass/quickResp.sass";

class QuickResponse extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            windowHeight: window.innerHeight,
            confirmDelete: false,
            deleteQuickResp: null,
            groupList: [],
            quickResp: [],
            filterOptions: {
                search: "",
                sort: ["asc"],
                groupIds: []
            },
            columns: [
                {
                    title: <div className="code">CODE</div>,
                    dataIndex: 'dp',
                    width: "8%",
                    render: (text, record) => <div> <span className={record.isActive ? "active" : "inactive"}></span><div className="short-code">{record.shortCode}</div></div>,
                },
                {
                    title: 'RESPONSE TEXT',
                    dataIndex: 'response',
                    key: 'response',
                    width: "58%",
                    render: (text, record) => <div className="resp-text">{text}</div>,
                },
                {
                    title: 'GROUPS',
                    key: 'groupIds',
                    dataIndex: 'groupIds',
                    width: "26%",
                    render: (groupIds,) => (
                        <span>
                            {groupIds && groupIds.map((groupId) => {
                                let groupName = this.getGroupName(groupId);
                                return (
                                    groupName && <Tag color={"geekblue"} key={groupId}>
                                        {groupName}
                                    </Tag>
                                );
                            })}
                        </span>
                    )
                },
                {
                    title: <div style={{ textAlign: "center" }}>ACTION</div>,
                    dataIndex: 'action',
                    width: "8%",
                    render: (text, record) => (
                        <div style={{ textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                            <EditOutlined className="edit" onClick={this.onAddOrUpdateDetails.bind(this, record)} />
                            <DeleteOutlined className="edit" onClick={this.onRemoveDetails.bind(this, record)} />
                        </div>
                    )
                }
            ]
        };
        this.initialLoad();
    }

    async initialLoad() {
        await this.props.getQuickResp();
        let groupResp = await this.props.getGroups();
        if (groupResp) {
            this.setState({ groupList: groupResp.groups });
        }
        if (this.props.ui.filterSortQuickResp) {
            this.setState({
                filterOptions: {
                    ...this.state.filterOptions,
                    sort: this.props.ui.filterSortQuickResp
                }
            });
        }
        if (this.props.ui.filterGroupQuickResp) {
            this.setState({
                filterOptions: {
                    ...this.state.filterOptions,
                    groupIds: this.props.ui.filterGroupQuickResp
                }
            });
        }
        if (this.props.ui.filterSearchQuickResp) {
            this.setState({
                filterOptions: {
                    ...this.state.filterOptions,
                    search: this.props.ui.filterSearchQuickResp
                }
            });
        }
        this.onHandleFilter(this.state.filterOptions)
    }

    getGroupName = (gId) => {
        let groups = this.state.groupList;
        let groupName = null;
        for (let i in groups) {
            if (groups[i]._id == gId) {
                groupName = groups[i].name.toUpperCase();
            }
        }
        return groupName;
    }
    onRemoveDetails = (data) => {
        this.setState({ confirmDelete: true, deleteQuickResp: data });
    }
    onAddOrUpdateDetails = (data) => {
        this.props.resourceSaveDetails({ info: Object.assign(data, { type: "quickresp" }) })
        this.props.updateResourceShow({ updateResourceShow: true });
    }
    componentWillReceiveProps(props) {
        if (props.quickResp && (props.quickResp.quickRespSave) || (props.quickResp.quickRespDelete && props.quickResp.quickRespDelete.code == "success")) {
            this.props.clearQuickRespSaveSuccess();
            this.props.clearQuickRespDeleteSuccess();
            this.initialLoad();
        }
    }
    onHandleFilter = (options) => {
        let quickResp = this.props.quickResp && this.props.quickResp.quickResp ? this.props.quickResp.quickResp : [];
        let filterQuickResp = quickResp.filter(function (item) {
            let isSearch = false;
            let isGroups = false;
            if (item.response.toLowerCase().includes(options.search) || !options.search) {
                isSearch = true;
            }
            if (options.groupIds && options.groupIds.length > 0) {
                for (let g in item.groupIds) {
                    if (options.groupIds.indexOf(item.groupIds[g]) > -1) {
                        isGroups = true;
                    }
                }
            } else {
                isGroups = true;
            }
            if (isSearch && isGroups) {
                return item.response
            }
        });
        this.props.searchQuickResp(options.search);
        this.props.filterGroupQuickResp(options.groupIds);
        this.props.filterSortQuickResp(options.sort);
        filterQuickResp.sort(
            compareValues("shortCode", null, "string", (options.sort ? options.sort[0] : "asc"))
        );
        this.setState({ quickResp: filterQuickResp });
    }
    handleDeleteQuickResp = () => {
        let data = this.state.deleteQuickResp;
        this.props.deleteQuickResp(data, data._id);
        this.setState({ deleteQuickResp: null, confirmDelete: false });
    }
    render() {
        return (
            <Row className="quick-resp" >
                <Col span={24}>
                    <Row>
                        {/* <Col span={1}>
                        </Col> */}
                        <Col span={24}>
                            <Header onAdd={this.onAddOrUpdateDetails} groups={this.state.groupList} onHandleFilter={this.onHandleFilter} default={this.state.filterOptions}></Header>
                        </Col>
                        {/* <Col span={1}>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col span={1}>
                        </Col>
                        <Col span={22}>
                            <Row className="table-view">
                                <Col span={24}>
                                    {/* <Row>
                                    <Col span={24}>
                                    </Col>
                                </Row> */}
                                    <Row style={{ height: (this.state.windowHeight - 300) + "px", marginTop: "20px" }}>
                                        <Col span={24}>
                                            {this.state.quickResp && <Table
                                                rowKey={(record: any) => {
                                                    return record._id;
                                                }}
                                                scroll={{ y: (this.state.windowHeight - 340) }}
                                                columns={this.state.columns}
                                                dataSource={this.state.quickResp}
                                            />}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row >
                        </Col>
                        <Col span={1}>
                        </Col>
                    </Row>
                </Col>
                <Modal
                    title="DELETE QUICK RESPONSE"
                    width="444px"
                    className="confirm-delete"
                    visible={this.state.confirmDelete}
                    onCancel={() => {
                        this.setState({ confirmDelete: false });
                    }}
                    footer={null}
                >
                    <div>
                        <div className="desc">Are you sure you want to delete the {this.state.deleteQuickResp && this.state.deleteQuickResp.shortCode} short code?</div>
                        <div className="action">
                            <Button className="yes" onClick={this.handleDeleteQuickResp}>YES</Button>
                            <Button
                                className="no"
                                onClick={() => {
                                    this.setState({ confirmDelete: false });
                                }}
                            >NO</Button>
                        </div>
                    </div>
                </Modal>
            </Row >
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        auth: state.auth,
        ui: state.ui,
        task: state.task,
        quickResp: state.quickResp
    };
};
export default connect(mapStateToProps, {
    clearQuickRespSaveSuccess,
    clearQuickRespDeleteSuccess,
    getGroups,
    getQuickResp,
    resourceSaveDetails,
    updateResourceShow,
    searchQuickResp,
    filterGroupQuickResp,
    filterSortQuickResp,
    deleteQuickResp
})(QuickResponse);
