import React from "react";
import { connect } from "react-redux";
import { Steps, Button, message, Modal, Upload, Row, Col } from "antd";
import {
  verifyDoc,
  createDoc,
  getBulkTemplate,
} from "../../actions/bulkuploadAction";
import "../../assets/sass/bulkupload.sass";
import uploadsFolderIcn from "../../assets/images/upload.svg";
import fileLoading from "../../assets/images/file-loading.gif";
import fileVerify from "../../assets/images/file-verify.png";
import fileVerifySuccess from "../../assets/images/file-verify-success.png";
import templateFile from "../../assets/images/xlsx_icon.svg";
import downloadFile from "../../assets/images/Download_icon.svg";
import fileCreating from "../../assets/images/file-creating.gif";
import fileCreate from "../../assets/images/file-create.png";
import fileVerifyFailed from "../../assets/images/file-verify-failed.png";
import bulkuploadSuccess from "../../assets/images/bulkupload-success.png";

const { Step } = Steps;
const { Dragger } = Upload;

const steps = [
  {
    title: "Upload",
  },
  {
    title: "Validation",
  },
  {
    title: "Create",
  },
];

class BulkUpload extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
      status: "upload",
      current: 0,
      next: "Next",
      isNext: false,
      isPrev: false,
      error: null,
      previous: "Previous",
      uploadFileProps: {
        // listType: ""
        name: "file",
        defaultFileList: [],
        fileList: [],
        multiple: false,
        onRemove: () => {
          this.setState({
            file: null,
            isNext: true,
            isPrev: false,
            uploadFileProps: {
              ...this.state.uploadFileProps,
              fileList: [],
            },
          });
        },
        beforeUpload: (file) => {
          if (file.type == "text/csv") {
            this.setState({
              file: file,
              isNext: true,
              isPrev: true,
              uploadFileProps: {
                ...this.state.uploadFileProps,
                fileList: [file],
              },
            });
          } else {
            message.error("You can only upload csv / xlsx file!");
          }
          return false;
        },
      },
    };
  }

  next = async () => {
    if (this.state.file) {
      if (this.state.current == 0) {
        const current = this.state.current + 1;
        this.setState({
          current,
          next: "Verifying",
          previous: "Previous",
          isNext: false,
          isPrev: false,
        });
        let doVerifyResp = await this.props.verifyDoc(
          this.state.file,
          this.props.toggleBulkUpload.type
        );
        if (
          doVerifyResp &&
          doVerifyResp.data &&
          (doVerifyResp.data.validation_errors || doVerifyResp.data.error)
        ) {
          let errorDetails = [];
          if (doVerifyResp.data.validation_errors) {
            let errors = doVerifyResp.data.validation_errors;
            for (let e in errors) {
              errorDetails.push(
                <Row>
                  <Col span={2} style={{ textAlign: 'center', fontWeight: 700 }}>{errors[e].record}</Col>
                  <Col span={1}></Col>
                  <Col span={21}>{errors[e].message}</Col>
                </Row>
              );
            }
          } else {
            errorDetails.push(
              <Row className="error-header">
                <Col span={24}>{doVerifyResp.data.error}</Col>
              </Row>
            );
          }
          this.setState({
            error: errorDetails,
            next: "Failure",
            previous: "Previous",
            isNext: true,
            isPrev: true,
          });
        } else {
          this.setState({
            next: "Continue",
            previous: "Previous",
            isNext: true,
            isPrev: true,
          });
        }
      } else if (this.state.current == 1 || this.state.current == 2) {
        const current =
          this.state.current == 2 ? this.state.current : this.state.current + 1;
        this.setState({
          current,
          error: null,
          next: "Creating",
          previous: "Previous",
          isNext: false,
          isPrev: false,
        });
        let createDocResp = await this.props.createDoc(
          this.state.file,
          this.props.toggleBulkUpload.type
        );

        if (
          createDocResp &&
          createDocResp.data &&
          createDocResp.data.validation_errors
        ) {
          let errorDetails = [];
          let errors = createDocResp.data.validation_errors;
          for (let e in errors) {
            errorDetails.push(
              <Row>
                <Col span={2} style={{ textAlign: 'center', fontWeight: 700 }}>{errors[e].record}</Col>
                <Col span={1}></Col>
                <Col span={21}>{errors[e].message}</Col>
              </Row>
            );
          }
          this.setState({
            error: errorDetails,
            next: "Recreate",
            previous: "Previous",
            isNext: true,
            isPrev: true,
          });
        } else {
          this.setState({
            next: "Done",
            previous: "Previous",
            isNext: false,
            isPrev: false,
          });
          message.success("Processing complete!");
        }
      }
    }
  };

  prev = () => {
    if (this.state.isPrev) {
      if (this.state.current == 1) {
        this.setState({
          error: null,
          next: "Next",
          uploadFileProps: {
            ...this.state.uploadFileProps,
            defaultFileList: [this.state.file],
          },
        });
      } else if (this.state.current == 2) {
        this.setState({
          error: null,
          next: "Verify",
          uploadFileProps: {
            ...this.state.uploadFileProps,
            defaultFileList: [this.state.file],
          },
        });
      }
      const current = this.state.current - 1;
      this.setState({ current });
    }
  };

  onClose = () => {
    this.props.onCloseBulkUpload();
  };

  render() {
    const { current } = this.state;
    return (
      <Modal
        title={
          this.props.toggleBulkUpload.type.toUpperCase() + " UPLOAD DOCUMENT"
        }
        width="40%"
        className="bulkupload"
        visible={this.props.toggleBulkUpload.isEnable}
        onCancel={this.onClose}
        footer={null}
      >
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">
          {current == 0 && (
            <div>
              <div style={{ color: "#2c2c2c" }}>Download Bulk Upload Template</div>
              <div className="download-template">
                <div className="content">
                  <img src={templateFile} />
                  <span className="file">{this.props.toggleBulkUpload.type}_create_template_file.csv</span>
                  <span className="icn">
                    <img
                      src={downloadFile}
                      onClick={() =>
                        this.props.getBulkTemplate(
                          this.props.toggleBulkUpload.type
                        )
                      }
                    />
                  </span>
                </div>
              </div>
              <Dragger {...this.state.uploadFileProps}>
                <p className="ant-upload-drag-icon">
                  <img src={uploadsFolderIcn} />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </div>
          )}
          {current == 1 && (
            <div style={{ textAlign: "center" }}>
              {this.state.error == null && this.state.next == "Verify" && (
                <img src={fileVerify} />
              )}
              {this.state.error != null && this.state.next == "Failure" && (
                <img src={fileVerifyFailed} style={{ height: "150px" }} />
              )}
              {this.state.error == null && this.state.next == "Verifying" && (
                <img src={fileLoading} style={{ height: "150px" }} />
              )}
              {this.state.error == null && this.state.next == "Continue" && (
                <img
                  src={fileVerifySuccess}
                  style={{ height: "120px", marginTop: "10px" }}
                />
              )}
              {this.state.error != null && (
                <div className="file-error">
                  <Row className="error-header">
                    <Col span={2} style={{ textAlign: "center" }}>Row</Col>
                    <Col span={1}></Col>
                    <Col span={21}>Error Details</Col>
                  </Row>
                  <div className="error-content">
                    {this.state.error}
                  </div>
                </div>
              )}
            </div>
          )}
          {current == 2 && (
            <div style={{ textAlign: "center" }}>
              {this.state.error != null && this.state.next == "Recreate" && (
                <img
                  src={fileCreate}
                  style={{
                    height: "120px",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                />
              )}
              {this.state.error == null && this.state.next == "Create" && (
                <img src={fileCreate} style={{ height: "120px" }} />
              )}
              {this.state.error == null && this.state.next == "Done" && (
                <img src={bulkuploadSuccess} style={{ height: "150px" }} />
              )}
              {this.state.error == null && this.state.next == "Creating" && (
                <img
                  src={fileCreating}
                  style={{ height: "120px", marginTop: "10px" }}
                />
              )}
              {this.state.error != null && (
                <div className="file-error">{this.state.error}</div>
              )}
            </div>
          )}
        </div>
        <div className="steps-action">
          {this.state.next != "Failure" && this.state.next != "Done" && (
            <Button
              type="primary"
              className={this.state.isNext == true ? "btn-primary" : ""}
              disabled={this.state.isNext == false}
              style={{
                opacity: this.state.isNext == false ? 0.6 : 1,
                cursor: this.state.isNext == false ? "default" : "pointer",
              }}
              onClick={() => this.next()}
            >
              {this.state.next}
            </Button>
          )}
          {this.state.next == "Done" && (
            <Button
              type="primary"
              className="btn-primary"
              onClick={this.onClose}
            >
              Done!
            </Button>
          )}
          {this.state.next != "Done" && current > 0 && (
            <Button
              className={this.state.isPrev == true ? "btn-primary" : ""}
              disabled={this.state.isPrev == false}
              style={{
                opacity: this.state.isPrev == false ? 0.6 : 1,
                cursor: this.state.isPrev == false ? "default" : "pointer",
                margin: "0 8px",
              }}
              onClick={() => this.prev()}
            >
              {this.state.previous}
            </Button>
          )}
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("BulkUpload mapStateToProps: ", state);
  return {
    task: state.task,
  };
}

export default connect(mapStateToProps, {
  getBulkTemplate,
  verifyDoc,
  createDoc,
})(BulkUpload);
