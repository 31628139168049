import React from 'react';
import { connect } from 'react-redux';
import { RightOutlined, DeleteOutlined, EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Input, Row, Col, Button, Divider, Switch, Modal } from 'antd';
import { getFaqCategories, createCategory, updateCategory, createQuestionByCategory, updateQuestionByCategory, deleteQuestionByCategory, deleteCategory } from '../../../actions/faqAction';
import { clearFaqNewCategory, clearFaqDeleteCategory } from "../../../actions/taskAction";
import Save from "./Save";
import '../../../assets/sass/faq.sass';

const { Search } = Input;
class FAQ extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            confirmDelete: false,
            faqEditDetails: null,
            newCategory: "",
            faqCategories: [],
            selectedCategory: null,
            isEditCategory: false,
            windowHeight: window.innerHeight
        };
    }

    displayWindowSize() {
        this.setState({
            windowHeight: window.innerHeight
        })
    }

    onAddCategory = (e, isFlag) => {
        if (e.key === 'Enter' || isFlag) {
            if (this.state.newCategory) {
                this.setState({ newCategory: '' });
                this.props.createCategory({
                    "name": this.state.newCategory,
                    "isActive": true
                });
            }
        }
    }

    async componentDidMount() {
        await this.initialLoad()
        window.addEventListener("resize", () => { this.displayWindowSize() });
    }

    async componentWillUnmount() {
        window.removeEventListener("resize", () => { this.displayWindowSize() });
    }

    async initialLoad() {
        let faqResp = await this.props.getFaqCategories();
        if (faqResp && faqResp.faqCategories) {
            this.setState({
                faqCategories: faqResp.faqCategories
            });
            if (this.state.selectedCategory) {
                for (let i in faqResp.faqCategories) {
                    if (this.state.selectedCategory._id == faqResp.faqCategories[i]._id) {
                        this.setState({ selectedCategory: faqResp.faqCategories[i], selectedFilterCategory: JSON.parse(JSON.stringify(faqResp.faqCategories[i])) })
                    }
                }
            } else {
                let faqCategory = faqResp.faqCategories.length > 0 ? faqResp.faqCategories[0] : null
                this.setState({ selectedCategory: faqCategory });
                if (faqCategory) {
                    this.setState({ newCategoryName: faqCategory.name, selectedFilterCategory: JSON.parse(JSON.stringify(faqCategory)) });
                }
            }
        }
    }

    onFilterChange = (categorySearch) => {
        let selectedFilterCategory = this.state.selectedFilterCategory;
        let questions = [];
        if (this.state.selectedCategory && this.state.selectedCategory.questions) {
            for (let q in this.state.selectedCategory.questions) {
                if (this.state.selectedCategory.questions[q].question.toLocaleLowerCase().includes(categorySearch)) {
                    questions.push(this.state.selectedCategory.questions[q])
                }
            }
        }
        selectedFilterCategory.questions = questions;
        this.setState({
            selectedFilterCategory: selectedFilterCategory
        })
    }

    onCategoryToggle = (category, toggle) => {
        this.setState({
            isCategoryActive: toggle
        });
        this.props.updateCategory({ isActive: toggle, name: category.name }, category._id, category);
    }

    componentWillReceiveProps(props) {
        if (props.faq.isFAQNewCategory) {
            this.props.clearFaqNewCategory();
            this.initialLoad();
            this.onClose();
        }

        if (props.faq.isFAQDeleteCategory) {
            this.setState({
                selectedCategory: null
            })
            this.props.clearFaqDeleteCategory();
            this.initialLoad();
            this.onClose();
        }
    }

    onClose = () => {
        this.setState({
            faqEditDetails: null
        })
    }

    onSave = (action, categoryId, questionId, data) => {
        if (action == "add") {
            this.props.createQuestionByCategory(categoryId, data)
        } else if (action == "update") {
            this.props.updateQuestionByCategory(categoryId, questionId, data)
        }
    }

    onDeleteQuestion = (categoryId, questionId) => {
        this.props.deleteQuestionByCategory(categoryId, questionId);
    }

    updateCategoryName = (e) => {
        e.stopPropagation();
        if (this.state.newCategoryName && this.state.newCategoryName.length > 0) {
            this.props.updateCategory({ isActive: true, name: this.state.newCategoryName }, this.state.selectedCategory._id, this.state.selectedCategory);
            this.setState({ isEditCategory: false });
        }
    }

    closeEditCategoryName = (e) => {
        this.setState({ isEditCategory: false });
    }

    render() {
        return (
            <div className="page-wrap page-fixed-nav faq">
                <div className="page-head filter">
                    <Row className="categories">
                        <Col span={5}>
                            <Row className="header">
                                <Col span={24} className="label">
                                    CATEGORIES
                                </Col>
                                <Col span={20} className="label">
                                    <Input placeholder="Add Category" value={this.state.newCategory} onChange={(e) => this.setState({ newCategory: e.target.value })} onKeyDown={(e) => this.onAddCategory(e, false)} />
                                </Col>
                                <Col span={4} className="action">
                                    <div>
                                        <div className="in" onClick={(e) => this.onAddCategory(e, true)} >
                                            <span className={this.state.newCategory.length > 0 ? "add-new-task active" : "add-new-task inactive"}>
                                                <span className="icon-add"></span>
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="list" style={{ height: (this.state.windowHeight - 230) + "px" }}>
                                <Col span={24}>
                                    <ul>
                                        {this.state.faqCategories && this.state.faqCategories.map((category, index) => (
                                            <li key={category._id} onClick={() => this.setState({ isEditCategory: false, selectedCategory: category, newCategoryName: category.name, selectedFilterCategory: JSON.parse(JSON.stringify(category)) })}>
                                                <Row>
                                                    <Col span={24} className="item ellipsis" title={category.name} >{category.name}</Col>
                                                    {/* <Col span={1} className="arrow"><RightOutlined /></Col> */}
                                                    <Col span={24} style={{
                                                        margin: "10px 0px"
                                                    }} >
                                                        {(category.isActive) ? <span className="active" >ACTIVE</span> : <span className="inactive" >INACTIVE</span>}
                                                        <Switch style={{
                                                            float: "right",
                                                            marginTop: "8px"
                                                        }} defaultChecked={category.isActive} onChange={(checked) => this.onCategoryToggle(category, checked)} />
                                                    </Col>
                                                    {this.state.faqCategories.length > 0 && this.state.faqCategories.length - 1 != index && <Divider className={category._id == (this.state.selectedCategory && this.state.selectedCategory._id) ? "divider selected" : "divider"} type="horizontal"></Divider>}
                                                </Row>
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={19} className="content" style={{ height: (this.state.windowHeight - 120) + "px" }}>
                            {this.state.selectedCategory && <>
                                <Row >
                                    <Col span={7} className="name ellipsis">
                                        {!this.state.isEditCategory && <label className="ellipsis" style={{ minWidth: "20px", maxWidth: "210px" }} title={this.state.selectedCategory && this.state.selectedCategory.name}>{this.state.selectedCategory && this.state.selectedCategory.name}</label>}
                                        {this.state.isEditCategory && <Input style={{
                                            paddingRight: "50px"
                                        }} defaultValue={this.state.selectedCategory && this.state.selectedCategory.name} onChange={(e) => this.setState({ newCategoryName: e.target.value })} suffix={<span style={{
                                            position: "absolute",
                                            fontSize: "14px",
                                            marginTop: "-3px",
                                            right: "8px"
                                        }}>
                                            <CheckOutlined className={this.state.newCategoryName && this.state.newCategoryName.length > 0 ? "active" : "inactive"} style={{ marginRight: "10px" }} onClick={this.updateCategoryName} />
                                            <CloseOutlined className="icon" onClick={this.closeEditCategoryName} />
                                        </span>} />}
                                        {/* onClick={() => this.setState({ isEditCategory: false })} */}
                                        <span style={{
                                            position: "absolute",
                                            fontSize: "14px",
                                            marginTop: "0px",
                                            marginLeft: "8px"
                                        }}>
                                            <EditOutlined className="icon" style={{ margin: "10px 6px" }} onClick={() => this.setState({ isEditCategory: true, newCategoryName: this.state.selectedCategory.name })} />
                                            <DeleteOutlined className="icon" style={{ margin: "10px 6px", }} onClick={() => this.setState({ confirmDelete: true })} />
                                        </span>
                                    </Col>
                                    <Col span={2}></Col>
                                    <Col span={10}>
                                        <Search
                                            className="search"
                                            placeholder="search by question"
                                            onChange={event => this.onFilterChange(event.target.value)}
                                        // onSearch={this.onFilterChange}
                                        />
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={4} style={{ textAlign: "right" }}>
                                        <Button className="addFAQs" onClick={() => this.setState({
                                            faqEditDetails: {
                                                action: "Add",
                                                selectedCategory: this.state.selectedCategory,
                                                question: "",
                                                answer: "",
                                                isActive: true
                                            }
                                        })} >ADD FAQs</Button>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "32px", overflowX: "hidden", overflowY: "auto", height: (this.state.windowHeight - 210) + "px" }}>
                                    <Col span={24}>
                                        {this.state.selectedFilterCategory && this.state.selectedFilterCategory.questions && this.state.selectedFilterCategory.questions.map((qna) => (
                                            <Row key={qna.id} className="qna" onClick={() => this.setState({ selectedQuestion: qna })} >
                                                <Col span={24} >
                                                    <Row>
                                                        <Col span={24} className="question" >
                                                            <div style={{
                                                                width: "86%",
                                                                wordBreak: "break-word",
                                                                float: "left"
                                                            }}>
                                                                {qna.question}
                                                            </div>
                                                            <span style={{
                                                                position: "absolute",
                                                                right: "0px",
                                                                fontSize: "14px",
                                                                marginTop: "-4px",
                                                            }}>
                                                                <span className={qna.isActive ? "status available" : "status unavailable"}>{qna.isActive ? "ACTIVE" : "INACTIVE"}</span>
                                                                <EditOutlined className="icon" style={{ margin: "10px" }} onClick={() => this.setState({
                                                                    faqEditDetails: {
                                                                        action: "Update",
                                                                        selectedCategory: this.state.selectedCategory,
                                                                        id: qna.id,
                                                                        question: qna.question,
                                                                        answer: qna.answer,
                                                                        isActive: qna.isActive
                                                                    }
                                                                })}></EditOutlined>
                                                                <DeleteOutlined className="icon" style={{ margin: "10px 0px" }} onClick={() => this.onDeleteQuestion(this.state.selectedCategory._id, qna.id)}></DeleteOutlined>
                                                            </span>
                                                        </Col>
                                                        <Col span={24} className={qna.id == (this.state.selectedQuestion && this.state.selectedQuestion.id) ? "answer-open" : "answer-close"} >{qna.answer}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                    <Col span={1} ></Col>
                                </Row>
                            </>}
                        </Col>
                    </Row>
                </div>
                {this.state.faqEditDetails && <Save faqEditDetails={this.state.faqEditDetails} onSave={this.onSave} onClose={this.onClose}></Save>}
                <Modal
                    title="DELETE FAQ CATEGORY"
                    width="444px"
                    className="confirm-delete"
                    visible={this.state.confirmDelete}
                    onCancel={() => {
                        this.setState({ confirmDelete: false });
                    }}
                    footer={null}
                >
                    <div>
                        <div className="desc">Are you sure you want to delete the {this.state.selectedCategory && this.state.selectedCategory.name} category?</div>
                        <div className="action">
                            <Button className="yes" onClick={() => { this.props.deleteCategory(this.state.selectedCategory && this.state.selectedCategory._id, this.state.selectedCategory); this.setState({ confirmDelete: false }); }}>YES</Button>
                            <Button
                                className="no"
                                onClick={() => {
                                    this.setState({ confirmDelete: false });
                                }}
                            >NO</Button>
                        </div>
                    </div>
                </Modal>
            </div >
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("FAQ mapStateToProps: ", state);
    return {
        auth: state.auth,
        ui: state.ui,
        faq: state.faq
    }
}

export default connect(mapStateToProps, { getFaqCategories, createCategory, updateCategory, createQuestionByCategory, updateQuestionByCategory, deleteQuestionByCategory, deleteCategory, clearFaqNewCategory, clearFaqDeleteCategory })(FAQ);
