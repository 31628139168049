import React from "react";
import { Modal } from "antd";
import "../../assets/sass/modal.scss";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa";

interface IModalSuccessProps {
  open: boolean;
  onClose: (e: any) => void;
  children: React.ReactNode;
}

const Content = styled.p`
  font-family: Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.42px;
  text-align: left;
  color: #646464;
`;

const Message = styled.p`
  font-family: Muli;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.72px;
  color: #010f34;
  margin-bottom: 14px;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  background: #00c926;
  border-radius: 50%;
  margin: 0 auto 21px;
`;

const ModalSuccess: React.FunctionComponent<IModalSuccessProps> = (props: IModalSuccessProps) => {
  const { open, onClose, children } = props;
  return (
    <Modal className="a8-console-modal-success" width="405px" visible={open} onCancel={onClose} footer={null}>
      <div data-testid="container" style={{ textAlign: "center", padding: "5px 55px 45px" }}>
        <Icon>
          <FaCheck data-testid="checkIcon" color="white" size="35px" />
        </Icon>
        <Message data-testid="message">Awesome!</Message>
        <Content data-testid="content">{children}</Content>
      </div>
    </Modal>
  );
};

export default ModalSuccess;
