import React from "react";
import { connect } from "react-redux";
import { Switch, Row, Col, Input, Button } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { isNotNullOrUndefinedString } from "../../../helpers/util";
import { Select as A8_SELECT } from "a8-uicomponents";
import _ from "lodash";
import agentIcon from "../../../assets/images/agent-icon.svg";

let selectOptions = {
  defaultValue: [],
  icon: agentIcon,
  subTitle: "email",
  optionValue: "_id",
  isSearch: true,
  title: "name",
  primaryColor: null,
  multiSelect: true,
};
let theme = null;
class GroupSaveDetials extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    let groupDetails = this.props.groupDetails;
    let agentIds = [];
    if (this.props.agent.agents && this.props.groupDetails._id) {
      for (let a in this.props.agent.agents) {
        if (
          this.props.agent.agents[a].groupIds.indexOf(
            this.props.groupDetails._id
          ) > -1
        ) {
          agentIds.push(this.props.agent.agents[a]._id);
        }
      }
    }
    Object.assign(groupDetails, { agentIds }, { originalAgentIds: agentIds });
    this.state = groupDetails;
    selectOptions.defaultValue = JSON.parse(JSON.stringify(agentIds));
  }

  componentWillMount() {
    if (this.props.ui.theme) {
      theme = this.props.ui.theme;
    }
    selectOptions.primaryColor = theme.secondaryColor;
  }

  onSubmit = () => {
    let data = this.state;
    let unAssignedAgents = _.difference(data.originalAgentIds, data.agentIds);
    Object.assign(data, { unAssignedAgents });
    console.log(data);
    if (!isNotNullOrUndefinedString(this.fieldValidation())) {
      this.props.onSubmit(data);
    }
  };

  onBulkUpload = () => {
    this.props.onBulkUpload("groups");
  };

  onClose = () => {
    this.props.onClose();
  };

  fieldValidation = () => {
    let error = "";
    if (!isNotNullOrUndefinedString(this.state.name)) {
      error = "Please enter the name";
    }
    this.setState({ error: error });
    return error;
  };

  componentWillReceiveProps(props) {
    if (props.ui.theme) {
      theme = props.ui.theme;
      selectOptions.primaryColor = theme.secondaryColor;
    }
  }

  // loadGruopDetails = () => {
  //     let groups = this.props.group.groups;
  //     for (let i in groups) {
  //         groupOpt.push(<Option key={groups[i]._id} value={groups[i]} >{groups[i].name}</Option>);
  //     }
  //     return groupOpt;
  // }

  render() {
    let agents = this.props.agent.agents;
    let auth = this.props.auth.info;
    let disabled = (auth.roles.includes("supervisor") && !auth.roles.includes("admin"));
    return (
      <div className="agent-details">
        <Row>
          <Col span={24}>
            <Row>
              <Col
                style={{
                  fontSize: "24px",
                  color: "#212332",
                  fontWeight: 600,
                }}
                span={11}
              >
                <ArrowLeftOutlined
                  onClick={this.onClose}
                  style={{
                    cursor: "pointer",
                    color: "#a8abbd",
                    position: "absolute",
                    marginTop: "6px",
                  }}
                />
                <span style={{ marginLeft: "32px" }}>
                  {this.state.action ? "New " : (auth.roles.includes("supervisor") && auth.roles.length == 1) ? "View " : "Update "} Details
            </span>
              </Col>
              <Col span={11} style={{ textAlign: "right", color: "#ff3300" }}></Col>
              {!disabled && <Col span={2} onClick={this.onBulkUpload}>
                <UploadOutlined className="bulkupload" title="Bulk Upload" />
              </Col>}
            </Row>
            <Row style={{ marginTop: "24px" }}>
              <Col span={1}></Col>
              <Col span={20}>
                <div className="form-group">
                  <div className="label">NAME</div>
                  <div className="value">
                    <Input
                      disabled={disabled}
                      className="form-control"
                      value={this.state.name}
                      onChange={(event) =>
                        this.setState({ name: event.target.value })
                      }
                    />
                  </div>
                </div>
                {!this.state.action && (
                  <div className="form-group">
                    <div className="label">AGENTS</div>
                    <div className="value">
                      <A8_SELECT
                        data={agents}
                        options={Object.assign(selectOptions, { disabled })}
                        onChange={(items) => this.setState({ agentIds: items })}
                      ></A8_SELECT>
                    </div>
                  </div>
                )}
                {!this.state.action && (
                  <div className="form-group">
                    <div className="value">
                      <Switch
                        disabled={disabled}
                        defaultChecked={this.state && this.state.isActive}
                        onChange={(checked) => this.setState({ isActive: checked })}
                      />
                      <span
                        style={{
                          marginLeft: "10px",
                          fontSize: "12px",
                          fontWeight: 700,
                        }}
                      >
                        {this.state && this.state.isActive ? "ACTIVE" : "INACTIVE"}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
              <Col span={1}></Col>
            </Row>
          </Col>
        </Row>
        {auth.roles.includes("admin") && <Row>
          <Col span={1}></Col>
          <Col span={20}>
            <div className="form-group">
              <div style={{ color: "red", fontWeight: 800 }}>
                {this.state.error}
              </div>
            </div>
            <div className="form-group action">
              <Button
                className="button button-primary submit"
                onClick={this.onSubmit}
                style={{ width: "100%" }}
              >
                {this.state.action ? "SAVE" : "UPDATE"} INFO
              </Button>
            </div>
          </Col>
          <Col span={1}></Col>
        </Row>}
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("Agent mapStateToProps: ", state);
  return {
    ui: state.ui,
    auth: state.auth,
    agent: state.agent,
    group: state.group,
  };
}

export default connect(mapStateToProps, {})(GroupSaveDetials);
