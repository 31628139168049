import type from "../types/actionTypes";
import axios from "axios";
import config from "../apiConfig";
import { store } from "../store";
// import { useDispatch } from "react-redux";

export function verifyDoc(file, docType) {
  // const dispatch = useDispatch();
  const data = new FormData();
  data.append("file", file);
  const options = {
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  return async (dispatch) => {
    try {
      let resp = await axios(
        `${config.baseUrl}${config.org}${
        config.v1
        }${config.validateTemplate.replace("{type}", docType)}`,
        options
      );
      return resp;
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        dispatch({
          type: type.ERROR,
          data: error.response.data,
        });
      } else if (
        error &&
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.message
      ) {
        return { data: { error: error.response.data.message } };
      } else {
        return { data: { error: "Something went wrong please try again" } };
      }
    }
  };
}

export function createDoc(file, docType) {
  const data = new FormData();
  data.append("file", file);
  const options = {
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    try {
      let resp = await axios(
        `${config.baseUrl}${config.org}${
        config.v1
        }${config.createEntries.replace("{type}", docType)}`,
        options
      );
      dispatch({
        type: type.BULKUPLOAD_SUCCESS,
        data: { docType },
      });
      return resp;
    } catch (error) {
      if (error.response.status === 401) {
        dispatch({
          type: type.ERROR,
          data: error.response.data,
        });
      } else {
        return { data: { error: "Something went wrong please try again" } };
      }
    }
  };
}

export function getBulkTemplate(docType) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };

  return async (dispatch) => {
    try {
      let resp = await axios(
        `${config.baseUrl}${config.org}${
        config.v1
        }${config.getBulkTemplate.replace("{type}", docType)}`,
        options
      );
      if (resp && resp.data) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${docType}_create_template_file.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        dispatch({
          type: type.ERROR,
          data: error.response.data,
        });
      } else if (error.message) {
        console.error(
          `Download the bulkupload template file: ${error.message}`
        );
      }
    }
  };
}
