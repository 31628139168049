import * as React from "react";
import styled from "styled-components";
import { toCustomDateTimeFormat } from "../../../../helpers/util";
import { SelectedChatMonitorMessageContext } from "..";
import { getRandomColor } from "../../../../utils/randomColor";
import HTMLReactParser from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import AttachmentIcon from "../../../../assets/images/attachments.svg";
import { CreditCardOutlined } from "@ant-design/icons";
import { updateSelectedMonitorChatId } from "../../../../actions/chatsAction";
import { LightenDarkenColor } from "../../../../utils/helpers";
import { loadClientConversation } from '../../../../actions/uiAction';

export interface IUserCardProps {
  message: any;
  name: string;
  id: string;
}

const Container = styled.div`
  min-height: 70px;
  max-height: 100px;
  margin-top: 12px;
  border-bottom: 2px solid ${(props) => (props.theme.isSelected ? props.theme.borderColor : "rgba(168, 171, 189, 0.4)")};
  padding-right: 16px;
  cursor: pointer;
  word-wrap: break-word;
`;

const Statics = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: ${(props) => props.theme.background + "40"};
  color: ${(props) => LightenDarkenColor(props.theme.background, 5)};
  border: 1px solid ${(props) => props.theme.background};
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
`;

const Name = styled.div`
  flex: 1;
  text-transform: capitalize;
  font-size: 13px;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #a8abbd;
  font-family: Muli;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  word-break: break-all;
  margin-right: 16px;
`;

const Time = styled.div`
  font-family: Muli;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #a8abbd;
`;

const Message = styled.div`
  color: ${(props) => (props.theme.isSelected ? "#2f3245" : "#a8abbd")};
  margin: 12px 0;
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
`;

const UserCard = React.forwardRef<HTMLDivElement, IUserCardProps>((props: IUserCardProps, ref) => {
  const { unMonitoredChats, monitoredChats } = useSelector((state: any) => state.chat)
  const { currentActiveMonitorTab } = useSelector((state: any) => state.task)
  const { message, name, id } = props;
  const dispatch = useDispatch();
  const handleClientConversation = React.useCallback(() => dispatch(loadClientConversation(null)), [dispatch]);
  const setSelectedId = React.useCallback(() => dispatch(updateSelectedMonitorChatId(id)), [dispatch]);

  React.useEffect(() => {
    if (currentActiveMonitorTab === "2" && !selectedMonitorChatId) {
      monitoredChats && dispatch(updateSelectedMonitorChatId(Object.keys(monitoredChats)[0]))
    } else if (!selectedMonitorChatId) {
      unMonitoredChats && dispatch(updateSelectedMonitorChatId(Object.keys(unMonitoredChats)[0]))
    }
  }, [])
  const { theme } = useSelector((state: any) => state.ui);
  const getMessage = (type, msg) => {
    if (type == "pdf" || type == "image") {
      return (
        <div>
          <span>
            <img
              style={{
                width: "14px",
                marginRight: "6px",
              }}
              src={AttachmentIcon}
              alt="Attachment"
            />
          </span>
          <span>{msg.desc ? msg.desc.substr(0, 70) : msg.fileName && msg.fileName.substr(0, 70)}</span>{" "}
        </div>
      );
    } else if (type == "text") {
      return HTMLReactParser(msg.text.substr(0, 70));
    } else if (type == "adaptiveCard") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CreditCardOutlined />
          &nbsp;<span>{msg.data.title}</span>
        </div>
      );
    } else {
      return "Message";
    }
  };
  const chatLastMsg = getMessage(message.msgType, message.msg);
  const { selectedMonitorChatId } = useSelector((state: any) => state.chat);
  const isSelected = selectedMonitorChatId === id;
  React.useEffect(() => { handleClientConversation() }, [selectedMonitorChatId]);

  return (
    <Container theme={{ isSelected, borderColor: theme.secondaryColor }} onClick={setSelectedId} ref={ref}>
      <Statics>
        <Icon theme={{ background: getRandomColor(id) }}>{name[0].toUpperCase()}</Icon>
        <Name title={name}>{name}</Name>
        <Time>{toCustomDateTimeFormat(message.sentAt)}</Time>
      </Statics>
      <Message theme={{ isSelected }}>{chatLastMsg}</Message>
    </Container>
  );
});

export default UserCard;
