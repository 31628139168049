import React from "react";
import { connect } from "react-redux";
import { Switch, Row, Col, Input, Button } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import { isNotNullOrUndefinedString } from "../../../helpers/util";
import { Select as A8_SELECT } from "a8-uicomponents";

import "../../../assets/sass/quickResp.sass";

let selectGPOptions = {
    defaultValue: [],
    optionValue: "_id",
    isSearch: false,
    title: "name",
    multiSelect: true,
    primaryColor: null,
};

const { TextArea } = Input;
let theme = null;

class Save extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ...this.props.quickResp
        };
        selectGPOptions.defaultValue = JSON.parse(
            JSON.stringify(this.state.groupIds ? this.state.groupIds : [])
        );
    }

    componentWillMount() {
        this.setState({
            email: this.state.email && this.state.email.split("@")[0],
        });
        if (this.props.ui.theme) {
            theme = this.props.ui.theme;
        }
        selectGPOptions.primaryColor = theme.secondaryColor;
    }

    onSubmit = () => {
        if (!isNotNullOrUndefinedString(this.fieldValidation())) {
            this.props.onSubmit(this.state);
        }
    };

    onClose = () => {
        this.props.onClose();
    };

    fieldValidation = () => {
        let error = "";
        if (
            !isNotNullOrUndefinedString(this.state.response) &&
            !isNotNullOrUndefinedString(this.state.shortCode)
        ) {
            error = "Please enter the all fields";
        } else if (!isNotNullOrUndefinedString(this.state.response)) {
            error = "Please enter the response text";
        } else if (!isNotNullOrUndefinedString(this.state.shortCode)) {
            error = "Please enter the short code";
        } else if (!this.state.groupIds || this.state.groupIds.length == 0) {
            error = "Please choose the group";
        }
        this.setState({ error: error });
        return error;
    };

    onBulkUpload = () => {
        this.props.onBulkUpload("agents");
    };

    componentWillReceiveProps(props) {
        if (props.ui.theme) {
            theme = props.ui.theme;
            selectGPOptions.primaryColor = theme.secondaryColor;
        }
    }

    render() {
        let groups = this.props.group.groups;
        return (
            <div className="quick-resp">
                <div className="save">
                    <Row>
                        <Col
                            style={{
                                fontSize: "24px",
                                color: "#212332",
                                fontWeight: 600,
                            }}
                            span={11}
                        >
                            <ArrowLeftOutlined
                                onClick={this.onClose}
                                style={{
                                    cursor: "pointer",
                                    color: "#a8abbd",
                                    position: "absolute",
                                    marginTop: "6px",
                                }}
                            />
                            <span style={{ marginLeft: "32px" }}>
                                {this.state.action ? "New " : "Update "} Details
            </span>
                        </Col>
                        <Col span={11} style={{ textAlign: "right", color: "#ff3300" }}></Col>
                        <Col span={2} onClick={this.onBulkUpload}>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "24px" }}>
                        <Col span={1}></Col>
                        <Col span={22}>
                            <div className="form-group">
                                <div className="label">SHORT CODE</div>
                                <div className="value">
                                    <Input
                                        maxLength={3}
                                        className="form-control"
                                        value={this.state.shortCode}
                                        onChange={(event) =>
                                            this.setState({ shortCode: event.target.value })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="label">RESPONSE TEXT</div>
                                <div className="value">
                                    <TextArea rows={4}
                                        className="form-control"
                                        value={this.state.response}
                                        onChange={(event) =>
                                            this.setState({ response: event.target.value })
                                        }
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="label">ADD TO GROUPS</div>
                                <div className="value">
                                    <A8_SELECT
                                        data={groups}
                                        options={selectGPOptions}
                                        onChange={(arrayList, arrayObjList) =>
                                            this.setState({ groupIds: arrayList })
                                        }
                                    ></A8_SELECT>
                                </div>
                            </div>
                            {!this.state.action && (
                                <div className="form-group">
                                    <div className="value">
                                        <Switch
                                            defaultChecked={this.state && this.state.isActive}
                                            onChange={(checked) => this.setState({ isActive: checked })}
                                        />
                                        <span
                                            style={{
                                                marginLeft: "10px",
                                                fontSize: "12px",
                                                fontWeight: 700,
                                            }}
                                        >
                                            {this.state && this.state.isActive ? "ACTIVE" : "INACTIVE"}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div className="form-group">
                                <div style={{ color: "red", fontWeight: 800 }}>
                                    {this.state.error}
                                </div>
                            </div>
                            <div className="form-group">
                                <Button
                                    className="button button-primary submit"
                                    onClick={this.onSubmit}
                                >
                                    {this.state.action ? "SAVE" : "UPDATE"} INFO
              </Button>
                            </div>
                        </Col>
                        <Col span={1}></Col>
                    </Row>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("Agent mapStateToProps: ", state);
    return {
        ui: state.ui,
        auth: state.auth,
        agent: state.agent,
        group: state.group,
    };
}

export default connect(mapStateToProps, {})(Save);
