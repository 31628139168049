export type SoloWidgetState = {
  sessionId?: string
  appId?: string
  nodeName?: string
  nodeId?: string
  show: boolean;
};

const initialState = {
  show: false,
} as const;

type SoloWidgetAction = {
  type: "SET_SHOW_SOLO_WIDGET";
  payload: boolean;
} | {
  type: "INIT_SOLO_WIDGET_SESSION"
  payload: {
    sessionId: string
    appId: string
    nodeName: string
    nodeId: string
  }
}

export default function soloWidgetReducer(state: SoloWidgetState = initialState, action: SoloWidgetAction): SoloWidgetState {
  switch (action.type) {
    case "INIT_SOLO_WIDGET_SESSION": {
      return {
        ...state,
        ...action.payload
      };
    }
    case "SET_SHOW_SOLO_WIDGET": {
      return {
        ...state,
        show: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
