import * as React from "react";
import styled from "styled-components";
// import theme_item from "../../../../assets/images/chat_routing.svg";
import ChatRoutingIcon from "../../../../assets/icons/chatRouting";
import ReasonCodesIcon from "../../../../assets/icons/reasonCodes";
import CredentialIcon from "../../../../assets/icons/credentials";
import { SelectedSettingsContext } from "../../general";

export interface ILeftDrawerProps { }

const DrawerButton = styled.button`
  position: absolute;
  right: ${(props) => (props.theme.isOpen ? "-11px" : "-20px")};
  top: 42px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  outline: none !important;
  background: white;
  border: 1px solid #1d7aff;
  color: #1d7aff;
`;

const Drawer = styled.div`
  width: 200px;
  height: 100vh;
  background: #f4f5f7;
  position: relative;
  margin-left: ${(props) => (!props.theme.isOpen ? "-200px" : "0")};
  transition: all 0.3s;
  padding: 20px 8px;
`;

const DrawerHeading = styled.div`
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1d7aff;
  margin-bottom: 24px;
`;

const DrawerList = styled.div`
  height: 35px;
  border-radius: 6px;
  background-color: ${(props) => (props.theme.selected ? "#d8e8ff" : "#f4f5f7")};
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #223979;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: #7f90af;
  margin: 8px 0;
  color: ${(props) => (props.theme.selected ? "#0948b2" : "#7f90af")};
  cursor: pointer;
  user-select: none;

  label {
    margin-left: 15px;
  }
`;

const MenuList = [
  {
    id: 1,
    label: "Chat Routing",
    Icon: ChatRoutingIcon,
    className: "chat-routing",
  },
  {
    id: 2,
    label: "Reason Codes",
    Icon: ReasonCodesIcon,
    className: "reason-codes",
  },
  {
    id: 3,
    label: "Credentials",
    Icon: CredentialIcon,
    className: "reason-codes",
  },
];

export default function LeftDrawer(props: ILeftDrawerProps) {
  const [leftDrawer, openDrawer] = React.useState(true);
  const { setSelectedMenu, selectedMenu } = React.useContext(SelectedSettingsContext);
  return (
    <Drawer theme={{ isOpen: leftDrawer }} className={`drawer ${leftDrawer ? "open" : ""}`}>
      {false && (
        <DrawerButton theme={{ isOpen: leftDrawer }} onClick={() => openDrawer((prevState) => !prevState)}>
          &lt;
        </DrawerButton>
      )}
      {MenuList.map(({ id, Icon, label, className }) => (
        <DrawerList
          key={id}
          className={`drawer-list-item-container ${selectedMenu === label ? "active" : ""}`}
          onClick={() => setSelectedMenu(label)}
          theme={{ selected: selectedMenu === label }}
        >
          <Icon color="#7f90af" />
          <label className={className}>{label}</label>
        </DrawerList>
      ))}
      {/* <div>
        <DrawerList theme={{ selected: true }}>
          <ReasonCodesIcon color="#0948b2" />
          &nbsp;Reason Codes
        </DrawerList>
      </div> */}
    </Drawer>
  );
}
