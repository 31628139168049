import * as React from "react";
import { Collapse, Slider } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { IState, ThemeContext } from "../../../../../themeDesigner";
import OpacityIcon from "../../../../../../../../assets/images/opacity-icon.svg";

const Panel = Collapse.Panel;

function callback(key) {
  console.log(key);
}

export interface IAppearancePropertiesProps {}

const Label = styled.label`
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #7f90af;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid #aaa;
  width: 30px;
  margin: 0 5px;

  font-family: Muli;
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #010f34;

  &:focus {
    outline: none;
  }
`;

export default function AppearanceProperties(
  props: IAppearancePropertiesProps
) {
  const { secondaryColor: outlineColor } = useSelector(
    (state: IState) => state.ui.theme
  );
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: {
      userInput: { opacity },
    },
  } = themeProps;
  const handleChange = (value: any) => {
    let num: number;
    if (typeof value === "string") {
      num = +value.slice(0, value.length - 1);
    } else {
      num = +value;
    }
    const updatedInput = update(themeProps, {
      widget: {
        userInput: {
          opacity: { $set: num },
        },
      },
    });
    setThemeProps(updatedInput);
  };
  return (
    <Collapse defaultActiveKey={["1"]} onChange={callback}>
      <Panel header="Appearance" key="1">
        <Label>Opacity</Label>
        <Wrapper>
          <img src={OpacityIcon} alt="Opacity Icon" />
          <Input
            value={`${opacity}%`}
            onChange={(e) => handleChange(e.target.value)}
            placeholder="30%"
          />
          <div style={{ flex: 1 }}>
            <Slider
              defaultValue={opacity}
              value={opacity}
              onChange={handleChange}
            />
          </div>
        </Wrapper>
      </Panel>
    </Collapse>
  );
}
