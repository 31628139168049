import { style } from "typestyle";

export const container = (
    isFeedback: boolean,
    color: string,
    backgroundColor: string
) =>
    style(
        { marginBottom: isFeedback ? "25px" : "0px" },
        { borderTop: `2px solid ${color}` },
        { padding: "16px" },
        { backgroundColor }
    );

export const titleStyle = (color: string) =>
    style(
        { fontSize: "14px" },
        { fontWeight: 600 },
        { marginBottom: "8px" },
        { fontStretch: "normal" },
        { fontStyle: "normal" },
        { lineHeight: "normal" },
        { letterSpacing: "normal" },
        { color }
    );

export const optionListContainer = style(
    { listStyle: "none" },
    { flexWrap: "wrap" },
    { display: "flex" },
    { justifyContent: "center" },
    { paddingLeft: 0 },
    { alignItems: "center" },
    { flexDirection: "row" },
    { listStyleType: "none" },
    { cursor: "pointer" }
);

export const optionList = (color: string, backgroundColor: string) =>
    style(
        { minWidth: "45%" },
        { margin: "5px" },
        { padding: "5px 20px" },
        { borderRadius: "15px" },
        { border: "1px solid #e8e9f1" },
        { cursor: "pointer" },
        { flexGrow: 1 },
        { display: "flex" },
        { alignItems: "center" },
        { justifyContent: "center" },
        { fontSize: "15px" },
        { textAlign: "center" },
        { fontFamily: "inherit" },
        { color },
        { wordWrap: "break-word" },
        { backgroundColor: "#fff" },
        // {
        //   $nest: {
        //     "&:hover": {
        //       backgroundColor:
        //         backgroundColor === "#ffffff"
        //           ? LightenDarkenColor(backgroundColor, -20)
        //           : LightenDarkenColor(backgroundColor, 50),
        //     },
        //   },
        // }
    );

export const option = (fontFamily: string) =>
    style({ wordBreak: "break-word" }, { fontFamily: "inherit" });
