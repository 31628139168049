import * as React from "react";
import { Collapse, Select } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";
import update from "immutability-helper";
import { ChromePicker } from "react-color";
import { IState, ThemeContext } from "../../../../../themeDesigner";
import GradientPickerIcon from "../../../../../../../../assets/icons/gradient.svg";
import BorderProperties from "./Broder";

const Panel = Collapse.Panel;

const { Option } = Select;

function callback(key) {
    console.log(key);
}

export interface ISurfaceQuestionProps {
    // color: any,
    // colorHover: any,
    // backgroundColor: any,
    // backgroundColorHover: any,
    // border: any,
    // borderRadius: any,
    // borderRadiusBottomLeft: any,
    // borderRadiusBottomRight: any,
    // borderRadiusTopLeft: any,
    // borderRadiusTopRight: any,
}


const BackgroundColorContainer = styled.div`
  height: 59px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #aaa; */
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

export default function SurfaceQuestion(props: ISurfaceQuestionProps) {

    const [openBGPicker, setOpenBGPicker] = React.useState(false);
    const [openFRPicker, setOpenFRPicker] = React.useState(false);
    const [openBGHoverPicker, setOpenBGHoverPicker] = React.useState(false);
    const [openFRHoverPicker, setOpenFRHoverPicker] = React.useState(false);

    const { secondaryColor: outlineColor } = useSelector(
        (state: IState) => state.ui.theme
    );
    const { themeProps, setThemeProps } = React.useContext(ThemeContext);
    const {
        widget: { surfaceQuestion },
    } = themeProps;


    const [bgColor, setBGColor] = React.useState(surfaceQuestion.backgroundColor);
    const [frColor, setFRColor] = React.useState(surfaceQuestion.color);
    const [bgColorHover, setBGColorHover] = React.useState(surfaceQuestion.backgroundColorHover);
    const [frColorHover, setFRColorHover] = React.useState(surfaceQuestion.colorHover);

    const handleBackgroundColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                surfaceQuestion: {
                    "backgroundColor": { $set: color.hex }
                }
            }
        });
        setThemeProps(updatedGeneralProperties);
    };

    const handleForeColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                surfaceQuestion: {
                    "color": { $set: color.hex }
                }
            },
        });
        setThemeProps(updatedGeneralProperties);
    };

    const handleBackgroundColorHoverChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                surfaceQuestion: {
                    "backgroundColorHover": { $set: color.hex }
                }
            }
        });
        setThemeProps(updatedGeneralProperties);
    };

    const handleForeColorHoverChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                surfaceQuestion: {
                    "colorHover": { $set: color.hex }
                }
            },
        });
        setThemeProps(updatedGeneralProperties);
    };
    return (
        <Collapse defaultActiveKey={["1"]} onChange={callback}>
            <Panel header="Surface Question" key="1">
                <BackgroundColorContainer>
                    <span className="text">Background Color</span>
                    <DisplayColor
                        theme={{ backgroundColor: bgColor }}
                        className="picker"
                        onClick={() => setOpenBGPicker(!openBGPicker)}
                    />
                    {openBGPicker && (
                        <div className="color-picker">
                            <ChromePicker
                                color={bgColor}
                                onChange={(color: any) => setBGColor(color.hex)}
                                onChangeComplete={handleBackgroundColorChange}
                            />
                        </div>
                    )}
                    <GradientPickerWrapper>
                        <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                    </GradientPickerWrapper>
                </BackgroundColorContainer>

                <BackgroundColorContainer>
                    <span className="text">Background Color Hover</span>
                    <DisplayColor
                        theme={{ backgroundColor: bgColorHover }}
                        className="picker"
                        onClick={() => setOpenBGHoverPicker(!openBGHoverPicker)}
                    />
                    {openBGHoverPicker && (
                        <div className="color-picker">
                            <ChromePicker
                                color={bgColorHover}
                                onChange={(color: any) => setBGColorHover(color.hex)}
                                onChangeComplete={handleBackgroundColorHoverChange}
                            />
                        </div>
                    )}
                    <GradientPickerWrapper>
                        <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                    </GradientPickerWrapper>
                </BackgroundColorContainer>
                <BackgroundColorContainer>
                    <span className="text">Foreground Color</span>
                    <DisplayColor
                        theme={{ backgroundColor: frColor }}
                        className="picker"
                        onClick={() => setOpenFRPicker(!openFRPicker)}
                    />
                    {openFRPicker && (
                        <div className="color-picker">
                            <ChromePicker
                                color={frColor}
                                onChange={(color: any) => setFRColor(color.hex)}
                                onChangeComplete={handleForeColorChange}
                            />
                        </div>
                    )}
                    <GradientPickerWrapper>
                        <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                    </GradientPickerWrapper>
                </BackgroundColorContainer>
                <BackgroundColorContainer>
                    <span className="text">Foreground Color Hover</span>
                    <DisplayColor
                        theme={{ backgroundColor: frColorHover }}
                        className="picker"
                        onClick={() => setOpenFRHoverPicker(!openFRHoverPicker)}
                    />
                    {openFRHoverPicker && (
                        <div className="color-picker">
                            <ChromePicker
                                color={frColorHover}
                                onChange={(color: any) => setFRColorHover(color.hex)}
                                onChangeComplete={handleForeColorHoverChange}
                            />
                        </div>
                    )}
                    <GradientPickerWrapper>
                        <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                    </GradientPickerWrapper>
                </BackgroundColorContainer>
                <BorderProperties />
            </Panel>
        </Collapse>
    );
}
