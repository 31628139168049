import React, { useState } from "react";
import styled from "styled-components";
import LeftIllustrationImage from "../../assets/images/left_illustration.svg";
import RightIllustrationImage from "../../assets/images/right_illustration.svg";
import InputLogoImage from "../../assets/images/mail_icon.svg";
import AccountRecoveryCard from "./AccountRecoveryCard";
import { resetPasswordSendOtp } from "../../actions/authActions";
import { withRouter } from "react-router-dom";
import { ForgotPasswordContext } from "../../App";
import _ from "lodash";
import { deviceBreakpoint } from "../../utils/deviceBreakpoints";
import { Button } from 'antd';

export interface IForgotPasswordProps {
  history: any;
}

const Container = styled.div`
  position: relative;
  height: 100vh;
`;

const LeftIllustration = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;

  // Hide on Mobile
  @media ${deviceBreakpoint.laptop} {
    display: block;
  }
`;

const RightIllustration = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;

  // Hide on Mobile
  @media ${deviceBreakpoint.laptop} {
    display: block;
  }
`;

const Heading = styled.div`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #10002e;
  margin-bottom: 31px;
`;

const Description = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #95abd3;
  margin-bottom: 44px;
`;

const FormLabel = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: left;
  color: #10002e;
  margin-bottom: 21px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const InputLogo = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-73%);
  left: 15px;
`;

const Input = styled.input`
  height: 50px;
  border-radius: 2.5px;
  border: solid 0.5px ${(props) => (props.theme.error ? "#d30029" : "#95abd3")};
  background-color: #ffffff;
  max-width: 100%;
  width: 100%;
  padding: 0 12px 0 45px;
  margin-bottom: 8px;
`;

const InputError = styled.p`
  font-family: Muli;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #d30029;
  margin-bottom: 27px;
  min-height: 13px;
`;

// const Button = styled.button`
/* width: 100%;
height: 50px;
border-radius: 2.5px;
border: none;
color: white;
box-shadow: 0 4px 6.5px 0 rgba(0, 118, 255, 0.27);
background-color: #0076ff;
font-family: Muli;
font-size: 14px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.29;
letter-spacing: normal;
color: #ffffff;
text-transform: uppercase;
margin-bottom: 34px;
// for example
}
`; */

const CancelButton = styled.button`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #95abd3;
  background: white;
  border: none;
  outline: none;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
`;

function ForgotPassword(props: IForgotPasswordProps) {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const context = React.useContext(ForgotPasswordContext);
  const { history } = props;

  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    history.push("/signin");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Enter a valid email");
    } else {
      try {
        setLoading(true)
        const result = await resetPasswordSendOtp(email);
        if (result && result.code === "success") {
          context.setResetEmail(email);
          history.push("/verify-otp");
        } else {
          setError("There is no account associated with this email.");
          setLoading(false)
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <Container>
      <LeftIllustration src={LeftIllustrationImage} alt="Left Illustartion" />
      <RightIllustration
        src={RightIllustrationImage}
        alt="Right Illustartion"
      />
      <AccountRecoveryCard>
        <Heading>Account recovery</Heading>
        <Description>
          <span style={{ fontWeight: "bold", color: "#10002e" }}>
            Forgot your password?
          </span>{" "}
          Just type your email and we will send you a verification code so you
          could reset your password.
        </Description>
        <form onSubmit={handleSubmit}>
          <FormLabel>Email</FormLabel>
          <InputWrapper>
            <InputLogo src={InputLogoImage} alt="mail icon" />
            <Input
              theme={{ error }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
              type="email"
              placeholder="Enter your email"
            />
          </InputWrapper>
          <InputError>{error}</InputError>
          <Button type="primary" htmlType="submit" block size="large" loading={loading} >Verify</Button>
          <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        </form>
      </AccountRecoveryCard>
    </Container>
  );
}

export default withRouter(ForgotPassword);
