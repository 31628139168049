import React, { useContext } from "react";
import styled from "styled-components";
import { SelectedSettingsContext } from "../../general";

const Menu = styled.header`
  background: #ffffff;
  height: 5rem;
  display: flex;
  align-items: center;
  padding: 0 25px 0 0px;
  margin: 0 37px 12px 27px;
  border-bottom: 2px solid #d7d9e5;
`;

const Breadcrumb = styled.p`
  width: 100%;
  margin-bottom: 8px;
  height: 15px;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
`;

// const PageTitle = styled.p`
//   margin: 0;
//   width: 48px;
//   height: 18px;
//   font-family: Muli;
//   font-size: 14px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.29;
//   letter-spacing: normal;
//   text-align: left;
//   color: #1d7aff;
// `;

const PageTitle = styled.div`
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #1d7aff;
  text-transform: uppercase;
`;

const Navbar = () => {
  const { selectedMenu } = useContext(SelectedSettingsContext);
  return (
    <Menu>
      <div style={{ margin: 0, display: "flex", flexDirection: "column" }}>
        <Breadcrumb>General &gt; {selectedMenu}</Breadcrumb>
        <PageTitle>
          {selectedMenu === "Reason Codes"
            ? "custom agent state"
            : selectedMenu}
        </PageTitle>
      </div>
      {/* Context */}
    </Menu>
  );
};

export default Navbar;
