import React from "react";
import styled from "styled-components";
import { useTheme } from '../../../../utils/useTheme'

type Props = {
  handleClick: () => void;
  align: string;
  label: string;
  className?: string;
  fromId: string;
}

export function Button(props: Props) {
  const { align, label, className, handleClick, fromId } = props
  const theme = useTheme(fromId)
  const widgetStyles = theme.widget
  const globalSettings = widgetStyles.messages.globalSettings
  const {
    fontSize
  } = globalSettings["agent"];

  return (
    <ButtonContainer
      theme={{
        fontSize,
        background: widgetStyles.dimension.primaryColor.hex,
        align,
        font: widgetStyles.dimension.font,
      }}
    >
      <button className={className || "primary"} onClick={handleClick}>
        {label}
      </button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  text-align: ${(props: any) => props.theme.align};

  button.primary {
    background: ${(props: any) => props.theme.background};
    color: white;
    border: none;
    margin-top: 8px;
    border-radius: 4px;
    text-transform: uppercase;
    font-family: ${(props: any) => props.theme.font};
    font-size: ${(props: any) => props.theme.fontSize + "px"};
    letter-spacing: 1px;
  }
`;
