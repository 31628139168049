import * as React from "react";
import { Collapse, Select } from "antd";
import styled from "styled-components";
import GradientPickerIcon from "../../../../../../../../assets/icons/gradient.svg";
import { ChromePicker } from "react-color";
import { ThemeContext } from "../../../../../themeDesigner";
import update from "immutability-helper";

export interface IMaximizeAndClosePropertiesProps { }

const Panel = Collapse.Panel;

const { Option } = Select;

function callback(key) {
  console.log(key);
}

const Container = styled.div`
  margin-top: 14px;
`;

const Input = styled.input`
  width: 100%;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;
  margin-bottom: 14px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const ColorContainer = styled.div`
  height: 59px;
  padding: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const ColorPicker = styled.div`
  .chrome-picker {
    width: 100% !important;
  }
`;

export default function MaximizeAndCloseProperties(
  props: IMaximizeAndClosePropertiesProps
) {
  const { themeProps, setThemeProps } = React.useContext(ThemeContext);
  const {
    widget: {
      header: { actions },
    },
  } = themeProps;
  const [openPicker, setOpenPicker] = React.useState(false);
  const [color, setColor] = React.useState(actions[0].color.hex);
  const handleColorChange = (color: any) => {
    const updatedGeneralProperties = update(themeProps, {
      widget: {
        header: {
          actions: {
            [0]: {
              color: {
                hex: { $set: color.hex },
              },
            },
            [1]: {
              color: {
                hex: { $set: color.hex },
              },
            },
            [2]: {
              color: {
                hex: { $set: color.hex },
              },
            },
          },
        },
      },
    });

    setThemeProps(updatedGeneralProperties);
  };
  const handleChange = (value: string) => {
    debugger
    const updatedHeader = update(themeProps, {
      widget: {
        header: {
          actions: {
            [0]: {
              size: { $set: value },
            },
            [1]: {
              size: { $set: value },
            },
            [2]: {
              size: { $set: value },
            },
          },
        },
      },
    });
    setThemeProps(updatedHeader);
  };
  return (
    <>
      <Collapse defaultActiveKey={["1"]} onChange={callback}>
        <Panel header="Header Action Icons" key="1">
          <ColorContainer>
            <span className="text">Color</span>
            <DisplayColor
              theme={{ backgroundColor: color }}
              className="picker"
              onClick={() => setOpenPicker(!openPicker)}
            />

            <GradientPickerWrapper>
              <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
            </GradientPickerWrapper>
          </ColorContainer>
          <DropdownWrapper>
            <span className="text">Size</span>

            <Select
              defaultValue={actions[0].size}
              style={{ width: 161, height: 28 }}
              onChange={handleChange}
              size="small"
            >
              <Option value="small">Small</Option>
              <Option value="medium">Medium</Option>
              <Option value="large">Large</Option>
            </Select>
          </DropdownWrapper>
        </Panel>
      </Collapse>
      {openPicker && (
        <ColorPicker>
          <ChromePicker
            color={color}
            onChange={(color: any) => setColor(color.hex)}
            onChangeComplete={handleColorChange}
          />
        </ColorPicker>
      )}
    </>
  );
}
