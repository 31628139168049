import * as React from "react";
import styled from "styled-components";
import { FaMicrophone, FaRegClipboard, FaPaperclip, FaRegArrowAltCircleRight } from "react-icons/fa";
import { SelectedPropertyContext, ThemeContext } from "../../../themeDesigner";

export interface IChatUserInputProps { }

const UserInput = styled.div`
  display: flex;
  font-family:${(props) => props.theme.fontFamily};
  flex-direction: column;
  background: ${(props) => props.theme.backgroundColor};
  min-height: 69px;
  border: ${(props) => props.theme.borderAll};
  border-radius: ${(props) =>
    props.theme.borderRadius
      ? props.theme.borderRadius
      : `${props.theme.borderTopLeft} ${props.theme.borderTopRight} ${props.theme.borderBottomRight} ${props.theme.borderBottomLeft}`};
  opacity: ${(props) => props.theme.opacity / 100};
`;

const Container = styled.div`
  display: ${(props) => props.theme.display};
  flex-direction: ${(props) => props.theme.direction};
  -webkit-box-pack: start;
  justify-content: ${(props) => props.theme.justifyContent};
  -webkit-box-align: ${(props) => props.theme.alignItems};
  align-items: ${(props) => props.theme.alignItems};
  grid-template-rows: 40px 20px;
  // border-width: 1px;
  // border-style: solid;
  // border-color: rgb(241, 241, 241);
  border-image: initial;
  margin: ${(props) => props.theme.marginAll};
  margin-right: ${(props) => props.theme.marginRight};
  margin-left: ${(props) => props.theme.marginLeft};
  margin-top: ${(props) => props.theme.marginTop};
  margin-bottom: ${(props) => props.theme.marginBottom};
  padding: ${(props) => props.theme.paddingAll};
  padding-right: ${(props) => props.theme.paddingRight};
  padding-left: ${(props) => props.theme.paddingLeft};
  padding-top: ${(props) => props.theme.paddingTop};
  padding-bottom: ${(props) => props.theme.paddingBottom};
`;

const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) => props.theme.direction};
  justify-content: ${(props) => props.theme.justifyContent};
  -webkit-box-align: ${(props) => props.theme.alignItems};
  align-items: ${(props) => props.theme.alignItems};
  grid-template-columns: ${(props) =>
    props.theme.isMicActive &&
      (props.theme.isNotesIconVisible || props.theme.isAttachmentIconVisible)
      ? "40px 1fr 60px"
      : props.theme.isMicActive &&
        !(props.theme.isNotesIconVisible || props.theme.isAttachmentIconVisible)
        ? "40px 1fr"
        : !props.theme.isMicActive &&
          (props.theme.isNotesIconVisible || props.theme.isAttachmentIconVisible)
          ? "1fr 60px"
          : "1fr 20px"};
`;

const Input = styled.input`
  width: 100%;
  font-family: inherit;
  padding: 0 15px;
  border: none;
  background-color: ${(props) => props.theme.backgroundColor};
  color: ${(props) => props.theme.color};

  &:focus {
    outline: none;
  }
`;

const UserInputMicContainer = styled.div`
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;

  @media all and (-ms-high-contrast: none) {
    margin-left: 10px;
  }
`;

const UserInputActions = styled.div`
  display: flex;
  width: 60px;
  align-items: center;
  justify-content: space-evenly;

  @media all and (-ms-high-contrast: none) {
    justify-content: space-between;
    margin-right: 10px;
    width: 40px;
  }
`;

const UserInputBanner = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: ${(props) => props.theme.size}px;
  text-align: center;
  color: ${(props) => props.theme.color};
  letter-spacing: 1px;
  margin-bottom: 12px;
  @media all and (-ms-high-contrast: none) {
    margin: 10px 0px;
  }
`;

export default function ChatUserInput(props: IChatUserInputProps) {
  const { setSelectedProperty } = React.useContext(SelectedPropertyContext);
  const { themeProps } = React.useContext(ThemeContext);
  let { userInput, dimension } = themeProps.widget;
  if (userInput && !userInput.sendIcon) {
    userInput = Object.assign(userInput, {
      sendIcon: {
        isVisible: false,
        size: "lg",
        color: { hex: "#555555" },
      }
    })
  }
  if (userInput && !userInput.keyboard) {
    userInput = Object.assign(userInput, {
      keyboard: {
        isVisible: false,
        size: "lg",
        color: { hex: "#555555" },
      }
    })
  }
  const {
    display,
    flexDirection,
    justifyContent,
    alignItems,
    background,
    color,
  } = userInput;
  const {
    margin,
    marginTop,
    marginLeft,
    marginBottom,
    marginRight,
    paddingRight,
    paddingLeft,
    paddingBottom,
    padding,
    paddingTop,
  } = userInput;
  const {
    border,
    borderBottomRight,
    borderTopLeft,
    borderRadius,
    borderBottomLeft,
    borderTopRight,
  } = userInput;
  const { opacity } = userInput;
  return (
    <UserInput
      theme={{
        backgroundColor: background.hex,
        border,
        borderBottomLeft,
        borderTopLeft,
        borderTopRight,
        borderRadius,
        borderBottomRight,
        opacity,
        fontFamily: dimension.font,
      }}
    >
      <Container
        theme={{
          display,
          direction: flexDirection,
          justifyContent,
          alignItems,
          margin,
          marginTop,
          marginLeft,
          marginBottom,
          marginRight,
          paddingRight,
          paddingLeft,
          paddingBottom,
          padding,
          paddingTop,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedProperty("input");
        }}
      >
        <InputRow
          theme={{
            isKeyboardVisible: userInput.keyboard && userInput.keyboard.isVisible ? userInput.keyboard.isVisible : false,
            isMicActive: userInput.mic.isVisible,
            isNotesIconVisible: userInput.note.isVisible,
            isAttachmentIconVisible: userInput.attachment.isVisible,
            isSendIconVisible: userInput.sendIcon && userInput.sendIcon.isVisible ? userInput.sendIcon.isVisible : false,
            display,
            direction: flexDirection,
            justifyContent,
            alignItems,
          }}
        >
          {userInput.mic.isVisible && (
            <UserInputMicContainer>
              <FaMicrophone color={userInput.mic.color.hex} />
            </UserInputMicContainer>
          )}
          <form style={{ height: "20px", flex: "1" }}>
            {userInput.keyboard.isVisible && <Input
              theme={{
                backgroundColor: background.hex,
                color: color.hex,
              }}
              placeholder={userInput.placeholder}
            />}
          </form>
          {(userInput.note.isVisible || userInput.attachment.isVisible || userInput.sendIcon.isVisible) && (
            <UserInputActions>
              {userInput.note.isVisible && (
                <FaRegClipboard color={userInput.note.color.hex} />
              )}
              {userInput.attachment.isVisible && (
                <FaPaperclip color={userInput.attachment.color.hex} />
              )}
              {userInput.sendIcon && userInput.sendIcon.isVisible && (
                <FaRegArrowAltCircleRight color={userInput.sendIcon.color.hex} />
              )}
            </UserInputActions>
          )}
        </InputRow>
      </Container>
      <UserInputBanner theme={{ size: userInput.footer.size }}>
        {userInput.footer.text}
      </UserInputBanner>
    </UserInput>
  );
}
