import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Tooltip, Table, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { updateSettingShow } from '../../../actions/uiAction';
import { settingSaveDetails } from '../../../actions/taskAction';
import agentIcon from '../../../assets/images/agent-icon.svg';

class AgentTableList extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        let columns = [
            {
                title: this.props.roles.includes("admin") && <div className="add" onClick={this.onAddOrUpdateDetails.bind(this, {
                    _id: 0,
                    action: true,
                    isActive: true
                })} ><span className="plus">+</span></div>,
                dataIndex: 'dp',
                width: 80,
                render: (text, record) => <div> <span className={record.isActive ? "active" : "inactive"}></span>< img className="dp-img" src={agentIcon} /></div>,
            },
            {
                title: 'NAME',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => <a style={{ fontWeight: 800 }}>{text.toUpperCase()}</a>,
            },
            {
                title: 'EMAIL',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'GROUPS',
                key: 'groupIds',
                dataIndex: 'groupIds',
                render: (groupIds,) => (
                    <span>
                        {groupIds && groupIds.map((groupId) => {
                            let groupName = this.getGroupName(groupId);
                            return (
                                groupName && <Tag color={"geekblue"} key={groupId}>
                                    {groupName}
                                </Tag>
                            );
                        })}
                    </span>
                )
            },
            {
                title: 'ROLES',
                key: 'roles',
                dataIndex: 'roles',
                render: (roles,) => (
                    <span>
                        {roles && roles.map((role) => {
                            return (
                                <Tag color={"orange"} key={role}>
                                    {role.toUpperCase()}
                                </Tag>
                            );
                        })}
                    </span>
                )
            }];
        if (this.props.roles.includes("admin")) {
            //onClick={this.onAddOrUpdateDetails.bind(this, record)}
            let editColumn: any = {
                title: 'EDIT',
                dataIndex: 'edit',
                render: (text, record) => (
                    <span>
                        <EditOutlined className="edit" />
                    </span>
                )
            };
            columns.push(editColumn);
        }
        this.state = {
            windowHeight: window.innerHeight,
            roles: props.roles,
            updateSettingShow: false,
            columns: columns
        };
    }

    displayWindowSize() {
        this.setState({
            windowHeight: window.innerHeight
        })
    }

    async componentDidMount() {
        window.addEventListener("resize", () => { this.displayWindowSize() });
    }

    async componentWillUnmount() {
        window.removeEventListener("resize", () => { this.displayWindowSize() });
    }
    getGroupName = (gId) => {
        let groups = this.props.group.groups;
        let groupName = null;
        for (let i in groups) {
            if (groups[i]._id == gId) {
                groupName = groups[i].name.toUpperCase();
            }
        }
        return groupName;
    }

    onAddOrUpdateDetails = (agent, event: any) => {
        debugger
        agent = { ...agent, type: "agent" };
        event.preventDefault();
        this.setState({ updateSettingShow: true });
        this.props.settingSaveDetails({ info: agent })
        this.props.updateSettingShow({ updateSettingShow: true });
    }

    loadGruopDetails = (groupIds) => {
        let groups = this.props.group.groups;
        let groupCount = 0;
        let groupsList = [];
        let isMore = false;
        let title = [];
        groupIds.map((gId) => {
            return groups && groups.map((group) => {
                if (group._id == gId) {
                    groupCount++;
                    if (groupCount >= 3) {
                        isMore = true;
                        title.push(<Col key={0} span={24} className="item single">{group.name}</Col>);
                    } else if (!isMore) {
                        groupsList.push(<Col key={gId} className="group" ><div className="item single">{group.name}</div></Col>)
                    }
                }
            })
        });
        if (isMore) {
            groupsList.push(<Col key={0} className="group"><Tooltip placement="rightTop" title={<Row className="more-group">{title}</Row>}><div className="item more">+{(groupIds.length - 2)}</div></Tooltip></Col>);
        }
        return groupsList;
    }

    render() {
        let agents = JSON.parse(JSON.stringify(this.props.agents));
        agents && agents.shift();
        return (
            <Row className="table-view">
                <Col span={24}>
                    <Row style={{ height: (this.state.windowHeight - 300) + "px", marginTop: "40px" }}>
                        <Col span={24}>
                            {agents && <Table
                                onRow={(record) => ({
                                    onClick: (event) => {
                                        debugger
                                        this.onAddOrUpdateDetails.bind(this, record)
                                    }
                                })}
                                rowKey={(record: any) => {
                                    return record._id;
                                }}
                                scroll={{ y: (this.state.windowHeight - 340) }}
                                columns={this.state.columns}
                                dataSource={agents}
                            />}
                        </Col>
                    </Row>
                </Col>
            </Row >
        );
    }
}

function mapStateToProps(state: any) {
    // console.log("Agent List mapStateToProps: ", state);
    return {
        auth: state.auth,
        group: state.group
    }
}

export default connect(mapStateToProps, { updateSettingShow, settingSaveDetails })(AgentTableList);
