import React from "react";
import styled from 'styled-components'
import Lottie from 'react-lottie';
import animationData from "../../assets/lotties/a8Loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

interface IProps {
  loading: boolean;
}

const StyledSpinner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return (
    <StyledSpinner>
      <Lottie options={defaultOptions} height={100} width={150} />
    </StyledSpinner>
  );
};

export default Spinner;
