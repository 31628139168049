import type from "../types/actionTypes";
const initialState = {
  /** login status
   *  type : boolean
   **/
  isLoggedIn: false,

  //maintain auth flow loading status
  isLoading: false,

  //basic user info
  info: null,

  //if any auth error
  error: null,
};

export default function authReducers(state = initialState, action) {
  // console.log(action)
  switch (action.type) {
    case type.SIGNIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        info: action.data,
        refreshToken: null,
      };
    case type.SESSION_TOKEN_SUCCESS:
      return {
        ...state,
        sessionToken: action.data,
      };
    case type.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        refreshToken: action.data,
      };
    case type.ERROR:
      return {
        ...state,
        error: action.data,
      };
    case type.SIGNIN_ERROR:
      return {
        ...state,
        error: action.data,
      };

    case type.SIGNOUT_ERROR:
      return {
        ...state,
        error: action.data,
      };
    case type.SIGNOUT_SUCCESS:
      return {
        ...state,
        isLoggedIn: false
      }
    case type.RESET_SESSION:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        info: null,
        refreshToken: null,
        sessionToken: null,
      }
    default:
      return state;
  }
}
