const apisUrl = {
  baseUrl: window.baseURL,
  org: "/org",
  v1: "/v1",
  v2: "/v2",
  signinUrl: "/authenticate",
  signupUrl: "/signup",
  meUrl: "/me",
  signoutUrl: "/logout",
  tagsUrl: "/tags",
  deleteTagUrl: "/tags/{tagId}",
  agentsUrl: "/agents",
  updateAgentUrl: "/agents/{agentId}",
  groupsUrl: "/groups",
  updateGroupUrl: "/groups/{groupId}",
  assignedAgentsUrl: "/groups/{groupId}/agents",
  updateAssignedAgentsUrl: "/groups/{groupId}/assignAgents",
  updateUnassignedAgentsUrl: "/groups/{groupId}/unassignAgents",
  topicsUrl: "/topics",
  updateTopicUrl: "/topics/{topicId}",
  getChatTranscript: "/chats/{chatId}",
  assignedChatsUrl: "/chats?state=assigned&limit={limit}",
  assignedChatToUrl: "/chats?assignedTo={assignedTo}&limit={limit}",
  newChatsUrl: "/chats?state=new",
  getChatHostory: "/chatHistory",
  unassignedChatsUrl: "/chats?state=unassigned&limit={limit}",
  faqCategorieUrl: "/faqCategories",
  updateFaqCategorieUrl: "/faqCategories/{faqCategorieId}",
  newFaqQuestionsUrl: "/faqCategories/{faqCategoryId}/questions",
  updateFaqQuestionsUrl:
    "/faqCategories/{faqCategoryId}/questions/{FaqQuestionId}",
  quickResponseUrl: "/quickResponses",
  updateQuickResponseUrl: "/quickResponses/{quickResponseId}",
  addTagToChatUrl: "/chats/{chatId}/tags",
  closeChatUrl: "/chats/{chatId}/caseStatus",
  emailConversationsUrl: "/emailconversations",
  attachmentUrl: "/storage/forchat/newfile",
  updateKnowledgeBaseUrl: "/kbarticles/{kbarticlesId}",
  newFileForClientUrl: "/storage/newfileforclient",
  knowledgeBaseUrl: "/kbarticles",
  emailTemplatesUrl: "/emailtemplates",
  emailConversationsReplyUrl: "/emailconversations/{emailConversationId}/reply",
  emailDraftsUrl: "/emaildrafts",
  updateEmailTemplatesUrl: "/emailtemplates/{emailTemplateId}",
  emailConversationsDraftUrl:
    "/emailconversations/{emailConversationId}/drafts",
  userinfoUrl: "/userinfo",
  emailAttachmentUrl: "/storage/foremail/newfile",
  freshDeskConnectUrl: "/services/freshdesk/connect",
  freshDeskUpdateUrl: "/services/freshdesk/tickets/<ticket_id>/update",
  assignAgentUrl: "/services/freshdesk/tickets/<ticket_id>/assign",
  refreshUrl: "/agents/refresh_token",
  getSessionToken: "/agents/sessionToken",
  getBulkTemplate: "/{type}/bulkTemplate",
  validateTemplate: "/{type}/validateTemplate",
  createEntries: "/{type}/createEntries",
  chatTranscript: "/chats/{chat_id}/exportChat",
  resetPassword: "/resetPassword",
  resetPasswordSendOtp: "/sendOTP",
  resetPasswordVerifyOtp: "/verifyOTP",
  publishTheme: "/bots/{bot_handle}/configuration",
  moniterChats:
    "/org/v2/chats?assignedTo=group&monitor={monitorState}&limit={limit}&lastTouched={lastTouched}",
  settings: "/settings",
  liveAgent: "/liveAgent",
  changeAgentState: "/changeAgentState",
  forceLogoutUrl: "/agents/{agent_id}/forceAgentLogout",
  getAPICredentialsUrl: "/credentials/tokens",
  getAPIScopesUrl: "/credentials/apiScopes",
  removeTokenUrl: "/credentials/revokeToken",
  generateTokenUrl: "/credentials/generateToken",
  getDashboardDetailsUrl: "/analytics/a8dashboard?token={token}",
  getClientChatsUrl: "/clients/{clientId}/chatHistory",
  nluConfiguration: "/bots/{botHandle}/nlu-configuration",
  assistFlow: "/a8solo/live-agent/v1/sessions/{sessionId}/assistFlow"
  // dashboardUrl: "/analytics/d/HXcGo9bMz/agent?orgId={orgId}&token={token}&kiosk&theme=dark"
};

export default {
  TargetOrigin: "*",
  ...apisUrl,
};
