import * as React from "react";
import stripHtml from "string-strip-html";
import styled from "styled-components";
import MarkdownIt from "markdown-it";
import { ThemeContext, SelectedPropertyContext } from "../../../settings/general/themeDesigner";
import {
    container,
    titleStyle,
    optionListContainer,
    optionList,
    option,
} from "./style";
import { SelectedBotContext } from "../../../settings/bots";
// import { decodeEntities } from "../../utils/utils";
export interface ILayoutProps {
    children?: React.ReactNode;
    onHandelClick?: (arg) => void;
    theme: any;
}

export interface ISingleChoiceProps {
    isChatIconVisible: boolean;
    icon: string;
    isFeedback: boolean;
    primaryColor: string;
    title: string;
    shouldShowActions: boolean;
    choices: any[];
    handleOptionSelect: (index: number) => void;
    fontFamily: string;
    backgroundColor: string;
    textColor: string;
    isUser: boolean;
    onHandelClick?: (arg: any) => void;
}

const LayoutContainer = styled.div`
  display: flex;
  font-family:  ${(props) => props.theme.fontFamily};
  justify-content: ${(props) => (props.theme.isUser ? "flex-end" : "flex-start")};
`;

export const decodeEntities = (
    encodedString: string,
    maxLimit = 140,
    shouldTruncate = false
) => {
    const md = new MarkdownIt({ html: true });
    if (encodedString) {
        let newStr = encodedString.replace(/\\n/g, "<br />");
        // if (shouldTruncate) {
        //   const caret = newStr.length > maxLimit ? "..." : "";
        //   newStr = newStr.substr(0, maxLimit) + caret;
        // }
        var textArea = document.createElement("textarea");
        textArea.innerHTML = newStr;
        const val = textArea.value;
        const markdownOutput = md.render(val);
        if (shouldTruncate) {
            const caret = markdownOutput.length > maxLimit ? "..." : "";
            return markdownOutput.substr(0, maxLimit) + caret;
        }
        return markdownOutput;
    }
    return encodedString;
};

const Layout = (props: ILayoutProps) => {
    const { children, onHandelClick, theme } = props;
    return (
        <LayoutContainer onClick={onHandelClick} theme={theme}>
            {children}
        </LayoutContainer>
    );
};

export default function SingleChoice(props: ISingleChoiceProps) {
    const { themeProps, setThemeProps } = React.useContext(ThemeContext);
    const { selectedBotName } = React.useContext(SelectedBotContext);
    const {
        widget: { messages },
    } = themeProps;
    const {
        choices,
        shouldShowActions,
        isChatIconVisible,
        icon,
        title,
        isUser,
        onHandelClick,
    } = props;
    const {
        optionsFRColor,
        optionsHRColor,
        optionsBGColor,
        optionsTextHRColor,
        optionsBorder,
        optionsShadow,
        optionsRadius,
        optionsFontSize,
        optionsHeight,
        optionsFontWeight,
    } = messages.simpleChoice.agent;
    const {
        fontSize,
        backgroundColor,
        color,
        border,
        borderRadius,
        borderTopLeft,
        borderTopRight,
        borderBottomRight,
        borderBottomLeft,
        outlineColor,
        outlineTop,
        outlineRight,
        outlineBottom,
        outlineLeft
    } = messages.globalSettings.agent;
    const optionHover = (liOption, isFlag) => {
        if (shouldShowActions && liOption) {
            liOption.style.background = isFlag
                ? optionsHRColor
                : optionsBGColor;
            liOption.style.color = isFlag
                ? optionsTextHRColor
                : optionsFRColor
        }
    };
    const liOptions = React.useRef([]);
    return (
        <Layout theme={{
            fontFamily: themeProps.widget.dimension.font,
            isUser: isUser
        }} onHandelClick={onHandelClick ? onHandelClick : undefined}>
            {isChatIconVisible && <div style={{
                width: "45px",
                minWidth: "45px",
                height: "45px",
                margin: `5px ${!isUser ? "10px" : 0} 5px ${isUser ? "10px" : 0}`,
                borderRadius: "50%",
                padding: "2px",
                order: isUser ? 1 : 0
            }}>
                {icon && <img src={icon} height="auto" width="100%" />}
                {!icon && isUser && <div style={{
                    backgroundColor: themeProps.widget.dimension.userColor.hex,
                    height: themeProps.widget.dimension.userHeight,
                    width: themeProps.widget.dimension.userWidth,
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: themeProps.widget.dimension.userTextColor.hex,
                    fontWeight: "bold",
                    borderRadius: themeProps.widget.dimension.borderRadiusUser
                }}>U</div>}

                {!icon && !isUser && <div style={{
                    backgroundColor: themeProps.widget.dimension.agentColor.hex,
                    height: themeProps.widget.dimension.agentHeight,
                    width: themeProps.widget.dimension.agentWidth,
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: themeProps.widget.dimension.agentTextColor.hex,
                    fontWeight: "bold",
                    borderRadius: themeProps.widget.dimension.borderRadiusAgent
                }}>{selectedBotName[0]}</div>}
            </div>}
            <div style={{
                overflow: "hidden",
                backgroundColor,
                color,
                border,
                borderRadius,
                borderTopLeftRadius: borderTopLeft,
                borderBottomRightRadius: borderBottomRight,
                borderTopRightRadius: borderTopRight,
                borderBottomLeftRadius: borderBottomLeft
            }} >
                <div style={{
                    padding: 10,
                    borderWidth: 0,
                    borderStyle: "solid",
                    borderColor: outlineColor,
                    borderLeftWidth: outlineLeft || "0px",
                    borderRightWidth: outlineRight || "0px",
                    borderTopWidth: outlineTop || "0px",
                    borderBottomWidth: outlineBottom || "0px"
                }} >
                    <div style={{ fontSize: fontSize + "px" }}>{title}</div>
                    {shouldShowActions && (
                        <ul className={optionListContainer}>
                            {choices.map((choice, index) => {
                                const textArea = document.createElement("textarea");
                                textArea.innerHTML = choice.label;
                                const title = textArea.value;
                                const encodedOption = decodeEntities(choice.label);
                                const decodedOption = stripHtml(encodedOption);
                                return (
                                    <li
                                        style={{
                                            padding: "8px 20px",
                                            color: optionsFRColor,
                                            background: optionsBGColor,
                                            margin: 5,
                                            minWidth: "45%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            boxShadow: optionsShadow,
                                            border: optionsBorder,
                                            height: optionsHeight == "" ? "auto" : optionsHeight,
                                            // height: optionsHeight + "px",
                                            borderRadius: optionsRadius + "px",
                                            fontWeight: optionsFontWeight,
                                            fontSize: optionsFontSize + "px"
                                        }}
                                        title={title}
                                        key={choice.id || index}
                                        ref={(el) => (liOptions.current[index] = el)}
                                        onMouseEnter={() =>
                                            optionHover(liOptions.current[index], true)
                                        }
                                        onMouseLeave={() =>
                                            optionHover(liOptions.current[index], false)
                                        }
                                    >
                                        <span>{decodedOption}</span>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </Layout>
    );
}