import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import LeftIllustrationImage from "../../assets/images/left_illustration.svg";
import RightIllustrationImage from "../../assets/images/right_illustration.svg";
import DescriptionLogoImage from "../../assets/images/mail_icon.svg";
import InputLogoImage from "../../assets/images/password_icon.svg";
import AccountRecoveryCard from "./AccountRecoveryCard";
import { checkPassword } from "../../utils/passwordStrength";
import { withRouter } from "react-router-dom";
import { ForgotPasswordContext } from "../../App";
import { changePassword } from "../../actions/authActions";
import zxcvbn from "zxcvbn";
import { deviceBreakpoint } from "../../utils/deviceBreakpoints";

export interface IChangePasswordProps {
  history: any;
}

const Container = styled.div`
  position: relative;
  height: 100vh;
`;

const LeftIllustration = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;

  // Hide on Mobile
  @media ${deviceBreakpoint.laptop} {
    display: block;
  }
`;

const RightIllustration = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;

  // Hide on Mobile
  @media ${deviceBreakpoint.laptop} {
    display: block;
  }
`;

const Heading = styled.div`
  font-family: Muli;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #10002e;
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #10002e;
  margin-bottom: 44px;
  display: flex;
  align-items: center;
`;

const DescriptionLogo = styled.img`
  margin-right: 8px;
`;

const FormLabel = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-transform: uppercase;
  text-align: left;
  color: #10002e;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const InputLogo = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-73%);
  left: 15px;
`;

const Input = styled.input`
  height: 50px;
  border-radius: 2.5px;
  border: solid 0.5px ${(props) => (props.theme.error ? "#d30029" : "#95abd3")};
  background-color: #ffffff;
  max-width: 100%;
  width: 100%;
  padding: 0 12px 0 45px;
  margin-bottom: 8px;
  letter-spacing: 12px;

  &::-webkit-input-placeholder {
    /* Edge */
    letter-spacing: initial;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    letter-spacing: initial;
  }

  &::placeholder {
    letter-spacing: initial;
  }
`;

const InputInstruction = styled.p`
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #95abd3;
  margin-bottom: 16px;
`;

const InputError = styled.p`
  font-family: Muli;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #d30029;
  min-height: 12px;
  margin-bottom: 13px;
`;

const ButtonContainer = styled.div``;

const Button = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 2.5px;
  border: none;
  color: white;
  box-shadow: 0 4px 6.5px 0 rgba(0, 118, 255, 0.27);
  background-color: #0076ff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

const CancelButton = styled.button`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #95abd3;
  background: white;
  border: none;
  outline: none;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
`;

const ProgressIndicator = styled.div`
  background: #e0eafc;
  border-radius: 4px;
  width: 100%;
  height: 5px;
  margin-bottom: 26px;

  div {
    width: ${(props) => props.theme.width.toString() + "%"};
    height: 5px;
    transition: width 0.5s;
    background: ${(props) =>
      props.theme.width <= 25
        ? "#d30029"
        : props.theme.width > 25 && props.theme.width < 50
        ? "#fa7412"
        : props.theme.width > 50 && props.theme.width < 75
        ? "#698f3f"
        : props.theme.width > 75
        ? "#00be2c"
        : "#e0eafc"};
  }

  p {
    text-align: right;
    font-family: Muli;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: ${(props) =>
      props.theme.width <= 25
        ? "#d30029"
        : props.theme.width > 25 && props.theme.width < 50
        ? "#fa7412"
        : props.theme.width > 50 && props.theme.width < 75
        ? "#698f3f"
        : props.theme.width > 75
        ? "#00be2c"
        : "#e0eafc"};
  }
`;

const UppercaseLabel = styled.span`
  color: #95abd3;
`;

const LowercaseLabel = styled.span`
  color: #95abd3;
`;

const NumberLabel = styled.span`
  color: #95abd3;
`;

const SymbolLabel = styled.span`
  color: #95abd3;
`;

const passwordLevel = {
  weak: "Weak",
  good: "Good",
  medium: "Medium",
  great: "Great",
};

function ChangePassword(props: IChangePasswordProps) {
  const { history } = props;
  const handleCancel = () => {
    history.push("/signin");
  };
  const { accessToken, resetEmail, setAccessToken } = useContext(
    ForgotPasswordContext
  );
  useEffect(() => {
    if (!resetEmail) {
      history.push("/forgot-password");
    } else if (!accessToken) {
      history.push("/verify-otp");
    }
  }, []);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, showNewPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [passwordStrength, setPasswordStrength] = React.useState(0);
  const [passwordStrengthLabel, setPasswordStrengthLabel] = React.useState("");
  const handleNewPasswordChange = (e) => {
    const { value } = e.target;
    setNewPassword(value);
    if (value) {
      const { score } = zxcvbn(value);
      showNewPasswordError(checkPassword(value));
      switch (score) {
        case 0:
          setPasswordStrengthLabel(passwordLevel.weak);
          setPasswordStrength(10);
          break;
        case 1:
          setPasswordStrengthLabel(passwordLevel.weak);
          setPasswordStrength(20);
          break;
        case 2:
          setPasswordStrengthLabel(passwordLevel.medium);
          setPasswordStrength(45);
          break;
        case 3:
          setPasswordStrengthLabel(passwordLevel.good);
          setPasswordStrength(70);
          break;
        case 4:
          setPasswordStrengthLabel(passwordLevel.great);
          setPasswordStrength(100);
          break;
        default:
          break;
      }
    } else {
      setPasswordStrengthLabel("");
      setPasswordStrength(0);
    }
  };
  const handleConfirmPasswordBlur = () => {
    if (!newPassword) {
      setConfirmPasswordError(true);
    }
    setConfirmPasswordError(newPassword !== confirmPassword);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword && !newPasswordError) {
      try {
        const result = await changePassword(accessToken, newPassword);
        history.push("/reset-password-success");
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <Container>
      <LeftIllustration src={LeftIllustrationImage} alt="Left Illustartion" />
      <RightIllustration
        src={RightIllustrationImage}
        alt="Right Illustartion"
      />
      <AccountRecoveryCard height="530px">
        <Heading>Change Password</Heading>

        <form onSubmit={handleSubmit}>
          <FormLabel>New Password</FormLabel>
          <InputWrapper>
            <InputLogo src={InputLogoImage} alt="mail icon" />
            <Input
              onChange={handleNewPasswordChange}
              value={newPassword}
              type="password"
              placeholder="Enter your password"
            />
          </InputWrapper>
          <InputError>{newPasswordError && "Invalid Password"}</InputError>
          <InputInstruction>
            Password must contain minimum{" "}
            <UppercaseLabel>1 uppercase</UppercaseLabel>,{" "}
            <LowercaseLabel>1 lowercase</LowercaseLabel>,{" "}
            <NumberLabel>1 number</NumberLabel>,{" "}
            <SymbolLabel>1 symbol(@#$%!)</SymbolLabel> in it.
          </InputInstruction>
          <ProgressIndicator theme={{ width: passwordStrength }}>
            <div />
            <p>{passwordStrengthLabel}</p>
          </ProgressIndicator>
          <FormLabel>Confirm Password</FormLabel>
          <InputWrapper theme={{ paddingBottom: "20px" }}>
            <InputLogo src={InputLogoImage} alt="mail icon" />
            <Input
              theme={{ error: confirmPasswordError }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={handleConfirmPasswordBlur}
              type="password"
              placeholder="Confirm your password"
            />
          </InputWrapper>
          <InputError>
            {confirmPasswordError && "Please make sure your passwords match."}
          </InputError>

          <ButtonContainer>
            <Button type="submit">Next</Button>
            <CancelButton onClick={handleCancel}>Cancel</CancelButton>
          </ButtonContainer>
        </form>
      </AccountRecoveryCard>
    </Container>
  );
}

export default withRouter(ChangePassword);
