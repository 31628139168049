import * as React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

export interface IResetPasswordSuccessProps {
  history: any;
}

const Container = styled.div`
  width: 80%;
  margin: 100px auto;
  height: 100vh;
`;

const Header = styled.div`
  font-size: 36px;
  font-family: Muli;
  text-align: center;
`;

const Description = styled.p`
  font-size: 20px;
  text-align: center;
`;

const Button = styled.button`
  width: 200px;
  height: 50px;
  border-radius: 2.5px;
  border: none;
  color: white;
  box-shadow: 0 4px 6.5px 0 rgba(0, 118, 255, 0.27);
  background-color: #0076ff;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 50px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

function ResetPasswordSuccess(props: IResetPasswordSuccessProps) {
  const { history } = props;
  return (
    <Container>
      <Header>Password changed successfully.</Header>
      <Description>Click here to sign in</Description>
      <ButtonWrapper>
        <Button onClick={() => history.push("/signin")}>Sign In</Button>
      </ButtonWrapper>
    </Container>
  );
}

export default withRouter(ResetPasswordSuccess);
