import * as React from "react";
import styled from "styled-components";
import { Upload } from "antd";
import sanitizeHtml from "sanitize-html";

import { sendChatMessage, autoPilot, sendTyping, transferChat } from "../../../../helpers/mqttService";
import PrivateIcon from "../../../../assets/icons/incognito";
import KeyIcon from "../../../../assets/icons/keyboard-shortcut";
import NoteIcon from "../../../../assets/icons/note";
import sendIcon from "../../../../assets/images/send.svg";
import Popup from "../../../common/Popup";
import attachmentIcon from "../../../../assets/images/ico-computer.svg";
import AttachmentIcon from "../../../../assets/icons/attachment";
import { uploadFile } from "../../../../actions/chatsAction";
import HTMLReactParser from "html-react-parser";
import Markdown from "markdown-it";
import { MainChatContext } from "./Main";
import { MonitorChatContext } from "../index";
const md = new Markdown({ html: true });

export interface ITypingAreaProps { }

const isClean = (dirty: any) => {
  return sanitizeHtml(dirty, {
    allowedTags: [
      "b",
      "i",
      "em",
      "strong",
      "a",
      "input",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "p",
      "span",
      "code",
      "pre",
      "br",
      "ol",
      "li",
      "ul",
    ],
    selfClosing: ["img", "br", "hr", "area", "base", "basefont", "input", "link", "meta"],
    allowedAttributes: {
      a: ["href"],
      input: ["type", "value", "onclick"],
    },
    allowedIframeHostnames: [],
    disallowedTagsMode: "escape",
  });
};

const AttachmentHeader = styled.p`
  font-size: 14px;
  color: #212332;
`;

const AttachmentContent = styled.span`
  color: #212332;
  font-size: 14px;
  font-weight: bold;
  padding-left: 13px;
`;

const ToggleText = styled.span`
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #7f90af;
  margin-left: 10px;
`;

const InputWrapper = styled.form`
  height: 72px;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(168, 171, 189, 0.4);
  background: ${(props) => (props.theme.privateChat ? "#2b2b2b" : "#FFFFFF")};
`;

const Input = styled.textarea`
  border: none;
  background: none;
  resize: none;
  outline: none;
  padding: 16px 29px 0 8px;
  flex: 1;
  color: ${(props) => (props.theme.privateChat ? "#ffffff" : "#000000")};
`;

const PrivateNoteWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${(props) => (props.theme.privateChat ? "#2b2b2b" : "#FFFFFF")};
`;

const IconWrapper = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
`;

const SendIconWrapper = styled.div`
  width: 50px;
  height: 50px;
  box-shadow: 0 4px 8px 0 ${(props) => props.theme.primaryColor};
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function TypingArea(props: any) {
  const {
    privateChat,
    selectedLiveChatId,
    handlePrivateChat,
    enableFAQ,
    setEnableFAQ,
    setEnableQuickResp,
    uploadFileProps,
    setIsComponentVisible,
    isComponentVisible,
    theme,
    onRemove,
    ref,
    setUploadFileProps,
    setShortCode,
    clientData,
    onCheckChar,
  } = props;
  const [prvSelectedLiveChatId, setPrvSelectedLiveChatId] = React.useState("");
  const [stopRepeatEnter, setStopRepeatEnter] = React.useState(null);
  const [typingareaText, setTypingareaText] = React.useState("");
  const { chatMessage, setChatMessage } = React.useContext(MainChatContext);
  const { setUserTextTyping, userTextTyping } = React.useContext(MonitorChatContext);
  const textLog = React.useRef(null);
  if ((userTextTyping[selectedLiveChatId] !== undefined && prvSelectedLiveChatId != selectedLiveChatId) || (userTextTyping[selectedLiveChatId] && typingareaText != userTextTyping[selectedLiveChatId])) {
    setPrvSelectedLiveChatId(selectedLiveChatId);
    setTypingareaText(userTextTyping[selectedLiveChatId]);
  } else if (userTextTyping[selectedLiveChatId] === undefined) {
    setPrvSelectedLiveChatId(selectedLiveChatId);
    let typingText = userTextTyping;
    typingText[selectedLiveChatId] = "";
    setUserTextTyping(typingText);
    setTypingareaText("");
  }
  React.useEffect(() => {
    setUserTextTyping({ ...userTextTyping, [selectedLiveChatId]: chatMessage[selectedLiveChatId] });
    setTypingareaText(chatMessage[selectedLiveChatId]);
  }, [chatMessage[selectedLiveChatId]]);
  const scrollToBottom = () => {
    if (textLog.current) {
      textLog.current.scrollTop = textLog.current.scrollHeight;
    }
  };

  const handleInputKeyPress = (e) => {
    if (e.keyCode === 13 && !(e.ctrlKey || e.altKey || e.shiftKey)) {
      e.preventDefault();
      handleSubmit(e);
      return false;
    } else if (e.keyCode === 13 && (e.ctrlKey || e.altKey)) {
      setTypingareaText(typingareaText + "\r\n");
      e.scrollTop = e.scrollHeight;
    }
  };

  const handleSubmit = (e) => {
    if (typingareaText && typingareaText.charAt(0) === "/") {
      setChatMessage({ ...chatMessage, [selectedLiveChatId]: "" });
      setShortCode(typingareaText);
      return;
    }
    e.preventDefault();
    if (uploadFileProps.fileList.length > 0) {
      if (!stopRepeatEnter) {
        setStopRepeatEnter(true);
        uploadFile(uploadFileProps.fileList[0])().then(() => {
          const msg = {
            url: uploadFileProps.fileList[0].uploadUrl.split("?")[0],
            fileName: uploadFileProps.fileList[0].name,
            fileSize: uploadFileProps.fileList[0].size,
            desc: typingareaText,
          };
          const payload = {
            msgType: uploadFileProps.fileList[0].msgType,
            msg,
            chatId: selectedLiveChatId,
            isPrivate: privateChat,
          };
          setTypingareaText("");
          setChatMessage({ ...chatMessage, [selectedLiveChatId]: "" });
          sendChatMessage(payload);
          setIsComponentVisible(false);
          setUploadFileProps({
            ...uploadFileProps,
            fileList: [],
          });
          setStopRepeatEnter(false);
        });
      }
    } else if (typingareaText && typingareaText.trim()) {
      const p = document.createElement("p");
      p.textContent = typingareaText.trim();
      const converted = p.innerHTML;
      const payload = {
        msgType: "text",
        msg: {
          text: converted,
        },
        chatId: selectedLiveChatId,
        isPrivate: privateChat,
      };
      setUserTextTyping({ ...userTextTyping, [selectedLiveChatId]: "" });
      setTypingareaText("");
      setChatMessage({ ...chatMessage, [selectedLiveChatId]: "" });
      sendChatMessage(payload);
    }
  };
  React.useEffect(scrollToBottom, [typingareaText]);
  return (
    <InputWrapper theme={{ privateChat }} onSubmit={handleSubmit}>
      <div style={{ display: "flex", alignItems: "baseline", flex: "1" }}>
        <div
          className="private-icon"
          style={{
            width: "22px",
            height: "22px",
            borderRadius: "50%",
            background: privateChat ? "#ffffff" : "#2b2b2b",
            marginLeft: "15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => handlePrivateChat(!privateChat, selectedLiveChatId)}
        >
          <PrivateIcon color={privateChat ? "#2b2b2b" : "#ffffff"} />
        </div>

        <Input
          theme={{ privateChat }}
          value={typingareaText}
          onKeyDown={handleInputKeyPress}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setUserTextTyping({ ...userTextTyping, [selectedLiveChatId]: e.target.value });
            onCheckChar(e.target.value);
            setTypingareaText(e.target.value);
            setChatMessage({ ...chatMessage, [selectedLiveChatId]: e.target.value });
          }}
          ref={textLog}
          placeholder="Start Typing"
        />
      </div>
      <IconWrapper>
        <span title={"FAQ"}>
          <NoteIcon
            isSelected={enableFAQ}
            onClick={() => {
              setUserTextTyping({ ...userTextTyping, [selectedLiveChatId]: "" });
              setChatMessage({ ...chatMessage, [selectedLiveChatId]: "" });
              setEnableFAQ(!enableFAQ);
              setEnableQuickResp(false);
            }}
          />
        </span>
        <Popup width="300px" height="154px" title="upload files" ref={ref} isVisible={isComponentVisible}>
          <AttachmentHeader>Add a file</AttachmentHeader>
          <div className="attachment-content">
            <Upload {...uploadFileProps}>
              <img src={attachmentIcon} alt="Attachment" />
              <AttachmentContent>from your computer</AttachmentContent>
            </Upload>
          </div>
        </Popup>
        <AttachmentIcon
          onClick={() => {
            setIsComponentVisible(!isComponentVisible);
            onRemove();
          }}
          className={isComponentVisible ? "active" : ""}
        />
        <SendIconWrapper theme={theme} onClick={handleSubmit}>
          <img src={sendIcon} alt="Attachment" />
        </SendIconWrapper>
      </IconWrapper>
    </InputWrapper>
  );
}
