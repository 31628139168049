import type from "../types/actionTypes";
import axios from "axios";
import config from "../apiConfig";
import { store } from "../store";
import { notification } from "antd";

const actionNotification = (notificationType, title, message) => {
    notification[notificationType]({
        message: title,
        description: message,
    });
    setTimeout(function () {
        notification.close();
    }, 1000);
};

export function getQuickResp() {
    const options = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
        },
    };
    return async (dispatch) => {
        try {
            let response = await axios(
                `${config.baseUrl}${config.org}${config.v1}${config.quickResponseUrl}`,
                options
            );
            dispatch({
                type: type.QUICK_RESP_SUCCESS,
                data: response.data,
            });
            return response.data;
        } catch (error) {
            if (error && error.response && error.response.data) {
                dispatch({
                    type: type.QUICK_RESP_ERROR,
                    data: {
                        error: error.response.data,
                        message: error.response.data.message,
                    },
                });
            }
        }
    };
}

export function createQuickResp(data) {
    const options = {
        method: "POST",
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
        },
    };
    return async (dispatch) => {
        try {
            let response = await axios(
                `${config.baseUrl}${config.org}${config.v1}${config.quickResponseUrl}`,
                options
            );
            dispatch({
                type: type.QUICK_RESP_SAVE_SUCCESS,
                data: response.data,
            });
            actionNotification(
                "info",
                "NEW QUICK RESPONSE CREATED",
                "Response successfully created"
            );
        } catch (error) {
            if (error.response.status === 401) {
                dispatch({
                    type: type.ERROR,
                    data: error.response.data,
                });
            } else {
                dispatch({
                    type: type.QUICK_RESP_SAVE_ERROR,
                    data: { error: error.response.data.message },
                });
            }
        }
    };
}

export function updateQuickResp(data, quickResponseId, originalData) {
    const options = {
        method: "PUT",
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
        },
    };
    return async (dispatch) => {
        try {
            let response = await axios(
                `${config.baseUrl}${config.org}${
                config.v1
                }${config.updateQuickResponseUrl.replace("{quickResponseId}", quickResponseId)}`,
                options
            );
            dispatch({
                type: type.QUICK_RESP_SAVE_SUCCESS,
                data: response.data,
            });
            actionNotification(
                "info",
                "QUICK RESPONSE UPDATED",
                `Quick Response ${data.shortCode} updated successfully`
            );
        } catch (error) {
            if (error.response.status === 401) {
                dispatch({
                    type: type.ERROR,
                    data: error.response.data,
                });
            } else {
                Object.assign(originalData, { error: error.response.data.message });
                dispatch({
                    type: type.QUICK_RESP_SAVE_ERROR,
                    data: { originalData },
                });
            }
        }
    };
}


export function deleteQuickResp(data, quickResponseId) {
    const options = {
        method: "DELETE",
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
        },
    };
    return async (dispatch) => {
        try {
            let response = await axios(
                `${config.baseUrl}${config.org}${
                config.v1
                }${config.updateQuickResponseUrl.replace("{quickResponseId}", quickResponseId)}`,
                options
            );
            dispatch({
                type: type.QUICK_RESP_DELETE_SUCCESS,
                data: response.data,
            });
            actionNotification(
                "info",
                "QUICK RESPONSE DELETE",
                `Quick Response ${data.shortCode} deleted successfully`
            );
        } catch (error) {
            if (error.response.status === 401) {
                dispatch({
                    type: type.ERROR,
                    data: error.response.data,
                });
            } else {
                Object.assign(data, { error: error.response.data.message });
                dispatch({
                    type: type.QUICK_RESP_DELETE_ERROR,
                    data: { data },
                });
            }
        }
    };
}