/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react";
import _ from "lodash";
import Navbar from "./components/navbar";
import styled from "styled-components";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import Main from "./components/Main";
import { FaRegIdBadge } from "react-icons/fa";
import botname from "../../../assets/images/botname.jpg";
import theme_item from "../../../assets/images/theme_item.svg";
import configuration_item from "../../../assets/images/spanner.svg";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { SelectedBotContext } from "../bots/index";
import { NLUConfiguration } from './components/NLUConfiguration'

interface IColor {
  r: number;
  g: number;
  b: number;
  a: number;
}

export interface IGeneralProperties {
  backgroundColor: string;
  isAvatarVisible: boolean;
  isMaximizeIconVisible: boolean;
  isCloseIconVisible: boolean;
  isStaticTextVisible: boolean;
  isBotnameVisible: boolean;
}

export interface IGeneralContentProperties {
  backgroundColor: IColor;
  spacing: ISpacingProperties;
}

export interface IGeneralInputProperties {
  placeholder: string;
  backgroundColor: IColor;
  textColor: IColor;
  isKeyboardVisible: boolean;
  isMicVisible: boolean;
  isNotesIconVisible: boolean;
  isSendBtnVisible: boolean;
  isAttachmentIconVisible: boolean;
}

interface IGeneralContainerProperties {
  width: string;
  height: string;
  primaryColor: IColor;
  secondaryColor: IColor;
}

interface IAvatarProperties {
  imageUrl: string;
  size: string;
}

interface ILabelProperties {
  color: string;
  size: string;
  text: string;
}

interface IBotnameProperties {
  color: IColor;
  size: string;
}

interface ILayoutProperties {
  display: string;
  direction: string;
  justifyContent: string;
  alignItems: string;
}

interface ISpacingProperties {
  marginAll: string;
  marginLeft: string;
  marginRight: string;
  marginTop: string;
  marginBottom: string;
  paddingAll: string;
  paddingLeft: string;
  paddingRight: string;
  paddingTop: string;
  paddingBottom: string;
}

interface IBorderProperties {
  borderAll: string;
  borderLeft: string;
  borderRight: string;
  borderTop: string;
  borderBottom: string;
  borderRadius: string;
}

interface IAppearanceProperties {
  opacity: number;
}

interface IHeaderProperties {
  general: IGeneralProperties;
  avatar: IAvatarProperties;
  title: ILabelProperties;
  subTitle: ILabelProperties;
  // botname: IBotnameProperties;
  layout: ILayoutProperties;
  spacing: ISpacingProperties;
  border: IBorderProperties;
  appearance: IAppearanceProperties;
}

interface IContentProperties {
  general: IGeneralContentProperties;
}

interface IFooterProperties {
  backgroundColor: IColor;
  text: string;
  size: string;
}

interface IInputProperties {
  general: IGeneralInputProperties;
  footer: IFooterProperties;
  layout: ILayoutProperties;
  spacing: ISpacingProperties;
  border: IBorderProperties;
  appearance: IAppearanceProperties;
}

interface IContainerProperties {
  general: IGeneralContainerProperties;
  widgetProperties: {
    enableChatIcon: boolean;
    agentIcon: IAvatarProperties;
    userIcon: IAvatarProperties;
    widgetIcon: IAvatarProperties;
  };
}

export interface IState {
  ui: IUiTheme;
}

interface IUiTheme {
  theme: ITheme;
}
interface ITheme {
  primaryColor: string;
  secondaryColor: string;
}

const Grid = styled.div`
  display: flex;
  height: calc(100vh - 3rem);
`;

const DrawerButton = styled.button`
  position: absolute;
  right: ${(props) => (props.theme.isOpen ? "-11px" : "-20px")};
  top: 28px;
  width: 18px;
  height: 18px;
  padding: 0;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  outline: none !important;
  background: white;
  border: 1px solid #1d7aff;
  color: #1d7aff;
`;

const Drawer = styled.div`
  width: ${(props) => (props.isConfigurationSelected ? "250px" : "220px")};
  height: 100vh;
  background: #f4f5f7;
  position: relative;
  margin-left: ${(props) => (!props.theme.isOpen ? "-200px" : "0")};
  transition: all 0.3s;
  padding: 20px;
`;

const DrawerHeading = styled.div`
  font-family: Muli;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1d7aff;
  margin-bottom: 24px;
`;

const DrawerList = styled.div`
  height: 35px;
  border-radius: 6px;
  background-color: ${props => props.selected ? "#d8e8ff" : ""};
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #223979;
  display: flex;
  align-items: center;
  padding: 0 15px;
`;

export const ThemeContext = React.createContext({
  themeProps: {
    widgetIcon: {
      size: "31px",
      icon: "https://cdn4.iconfinder.com/data/icons/avatar-circle-1-1/72/39-512.png",
      color: {
        hex: "#ffffff",
      },
      background: {
        isVisible: true,
        hex: "#f77f43",
      },
      position: {
        top: 0,
        bottom: "20",
        left: 0,
        right: "20",
      },
      dimensions: {
        width: "60",
        height: "60",
        isConstraint: false,
      },
      shape: {
        rotate: "0",
        border: {
          isMixed: true,
          borderTopLeft: "15",
          borderTopRight: "5",
          borderBottomRight: "15",
          borderBottomLeft: "5",
        },
      },
    },
    widget: {
      dimension: {
        maxWidth: "400px",
        minHeight: "85vh",
        agentWidth: "100%",
        agentHeight: "45px",
        userHeight: "45px",
        userWidth: "100",
        borderRadiusAgent: "18px 0px 18px 0px",
        borderRadiusUser: "18px 0px 18px 0px",
        bottom: "20px",
        right: "20px",
        botName: "",
        zIndex: "9999",
        background: "Transparent",
        typesense: false,
        loading: {
          url: "https://a8live.s3.ap-south-1.amazonaws.com/a8chatwidget/loading.gif",
          size: "31"
        },
        chatIcon: {
          size: "31px",
          isVisible: true,
          icon: "https://cdn4.iconfinder.com/data/icons/avatar-circle-1-1/72/39-512.png",
          userIcon: "https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/11_avatar-512.png",
        },
        userChatBackground: {
          hex: "#cc092f",
        },
        userChatTextColor: {
          hex: "#ffffff",
        },
        chatBackground: {
          hex: "#ffffff",
        },
        chatTextColor: {
          hex: "#555555",
        },
        primaryColor: {
          hex: "#cc092f",
        },
        secondaryColor: {
          hex: "#555555",
        },
        agentColor: {
          hex: "#cc092f",
        },
        userColor: {
          hex: "#cc092f",
        },
        agentTextColor: {
          hex: "#555555",
        },
        userTextColor: {
          hex: "#555555",
        },
        font: "OpenSans",
      },
      calloutMessage: {
        timeoutValue: 5,
        text: "Hello! How may I assist you?"
      },
      header: {
        enableCloseEvent: false,
        opacity: 100,
        dimension: {
          height: "60px",
        },
        display: "flex",
        background: {
          hex: "#313448",
        },
        color: {
          hex: "#a8abbd",
        },
        isMixed: true,
        border: "",
        borderBottomLeft: "",
        borderBottomRight: "",
        borderRadius: "",
        borderTopLeft: "45px",
        borderTopRight: "7px",
        avatar: {
          isVisible: false,
          icon: "https://cdn4.iconfinder.com/data/icons/avatar-circle-1-1/72/39-512.png",
          color: {
            hex: "#ffffff",
          },
          size: "31px",
        },
        title: {
          color: {
            hex: "#a8abbd",
          },
          size: "small",
          fontFamily: "OpenSans",
          text: "You are chatting with",
          isVisible: true,
        },
        subTitle: {
          color: {
            hex: "#ffffff",
          },
          size: "small",
          fontFamily: "OpenSans",
          text: "Autonom8Bot",
          isVisible: true,
        },
        padding: "",
        paddingTop: "",
        paddingLeft: "30px",
        paddingRight: "5px",
        paddingBottom: "",
        margin: "",
        marginTop: "",
        marginBottom: "",
        marginLeft: "",
        marginRight: "",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        actions: [
          {
            icon: "faExpandAlt",
            color: {
              hex: "#a8abbd",
            },
            size: "sm",
            onClick: () => window.parent.postMessage("maximize", "*"),
            name: "maximize",
            isVisible: true,
          },
          {
            icon: "faTimes",
            color: {
              hex: "#a8abbd",
            },
            size: "sm",
            name: "close",
            onClick: () => window.parent.postMessage("close", "*"),
            isVisible: true,
          },
          {
            icon: "faSync",
            color: {
              hex: "#a8abbd",
            },
            size: "sm",
            onClick: () => window.parent.postMessage("reset-chat", "*"),
            name: "resetChat",
            isVisible: false,
          },
        ],
      },
      content: {
        background: {
          hex: "#f6f7fb",
        },
        padding: "",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "16px",
        paddingRight: "16px",
        margin: "",
        marginTop: "",
        marginBottom: "",
        marginLeft: "",
        marginRight: "",
      },
      messages: {
        simpleChoice: {
          agent: {
            optionsBGColor: "#FBFBFB",
            optionsFRColor: "#000",
            optionsHRColor: "#f6f6f6",
            optionsTextHRColor: "#000",
            optionsShadow: "0px 2px 5px 0px rgb(226 226 226)",
            optionsBorder: "1px solid rgb(226 225 225)",
            optionsRadius: 15,
            optionsHeight: "40",
            optionsFontSize: 15,
            optionsFontWeight: 600,
          }
        },
        cardChoice: {
          agent: {
            optionsBGColor: "#FBFBFB",
            optionsFRColor: "#000",
            optionsHRColor: "#f6f6f6",
            optionsTextHRColor: "#000",
            optionsShadow: "0px 2px 5px 0px rgb(226 226 226)",
            optionsBorder: "1px solid rgb(226 225 225)",
            optionsRadius: 15,
            optionsHeight: "40",
            optionsFontSize: 15,
            optionsFontWeight: 600,
          }
        },
        text: {
          user: {
            padding: "16px",
            paddingLeft: "",
            paddingRight: "",
            paddingBottom: "",
            paddingTop: "",
          },
          agent: {
            padding: "16px",
            paddingLeft: "",
            paddingRight: "",
            paddingBottom: "",
            paddingTop: "",
          },
        },
        globalSettings: {
          user: {
            fontSize: "14",
            backgroundColor: "",
            color: "",
            border: "",
            borderRadius: "",
            borderTopLeft: "",
            borderTopRight: "",
            borderBottomRight: "",
            borderBottomLeft: "",
            outlineColor: "",
            outlineTop: "",
            outlineRight: "",
            outlineBottom: "",
            outlineLeft: ""
          },
          agent: {
            fontSize: "14",
            backgroundColor: "",
            color: "",
            border: "",
            borderRadius: "",
            borderTopLeft: "",
            borderTopRight: "",
            borderBottomRight: "",
            borderBottomLeft: "",
            outlineColor: "",
            outlineTop: "",
            outlineRight: "",
            outlineBottom: "",
            outlineLeft: ""
          },
        },
      },
      userInput: {
        opacity: 100,
        background: {
          hex: "#ffffff",
        },
        color: {
          hex: "#555555",
        },
        placeholder: "Start typing...",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        fontSize: "sm",
        margin: 0,
        marginTop: "",
        marginLeft: "",
        marginBottom: "",
        marginRight: "",
        padding: 0,
        paddingTop: "15px",
        paddingLeft: "",
        paddingBottom: "15px",
        paddingRight: "",
        border: "",
        borderRadius: "",
        borderTopLeft: "",
        borderTopRight: "",
        borderBottomRight: "30px",
        borderBottomLeft: "7px",
        keyboard: {
          isVisible: true,
          size: "lg",
          color: { hex: "#555555" },
        },
        mic: {
          isVisible: true,
          size: "lg",
          color: { hex: "#555555" },
        },
        textToSpeech: {
          isVisible: false,
        },
        attachment: {
          isVisible: true,
          size: "lg",
          color: { hex: "#555555" },
        },
        note: {
          isVisible: false,
          size: "lg",
          color: { hex: "#555555" },
        },
        sendIcon: {
          isVisible: false,
          size: "lg",
          color: { hex: "#555555" },
        },
        footer: {
          color: {
            hex: "#999999",
          },
          isVisible: true,
          text: "Powered by Autonom8",
          size: "tiny",
        },
      },
      surfaceQuestion: {
        color: "#000",
        colorHover: "#fff",
        backgroundColor: "",
        backgroundColorHover: "#FF0000",
        border: "1px solid #d7d7d7",
        borderRadius: "",
        borderRadiusBottomLeft: "5px",
        borderRadiusBottomRight: "5px",
        borderRadiusTopLeft: "5px",
        borderRadiusTopRight: "5px",
      },
    },
    text: {
      color: {
        hex: "#212332",
      },
      size: "sm",
    },
  },
  setThemeProps: (arg: any) => { },
});

export const SelectedPropertyContext = React.createContext({
  selectedProperty: "",
  setSelectedProperty: (arg: string) => { },
});

export default function ThemeDesigner(props: any) {
  const [isConfigurationSelected, setIsConfigurationSelected] = React.useState(false)

  const [themeProps, setThemeProps] = React.useState({
    widgetIcon: {
      size: "60",
      icon: "https://a8live.s3.ap-south-1.amazonaws.com/org-5e58fb744fd9117bd2e50d53/assest/chatbot_orangeIcon.png",
      color: {
        hex: "#ffffff",
      },
      background: {
        isVisible: true,
        hex: "#f77f43",
      },
      position: {
        top: 0,
        bottom: "20",
        left: 0,
        right: "20",
      },
      dimensions: {
        width: "60",
        height: "60",
        isConstraint: false,
      },
      shape: {
        rotate: "0",
        border: {
          isMixed: true,
          borderTopLeft: "15",
          borderTopRight: "5",
          borderBottomRight: "15",
          borderBottomLeft: "5",
        },
      },
    },
    widget: {
      dimension: {
        maxWidth: "400px",
        minHeight: "85vh",
        agentWidth: "100%",
        agentHeight: "45px",
        userHeight: "45px",
        userWidth: "100",
        borderRadiusUser: "18px 0px 18px 0px",
        borderRadiusAgent: "18px 0px 18px 0px",
        bottom: "20px",
        right: "20px",
        botName: "",
        zIndex: "9999",
        background: "Transparent",
        typesense: false,
        loading: {
          url: "https://a8live.s3.ap-south-1.amazonaws.com/a8chatwidget/loading.gif",
          size: "31"
        },
        chatIcon: {
          size: "31px",
          isVisible: true,
          icon: "https://cdn4.iconfinder.com/data/icons/avatar-circle-1-1/72/39-512.png",
          userIcon: "https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/11_avatar-512.png",
        },
        userChatBackground: {
          hex: "#cc092f",
        },
        userChatTextColor: {
          hex: "#ffffff",
        },
        chatBackground: {
          hex: "#ffffff",
        },
        chatTextColor: {
          hex: "#555555",
        },
        primaryColor: {
          hex: "#cc092f",
        },
        secondaryColor: {
          hex: "#555555",
        },
        agentColor: {
          hex: "#cc092f",
        },
        userColor: {
          hex: "#cc092f",
        },
        agentTextColor: {
          hex: "#555555",
        },
        userTextColor: {
          hex: "#555555",
        },
        font: "OpenSans",
      },
      calloutMessage: {
        timeoutValue: 5,
        text: "Hello! How may I assist you?"
      },
      header: {
        enableCloseEvent: false,
        opacity: 100,
        dimension: {
          height: "60px",
        },
        display: "flex",
        background: {
          hex: "#313448",
        },
        color: {
          hex: "#a8abbd",
        },
        isMixed: true,
        border: "",
        borderBottomLeft: "",
        borderBottomRight: "",
        borderRadius: "",
        borderTopLeft: "45px",
        borderTopRight: "7px",
        avatar: {
          isVisible: false,
          icon: "https://cdn4.iconfinder.com/data/icons/avatar-circle-1-1/72/39-512.png",
          color: {
            hex: "#ffffff",
          },
          size: "31px",
        },
        title: {
          color: {
            hex: "#a8abbd",
          },
          size: "small",
          fontFamily: "OpenSans",
          text: "You are chatting with",
          isVisible: true,
        },
        subTitle: {
          color: {
            hex: "#ffffff",
          },
          size: "small",
          fontFamily: "OpenSans",
          text: "Autonom8Bot",
          isVisible: true,
        },
        padding: "",
        paddingTop: "",
        paddingLeft: "30px",
        paddingRight: "5px",
        paddingBottom: "",
        margin: "",
        marginTop: "",
        marginBottom: "",
        marginLeft: "",
        marginRight: "",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        actions: [
          {
            icon: "faExpandAlt",
            color: {
              hex: "#a8abbd",
            },
            size: "sm",
            onClick: () => window.parent.postMessage("maximize", "*"),
            name: "maximize",
            isVisible: true,
          },
          {
            icon: "faTimes",
            color: {
              hex: "#a8abbd",
            },
            size: "sm",
            name: "close",
            onClick: () => window.parent.postMessage("close", "*"),
            isVisible: true,
          },
          {
            icon: "faSync",
            color: {
              hex: "#a8abbd",
            },
            size: "sm",
            onClick: () => window.parent.postMessage("reset-chat", "*"),
            name: "resetChat",
            isVisible: false,
          },
        ],
      },
      content: {
        background: {
          hex: "#f6f7fb",
        },
        padding: "",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "16px",
        paddingRight: "16px",
        margin: "",
        marginTop: "",
        marginBottom: "",
        marginLeft: "",
        marginRight: "",
      },
      messages: {
        simpleChoice: {
          agent: {
            optionsBGColor: "#FBFBFB",
            optionsFRColor: "#000",
            optionsHRColor: "#f6f6f6",
            optionsTextHRColor: "#000",
            optionsShadow: "0px 2px 5px 0px rgb(226 226 226)",
            optionsBorder: "1px solid rgb(226 225 225)",
            optionsRadius: 15,
            optionsHeight: "40",
            optionsFontSize: 15,
            optionsFontWeight: 600,
          }
        },
        cardChoice: {
          agent: {
            optionsBGColor: "#FBFBFB",
            optionsFRColor: "#000",
            optionsHRColor: "#f6f6f6",
            optionsTextHRColor: "#000",
            optionsShadow: "0px 2px 5px 0px rgb(226 226 226)",
            optionsBorder: "1px solid rgb(226 225 225)",
            optionsRadius: 15,
            optionsHeight: "40",
            optionsFontSize: 15,
            optionsFontWeight: 600,
          }
        },
        text: {
          user: {
            padding: "16px",
            paddingLeft: "",
            paddingRight: "",
            paddingBottom: "",
            paddingTop: "",
          },
          agent: {
            padding: "16px",
            paddingLeft: "",
            paddingRight: "",
            paddingBottom: "",
            paddingTop: "",
          },
        },
        globalSettings: {
          user: {
            fontSize: "14",
            backgroundColor: "#ffffff",
            color: "#6B6B6B",
            border: "",
            borderRadius: "",
            borderTopLeft: "",
            borderTopRight: "",
            borderBottomRight: "",
            borderBottomLeft: "",
            outlineColor: "",
            outlineTop: "",
            outlineRight: "",
            outlineBottom: "",
            outlineLeft: ""
          },
          agent: {
            fontSize: "14",
            backgroundColor: "#ffffff",
            color: "#6B6B6B",
            border: "",
            borderRadius: "",
            borderTopLeft: "",
            borderTopRight: "",
            borderBottomRight: "",
            borderBottomLeft: "",
            outlineColor: "#FF0000",
            outlineTop: "",
            outlineRight: "",
            outlineBottom: "",
            outlineLeft: "4px"
          },
        },
      },
      userInput: {
        opacity: 100,
        background: {
          hex: "#ffffff",
        },
        color: {
          hex: "#555555",
        },
        placeholder: "Start typing...",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "stretch",
        fontSize: "sm",
        margin: 0,
        marginTop: "",
        marginLeft: "",
        marginBottom: "",
        marginRight: "",
        padding: 0,
        paddingTop: "15px",
        paddingLeft: "",
        paddingBottom: "15px",
        paddingRight: "",
        border: "",
        borderRadius: "",
        borderTopLeft: "",
        borderTopRight: "",
        borderBottomRight: "30px",
        borderBottomLeft: "7px",
        keyboard: {
          isVisible: true,
          size: "lg",
          color: { hex: "#555555" },
        },
        mic: {
          isVisible: true,
          size: "lg",
          color: { hex: "#555555" },
        },
        textToSpeech: {
          isVisible: false,
        },
        attachment: {
          isVisible: true,
          size: "lg",
          color: { hex: "#555555" },
        },
        note: {
          isVisible: false,
          size: "lg",
          color: { hex: "#555555" },
        },
        sendIcon: {
          isVisible: false,
          size: "lg",
          color: { hex: "#555555" },
        },
        footer: {
          color: {
            hex: "#999999",
          },
          isVisible: true,
          text: "Powered by Autonom8",
          size: "tiny",
        },
      },
      surfaceQuestion: {
        color: "#000",
        colorHover: "#fff",
        backgroundColor: "",
        backgroundColorHover: "#FF0000",
        border: "1px solid #d7d7d7",
        borderRadius: "",
        borderRadiusBottomLeft: "5px",
        borderRadiusBottomRight: "5px",
        borderRadiusTopLeft: "5px",
        borderRadiusTopRight: "5px",
      },
    },
    text: {
      color: {
        hex: "#212332",
      },
      size: "small",
    },
  });
  const { selectedBot, selectedBotName, botTheme, selectedBotId, bots } = React.useContext(SelectedBotContext);
  const botInfo = bots.find(bot => bot._id === selectedBotId)
  const nlu = botInfo && botInfo.bot && botInfo.bot.nlu
  const [selectedProperty, setSelectedProperty] = React.useState("container");
  const [leftDrawer, openDrawer] = React.useState(true);
  React.useEffect(() => {
    setThemeProps(_.merge({}, themeProps, JSON.parse(atob(botTheme))));
  }, [botTheme]);

  return (
    <div className="theme-designer" style={{ display: "flex" }}>
      <DndProvider backend={HTML5Backend}>
        <ThemeContext.Provider
          value={{
            themeProps,
            setThemeProps,
          }}
        >
          <Drawer isConfigurationSelected={isConfigurationSelected} theme={{ isOpen: leftDrawer }} className={`drawer ${leftDrawer ? "open" : ""}`}>
            <DrawerButton theme={{ isOpen: leftDrawer }} onClick={() => openDrawer((prevState) => !prevState)}>
              {leftDrawer ? (
                <FaChevronLeft
                  size="9px"
                  style={{
                    position: "absolute",
                    top: "52%",
                    left: "47%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              ) : (
                <FaChevronRight
                  size="9px"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "53%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
            </DrawerButton>
            <DrawerHeading>
              <img src={botname} alt="botname" width="35px" height="35px" style={{ paddingRight: "5px" }} />
              {selectedBotName}
            </DrawerHeading>
            <DrawerList selected={!isConfigurationSelected} onClick={() => setIsConfigurationSelected(false)}>
              <img src={theme_item} alt="theme" style={{ paddingRight: "8px" }} /> &nbsp;Theme
            </DrawerList>
	    <div style={{ marginTop: 16, position: "relative", left: -4 }} onClick={() => setIsConfigurationSelected(true)}>
	      <DrawerList selected={isConfigurationSelected}>
		<img src={configuration_item} alt="configuration" style={{ paddingRight: "6px" }} /> &nbsp;NLU Config
	      </DrawerList>
	    </div>
          </Drawer>
	  {isConfigurationSelected ? <NLUConfiguration initialValue={nlu} botHandle={botInfo.botHandle} /> : (
	    <div style={{ flex: 1 }}>
	      <Navbar slug={selectedBot} />
	      <SelectedPropertyContext.Provider value={{ selectedProperty, setSelectedProperty }}>
		<Grid>
		  <LeftSidebar />
		  <Main />
		  <RightSidebar />
		</Grid>
	      </SelectedPropertyContext.Provider>
	    </div>
	  )}
        </ThemeContext.Provider>
      </DndProvider>
    </div>
  );
}
