export function checkPassword(strPassword) {
  const m_strUpperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const m_strLowerCase = "abcdefghijklmnopqrstuvwxyz";
  const m_strNumber = "0123456789";
  const m_strCharacters = "!@#$%^&*?_~";
  // Reset combination count
  var nUpperCount = countContain(strPassword, m_strUpperCase);
  var nLowerCount = countContain(strPassword, m_strLowerCase);
  var nCharacterCount = countContain(strPassword, m_strCharacters);
  var nNumberCount = countContain(strPassword, m_strNumber);

  return !(nUpperCount && nLowerCount && nCharacterCount && nNumberCount);
}

function countContain(strPassword: string, strCheck: string) {
  let nCount = 0;

  for (let i = 0; i < strPassword.length; i++) {
    if (strCheck.indexOf(strPassword.charAt(i)) > -1) {
      nCount++;
    }
  }

  return nCount;
}
