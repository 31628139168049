import React from "react";
import { connect } from "react-redux";
import { Tabs, Row, Col } from "antd";
import { rootSelectedTab } from "../../actions/uiAction";

const { TabPane } = Tabs;
const chatMenuList = ["live", "monitor", "history"];

class ChatMenu extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentWillMount() {
    const roles: string[] = (this.props.auth.info && this.props.auth.info.roles) || []
    let activeRootTab = roles.includes("agent") ? 'live' : roles.includes("supervisor") ? "monitor" : "live"
    if (chatMenuList.indexOf(this.props.ui.activeRootTab) > -1) {
      activeRootTab = this.props.ui.activeRootTab;
    }
    this.setState({
      selectedMenu: activeRootTab,
    });
    this.props.rootSelectedTab(activeRootTab);
  }

  onTabChange = (key: any) => {
    this.props.rootSelectedTab(key);
  };

  render() {
    const roles = this.props.auth.info && this.props.auth.info.roles;
    return (
      <Row className="menu">
        <Col span="1" style={{ marginRight: "16px" }} className="title">
          CHATS
        </Col>
        <Col span="19">
          <Tabs
            defaultActiveKey={this.state.selectedMenu}
            onChange={this.onTabChange}
          >
            {roles && roles.includes("agent") && <TabPane tab="Live" key="live"></TabPane>}
            {roles && roles.includes("supervisor") && <TabPane tab="Monitor" key="monitor"></TabPane>}
            <TabPane tab="History" key="history"></TabPane>
          </Tabs>
        </Col>
        <Col span="4"></Col>
      </Row>
    );
  }
}

function mapStateToProps(state: any) {
  // console.log("Chat Menu mapStateToProps: ", state);
  return {
    auth: state.auth,
    ui: state.auth,
  };
}

export default connect(mapStateToProps, { rootSelectedTab })(ChatMenu);
