import React from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { actionNotification } from "../../../../actions/themeActions";
import apiConfig from "../../../../apiConfig";
import "./assist-flow-dialog.scss";

type Props = {
  sessionId: string
  setAssistFlow: React.Dispatch<React.SetStateAction<{
   sessionId: string 
   show: boolean
  }>>
};

export function AssistFlowAlertDialog(props: Props) {
  const { sessionId, setAssistFlow: setAssistFlowData } = props;
  const { accessToken, orgName } = useSelector((state: any) => state.auth.info);

  async function assistFlow(assist: boolean) {
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    // TODO: configure this dynamically
    const baseUrl = `https://${orgName}-flow.autonom8.com`
    const url = `${baseUrl}${apiConfig.assistFlow}?assist=${assist}`.replace('{sessionId}', sessionId)
    try {
      await axios(url, options);
      setAssistFlowData({ show: false, sessionId: "" })
    } catch(err) {
      actionNotification("error", "Assist Flow", `Failed. Try Again.`);
    }
  }

  return (
    <div className="assist-flow-dialog">
      <p>The customer is currently in a flow. Do you want to assist?</p>
      <div className="actions">
        <button className="cancel" onClick={() => assistFlow(false)}>
          No
        </button>
        <button className="ok" onClick={() => assistFlow(true)}>
          Yes
        </button>
      </div>
    </div>
  );
}
