import * as React from "react";
import { Collapse, Input as InputAntd, Select } from "antd";
import styled from "styled-components";
import update from "immutability-helper";
import { ChromePicker } from "react-color";
import GradientPickerIcon from "../../../../../../../assets/icons/gradient.svg";
import { ThemeContext, SelectedPropertyContext } from "../../../../themeDesigner";
const Panel = Collapse.Panel;

const { Option } = Select;

export interface ITextProps {
    msgType: string
}


const Input = styled.input`
  width: ${(props) => props.theme.width}px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #d3daef;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  padding: 0 8px;

  &:focus {
    outline: 1px solid ${(props) => props.theme.outlineColor};
  }

  &::placeholder {
    font-family: Muli;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #ccd0dd;
  }
`;

const AllWrapperRow = styled.div`
  margin-top: ${(props) => props.theme.marginTop}px;
  margin-bottom: ${(props) => props.theme.marginTop}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    margin-bottom: 5px;
  }
`;

const AllWrapper = styled.div`
  margin-top: ${(props) => props.theme.marginTop}px;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;

  .text {
    flex: 1;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    margin-bottom: 5px;
  }
`;

const MarginWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.marginBottom}px;
  justify-content: space-between;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
  }
`;

const Label = styled.label`
  font-family: Muli;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #010f34;
  margin: 14px 0;
`;

const Container = styled.div`
  /* margin-top: 14px; */
`;

const GeneralPropertyWrapper = styled.div`
  /* padding: 0 15px; */
  /* border-bottom: 1px solid #eee; */
`;

const Divider = styled.div`
  margin: 10px 0px;
  border-bottom: 1px solid #eee;
`;

const BackgroundColorContainer = styled.div`
  height: 59px;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid #aaa; */
  position: relative;

  span.text {
    width: 109px;
    height: 16px;
    font-family: Muli;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #7f90af;
    flex: 1;
  }

  .picker {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #d3daef;
  }

  .color-picker {
    position: absolute;
    top: 50px;
    z-index: 1;
  }
`;

const DisplayColor = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

const GradientPickerWrapper = styled.div`
  margin-left: 9.7px;
  margin-right: 5px;
`;

function callback(key) {
    console.log(key);
}

export default function GlobalSettings(props: any) {
    const { selectedProperty } = React.useContext(SelectedPropertyContext);
    const [openOutlineBGPicker, setOpenOutlineBGPicker] = React.useState(false);
    const [openOptBGPicker, setOpenOptBGPicker] = React.useState(false);
    const [openOptFRPicker, setOpenOptFRPicker] = React.useState(false)
    const [openBGPicker, setOpenBGPicker] = React.useState(false);
    const [openFRPicker, setOpenFRPicker] = React.useState(false);
    const [openOptHRPicker, setOpenOptHRPicker] = React.useState(false)
    const { themeProps, setThemeProps } = React.useContext(ThemeContext);
    const {
        widget: { messages },
    } = themeProps;
    const {
        fontSize,
        border,
        borderTopLeft,
        borderTopRight,
        borderBottomLeft,
        borderBottomRight,
        borderRadius,
        optionsBorder,
        optionsShadow,
        optionsRadius,
        optionsFontSize,
        optionsHeight,
        optionsFontWeight,
    } = messages["globalSettings"][props.msgType];
    const [outlineColor, setOutlineColor] = React.useState(messages["globalSettings"][props.msgType].outlineColor);
    const [outlineTop, setOutlineTop] = React.useState(messages["globalSettings"][props.msgType].outlineTop);
    const [outlineRight, setOutlineRight] = React.useState(messages["globalSettings"][props.msgType].outlineRight);
    const [outlineBottom, setOutlineBottom] = React.useState(messages["globalSettings"][props.msgType].outlineBottom);
    const [outlineLeft, setOutlineLeft] = React.useState(messages["globalSettings"][props.msgType].outlineLeft);
    const [bgColor, setBGColor] = React.useState(messages["globalSettings"][props.msgType].backgroundColor);
    const [frColor, setFRColor] = React.useState(messages["globalSettings"][props.msgType].color);
    const [optBGColor, setOptBGColor] = React.useState(messages["globalSettings"][props.msgType].optionsBGColor);
    const [optFRColor, setOptFRColor] = React.useState(messages["globalSettings"][props.msgType].optionsFRColor);
    const [optHRColor, setOptHRColor] = React.useState(messages["globalSettings"][props.msgType].optionsHRColor);
    React.useEffect(() => {
        setOutlineColor(messages["globalSettings"][props.msgType].outlineColor);
        setBGColor(messages["globalSettings"][props.msgType].backgroundColor);
        setFRColor(messages["globalSettings"][props.msgType].color);
        setOptBGColor(messages["globalSettings"][props.msgType].optionsBGColor);
        setOptFRColor(messages["globalSettings"][props.msgType].optionsFRColor);
        setOptHRColor(messages["globalSettings"][props.msgType].optionsHRColor);
    }, [selectedProperty]);
    const handleBackgroundColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    globalSettings: {
                        [props.msgType]: {
                            "backgroundColor": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    };

    const handleForeColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    globalSettings: {
                        [props.msgType]: {
                            "color": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    }

    const handleOptBGColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    globalSettings: {
                        [props.msgType]: {
                            "optionsBGColor": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    };

    const handleOptFRColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    globalSettings: {
                        [props.msgType]: {
                            "optionsFRColor": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    }

    const handleOptHRColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    globalSettings: {
                        [props.msgType]: {
                            "optionsHRColor": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    }

    const handleOutlineColorChange = (color) => {
        const updatedGeneralProperties = update(themeProps, {
            widget: {
                messages: {
                    globalSettings: {
                        [props.msgType]: {
                            "outlineColor": { $set: color.hex }
                        }
                    },
                },
            },
        });
        setThemeProps(updatedGeneralProperties);
    };

    const handleChange = (value: string, property: string) => {
        const updatedInput = update(themeProps, {
            widget: {
                messages: {
                    globalSettings: {
                        [props.msgType]: {
                            [property]: { $set: value }
                        }
                    }
                },
            },
        });
        setThemeProps(updatedInput);
    };

    return (
        <Container>
            <Collapse defaultActiveKey={["1"]} onChange={callback}>
                <Panel header="GENERAL Settings" key="1">
                    <GeneralPropertyWrapper>
                        <BackgroundColorContainer>
                            <span className="text">Font Size</span>
                            <InputAntd
                                style={{ width: "66px" }}
                                maxLength={2}
                                type="number"
                                placeholder="14"
                                suffix={"px"}
                                value={fontSize}
                                onChange={(e) => { console.log(e.target.value); handleChange(e.target.value, "fontSize") }}
                            />
                            {/* <Input
                                min="10"
                                max="50"
                                type="number"
                                placeholder="14"
                                value={fontSize}
                                onChange={(e) => handleChange(e.target.value, "fontSize")}
                                theme={{ width: 66 }}
                            /> */}
                        </BackgroundColorContainer>
                        <BackgroundColorContainer>
                            <span className="text">Background Color</span>
                            <DisplayColor
                                theme={{ backgroundColor: bgColor }}
                                className="picker"
                                onClick={() => setOpenBGPicker(!openBGPicker)}
                            />
                            {openBGPicker && (
                                <div className="color-picker">
                                    <ChromePicker
                                        color={bgColor}
                                        onChange={(color: any) => setBGColor(color.hex)}
                                        onChangeComplete={handleBackgroundColorChange}
                                    />
                                </div>
                            )}
                            <GradientPickerWrapper>
                                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                            </GradientPickerWrapper>
                        </BackgroundColorContainer>
                        <BackgroundColorContainer>
                            <span className="text">Foreground Color</span>
                            <DisplayColor
                                theme={{ backgroundColor: frColor }}
                                className="picker"
                                onClick={() => setOpenFRPicker(!openFRPicker)}
                            />
                            {openFRPicker && (
                                <div className="color-picker">
                                    <ChromePicker
                                        color={frColor}
                                        onChange={(color: any) => setFRColor(color.hex)}
                                        onChangeComplete={handleForeColorChange}
                                    />
                                </div>
                            )}
                            <GradientPickerWrapper>
                                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                            </GradientPickerWrapper>
                        </BackgroundColorContainer>
                    </GeneralPropertyWrapper>
                    <AllWrapper theme={{ marginTop: 10 }}>
                        <span className="text">Border</span>
                        <Input
                            theme={{ outlineColor, width: 210 }}
                            placeholder="1px solid black"
                            value={border}
                            onChange={(e) => handleChange(e.target.value, "border")}
                        />
                    </AllWrapper>
                    <AllWrapper>
                        <span className="text">Border Radius</span>
                        <Input
                            theme={{ outlineColor, width: 210 }}
                            placeholder="1px or 1% or 1rem or 1em"
                            value={borderRadius}
                            onChange={(e) => handleChange(e.target.value, "borderRadius")}
                        />
                    </AllWrapper>
                    <MarginWrapper theme={{ marginBottom: 0 }}>
                        <ColumnWrapper>
                            <label>Right</label>
                            <Input
                                theme={{ outlineColor, width: 45 }}
                                value={borderTopRight}
                                onChange={(e) => handleChange(e.target.value, "borderTopRight")}
                            />
                        </ColumnWrapper>
                        <ColumnWrapper>
                            <label>Left</label>
                            <Input
                                theme={{ outlineColor, width: 45 }}
                                value={borderBottomLeft}
                                onChange={(e) => handleChange(e.target.value, "borderBottomLeft")}
                            />
                        </ColumnWrapper>
                        <ColumnWrapper>
                            <label>Top</label>
                            <Input
                                theme={{ outlineColor, width: 45 }}
                                value={borderTopLeft}
                                onChange={(e) => handleChange(e.target.value, "borderTopLeft")}
                            />
                        </ColumnWrapper>
                        <ColumnWrapper>
                            <label>Bottom</label>
                            <Input
                                theme={{ outlineColor, width: 45 }}
                                value={borderBottomRight}
                                onChange={(e) => handleChange(e.target.value, "borderBottomRight")}
                            />
                        </ColumnWrapper>
                    </MarginWrapper>
                    <Divider></Divider>
                    <AllWrapper>
                        <Label>OUTLINE</Label>
                        <BackgroundColorContainer>
                            <span className="text">Color</span>
                            <DisplayColor
                                theme={{ backgroundColor: outlineColor }}
                                className="picker"
                                onClick={() => setOpenOutlineBGPicker(!openOutlineBGPicker)}
                            />
                            {openOutlineBGPicker && (
                                <div className="color-picker">
                                    <ChromePicker
                                        color={outlineColor}
                                        onChange={(color: any) => setOutlineColor(color.hex)}
                                        onChangeComplete={handleOutlineColorChange}
                                    />
                                </div>
                            )}
                            <GradientPickerWrapper>
                                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                            </GradientPickerWrapper>
                        </BackgroundColorContainer>
                    </AllWrapper>
                    <MarginWrapper theme={{ marginBottom: 10 }}>
                        <ColumnWrapper>
                            <label>Right</label>
                            <Input
                                theme={{ outlineColor, width: 45 }}
                                value={outlineRight}
                                onChange={(e) => { setOutlineRight(e.target.value); handleChange(e.target.value, "outlineRight") }}
                            />
                        </ColumnWrapper>
                        <ColumnWrapper>
                            <label>Left</label>
                            <Input
                                theme={{ outlineColor, width: 45 }}
                                value={outlineLeft}
                                onChange={(e) => { setOutlineLeft(e.target.value); handleChange(e.target.value, "outlineLeft") }}
                            />
                        </ColumnWrapper>
                        <ColumnWrapper>
                            <label>Top</label>
                            <Input
                                theme={{ outlineColor, width: 45 }}
                                value={outlineTop}
                                onChange={(e) => { setOutlineTop(e.target.value); handleChange(e.target.value, "outlineTop") }} />
                        </ColumnWrapper>
                        <ColumnWrapper>
                            <label>Bottom</label>
                            <Input
                                theme={{ outlineColor, width: 45 }}
                                value={outlineBottom}
                                onChange={(e) => { setOutlineBottom(e.target.value); handleChange(e.target.value, "outlineBottom") }}
                            />
                        </ColumnWrapper>
                    </MarginWrapper>
                    {/* </GeneralPropertyWrapper> */}
                    {/* <Divider></Divider>
                    <AllWrapper>
                        <Label>OPTIONS STYLE</Label>
                        <BackgroundColorContainer>
                            <span className="text">Background</span>
                            <DisplayColor
                                theme={{ backgroundColor: optBGColor }}
                                className="picker"
                                onClick={() => setOpenOptBGPicker(!openOptBGPicker)}
                            />
                            {openOptBGPicker && (
                                <div className="color-picker">
                                    <ChromePicker
                                        color={optBGColor}
                                        onChange={(color: any) => setOptBGColor(color.hex)}
                                        onChangeComplete={handleOptBGColorChange}
                                    />
                                </div>
                            )}
                            <GradientPickerWrapper>
                                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                            </GradientPickerWrapper>
                        </BackgroundColorContainer>
                        <BackgroundColorContainer>
                            <span className="text">Foreground</span>
                            <DisplayColor
                                theme={{ backgroundColor: optFRColor }}
                                className="picker"
                                onClick={() => setOpenOptFRPicker(!openOptFRPicker)}
                            />
                            {openOptFRPicker && (
                                <div className="color-picker">
                                    <ChromePicker
                                        color={optFRColor}
                                        onChange={(color: any) => setOptFRColor(color.hex)}
                                        onChangeComplete={handleOptFRColorChange}
                                    />
                                </div>
                            )}
                            <GradientPickerWrapper>
                                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                            </GradientPickerWrapper>
                        </BackgroundColorContainer>
                        <BackgroundColorContainer>
                            <span className="text">Hover</span>
                            <DisplayColor
                                theme={{ backgroundColor: optHRColor }}
                                className="picker"
                                onClick={() => setOpenOptHRPicker(!openOptHRPicker)}
                            />
                            {openOptHRPicker && (
                                <div className="color-picker">
                                    <ChromePicker
                                        color={optHRColor}
                                        onChange={(color: any) => setOptHRColor(color.hex)}
                                        onChangeComplete={handleOptHRColorChange}
                                    />
                                </div>
                            )}
                            <GradientPickerWrapper>
                                <img src={GradientPickerIcon} alt="Gradient Picker Icon" />
                            </GradientPickerWrapper>
                        </BackgroundColorContainer>
                        <AllWrapper theme={{ marginTop: 10 }}>
                            <span className="text">Border</span>
                            <Input
                                theme={{ optionsBorder, width: 210 }}
                                placeholder="1px solid black"
                                value={optionsBorder}
                                onChange={(e) => handleChange(e.target.value, "optionsBorder")}
                            />
                        </AllWrapper>
                        <AllWrapper theme={{ marginTop: 10 }}>
                            <span className="text">Shadow</span>
                            <Input
                                theme={{ optionsShadow, width: 210 }}
                                placeholder="0px 2px 5px 0px rgb(226 226 226)"
                                value={optionsShadow}
                                onChange={(e) => handleChange(e.target.value, "optionsShadow")}
                            />
                        </AllWrapper>
                        <AllWrapperRow>
                            <AllWrapper>
                                <span className="text">Border Radius</span>
                                <InputAntd
                                    style={{ width: "88px" }}
                                    maxLength={2}
                                    type="number"
                                    placeholder="15"
                                    suffix={"px"}
                                    value={optionsRadius}
                                    onChange={(e) => { handleChange(e.target.value, "optionsRadius") }}
                                />
                            </AllWrapper>
                            <AllWrapper>
                                <span className="text">Height</span>
                                <InputAntd
                                    style={{ width: "88px" }}
                                    maxLength={2}
                                    type="number"
                                    placeholder="14"
                                    suffix={"px"}
                                    value={optionsHeight}
                                    onChange={(e) => { handleChange(e.target.value, "optionsHeight") }}
                                />
                            </AllWrapper>
                        </AllWrapperRow>
                        <AllWrapperRow>
                            <AllWrapper>
                                <span className="text">Font Size</span>
                                <InputAntd
                                    style={{ width: "88px" }}
                                    maxLength={2}
                                    type="number"
                                    placeholder="14"
                                    suffix={"px"}
                                    value={optionsFontSize}
                                    onChange={(e) => { handleChange(e.target.value, "optionsFontSize") }}
                                />
                            </AllWrapper>
                            <AllWrapper>
                                <span className="text">Font Weight</span>
                                <Select
                                    defaultValue={optionsFontWeight}
                                    style={{ height: "28px", width: "88px" }}
                                    onChange={(value) => { handleChange(value, "optionsFontWeight") }}
                                >
                                    <Option value={100}>100</Option>
                                    <Option value={200}>200</Option>
                                    <Option value={300}>300</Option>
                                    <Option value={400}>400</Option>
                                    <Option value={500}>500</Option>
                                    <Option value={600}>600</Option>
                                    <Option value={700}>700</Option>
                                    <Option value={800}>800</Option>
                                    <Option value={900}>900</Option>
                                </Select>
                            </AllWrapper>
                        </AllWrapperRow>
                    </AllWrapper> */}
                </Panel>
            </Collapse>
        </Container>
    );
}