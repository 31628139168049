import type from "../types/actionTypes";

export const setDashboardFilters = (data) => {
  return {
    type: type.DASHBOARD_FILTERS,
    data
  };
}

export const rootSelectedMenu = (data) => {
  return {
    type: type.ROOT_SELECTED_MENU,
    data,
  };
};

export const rootSelectedTab = (data) => {
  return {
    type: type.ROOT_SELECTED_TAB,
    data,
  };
};

export const setTheme = (data) => {
  return {
    type: type.THEME,
    data,
  };
};

export const toggleBulkUpload = (data) => {
  return {
    type: type.TOGGLE_BULK_UPLOAD,
    data,
  };
};

export const updateSettingShow = (data) => {
  return {
    type: type.SHOW_UPDATE_SETTING,
    data,
  };
};


export const updateResourceShow = (data) => {
  return {
    type: type.SHOW_UPDATE_RESOURCE,
    data,
  };
};

export const filterAgentSort = (data) => {
  return {
    type: type.AGENT_SORT_FILTER,
    data,
  };
};

export const filterAgentType = (data) => {
  return {
    type: type.AGENT_TYPE_FILTER,
    data,
  };
};

export const filterAgentGroupList = (data) => {
  return {
    type: type.AGENT_GROUP_LIST_FILTER,
    data,
  };
};

export const filterAgent = (data) => {
  return {
    type: type.AGENT_FILTER,
    data,
  };
};

export const searchAgent = (data) => {
  return {
    type: type.AGENT_SEARCH,
    data,
  };
};

export const agentViewMode = (data) => {
  return {
    type: type.AGENT_VIEW_MODE,
    data,
  };
};

export const filterGroupSort = (data) => {
  return {
    type: type.GROUP_SORT_FILTER,
    data,
  };
};

export const filterGroupType = (data) => {
  return {
    type: type.GROUP_TYPE_FILTER,
    data,
  };
};

export const filterGroupAgent = (data) => {
  return {
    type: type.GROUP_AGENT_FILTER,
    data,
  };
};

export const filterGroup = (data) => {
  return {
    type: type.GROUP_FILTER,
    data,
  };
};

export const searchQuickResp = (data) => {
  return {
    type: type.QUICK_RESP_SEARCH,
    data,
  };
};

export const filterGroupQuickResp = (data) => {
  return {
    type: type.QUICK_RESP_GROUP_FILTER,
    data,
  };
};

export const filterSortQuickResp = (data) => {
  return {
    type: type.QUICK_RESP_SORT_FILTER,
    data,
  };
};

export const searchGroup = (data) => {
  return {
    type: type.GROUP_SEARCH,
    data,
  };
};

export const blockLayer = (data) => {
  return {
    type: type.BLOCK_LAYER,
    data,
  };
};

export const persistChat = (chatId, payload) => {
  return {
    type: type.PERSIST_CHAT,
    data: { chatId, payload },
  };
};

export const clearPersistedChat = () => {
  return {
    type: type.CLEAR_PERSISTED_CHAT,
  };
};

export const resetPersistedStore = () => {
  return {
    type: "RESET",
  };
};

export const loadClientConversation = (data) => {
  return {
    type: type.CLIENT_CONVERSATION,
    data,
  };
};

export const addToUnread = chatId => {
  return {
    type: type.ADD_TO_UNREAD,
    payload: chatId
  }
}

export const removeToRead = chatId => {
  return {
    type: type.REMOVE_FROM_READ,
    payload: chatId
  };
};