import * as React from "react";
import styled from "styled-components";
import { Collapse } from "antd";

const { Panel } = Collapse;

import UserCard from "./UserCard";
import { useSelector, useDispatch } from "react-redux";
import searchIcon from "../../../../assets/images/ico-search.svg";
import { getMonitorChats, getChatById, updateUnmonitoredChats, removeFromUnmonitoredChatIds } from "../../../../actions/chatsAction";
import { openMonitoredChatsTab } from "../../../../actions/taskAction";

import "../../../../assets/sass/monitor.sass";

export interface ISidebarProps { }

const Container = styled.div`
  width: 300px;
  border-right: 1px solid rgba(168, 171, 189, 0.4);
  max-height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  &:nth-child(2) {
    margin-top: 20px;
  }
`;

const CardContainer = styled.div`
  margin-top: 0px;
`;

const MonitorContainer = styled.div`
  max-height: calc(100vh - 95px - 95px - 58px);
  overflow-y: auto;
`;

const NoChatsFound = styled.div`
  width: 100%;
  text-align: center;
  color: #a8abbd;
`;

export default function Sidebar(props: ISidebarProps) {
  const dispatch = useDispatch();
  const { unMonitoredChats, isLoading, unMonitoredChatIds } = useSelector((state: any) => state.chat);
  const { monitoredChats } = useSelector((state: any) => state.chat);
  const [lastTouched, setLastTouched] = React.useState("");
  const [limit, setLimit] = React.useState(10);

  const { currentActiveMonitorTab } = useSelector((state: any) => state.task);

  const handlePanelChange = (key: any) => {
    if (currentActiveMonitorTab === key) {
      dispatch(openMonitoredChatsTab(null));
    } else {
      dispatch(openMonitoredChatsTab(key));
    }
  };

  // unmChats - unm is shorthand notation for un-monitored Chats
  const [unmChats, setunmChats] = React.useState({});

  // mChats - Similarly mChats represent the monitored chats
  const [mChats, setmChats] = React.useState({});

  const observer: any = React.useRef();

  // Handle Change on un Monitored Chats
  React.useEffect(() => {
    if (typeof unMonitoredChats != typeof {}) {
      setunmChats({});
    } else {
      setunmChats(unMonitoredChats);
    }

    if (unMonitoredChats && Object.keys(unMonitoredChats).length > 0) {
      setLastTouched(
        new Date(
          unMonitoredChats[Object.keys(unMonitoredChats)[Object.keys(unMonitoredChats).length - 1]].lastTouched
        ).toISOString()
      );
    }
  }, [unMonitoredChats]);

  // Handle Change on Monitored Chats
  React.useEffect(() => {
    if (typeof monitoredChats != typeof {}) {
      setmChats({});
    } else {
      setmChats(monitoredChats);
    }

    if (monitoredChats && Object.keys(monitoredChats).length > 0) {
      setLastTouched(
        new Date(
          monitoredChats[Object.keys(monitoredChats)[Object.keys(monitoredChats).length - 1]].lastTouched
        ).toISOString()
      );
    }
  }, [monitoredChats]);

  //  Fetch Unmonitored Chats

  const fetchUnMonitoredChats = React.useCallback(
    (limit: number) => dispatch(getMonitorChats(limit, false, new Date().toISOString())),
    [dispatch]
  );

  React.useEffect(() => {
    fetchUnMonitoredChats(limit);
    fetchMonitoredChats(limit);
  }, [limit]);

  //  Fetch Monitored Chats
  const fetchMonitoredChats = React.useCallback(
    (limit: number) => dispatch(getMonitorChats(limit, true, new Date().toISOString())),
    [dispatch]
  );

  React.useEffect(() => {
    fetchMonitoredChats(limit);
    fetchMonitoredChats(limit);
  }, [limit]);

  async function fetchUnmonitoredChatsUsingIds () {
    for (const chatId of unMonitoredChatIds) {
      const response = await getChatById(chatId)()
      dispatch(removeFromUnmonitoredChatIds(chatId))
      dispatch(updateUnmonitoredChats(chatId, response))
    }
  }

  React.useEffect(() => {
    fetchUnmonitoredChatsUsingIds();
  }, [])

  // Renders UserCards Based on the Chats Passed!
  const renderUserCard = (chats) => {
    if (Object.keys(chats).length === 0) {
      return <NoChatsFound>There are currently no chats in this section !</NoChatsFound>;
    }
    return (
      Object.keys(chats).length > 0 &&
      Object.keys(chats).map((id: any, index: number) => {
        const {
          messages,
          clientData: { fromName },
          _id,
        } = chats[id];

        let msg = messages && messages.length > 0 && messages[messages.length - 1];

        if (Object.keys(chats).length === index + 1) {
          return msg && <UserCard key={_id} id={_id} message={msg} name={fromName} />;
        }
        return msg && <UserCard key={_id} id={_id} message={msg} name={fromName} />;
      })
    );
  };

  return (
    <Container className={"monitor"}>
      <Collapse
        accordion={true}
        onChange={(activeKey) => {
          handlePanelChange(activeKey);
        }}
        activeKey={currentActiveMonitorTab}
      >
        <Panel header="MONITORED CHATS" style={{ fontWeight: 600 }} key={1} forceRender={true}>
          <MonitorContainer>
            <CardContainer>{renderUserCard(mChats)}</CardContainer>
          </MonitorContainer>
        </Panel>
        <Panel header="UNMONITORED CHATS" key={2} style={{ fontWeight: 600 }}>
          <MonitorContainer>
            <CardContainer>{renderUserCard(unmChats)}</CardContainer>
          </MonitorContainer>
        </Panel>
      </Collapse>
    </Container>
  );
}
