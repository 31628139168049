import React from 'react';
import styled from "styled-components";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { openSoloWidget } from '../../SoloWidget/utils';

type FormMsg = {
  sessionId: string;
  formKey: string;
  nodeName: string;
  nodeId: string
  appId: string
}

type Props = {
	msg: FormMsg;
	isUser: boolean;
	sentAt: number;
};

export function Form(props: Props) {
	const { isUser, sentAt } = props;
  const { nodeName, sessionId, appId, nodeId, formKey } = props.msg;
  const dispatch = useDispatch()
	const activeRootTab = useSelector((state: any) => state.ui.activeRootTab)
  const { accessToken } = useSelector((state: any) => state.auth.info)

	return (
		<StyledForm theme={{
			isUser
		}}>
			<div className="form-node-body">
				<p>{nodeName}</p>
				<button onClick={() => {
					openSoloWidget({
						sessionId,
						appId,
						isCompleted: isUser || activeRootTab !== "live",
						nodeId,
						nodeName,
						accessToken,
						formKey,
					})
				}}>Open</button>
			</div>
			<div className="form-node-footer">
				<span style={{ color: isUser ? '#00B796' : '#ABABAB' }}>
					{!isUser ? 'Waiting to complete' : 'Completed'}
				</span>
				<span>{moment(new Date(sentAt)).format('DD/MM/YYYY, HH:mm')}</span>
			</div>
		</StyledForm>
	);
}

const StyledForm = styled.div`
	display: flex;
  flex-direction: column;
	align-items: ${(props) => !props.theme.isUser ? 'flex-end' : 'flex-start'};

  .form-node-body {
		border-radius: 8px;
		padding: 10px 6px;
		border: 1px solid #EDEEF3;
		background: #FFF;
		box-shadow: 0px 4px 4px 0px rgba(218, 226, 240, 0.18);
		display: inline-flex;
		align-items: center;
		width: 242px;
		gap: 10px;
		justify-content: space-between;
	}

	.form-node-body p, .form-node-body button {
		margin: 0;
	}

	.form-node-body p {
		color: #121212;
		font-size: 12px;
		font-weight: 400;
	}

	.form-node-body button {
		cursor: pointer;
		padding: 5px;
		border-radius: 5px;
		border: 1px solid #F1722E;
		background: #FFF;
		color: #F1722E;
		font-size: 10px;
		font-weight: 400;
	}

  .form-node-footer {
		display: flex;
		justify-content: space-between;
		width: 242px;
		color: #ABABAB;
		font-size: 10px;
		font-weight: 400;
		margin-top: 5px;
		font-style: italic;
	}

  .form-node-footer span:first-child {
		color: ${(props) => props.theme.isUser ? '#00B796' : '#ABABAB'};
	}
`
