export default {
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_ERROR: "SIGNIN_ERROR",
  SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
  SIGNOUT_ERROR: "SIGNOUT_ERROR",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  SIGNOUT: "SIGNOUT",
  RESET_SESSION: "RESET_SESSION",
  ROOT_SELECTED_MENU: "ROOT_SELECTED_MENU",
  ROOT_SELECTED_TAB: "ROOT_SELECTED_TAB",
  ME_GET_SUCCESS: "ME_GET_SUCCESS",
  ME_GET_ERROR: "ME_GET_ERROR",
  AGENTS_GET_SUCCESS: "AGENTS_GET_SUCCESS",
  AGENTS_GET_ERROR: "AGENTS_GET_ERROR",
  CREDENTIALS_GET_ERROR: "CREDENTIALS_GET_ERROR",
  SCOPES_GET_ERROR: "SCOPES_GET_ERROR",
  REMOVE_TOKEN_SUCCESS: "REMOVE_TOKEN_SUCCESS",
  REMOVE_TOKEN_ERROR: "REMOVE_TOKEN_ERROR",
  GENERATE_TOKEN_SUCCESS: "GENERATE_TOKEN_SUCCESS",
  GENERATE_TOKEN_ERROR: "GENERATE_TOKEN_ERROR",
  GROUPS_SUCCESS: "GROUPS_SUCCESS",
  GROUPS_ERROR: "GROUPS_ERROR",
  QUICK_RESP_SUCCESS: "QUICK_RESP_SUCCESS",
  QUICK_RESP_SAVE_SUCCESS: "QUICK_RESP_SAVE_SUCCESS",
  QUICK_RESP_SAVE_ERROR: "QUICK_RESP_SAVE_ERROR",
  SHOW_UPDATE_SETTING: "SHOW_UPDATE_SETTING",
  SHOW_UPDATE_RESOURCE: "SHOW_UPDATE_RESOURCE",
  RESOURCE_UPDATE_DETAILS: "RESOURCE_UPDATE_DETAILS",
  SETTING_UPDATE_DETAILS: "SETTING_UPDATE_DETAILS",
  SESSION_TOKEN_SUCCESS: "SESSION_TOKEN_SUCCESS",
  ERROR: "ERROR",
  QUICK_RESP_ERROR: "QUICK_RESP_ERROR",
  QUICK_RESP_SEARCH: "QUICK_RESP_SEARCH",
  QUICK_RESP_GROUP_FILTER: "QUICK_RESP_GROUP_FILTER",
  QUICK_RESP_SORT_FILTER: "QUICK_RESP_SORT_FILTER",
  QUICK_RESP_DELETE_SUCCESS: "QUICK_RESP_DELETE_SUCCESS",
  QUICK_RESP_DELETE_ERROR: "QUICK_RESP_DELETE_ERROR",
  AGENT_ERROR: "AGENT_ERROR",
  GROUP_ERROR: "GROUP_ERROR",
  GROUP_SAVE_SUCCESS: "GROUP_SAVE_SUCCESS",
  AGENT_SAVE_SUCCESS: "AGENT_SAVE_SUCCESS",
  UNASSIGNED_GET_CHATS_ERROR: "UNASSIGNED_GET_CHATS_ERROR",
  ASSIGNED_GET_CHATS_ERROR: "ASSIGNED_GET_CHATS_ERROR",
  UNASSIGNED_GET_CHATS_SUCCESS: "UNASSIGNED_GET_CHATS_SUCCESS",
  UPDATED_UNASSIGNED_CHATS: "UPDATED_UNASSIGNED_CHATS",
  ASSIGNED_GET_CHATS_SUCCESS: "ASSIGNED_GET_CHATS_SUCCESS",
  CHAT_LOADING: "CHAT_LOADING",
  CHAT_HISTORY_SUCCESS: "CHAT_HISTORY_SUCCESS",
  CHAT_HISTORY_ERROR: "CHAT_HISTORY_ERROR",
  MODIFY_ASSIGNED_CHAT: "MODIFY_ASSIGNED_CHAT",
  UPDATE_ASSIGNED_CHATS: "UPDATE_ASSIGNED_CHATS",
  RESET_ASSIGNED_CHATS: "RESET_ASSIGNED_CHATS",
  UPDATE_CHAT_BY_ID: "UPDATE_CHAT_BY_ID",
  UPDATE_ASSIGNED_CHAT_MESSAGE_BY_ID: "UPDATE_ASSIGNED_CHAT_MESSAGE_BY_ID",
  ASSIGN_NEW_CHAT_BY_ID: "ASSIGN_NEW_CHAT_BY_ID",
  FAQ_GET_SUCCESS: "FAQ_GET_SUCCESS",
  FAQ_GET_ERROR: "FAQ_GET_ERROR",
  FAQ_CATEGORY_SAVE_SUCCESS: "FAQ_CATEGORY_SAVE_SUCCESS",
  FAQ_CATEGORY_SAVE_ERROR: "FAQ_CATEGORY_SAVE_ERROR",
  FAQ_QUESTION_SAVE_SUCCESS: "FAQ_QUESTION_SAVE_SUCCESS",
  FAQ_QUESTION_SAVE_ERROR: "FAQ_QUESTION_SAVE_ERROR",
  FAQ_CATEGORY_DELETE_SUCCESS: "FAQ_CATEGORY_DELETE_SUCCESS",
  FAQ_CATEGORY_DELETE_ERROR: "FAQ_CATEGORY_DELETE_ERROR",
  BLOCK_LAYER: "BLOCK_LAYER",
  AGENT_VIEW_MODE: "AGENT_VIEW_MODE",
  AGENT_SEARCH: "AGENT_SEARCH",
  AGENT_SORT_FILTER: "AGENT_SORT_FILTER",
  AGENT_TYPE_FILTER: "AGENT_TYPE_FILTER",
  AGENT_GROUP_LIST_FILTER: "AGENT_GROUP_LIST_FILTER",
  AGENT_FILTER: "AGENT_FILTER",
  GROUP_SEARCH: "GROUP_SEARCH",
  GROUP_SORT_FILTER: "GROUP_SORT_FILTER",
  GROUP_TYPE_FILTER: "GROUP_TYPE_FILTER",
  GROUP_AGENT_FILTER: "GROUP_AGENT_FILTER",
  GROUP_FILTER: "GROUP_FILTER",
  THEME: "THEME",
  TOGGLE_BULK_UPLOAD: "TOGGLE_BULK_UPLOAD",
  BULKUPLOAD_SUCCESS: "BULKUPLOAD_SUCCESS",
  TOAST_UPDATE: "TOAST_UPDATE",
  TOAST_ERROR: "TOAST_ERROR",
  UPDATE_SELECTED_LIVE_CHAT_ID: "UPDATE_SELECTED_LIVE_CHAT_ID",
  UPDATE_SELECTED_HISTORY_CHAT_ID: "UPDATE_SELECTED_HISTORY_CHAT_ID",
  UPDATE_AGENT_NOTIFICATION: "UPDATE_AGENT_NOTIFICATION",
  PERSIST_CHAT: "PERSIST_CHAT",
  CLEAR_PERSISTED_CHAT: "CLEAR_PERSISTED_CHAT",
  SET_PERSISTED_CHAT: "SET_PERSISTED_CHAT",
  SET_ALL_MONITOR_CHATS: "SET_ALL_MONITOR_CHATS",
  SET_MONITORED_CHATS: "SET_MONITORED_CHATS",
  SET_UNMONITORED_CHATS: "SET_UNMONITORED_CHATS",
  SET_SELECTED_MONITOR_CHAT_ID: "SET_SELECTED_MONITOR_CHAT_ID",
  UPDATE_SELECTED_MONITOR_CHAT_MESSAGES: "UPDATE_SELECTED_MONITOR_CHAT_MESSAGES",
  UPDATE_MONITORED_CHATS: "UPDATE_MONITORED_CHATS",
  UPDATE_UNMONITORED_CHATS: "UPDATE_UNMONITORED_CHATS",
  UPDATE_MONITOR_CHAT_BY_ID: "UPDATE_MONITOR_CHAT_BY_ID",
  UPDATE_USER_CHAT_MSG_PREVIEW: "UPDATE_USER_CHAT_MSG_PREVIEW",
  CLEAR_SELECTED_MONITOR_CHAT: "CLEAR_SELECTED_MONITOR_CHAT",
  SET_AGENT_STATE: "SET_AGENT_STATE",
  SET_AGENT_REASONS_LIST: "SET_AGENT_REASONS_LIST",
  SET_CHAT_ROUTING_ALGORITHM: "SET_CHAT_ROUTING_ALGORITHM",
  OPEN_MONITORED_CHATS_TAB: "OPEN_MONITORED_CHATS_TAB",
  SELECTED_CHAT_MONITOR_GROUP: "SELECTED_CHAT_MONITOR_GROUP",
  REMOVE_UNMONITOR_ON_COMPLETE: "REMOVE_UNMONITOR_ON_COMPLETE",
  REMOVE_CHAT_FROM_MONITOR: "REMOVE_CHAT_FROM_MONITOR",
  SETTINGS_ERROR: "SETTINGS_ERROR",
  UPDATE_AGENT_INFO: "UPDATE_AGENT_INFO",
  FORCE_LOGOUT_SUCCESS: "FORCE_LOGOUT_SUCCESS",
  FORCE_LOGOUT_ERROR: "FORCE_LOGOUT_ERROR",
  DASHBOARD_URL_ERROR: "DASHBOARD_URL_ERROR",
  DASHBOARD_FILTERS: "DASHBOARD_FILTERS",
  CLIENT_CONVERSATION: "CLIENT_CONVERSATION",
  ADD_TO_UNREAD: "ADD_TO_UNREAD",
  REMOVE_FROM_READ: "REMOVE_FROM_READ",
  ADD_TO_UNMONITORED_CHAT_IDS: "ADD_TO_UNMONITORED_CHAT_IDS",
  REMOVE_FROM_UNMONITORED_CHAT_IDS: "REMOVE_FROM_UNMONITORED_CHAT_IDS",
  ADD_TO_COMPLETED_CHATS: "ADD_TO_COMPLETED_CHATS",
};
