export const setShowSoloWidget = (payload: boolean) => {
  return {
    type: "SET_SHOW_SOLO_WIDGET",
    payload
  }
};

export const initSoloSession = (payload: {
  appId: string
  sessionId: string
  nodeName: string
  nodeId: string
}) => {
  return {
    type: "INIT_SOLO_WIDGET_SESSION",
    payload
  }
};
