import * as React from "react";
import styled from "styled-components";
import MaxIcon from "../../../../../../assets/icons/maximize";
import CloseIcon from "../../../../../../assets/icons/close";
import ResetChatIcon from "../../../../../../assets/icons/resetChat";
import { ThemeContext, SelectedPropertyContext } from "../../../themeDesigner";

export interface IChatHeaderProps { }

const getFontSize = (fontStr: string) => {
  switch (fontStr) {
    case "sm":
      return "10px";
    case "small":
      return "14px";
    case "medium":
      return "18px";
    case "large":
      return "22px";
    case "xl":
      return "26px";
    case "huge":
      return "32px";
  }
};

const Container = styled.div`
  display: ${(props) => props.theme.display};
  flex-direction: ${(props) => props.theme.direction};
  justify-content: ${(props) => props.theme.justifyContent};
  align-items: ${(props) => props.theme.alignItems};
  margin: ${(props) => props.theme.marginAll};
  margin-right: ${(props) => props.theme.marginRight};
  margin-left: ${(props) => props.theme.marginLeft};
  margin-top: ${(props) => props.theme.marginTop};
  margin-bottom: ${(props) => props.theme.marginBottom};
  padding: ${(props) => props.theme.paddingAll};
  padding-right: ${(props) => props.theme.paddingRight};
  padding-left: ${(props) => props.theme.paddingLeft};
  padding-top: ${(props) => props.theme.paddingTop};
  padding-bottom: ${(props) => props.theme.paddingBottom};
  min-height: 60px;
  background: ${(props) => props.theme.backgroundColor};
  border: ${(props) => props.theme.borderAll};
  border-radius: ${(props) =>
    props.theme.borderRadius
      ? props.theme.borderRadius
      : `${props.theme.borderTop} ${props.theme.borderRight} 0px 0px`};
  opacity: ${(props) => props.theme.opacity / 100};
`;

const ImageContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 16px;
`;

const Image = styled.img`
  height: ${(props) => (props.theme.height ? props.theme.height : "31")};
  width: auto;
`;

const InfoContainer = styled.div`
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
`;

const StaticLabel = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => (props.theme.fontSize ? getFontSize(props.theme.fontSize) : "14px")};
  color: ${(props) => props.theme.color};
  user-select: none;
  font-weight: 800;
`;

const SubTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: ${(props) => (props.theme.fontSize ? getFontSize(props.theme.fontSize) : "14px")};
  color: ${(props) => props.theme.color};
  user-select: none;
`;

const IconContainer = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-around;
  width: 85px;
  margin-left: 12px;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  &:hover {
    background: #575757;
  }
`;

export default function ChatHeader(props: IChatHeaderProps) {
  const {
    themeProps: {
      widget: { header, dimension },
    },
  } = React.useContext(ThemeContext);
  const {
    background,
    display,
    opacity,
    flexDirection,
    justifyContent,
    alignItems,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    padding,
    border,
    borderRadius,
    borderBottomLeft,
    borderBottomRight,
    borderTopLeft,
    borderTopRight,
    title,
    subTitle,
    avatar,
    actions,
  } = header;
  const { setSelectedProperty } = React.useContext(SelectedPropertyContext);
  const isMaximizeIconVisible = actions[0].isVisible;
  const isCloseIconVisible = actions[1].isVisible;
  const isResetChatIconVisible = actions[2].isVisible;
  return (
    <Container
      theme={{
        backgroundColor: background.hex,
        display,
        direction: flexDirection,
        justifyContent,
        alignItems,
        marginAll: margin,
        marginBottom,
        marginTop,
        marginLeft,
        marginRight,
        paddingAll: padding,
        paddingBottom,
        paddingTop,
        paddingLeft,
        paddingRight,
        borderAll: border,
        borderBottom: borderBottomRight,
        borderLeft: borderBottomLeft,
        borderRadius,
        borderRight: borderTopRight,
        borderTop: borderTopLeft,
        opacity,
      }}
      onClick={(e) => {
        e.stopPropagation();
        setSelectedProperty("header");
      }}
    >
      {avatar.isVisible && (
        <ImageContainer>
          <Image src={avatar.icon} className="ui image" theme={{ height: avatar.size }} />
        </ImageContainer>
      )}
      <InfoContainer>
        {title && title.isVisible && (
          <StaticLabel theme={{ fontSize: title.size, color: title.color.hex, fontFamily: dimension.font }}>{title.text}</StaticLabel>
        )}
        {subTitle && subTitle.isVisible && (
          <SubTitle theme={{ fontSize: subTitle.size, color: subTitle.color.hex, fontFamily: dimension.font }}>{subTitle.text}</SubTitle>
        )}
      </InfoContainer>
      <IconContainer>
        {isResetChatIconVisible &&
          <IconWrapper>
            <ResetChatIcon size={getFontSize(actions[0].size)} color={actions[0].color.hex} />
          </IconWrapper>
        }
        {isMaximizeIconVisible && (
          <IconWrapper>
            <MaxIcon size={getFontSize(actions[0].size)} color={actions[0].color.hex} />
          </IconWrapper>
        )}
        {isCloseIconVisible && (
          <IconWrapper>
            <CloseIcon size={getFontSize(actions[0].size)} color={actions[1].color.hex} />
          </IconWrapper>
        )}
      </IconContainer>
    </Container>
  );
}
