import HTMLReactParser from "html-react-parser";
import { isClean, md } from "..";
import _ from "lodash";

export function decodeEntities(html: string) {
  const textarea = document.createElement("textarea")
  textarea.innerHTML = html
  return textarea.value

}

export function htmlToReactEl(html: string) {
  return HTMLReactParser(isClean(md.render(_.unescape(html))))
}

export const formatText = (text: string) => {
  return text
    .replace(/\\n/g, "&lt;br&gt;")
    .replace(/<br>/g, "&lt;br&gt;")
    .replace(/<br\/>/g, "&lt;br&gt;")
    .replace(/<br \/>/g, "&lt;br&gt;")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;lt;/g, "<")
    .replace(/&amp;/g, "&")
    .replace(/&amp;gt;/g, ">")
    .replace(/&#39;/g, "'")
    .replace(/&amp;#39;/g, "'");
};
