import React from "react";
import { Modal } from "antd";
import "../../assets/sass/modal.scss";
import Loader from "react-loader-spinner";
import styled from "styled-components";

interface IModalLoadingProps {
  open: boolean;
  onClose?: (e: any) => void;
  children: React.ReactNode;
}

const Content = styled.p`
  font-family: Muli;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.42px;
  color: #010f34;
  margin-top: 34px;
`;

const ModalLoading: React.FunctionComponent<IModalLoadingProps> = (props: IModalLoadingProps) => {
  const { open, onClose, children } = props;
  return (
    <Modal className="a8-console-modal-loading" width="405px" visible={open} onCancel={onClose} footer={null}>
      <div data-testid="container" style={{ textAlign: "center", padding: "25px 55px 45px" }}>
        <Loader type="TailSpin" color="#00c926" height={72} width={72} />
        <Content data-testid="content">{children}</Content>
      </div>
    </Modal>
  );
};

export default ModalLoading;
