import React, { ReactElement } from "react";
import styled from "styled-components";

interface Props {
  data: any;
  handleClick: any;
  align?: string;
  width?: string;
}

const Container = styled.div`
  text-align: ${(props) => (props.theme.align ? props.theme.align : "end")};
`;

const baseUrl = "https://quickchart.io/chart?bkg=white&c=";

const Wrapper = styled.div`
  display: flex;
  justify-content: ${(props) => props.theme.justify};
`;

export default function Chart({
  data,
  handleClick,
  align,
  width,
}: Props): ReactElement {
  const encodedUrl = encodeURIComponent(JSON.stringify(data));
  // const widgetProps = useSelector((state: any) => state.theme.widget.dimension);
  // const { isVisible: isChatIconVisible, icon } = widgetProps.chatIcon;

  const url = `${baseUrl}${encodedUrl}`;

  const handlePictureClick = () => {
    // const event = {
    //   eventName: "open-modal",
    //   data,
    //   type: "chart",
    // };
    // window.parent.postMessage(event, "*");
  };

  return (
    <Wrapper theme={{ justify: "flex-end" }}>
      <Container theme={{ align }}>
        <img
          onClick={handleClick}
          src={url}
          alt="Chart"
          width={width || "50%"}
        />
      </Container>
    </Wrapper>
  );
}
