import type from "../types/actionTypes";
const initialState = {
  agents: [],
};
export default function agentReducers(state = initialState, action) {
  switch (action.type) {
    case type.AGENTS_GET_SUCCESS:
      return {
        ...state,
        agents: action.data.agents,
      };
    case type.AGENT_SAVE_SUCCESS:
      return {
        ...state,
        agentSaveSuccess: action.data,
      };
    case type.BULKUPLOAD_SUCCESS:
      return {
        ...state,
        bulkuploadSuccess: action.data,
      };
    case type.AGENT_ERROR:
      return {
        ...state,
        agentError: action.data,
      };
    case type.FORCE_LOGOUT_SUCCESS:
      return {
        ...state,
        agentForceLogout: action.data,
      };
    case type.FORCE_LOGOUT_ERROR:
      return {
        ...state,
        agentForceLogoutError: action.data,
      };
    case type.ERROR:
      return {
        ...state,
        error: action.data,
      };

    case type.UPDATE_AGENT_INFO:
      const { agents } = state;
      const index = agents.findIndex(agent => agent._id === action.data.agentId);
      const oldAgentInfo = { ...agents[index] }

      oldAgentInfo['groupIds'] = action.data['agentGroupIds']
      oldAgentInfo['name'] = action.data['agentName']
      oldAgentInfo['roles'] = action.data['agentRoles']
      oldAgentInfo['state'] = action.data['agentState']

      const updatedAgentInfo = { ...agents[index], ...oldAgentInfo }
      agents.splice(index, 1, updatedAgentInfo)
      return ({
        ...state,
        agents
      })

    default:
      return state;
  }
}
