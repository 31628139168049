import React, { useState } from "react";
import styled from "styled-components";
import VisualElement from "./VisualElement";
import { Input } from "antd";
import { IoIosSearch, IoIosCloseCircleOutline } from "react-icons/io";
// import ContainerComponent from "./Container";

const Container = styled.menu`
  max-height: calc(100vh - 3rem);
  overflow-y: auto;
  overflow-x: visible;
  width: 232px;
  /* height: 742px; */
  background-color: #ebf4ff;
  padding: 15px 9px;
  margin: 0;
`;

const TabMenu = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  background: rgb(30, 121, 254);
  margin-top: 10px;
  height: 28px;
  padding-top: 4px;
  color: #fff;
  cursor: pointer;
`;

const SearchContainer = styled.div`
  width: 214px;
  height: 35px;
  border-radius: 6px;
  border: solid 1px #d3daef;
  background-color: #ffffff;
  margin-top: 15px 0 9px;
  position: relative;

  input {
    height: 100%;
    border: none;
    padding-left: 35px;
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const generalMenuItems = [
  {
    icon: "hourglass",
    title: "Loading Icon",
    type: "loading",
  }
];

const agentMenuItems = [
  {
    icon: "table",
    title: "Auto Complete",
    type: "autoComplete",
  },
  {
    icon: "address card outline",
    title: "Card Choice",
    type: "cardChoice",
  },
  {
    icon: "chart area",
    title: "Chart",
    type: "chart",
  },
  {
    icon: "table",
    title: "Data Table",
    type: "dataTable",
  },
  {
    icon: "file text",
    title: "Document",
    type: "document",
  },
  {
    icon: "inbox",
    title: "Image",
    type: "image",
  },
  {
    icon: "radio",
    title: "Simple Choice",
    type: "simpleChoice",
  },
  {
    icon: "bell",
    title: "Text Message",
    type: "agentTextMessage",
  },
  {
    icon: "video",
    title: "Video",
    type: "video",
  },
];

const userMenuItems = [
  {
    icon: "bell",
    title: "Text Message",
    type: "userTextMessage",
  },
];

const LeftSidebar = () => {
  const [generalSettings, setGeneralSettings] = useState(generalMenuItems);
  const [agentList, setAgentList] = useState(agentMenuItems);
  const [userList, setUserList] = useState(userMenuItems);
  const [searchText, setSearchText] = useState("");
  const [menuSelection, setMenuSelection] = useState("general");
  const handleSearch = (e) => {
    const { value: text } = e.target;
    let menus = [];
    menus = agentMenuItems.filter((item) => item.type.toLowerCase().includes(text.toLowerCase()));
    setAgentList(menus);
    menus = userMenuItems.filter((item) => item.type.toLowerCase().includes(text.toLowerCase()));
    setUserList(menus);
    menus = generalMenuItems.filter((item) => item.type.toLowerCase().includes(text.toLowerCase()));
    setGeneralSettings(menus);
    setSearchText(e.target.value);
  };
  return (
    <Container>
      <SearchContainer>
        <IoIosSearch style={{ position: "absolute", top: "10px", left: "10px", zIndex: 1 }} />
        <Input
          style={{ width: "100%" }}
          placeholder="Search Component"
          size="small"
          value={searchText}
          onChange={handleSearch}
        />
      </SearchContainer>
      <TabMenu onClick={() => setMenuSelection("general")} >GENERAL SETTINGS</TabMenu>
      {menuSelection == "general" && <VisualElement widgetList={generalSettings} />}
      <TabMenu onClick={() => setMenuSelection("agent")} >AGENT MESSAGES</TabMenu>
      {menuSelection == "agent" && <VisualElement widgetList={agentList} />}
      <TabMenu onClick={() => setMenuSelection("user")}>USER MESSAGES</TabMenu>
      {menuSelection == "user" && <VisualElement widgetList={userList} />}
      {/* <ContainerComponent /> */}
    </Container>
  );
};

export default LeftSidebar;
