import type from "../types/actionTypes";
const initialState = {
    faqs: {}
}
export default function faqReducers(state = initialState, action) {
    switch (action.type) {
        case type.FAQ_GET_SUCCESS:
            return {
                ...state,
                faqs: action.data.faqs
            }
        case type.FAQ_CATEGORY_SAVE_SUCCESS:
            return {
                ...state,
                isFAQNewCategory: action.data
            }
        case type.FAQ_QUESTION_SAVE_SUCCESS:
            return {
                ...state,
                isFAQNewCategory: action.data
            }

        case type.FAQ_CATEGORY_DELETE_SUCCESS:
            return {
                ...state,
                isFAQDeleteCategory: action.data
            }
        default:
            return state;
    }
}