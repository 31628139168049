/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import type from "../types/actionTypes";
import axios from "axios";
import config from "../apiConfig";
import { store } from "../store";

export function resetSession() {
  return async (dispatch) => {
    dispatch({
      type: type.RESET_SESSION,
    });
  };
}

export function signout() {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
    data: JSON.stringify({
      sessionId: store.getState().auth.sessionToken.sessionId,
      clientId: sessionStorage.clientId
    })
  };
  let authResp;
  return async (dispatch) => {
    try {
      authResp = await axios(`${config.baseUrl}${config.org}${config.v1}${config.signoutUrl}`, options);
      console.log(authResp);
      dispatch({
        type: type.SIGNOUT_SUCCESS,
        data: authResp.data,
      });
    } catch (e) {
      dispatch({
        type: type.SIGNOUT_ERROR,
        data: "Invalid email or password",
      });
    }
  };
}

export function sign({ email, password }) {
  const options = {
    method: "POST",
    data: JSON.stringify({
      email,
      password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  };
  let authResp;
  return async (dispatch) => {
    try {
      authResp = await axios(`${config.baseUrl}${config.org}${config.v1}${config.signinUrl}`, options);
      // console.log(response)
      dispatch({
        type: type.SIGNIN_SUCCESS,
        data: authResp.data,
      });
    } catch (e) {
      dispatch({
        type: type.SIGNIN_ERROR,
        data: "Invalid email or password",
      });
    }
  };
}

export function getSessionToken(data) {
  const options = {
    method: "POST",
    data: JSON.stringify({ userMetaInfo: data }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  let sessionResp;
  return async (dispatch) => {
    try {
      sessionResp = await axios(`${config.baseUrl}${config.org}${config.v1}${config.getSessionToken}`, options);
      dispatch({
        type: type.SESSION_TOKEN_SUCCESS,
        data: sessionResp.data,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function getDashboarDetals() {
  const options = {
    method: 'GET'
  };
  return async (dispatch) => {
    try {
      let url = `${config.baseUrl.replace("/apis", "")}${config.getDashboardDetailsUrl.replace("{token}", store.getState().auth.info.accessToken)}`;
      let resp = await axios(url, options);
      return resp && resp.data ? resp.data : null;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch({
          type: type.ERROR,
          data: error.response.data
        });
      } else {
        dispatch({
          type: type.DASHBOARD_URL_ERROR,
          error: error.message
        });
      }
    }
  };
}


export function getRefreshToken(data) {
  const options = {
    method: "POST",
    data: JSON.stringify({
      grant_type: "refresh_token",
      refresh_token: data.refreshToken,
      orgId: data.orgId,
      _id: data.agentId,
      roles: data.roles,
    }),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${store.getState().auth.info.accessToken}`,
    },
  };
  // console.log(options)
  let tokenResp;
  return async (dispatch) => {
    try {
      tokenResp = await axios(`${config.baseUrl}${config.org}${config.v1}${config.refreshUrl}`, options);
      console.log("Got new token: ", tokenResp);
      dispatch({
        type: type.REFRESH_TOKEN_SUCCESS,
        data: tokenResp.data,
      });
    } catch (error) {
      dispatch({
        type: type.ERROR,
        data: { code: "access_denied" },
      });
    }
  };
}

export const resetPasswordSendOtp = async (email) => {
  try {
    const { baseUrl, org, v1, resetPassword, resetPasswordSendOtp } = config;
    const options = {
      method: "PUT",
      data: { email },
      headers: {
        "Content-Type": "application/json",
      },
    };
    const resp = await axios(`${baseUrl}${org}${v1}${resetPassword}${resetPasswordSendOtp}`, options);
    return resp.data;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const resetPasswordVerifyOtp = async (email, otp) => {
  try {
    const { baseUrl, org, v1, resetPassword, resetPasswordVerifyOtp } = config;
    const options = {
      method: "PUT",
      data: { email, otp },
      headers: {
        "Content-Type": "application/json",
      },
    };
    const resp = await axios(`${baseUrl}${org}${v1}${resetPassword}${resetPasswordVerifyOtp}`, options);
    return resp.data;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const changePassword = async (accessToken, newPassword) => {
  try {
    const { baseUrl, org, v1, resetPassword } = config;
    const options = {
      method: "PUT",
      data: { newPassword },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const resp = await axios(`${baseUrl}${org}${v1}${resetPassword}`, options);
    return resp.data;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const setAgentState = (data) => {
  return {
    type: type.SET_AGENT_STATE,
    data,
  };
};
