import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onPickupChat } from "../../helpers/mqttService";

export interface IToasterProps {}

export const newChatNotification = (chatId: string) =>
  toast.info("New Chat Occurred", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    onClick: () => {
      onPickupChat(chatId);
      toast.dismiss();
    },
  });

export default function Toaster(props: IToasterProps) {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      draggable
      pauseOnHover
    />
  );
}
