import * as React from "react";
import styled from "styled-components";
import LogoImage from "../../assets/images/a8chat_icon.svg";
import FooterLogo from "../../assets/images/a8-footer-logo.svg";

export interface IForgotPasswordCardProps {
  children: any;
  height?: string;
}

const Container = styled.div`
  width: 369px;
  margin: 0 auto;
  min-height: 550px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const Logo = styled.img``;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const Heading = styled.div`
  font-family: Muli;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #10002e;
  padding-left: 15px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: ${(props) => (props.theme.height ? props.theme.height : "469px")};
  border-radius: 2.5px;
  box-shadow: 0 0 10px 0 rgba(149, 171, 211, 0.53);
  background-color: #ffffff;
  padding: 28px;
  margin-bottom: 24px;
`;

const FooterWrapper = styled.div`
  font-family: HelveticaNeue, Muli;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #8797a8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ForgotPasswordCard(props: IForgotPasswordCardProps) {
  const { children, height } = props;
  return (
    <Container>
      <HeaderWrapper>
        <Logo src={LogoImage} />
        <Heading>A8Chat</Heading>
      </HeaderWrapper>
      <ContentWrapper theme={{ height }}>{children}</ContentWrapper>
      <FooterWrapper>
        <span>Powered By</span>
        <img
          style={{ marginLeft: "20px" }}
          src={FooterLogo}
          alt="Footer Logo"
        />
      </FooterWrapper>
    </Container>
  );
}
