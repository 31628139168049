import React from 'react'
import './solo-widget.css'
import { useSelector, useDispatch } from 'react-redux'
import { SoloWidgetState } from '../../../reducers/soloWidgetReducer';
import { setShowSoloWidget } from '../../../actions/soloWidgetActions';
import { FiXCircle } from "react-icons/fi";

export function SoloWidget() {
  const dispatch = useDispatch()
  const soloWidget: SoloWidgetState = useSelector((state: any) => state.soloWidget);

  function close() {
    dispatch(setShowSoloWidget(false))
  }

  return (
    <div className="solo-widget-overlay" data-show={soloWidget.show}>
      <div className="solo-widget">
         <div className="solo-widget-header">
           <span>{soloWidget.nodeName}</span>
           <FiXCircle size="20" onClick={close} />
         </div>
         <div id="solo-widget-main">
         </div>
      </div>
    </div>
  )
}
