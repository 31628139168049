import type from "../types/actionTypes";
import update from "immutability-helper";
const initialState = {
  chats: [],
  assignedChats: {},
  unAssignedChats: {},
  getChatlastTouched: null,
  chatHistory: [],
  isLoading: false,
  selectedLiveChatId: "",
  selectedHistoryChatId: "",
  selectedMonitorChatId: "",
  agentNotification: "",
  unMonitoredChatIds: [],
  completedChats: [],
};

const array2Objects = (array, field) => {
  return array.reduce((a, c) => {
    a[c[field]] = c;
    return a;
  }, {});
};

const sortChats = (unMonitoredChats) => {
  let sortedUnMonitored = Object.keys(unMonitoredChats).sort(
    (a, b) => unMonitoredChats[a]["lastTouched"] - unMonitoredChats[b]["lastTouched"]
  );
  let newUnMonitoredChats = {};
  sortedUnMonitored = sortedUnMonitored.reverse();
  sortedUnMonitored.forEach((item) => {
    newUnMonitoredChats[item] = unMonitoredChats[item];
  });
  return newUnMonitoredChats;
};

export default function chatReducers(state = initialState, action) {
  switch (action.type) {
    case type.SIGNOUT_SUCCESS:
      return initialState;
    case type.MODIFY_ASSIGNED_CHAT:
      return update(state, {
        assignedChats: { $set: action.data },
      });
    case type.UPDATE_ASSIGNED_CHATS: {
      const { selectedLiveChatId } = action;
      const assignedChats = array2Objects(action.data, "_id");
      if (selectedLiveChatId && Object.keys(assignedChats[selectedLiveChatId]).length > 0) {
        assignedChats[selectedLiveChatId] = state.assignedChats[selectedLiveChatId];
      }
      return update(state, {
        assignedChats: { $set: assignedChats },
      });
    }
    case type.RESET_ASSIGNED_CHATS: {
      return update(state, {
        assignedChats: { $set: {} },
      });
    }
    case type.UPDATE_CHAT_BY_ID: {
      if (!!state.assignedChats[action.chatId]) {
        let messages = action.payload
        if(!Array.isArray(messages)) {
          messages = [action.payload]
        }
        return update(state, {
          assignedChats: {
            [action.chatId]: {
              messages: {
                $push: messages,
              },
            },
          },
        });
      }
      return state;
    }
    case type.UPDATE_ASSIGNED_CHAT_MESSAGE_BY_ID: {
      return update(state, {
        assignedChats: {
          [action.chatId]: {
            messages: { $set: action.payload.messages },
            participants: { $set: action.payload.participants },
            clientData: { $set: action.payload.clientData },
            clientId: { $set: action.payload.clientId },
          },
        },
      });
    }
    case type.ASSIGN_NEW_CHAT_BY_ID: {
      const updatedAssignedChats = Object.assign(
        {
          [action.chatId]: {
            ...action.payload,
            _id: action.chatId,
            lastTouched: Date.now(),
            isUnread: true,
            messages: action.payload.lastChatMessage ? [action.payload.lastChatMessage] : [],
          },
        },
        state.assignedChats
      );

      return update(state, {
        assignedChats: { $set: updatedAssignedChats },
      });
    }

    case type.UNASSIGNED_GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: [...state.chats, ...action.data.chats],
        getChatlastTouched: action.data.getChatlastTouched,
      };
    case type.UPDATED_UNASSIGNED_CHATS:
      return update(state, {
        chats: { $set: action.payload || {} },
      });
    case type.ERROR:
      return {
        ...state,
        error: action.data,
      };
    case type.CHAT_HISTORY_SUCCESS:
      return update(state, {
        chatHistory: { $set: array2Objects(action.data, "_id") },
      });
    case type.CHAT_HISTORY_ERROR:
      return update(state, {
        error: { $set: action.data },
      });
    case type.CHAT_LOADING:
      return update(state, {
        isLoading: { $set: action.data },
      });
    case type.UPDATE_SELECTED_HISTORY_CHAT_ID:
      return update(state, {
        selectedHistoryChatId: { $set: action.data },
      });
    case type.UPDATE_USER_CHAT_MSG_PREVIEW:
      return update(state, {
        userChatMsgPreview: { $set: action.payload },
      });
    case type.UPDATE_SELECTED_LIVE_CHAT_ID: {
      if (action.data === "") {
        return update(state, {
          selectedLiveChatId: { $set: action.data },
        });
      } else {
        return update(state, {
          selectedLiveChatId: { $set: action.data },
          assignedChats: {
            [action.data]: {
              isUnread: { $set: false },
            },
          },
        });
      }
    }
    case type.UPDATE_AGENT_NOTIFICATION:
      return update(state, {
        agentNotification: { $set: action.data },
      });

    case type.SET_ALL_MONITOR_CHATS:
      return update(state, {
        allmonitorChats: { $set: action.data },
      });

    case type.SET_UNMONITORED_CHATS:
      const { selectedMonitorChatId } = action;
      const unMonitoredChats = Array.isArray(action.data) ? array2Objects(action.data, "_id") : action.data;

      if (
        selectedMonitorChatId &&
        unMonitoredChats[selectedMonitorChatId] &&
        Object.keys(unMonitoredChats[selectedMonitorChatId]).length > 0
      ) {
        unMonitoredChats[selectedMonitorChatId] = state.unMonitoredChats[selectedMonitorChatId];
      }
      return {
        ...state,
        unMonitoredChats: {
          ...state.unMonitoredChats,
          ...unMonitoredChats
        }
      }

    case type.SET_MONITORED_CHATS: {
      const { selectedMonitorChatId } = action;
      const monitoredChats = Array.isArray(action.data) ? array2Objects(action.data, "_id") : action.data;

      if (
        selectedMonitorChatId &&
        monitoredChats[selectedMonitorChatId] &&
        Object.keys(monitoredChats[selectedMonitorChatId]).length > 0
      ) {
        monitoredChats[selectedMonitorChatId] = state.monitoredChats[selectedMonitorChatId];
      }
      return {
        ...state,
        monitoredChats: {
          ...state.monitoredChats,
          ...monitoredChats
        }
      }
    }

    case type.UPDATE_MONITORED_CHATS: {
      let newMonitoredChat = {};
      newMonitoredChat[action.chatId] = action.payload;
      return update(state, {
        monitoredChats: {
          $set: { ...newMonitoredChat, ...state.monitoredChats },
        },
        unMonitoredChats: { $unset: [action.chatId] },
      });
    }

    case type.UPDATE_UNMONITORED_CHATS: {
      let newUnmonitoredChat = {};
      newUnmonitoredChat[action.chatId] = action.payload;
      let sortedChats = sortChats({
        ...state.unMonitoredChats,
        ...newUnmonitoredChat,
      });
      return update(state, {
        unMonitoredChats: { $set: { ...sortedChats } },
        monitoredChats: { $unset: [action.chatId] },
      });
    }

    case type.SET_SELECTED_MONITOR_CHAT_ID: {
      const updatedChatId = update(state, {
        selectedMonitorChatId: { $set: action.data },
      });
      return updatedChatId;
    }
    case type.UPDATE_SELECTED_MONITOR_CHAT_MESSAGES: {
      if (action.monitorGroup) {
        return update(state, {
          monitoredChats: {
            [action.chatId]: {
              messages: { $set: action.payload },
              participants: { $set: action.participants },
              groupId: { $set: action.groupId },
            },
          },
        });
      } else {
        return update(state, {
          unMonitoredChats: {
            [action.chatId]: {
              messages: { $set: action.payload },
              participants: { $set: action.participants },
              groupId: { $set: action.groupId },
            },
          },
        });
      }
    }

    case type.UPDATE_MONITOR_CHAT_BY_ID: {
      if (state.monitoredChats.hasOwnProperty(action.chatId)) {
        let messages = action.payload
        if(!Array.isArray(messages)) {
          messages = [action.payload]
        }
        return update(state, {
          monitoredChats: {
            [action.chatId]: {
              messages: {
                $push: messages,
              },
            },
          },
        });
      } else {
        return update(state, {
          unMonitoredChats: {
            [action.chatId]: {
              messages: {
                $push: [action.payload],
              },
            },
          },
        });
      }
    }

    case type.CLEAR_SELECTED_MONITOR_CHAT: {
      return update(state, {
        selectedMonitorChatId: { $set: null },
      });
    }

    case type.REMOVE_UNMONITOR_ON_COMPLETE: {
      if (state.unMonitoredChats && state.unMonitoredChats.hasOwnProperty(action.chatId)) {
        if (action.isSelected) {
          const resetSelectedId = "";
          const updatedState = update(state, {
            unMonitoredChats: { $unset: [action.chatId] },
            selectedMonitorChatId: { $set: resetSelectedId },
          });
          return updatedState;
        } else {
          const updatedState = update(state, {
            unMonitoredChats: { $unset: [action.chatId] },
          });
          return updatedState;
        }
      } else {
        return state;
      }
    }

    case type.REMOVE_CHAT_FROM_MONITOR: {
      return update(state, {
        monitoredChats: { $unset: [action.chatId] },
      });
    }

    case type.ADD_TO_UNMONITORED_CHAT_IDS: {
      if (state.unMonitoredChatIds.includes(action.payload)) {
        return state;
      }

      return {
        ...state,
        unMonitoredChatIds: [...state.unMonitoredChatIds, action.payload]
      }
    }

    case type.ADD_TO_COMPLETED_CHATS: {
      if (state.completedChats.includes(action.payload)) {
        return state;
      }

      return {
        ...state,
        completedChats: [...state.completedChats, action.payload]
      }
    }

    case type.REMOVE_FROM_UNMONITORED_CHAT_IDS: {
      return {
        ...state,
        unMonitoredChatIds: state.unMonitoredChatIds.filter(chatId => chatId !== action.payload),
      }
    }
    default:
      return state;
  }
}
