import React, { useContext } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import GeneralHeaderProperties from "./components/WidgetHeader/GeneralProperties";

import "../../../../../assets/sass/theme-designer.scss";
import AvatarProperties from "./components/WidgetHeader/Avatar";
import LabelProperties from "./components/WidgetHeader/Label";
import BotNameProperties from "./components/WidgetHeader/BotName";
import MaximizeAndCloseProperties from "./components/WidgetHeader/MaximizeAndClose";
import LayoutProperties from "./components/WidgetHeader/Layout";
import SpacingProperties from "./components/WidgetHeader/Spacing";
import BorderProperties from "./components/WidgetHeader/Border";
import AppearanceProperties from "./components/WidgetHeader/Appearance";
import { SelectedPropertyContext } from "../../themeDesigner";
import ContentBackground from "./components/WidgetContent/ContentBackground";
import TextMsg from "./components/WidgetMessages/Text";
import SimpleChoiceMsg from "./components/WidgetMessages/SimpleChoice";
import CardChoiceMsg from "./components/WidgetMessages/CardChoice";
import Spacing from "./components/WidgetContent/Spacing";
import InputGeneral from "./components/WidgetInput";
import FooterProperties from "./components/WidgetInput/Footer";
import InputLayoutProperties from "./components/WidgetInput/Layout";
import InputSpacingProperties from "./components/WidgetInput/Spacing";
import InputBorderProperties from "./components/WidgetInput/Border";
import MessageBorderProperties from "./components/WidgetMessages/Broder";
import MessageOutlineProperties from "./components/WidgetMessages/Outline";
import InputAppearanceProperties from "./components/WidgetInput/Appearance";
import GeneralContainer from "./components/WigetContainer/General";
import WidgetProperties from "./components/WigetContainer/WidgetProperties";
import SurfaceQuestion from "./components/WidgetContent/SurfaceQuestion";
import GlobalSettings from "./components/WidgetMessages/GlobalSetting";

interface ISelectedElement {
  type: string;
  index: number;
}

export interface IState {
  ui: IUiTheme;
}

interface IUiTheme {
  theme: ITheme;
}
interface ITheme {
  primaryColor: string;
  secondaryColor: string;
}

const Container = styled.div`
  max-height: calc(100vh - 17rem);
  overflow-y: auto;
  background-color: rgb(247, 250, 252);
  overflow-y: auto;
  overflow-x: hidden;
  flex: 0 0 15rem;
  border-left: 1px solid rgb(202, 213, 222);
  min-width: 258px;
`;

const Header = styled.div`
  width: 248px;
  height: 33px;
  background-color: ${(props) => props.theme.backgroundColor};

  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.42px;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding-left: 11.5px;
  text-transform: uppercase;
`;

const renderHeaderProperties = (backgroundColor: string) => (
  <>
    <Header theme={{ backgroundColor }}>Widget Header</Header>
    <GeneralHeaderProperties />
    <AvatarProperties />
    <LabelProperties />
    {/* <BotNameProperties /> */}
    <MaximizeAndCloseProperties />
    <LayoutProperties />
    <SpacingProperties />
    <BorderProperties />
    <AppearanceProperties />
  </>
);

const renderContentProperties = (backgroundColor: string) => (
  <>
    <Header theme={{ backgroundColor }}>Widget Content</Header>
    <ContentBackground />
    <Spacing />
    <SurfaceQuestion></SurfaceQuestion>
  </>
);

const renderInputProperties = (backgroundColor: string) => (
  <>
    <Header theme={{ backgroundColor }}>Widget Input</Header>
    <InputGeneral />
    <FooterProperties />
    <InputLayoutProperties />
    <InputSpacingProperties />
    <InputBorderProperties />
    <InputAppearanceProperties />
  </>
);

const renderContainerProperties = (backgroundColor: string) => (
  <>
    <Header theme={{ backgroundColor }}>Widget Container</Header>
    <GeneralContainer />
    <WidgetProperties />
  </>
);

const renderTextMsgProperties = (backgroundColor: string, msgType: string) => (
  <>
    <Header theme={{ backgroundColor }}>{msgType.toLocaleUpperCase()} Text Message</Header>
    <TextMsg msgType={msgType} />
    <GlobalSettings msgType={msgType} />
  </>
);

const renderSimpleChoiceMsgProperties = (backgroundColor: string, msgType: string) => (
  <>
    <Header theme={{ backgroundColor }}>{msgType.toLocaleUpperCase()} Simple Choice</Header>
    <SimpleChoiceMsg msgType={msgType} />
    <GlobalSettings msgType={msgType} />
  </>
);

const renderCardChoiceMsgProperties = (backgroundColor: string, msgType: string) => (
  <>
    <Header theme={{ backgroundColor }}>{msgType.toLocaleUpperCase()} Card Choice</Header>
    <CardChoiceMsg msgType={msgType} />
    <GlobalSettings msgType={msgType} />
  </>
);

const RightSidebar = () => {
  const { primaryColor: backgroundColor } = useSelector(
    (state: IState) => state.ui.theme
  );
  const { selectedProperty } = useContext(SelectedPropertyContext);
  return (
    <Container>

      {(selectedProperty === "agentTextMsg" || selectedProperty === "userTextMsg") && renderTextMsgProperties(backgroundColor, (selectedProperty === "agentTextMsg" ? "agent" : "user"))}

      {(selectedProperty === "agentSimpleChocieMsg" || selectedProperty === "userSimpleChocieMsg") && renderSimpleChoiceMsgProperties(backgroundColor, (selectedProperty === "agentSimpleChocieMsg" ? "agent" : "user"))}

      {(selectedProperty === "agentCardChocieMsg" || selectedProperty === "userCardChocieMsg") && renderCardChoiceMsgProperties(backgroundColor, (selectedProperty === "agentCardChocieMsg" ? "agent" : "user"))}

      {selectedProperty === "header" && renderHeaderProperties(backgroundColor)}

      {selectedProperty === "content" &&
        renderContentProperties(backgroundColor)}

      {selectedProperty === "input" && renderInputProperties(backgroundColor)}

      {selectedProperty === "container" &&
        renderContainerProperties(backgroundColor)}
    </Container>
  );
};

export default RightSidebar;
