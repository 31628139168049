import React, { useState } from "react";
import styled from "styled-components";
import ChatWidget from "../ChatWidget";
import { SelectedPropertyContext } from "../../themeDesigner";
const Container = styled.div`
  background-color: rgb(255, 255, 255);
  z-index: 10;
  position: relative;
  flex: 1 1 0%;
`;

const Content = styled.div`
  background-image: linear-gradient(
      to right,
      rgb(217, 226, 233) 1px,
      transparent 1px
    ),
    linear-gradient(rgb(217, 226, 233) 1px, transparent 1px);
  background-size: 20px 20px;
  background-color: #f7fdff;
  height: 100%;
  min-width: 10rem;
  width: 100%;
  display: block;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  padding: 2.5rem;
  overflow: auto;
`;

const Main = () => {
  const { setSelectedProperty } = React.useContext(SelectedPropertyContext);
  return (
    <Container>
      <Content onClick={() => setSelectedProperty("container")}>
        <ChatWidget />
      </Content>
    </Container>
  );
};

export default Main;
