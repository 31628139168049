import { initSoloSession, setShowSoloWidget } from "../../../actions/soloWidgetActions";
import { store } from "../../../store";

export function postMessage(event: "AGENT_NEXT_NODE", data: Record<string, any>) {
  const iframe = document.getElementById("a8_solo_widget_frame") as HTMLIFrameElement | null
  if(!iframe) {
    console.error("error: postMessage: iframe is missing")
    return
  }
  iframe.contentWindow.postMessage({ event, ...data }, iframe.src);
}

declare global {
  interface Window {
    a8solo: {
      onInit: (config: any) => Promise<void>
    }
  }
}

export function cleanupSoloSession() {
 sessionStorage.removeItem("sessionId") 
 sessionStorage.removeItem("endSession") 
}

export function initSoloFlow(data: {
  sessionId: string
  appId: string
}) {
  const { sessionId, appId } = data
  cleanupSoloSession()
  const { orgName, accessToken } = store.getState().auth.info
  const { activeRootTab } = store.getState().ui;
  sessionStorage.setItem("sessionId", sessionId)
  const config = {
    isLiveAgent: true,
    sessionId,
    assigned: activeRootTab === "live",
    dimension: { 
      width: 380, 
      height: 490,
    },
    renderDomId: "solo-widget-main",
    flowName: "",
    formTheme: {},
    customerAccessToken: accessToken,
    orgName,
    appId: appId,
    isLive: true,
    containerTheme: {},
  };
  window.a8solo.onInit(config).then(() => {
    const iframe = document.getElementById("a8_solo_widget_frame") as HTMLIFrameElement | null
    if(iframe) {
      iframe.style.borderBottomLeftRadius = '10px'
      iframe.style.borderBottomRightRadius = '10px'
    }
  })
}

export function openSoloWidget(data: {
 sessionId: string 
 appId: string 
 nodeName: string 
 nodeId: string
 accessToken: string
 isCompleted: boolean
 formKey: string
}) {
  store.dispatch(setShowSoloWidget(true))
  store.dispatch(initSoloSession(data))
  postMessage("AGENT_NEXT_NODE", data)
}
