import React, { useState, useCallback, useEffect, useLayoutEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { getAssignedChats, testFunction } from "../../actions/chatsAction";
import { blockLayer } from "../../actions/uiAction";
import Live from "./live";
import { newTestFunction } from "../../helpers/rxServices";
import HistoryComponent from "./History";
import MonitorComponent from "./Monitor";

export interface IChatsProps {}

export default function Chats(props: IChatsProps) {
  // const [chats, setChats] = useState([]);
  const dispatch = useDispatch();
  const fetchAssignedChats = useCallback((limit: number) => dispatch(getAssignedChats(limit)), [dispatch]);
  const handleBlockLayer = useCallback((payload: boolean) => dispatch(blockLayer(payload)), [dispatch]);

  const handleTestFunction = useCallback((payload) => dispatch(testFunction(payload)), [dispatch]);
  const auth = useSelector((state: any) => state.auth);
  const ui = useSelector((state: any) => state.ui);
  const chat = useSelector((state: any) => state.chat);
  const { activeRootNav: headerName, activeRootTab: tabName } = ui;

  const handleSubscribedFunction = (msg) => {
    debugger;
    console.log(msg);
  };

  useEffect(() => {
    // fetchAssignedChats(10);
    handleBlockLayer(false);
  }, []);

  useLayoutEffect(() => {
    // newTestFunction.subscribe(handleSubscribedFunction);
  });

  return (
    <div className="chat">
      <Row>
        <Col span={24} style={{ padding: 0 }}>
          {headerName == "chats" && auth.info.roles.includes("agent") && tabName == "live" && (
            <Live chats={chat.assignedChats} />
          )}
          {headerName == "chats" && tabName == "history" && <HistoryComponent chats={chat.assignedChats} />}
          {headerName == "chats" && auth.info.roles.includes("supervisor") && tabName == "monitor" && (
            <MonitorComponent chats={chat.assignedChats} />
          )}
        </Col>
      </Row>
    </div>
  );
}
