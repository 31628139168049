import React, { useContext } from "react";
import styled from "styled-components";
import { ThemeContext } from "../../../settings/general/themeDesigner";
import { SelectedBotContext } from "../../../settings/bots";
import HTMLReactParser from "html-react-parser";
import Markdown from "markdown-it";
import '../../../../assets/css/markdown.css'
import sanitizeHtml from "sanitize-html";

export interface ITextMessageProps {
  icon?: any;
  isChatIconVisible?: any;
  isUser: boolean;
  backgroundColor: string;
  color: string;
  fontSize: string;
  message: any;
  padding?: any;
  paddingLeft?: any;
  paddingTop?: any;
  paddingRight?: any;
  paddingBottom?: any;
  border?: any;
  borderRadius?: any;
  borderTopLeft?: any;
  borderTopRight?: any;
  borderBottomLeft?: any;
  borderBottomRight?: any;
  outlineColor?: any;
  outlineLeft?: any;
  outlineRight?: any;
  outlineTop?: any;
  outlineBottom?: any;
  onHandelClick?: (arg) => void;
  fontFamily?: string;
  markdownText?: string
}

export interface ILayoutProps {
  children?: React.ReactNode;
  isUser: boolean;
  onHandelClick?: (arg) => void;
}

const LayoutContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.theme.isUser ? "flex-end" : "flex-start")};
`;

const Wrapper = styled.div`
  position: relative;
  border: ${(props) => props.theme.border};

  opacity: ${(props) => props.theme.opacity / 100};
  background-color: ${(props) => props.theme.backgroundColor};
  font-size: ${(props) => props.theme.fontSize};
  color: ${(props) => props.theme.color};
  word-break: break-all;
  overflow: hidden;
  font-family: ${(props) => `${props.theme.fontFamily}, sans-serif`};
  align-self: center;
`;

const Outline = styled.div`
  position: relative;
  min-width: 60px;
  max-width: 500px;
  align-self: center;
  padding: ${(props) => props.theme.padding ? props.theme.padding : "16px"};
  padding-top: ${(props) => props.theme.paddingTop};
  padding-bottom: ${(props) => props.theme.paddingBottom};
  padding-right: ${(props) => props.theme.paddingRight};
  padding-left: ${(props) => props.theme.paddingLeft};
  border: 0px solid;
  border-color: ${(props) => (props.theme.outlineColor ? props.theme.outlineColor : "transparent")};
  border-bottom-width: ${(props) => (props.theme.outlineBottom ? props.theme.outlineBottom : "0px")};
  border-top-width: ${(props) => (props.theme.outlineTop ? props.theme.outlineTop : "0px")};
  border-left-width: ${(props) => (props.theme.outlineLeft ? props.theme.outlineLeft : "0px")};
  border-right-width: ${(props) => (props.theme.outlineRight ? props.theme.outlineRight : "0px")};
  font-family: ${(props) => `${props.theme.fontFamily}, sans-serif`};
  word-break: break-word;
`;

const md = new Markdown({ html: true });

const Layout = (props: ILayoutProps) => {
  const { isUser, children, onHandelClick } = props;
  return (
    <LayoutContainer onClick={onHandelClick} theme={{ isUser }}>
      {children}
    </LayoutContainer>
  );
};
export default function TextMessage(props: ITextMessageProps) {
  const context = useContext(ThemeContext);
  const { selectedBotName } = useContext(SelectedBotContext);
  const {
    isUser,
    isChatIconVisible,
    icon,
    backgroundColor,
    borderRadius,
    border,
    borderTopLeft,
    borderTopRight,
    borderBottomLeft,
    borderBottomRight,
    outlineColor,
    outlineTop,
    outlineRight,
    outlineLeft,
    outlineBottom,
    padding,
    paddingLeft,
    paddingTop,
    paddingBottom,
    paddingRight,
    fontSize,
    color,
    message,
    onHandelClick,
    fontFamily,
    markdownText
  } = props;

  const userIcon = context.themeProps.widget.dimension.chatIcon.userIcon;
  const icons = isUser ? userIcon : icon;

  return (
    <Layout isUser={isUser} onHandelClick={onHandelClick ? onHandelClick : undefined}>
      {isChatIconVisible && <div style={{
        width: "45px",
        minWidth: "45px",
        height: "45px",
        margin: `5px ${!isUser ? "10px" : 0} 5px ${isUser ? "10px" : 0}`,
        borderRadius: "50%",
        padding: "2px",
        order: isUser ? 1 : 0
      }}>
        {icons && < img src={icons} height="auto" width="100%" />}
        {!icons && isUser && <div style={{
          backgroundColor: context.themeProps.widget.dimension.userColor.hex,
          height: context.themeProps.widget.dimension.userHeight,
          width: context.themeProps.widget.dimension.userWidth,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: context.themeProps.widget.dimension.userTextColor.hex,
          fontWeight: "bold",
          borderRadius: context.themeProps.widget.dimension.borderRadiusUser
        }}>U</div>}

        {!icons && !isUser && <div style={{
          backgroundColor: context.themeProps.widget.dimension.agentColor.hex,
          height: context.themeProps.widget.dimension.agentHeight,
          width: context.themeProps.widget.dimension.agentWidth,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: context.themeProps.widget.dimension.agentTextColor.hex,
          fontWeight: "bold",
          borderRadius: context.themeProps.widget.dimension.borderRadiusAgent
        }}>{selectedBotName[0]}</div>}
      </div>}
      <Wrapper
        theme={{
          backgroundColor,
          border,
          fontSize,
          color,
          isUser,
          fontFamily,
        }}
        style={{
          borderRadius: borderRadius
            ? borderRadius
            : `${borderTopLeft} ${borderTopRight} ${borderBottomRight} ${borderBottomLeft}`,
        }}
      >
        <Outline
          theme={{
            padding,
            paddingLeft,
            paddingTop,
            paddingBottom,
            paddingRight,
            outlineColor,
            outlineLeft,
            outlineRight,
            outlineBottom,
            outlineTop,
            fontFamily,
          }}
          style={{
            borderRadius: borderRadius
              ? borderRadius
              : `${borderTopLeft} ${borderTopRight} ${borderBottomRight} ${borderBottomLeft}`,
          }}
        >
          {markdownText ? (
            <div className="markdown-body">
              {HTMLReactParser(sanitizeHtml(md.render(markdownText)))}
            </div>
          ) : message}
        </Outline>
      </Wrapper>
    </Layout>
  );
}
