/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useState, useEffect, useCallback } from "react";
// import Scrollbar from "react-scrollbars-custom";
import { useDispatch, useSelector } from "react-redux";
// import { blockLayer } from "../../actions/uiAction";
// import { getUnassignedChats } from "../../actions/chatsAction";
import { onPickupChat } from "../../helpers/mqttService";
// import { withRouter } from "react-router-dom";
// import { BellOutlined } from "@ant-design/icons";
// import { FaTimes } from "react-icons/fa";
import moment from "moment";
import { Input, Button, Row, Col, Divider } from "antd";
import _ from "lodash";
import styled from "styled-components";
import agentIcon from "../../assets/images/agent-icon.svg";
import notificationIcon from "../../assets/images/notifications.svg";
import "../../assets/sass/notification.sass";
import { useComponentVisible } from "../../utils/customHooks";
// import { newTransferChat } from "../../helpers/rxServices";
import { Toast } from "./Toast";
import { getUnassignedChats, updateUnassignedChats } from "../../actions/chatsAction";

interface IChat {
  _id: string;
  state: string;
  messages: string;
}

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .heading {
  }
`;

const ListWrapper = styled.div`
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
`;

const List = styled.ul``;

const Notification: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const fetchUnAssignedChats = useCallback(() => dispatch(getUnassignedChats()), [dispatch]);
  const updateChats = useCallback((chats) => dispatch(updateUnassignedChats(chats)), [dispatch]);
  // const handleBlockLayer = useCallback((payload: boolean) => dispatch(blockLayer(payload)), [dispatch]);
  const ui = useSelector((state: any) => state.ui);
  const chat = useSelector((state: any) => state.chat);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const [counts, setCounts] = useState(0);
  const [transferredChats, setTransferredChat] = useState({});

  const toggleNotification = () => {
    // handleBlockLayer(!isComponentVisible);
    setIsComponentVisible(!isComponentVisible);
  };

  const loadChats = (chats: IChat[]) => {
    const transferredChats = {};
    let counts = 0;
    for (const chat of chats) {
      const { state, messages = [], _id } = chat;
      if (state === "transferred") {
        // const lastUserMsgIndex = _.findLastIndex(messages, (m: any) => {
        //   if (m["fromId"].indexOf("u/") > -1) {
        //     return m["msg"];
        //   }
        // });
        if (messages.length > 0) {
          counts++;
          transferredChats[_id] = Object.assign(chat, {
            lastUserMsg: messages[0],
          });
        }
      }
    }
    setCounts(counts);
    setTransferredChat(transferredChats);
  };

  const handlePickupChat = (chatId: string) => {
    const updatedTransferredChats = { ...transferredChats };
    delete updatedTransferredChats[chatId];
    updateChats(Object.keys(updatedTransferredChats).length > 0 ? [updatedTransferredChats] : []);
    setCounts((prevState) => prevState - 1);
    toggleNotification();
    onPickupChat(chatId);
  };

  useEffect(() => {
    fetchUnAssignedChats();
  }, []);

  useEffect(() => {
    loadChats(chat.chats);
  }, [chat.chats]);
  const { agentChatRouting } = ui;
  const routingAlgorithm = (agentChatRouting && agentChatRouting.routingAlgorithm) || "";
  const showNotification = routingAlgorithm !== "intelligentRouting";
  return (
    <>
      {showNotification && (
        <div className="notification">
          <Toast onClick={toggleNotification} />
          <div style={{ marginTop: "16px" }}>
            <img
              src={notificationIcon}
              className="icon"
              onClick={toggleNotification}
              style={{
                color: isComponentVisible ? "#ff7f43" : "#a8abbd",
                zIndex: isComponentVisible ? 1002 : 100,
              }}
            />

            {/* <BellOutlined
          className="icon"
          onClick={this.showNotification}
          style={{
            color: this.state.isShowNotification ? "#ff7f43" : "#a8abbd",
            zIndex: this.state.isShowNotification ? 1002 : 100,
          }}
        /> */}
            {Object.keys(transferredChats).length > 0 && (
              <span
                className="dot"
                style={{
                  background: "#ff7f43",
                  zIndex: 1003,
                }}
              ></span>
            )}

            <div
              ref={ref}
              className="box"
              style={{
                display: isComponentVisible ? "block" : "none",
              }}
            >
              <div className="arrow"></div>
              <Title className="title">
                <div className="heading">
                  <span className="text">NOTIFICATIONS</span>
                  <span className="counts">{counts}</span>
                </div>
                {/* <FaTimes onClick={toggleNotification} /> */}
              </Title>
              <ListWrapper className="chats" style={{ padding: "0" }}>
                <List>
                  {transferredChats &&
                    Object.keys(transferredChats).map((chat) => (
                      <li key={transferredChats[chat]._id}>
                        <Row>
                          <Col span={2}>
                            <img className="msg-db" src={agentIcon} />
                          </Col>
                          <Col span={21}>
                            <Row className="msg-header">
                              <Col span={13} style={{ paddingLeft: "10px" }}>
                                {transferredChats[chat].lastUserMsg &&
                                  transferredChats[chat].lastUserMsg.fromName.charAt(0).toUpperCase() +
                                    transferredChats[chat].lastUserMsg.fromName.slice(1)}
                              </Col>
                              <Col span={11} style={{ textAlign: "right" }}>
                                {transferredChats[chat].lastUserMsg &&
                                  moment(transferredChats[chat].lastUserMsg.sentAt)
                                    .format("MMM-DD-YYYY HH:mm a")
                                    .toLocaleUpperCase()}
                              </Col>
                            </Row>
                            <Row className="msg-content">
                              <Col span={24} style={{ paddingLeft: "10px" }}>
                                {transferredChats[chat].lastUserMsg &&
                                transferredChats[chat].lastUserMsg.msgType == "text"
                                  ? transferredChats[chat].lastUserMsg.msg.text
                                  : "Rich Text"}
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24} style={{ paddingLeft: "10px" }}>
                                <Button className="pickup" onClick={() => handlePickupChat(transferredChats[chat]._id)}>
                                  PICKUP
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Divider type="horizontal" />
                          </Col>
                        </Row>
                      </li>
                    ))}
                </List>
              </ListWrapper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
